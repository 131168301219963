import {SortDir,SortSpec,DataModelSchema, DataType} from './model/model'
import {generateUUID}   from '../utils/utils'
import { DataModelColumn } from './model/model';
import { arrayMoveImmutable } from 'array-move';



interface ViewColumnJSON  
{
    column_id   : string,
    data_id     : string,
    sort        : number, 
    width       : number,
    options     : { [id: string]: any }
}

interface ViewSortJSON
{
    sort:       Array<{ column_id: string, dir: SortDir }>,
    categories: Array<{ column_id: string, dir: SortDir }>
}

export class ViewColumn 
{
    dataModelColumn:    DataModelColumn
    uuid:               string
    column_id:          string
    data_id:            string
    dir:                SortDir
    width:              number
    name:               string
    kind:               DataType
    auto_filter_id:     string | null
    indicator:          string | null
    unit:               string | null
    static_params:      any
    sub_id:             string | undefined,
    options:            {[id: string] : any}
    entity:             string | null

    use_cat_value: boolean

    visible: boolean

    constructor(dc: DataModelColumn, column_id: string, data_id : string, name: string, indicator: string | null, dir: SortDir, use_cat_value : boolean, width = null) 
    {
        this.uuid       = generateUUID()

        this.dataModelColumn    = dc
        this.column_id          = column_id
        this.data_id            = data_id
        this.dir                = dir
        this.indicator          = indicator
        this.width              = width  || 50     // Null means use the default width of the Column
        this.name               = name
        this.use_cat_value      = use_cat_value
        this.visible            = true
        this.kind               = DataType.String
        this.auto_filter_id     = null
        this.unit               = null
        this.options            = {}
        this.entity             = dc.entity

        const sp = `${column_id}`.split(":")
        if (sp.length == 2)
            this.sub_id     = sp[1]
    }

    clone() {
        const vc                = new ViewColumn(this.dataModelColumn, this.column_id, this.data_id, this.name, this.indicator, this.dir, this.use_cat_value)
        vc.width                = this.width
        vc.visible              = this.visible
        vc.auto_filter_id       = this.auto_filter_id
        vc.unit                 = this.unit
        vc.indicator            = this.indicator
        vc.static_params        = this.static_params
        vc.kind                 = this.kind
        vc.data_id              = this.data_id
        vc.sub_id               = this.sub_id
        vc.options              = this.options
        vc.entity               = this.entity
        return vc
    }

    toJSON(): ViewColumnJSON
    {
        return {
            column_id:      this.column_id,
            data_id:        this.data_id,
            sort:           this.dir,
            width:          this.width,
            options:        this.options
        }
    }

    getServerID()
    {
        return this.dataModelColumn.server_id
    }

    static createViewColumn(viewSchema: ViewSchema, spec: string | ViewColumnJSON ): null | ViewColumn 
    {
        const id = typeof spec == 'string' ? spec : spec.data_id

        const dataSchema = viewSchema.schema
        if (!spec)
            return null
        const dataColumn = dataSchema.getColumn(id)
        if (!dataColumn)
            return null

        let n = 0
        let data_id     = id
        let column_id   = id
        let found_column            = viewSchema.findColumn( column_id )
        while (found_column)
        {
            n++
            column_id                 = `${id}_${n}`
            found_column = viewSchema.findColumn(column_id)
        }

        const viewCol               = new ViewColumn(dataColumn, column_id, data_id, dataColumn.name, dataColumn.indicator, SortDir.None, dataColumn.use_cat_value)
        viewCol.width               = dataColumn.default_width || 150
        viewCol.kind                = dataColumn.kind
        viewCol.auto_filter_id      = dataColumn.auto_filter_id
        viewCol.unit                = dataColumn.unit
        viewCol.static_params       = dataColumn.static_params

        if (typeof spec !== 'string')
        {
            viewCol.dir     = spec.sort
            viewCol.width   = spec.width
            viewCol.options = spec.options
        }

        return viewCol
    }

    setViewColumnOption( id: string, value: any)
    {
        this.options[id] = value
    }
}






export enum HTMLTableColumnType {
    Selection,
    RowHeader,
    Category,
    Normal
}

export enum ViewSectionType 
{
    Root,
    Tab,
    Section,
    Category,
    Normal
}

export interface ViewSection {
    kind: ViewSectionType,
    dir: SortDir,
    view_column: ViewColumn
}


export interface HTMLTableColumn {
    width: number,
    kind: HTMLTableColumnType
}


export class ViewSchema 
{
    schema: DataModelSchema

    tab: ViewColumn | null
    section: ViewColumn | null
    category: ViewColumn[]
    sort: ViewColumn[]
    columns: ViewColumn[]

    dirty: boolean
    viewRenderSchema:   ViewSection[]
    sortOrders:         SortSpec
    htmlTableColumns:   HTMLTableColumn[]


    // These are calculated in the update and are used quite a lot
    view_categories:    ViewSection[]
    view_columns:       ViewSection[]
    first_column_level: number
    first_category_level: number



    constructor(    schema:             DataModelSchema, 
                    initial_columns:    Array<string | ViewColumnJSON>, 
                    initial_sort:       ViewSortJSON? ) 
    {
        this.schema = schema

        this.tab = null
        this.section = null

        this.category = []
        this.sort = []

        this.columns = []

        this.dirty                  = true
        this.viewRenderSchema       = []
        this.sortOrders             = []
        this.htmlTableColumns       = []

        this.view_columns           = []
        this.view_categories        = []
        this.first_column_level     = -1
        this.first_category_level   = -1

        if (initial_columns)
            initial_columns.forEach( c => this.addViewColumn( c ))

        if (initial_sort)
            this.setSortOrder( initial_sort )
    }


    columnSpecJSON() : Array<ViewColumnJSON>
    {
        return this.columns.map( c => c.toJSON() )
    }

    sortSpecJSON() : ViewSortJSON
    {
        const sort         = (this.sort     ?? []).map(c => {return {column_id: c.column_id,  dir: c.dir}})
        const categories    = (this.category ?? []).map(c => {return {column_id: c.column_id,  dir: c.dir }})
        
        return {categories, sort}
    }


    findColumn(id: string): ViewColumn | null 
    {
        for (let col of this.columns) {
            if (col.column_id == id)
                return col
        }
        return null
    }

    addViewColumn(spec: ViewColumnJSON | string): ViewColumn | null 
    {
        const vc = ViewColumn.createViewColumn(this, spec) 
        
        if (!vc)
            return null

        this.columns.push(vc)
        this.dirty = true

        return vc
    }

    removeViewColumn( uuid: string )
    {
        this.columns = this.columns.filter( c => c.uuid != uuid)
        this.dirty = true

//        const found = this.view_columns.find( c => c.uuid = uuid )
//        if (found)
//            found.visible = false

    }

    moveViewColumn(src_position : number ,tgt_position : number )
    {
        let cols = [...this.columns]

        if (src_position < tgt_position)
            cols = arrayMoveImmutable(cols, src_position, tgt_position - 1)
        else if (src_position > tgt_position)
            cols = arrayMoveImmutable(cols, src_position, tgt_position)
        this.columns = cols

        this.dirty = true
    }
    

    setSortOrder( sort: ViewSortJSON )
    {
        if (!sort)
            return 
        if (sort?.sort)
            sort.sort.forEach( (s,i) => this.addSort( s.column_id, s.dir, ( i == 0)) )
        
        if (sort?.categories)
            sort.categories.forEach( (s) => this.addCategory( s.column_id, s.dir ))
    }


    findInSort(id: string) {
        return this.sort.find(c => c.column_id == id)
    }


    findInCategory(id: string) {
        return this.category.find(c => c.column_id == id)
    }


    setTab(id: string | null) {
        this.dirty = true
        if (id == null) {
            if (this.tab) {
                const tabColumn = this.findColumn(this.tab.column_id)
                if (tabColumn)
                    tabColumn.visible = true
                this.tab = null
            }
        }
        else {
            const tabColumn = this.findColumn(id)
            if (tabColumn) {
                this.tab = tabColumn.clone()
                tabColumn.visible = false
            }
        }
    }


    setSection(id: string | null) {
        this.dirty = true
        if (id == null) {
            if (this.section) {
                const sectionColumn = this.findColumn(this.section.column_id)
                if (sectionColumn)
                    sectionColumn.visible = true
                this.section = null
            }
        }
        else {
            const sectionColumn = this.findColumn(id)
            if (sectionColumn) {
                this.section = sectionColumn.clone()
                sectionColumn.visible = false
            }
        }
    }


    toggleSort(id: string, reset: boolean = false) 
    {
        this.dirty = true
        if (reset) 
            this.sort = []

        const col = this.findInSort(id)

        if (!col) 
            this.addSort( id, SortDir.Up, true )
        else
            col.dir = col.dir == SortDir.Down ? SortDir.Up : SortDir.Down
    }


    addSort(id: string, dir: SortDir, reset: boolean = false) 
    {
        this.dirty = true
        if (reset) {
            this.sort = []
        }
        const col = this.findInSort(id)
        if (!col) {
            const vc = this.findColumn(id)
            if (vc) {
                const svc = vc.clone()
                svc.dir = dir
                this.sort.push(svc)
            }
        }
    }


    addCategory(id: string, dir: SortDir) 
    {
        this.dirty = true
        const col = this.findInCategory(id)
        if (!col) {
            const vc = this.findColumn(id)
            if (vc) {
                const svc = vc.clone()
                svc.dir = dir
                this.category.unshift(svc)
                vc.visible = vc.use_cat_value
            }
        }
    }

    removeCategory( id: string )
    {
        this.dirty = true
        this.category = this.category.filter( c => c.column_id != id )
        const vc = this.findColumn(id)
        if (vc) 
            vc.visible = true

    }


    update() 
    {
        if (!this.dirty)
           return

        this.viewRenderSchema   = this.createViewRenderSchema()
        this.sortOrders         = this.createSortOrders(this.viewRenderSchema)
        this.htmlTableColumns   = this.createTableColumns(this.viewRenderSchema)



        this.view_categories        = []
        this.view_columns           = []
        this.first_column_level     = -1
        this.first_category_level   = -1

        const rc = this.viewRenderSchema
        for (let level = 0; level < rc.length; level++ ) 
        {
            if (rc[level].kind == ViewSectionType.Category)
            {
                this.view_categories.push(rc[level])
                if (this.first_category_level == -1)
                   this.first_category_level = level
            }

            if (rc[level].kind == ViewSectionType.Normal)
            {
                this.view_columns.push(rc[level])
                if (this.first_column_level == -1)
                    this.first_column_level = level
            }

        }
        
        this.dirty = false
    }



    createViewRenderSchema(): ViewSection[] 
    {
        const found: { [id: string]: boolean } = {}
        const parts: ViewSection[] = []


        if (this.tab) {
            parts.push({ view_column: this.tab, dir: SortDir.Up, kind: ViewSectionType.Tab })
            found[this.tab.column_id] = true
        }

        if (this.section) {
            const { column_id } = this.section
            if (!found[column_id]) {
                parts.push({ view_column: this.section, dir: SortDir.Up, kind: ViewSectionType.Section })
                found[column_id] = true
            }
        }

        for (let cat of this.category) {
            const { column_id, dir } = cat
            if (!found[column_id]) {
                parts.push({ view_column: cat, dir: cat.dir, kind: ViewSectionType.Category })
                found[column_id] = true
            }
        }

        for (let col of this.columns) 
        {
            if (col.visible)
                parts.push({ view_column: col, dir: col.dir, kind: ViewSectionType.Normal })
        }

        return parts
    }



    //  export interface ColumnSort {
    //      column_id: string,
    //      dir: SortDir,
    //      new_level: boolean,
    //      use_cat_value: boolean,
    //      detail_sort: boolean
    //  }
    //  
    //  export type SortSpec = ColumnSort[]





    createSortOrders(sections: ViewSection[]): SortSpec 
    {

        const so: SortSpec = []

        function defDir(dir: SortDir) {
            if (dir == SortDir.None)
                return SortDir.Up
            else
                return dir
        }


        for (let section of sections) 
        {
            const catVal             = section.view_column.use_cat_value
            const { column_id, dir } = section.view_column
            if (section.kind != ViewSectionType.Normal)
                so.push({ column_id: column_id, dir: defDir(dir), new_level: true, use_cat_value : catVal })
            else
                break
        }

        const sortSpec = this.sort || []
        
        for (let col of sortSpec)
        {
            const { column_id, dir } = col
            const found = so.find(s => s.column_id == col.column_id)
            if (!found)
                so.push({ column_id: column_id, dir: defDir(dir), new_level: false, use_cat_value : false })
        }

        return so
    }



    createTableColumns(sections: ViewSection[]): HTMLTableColumn[] {
        const found: { [id: string]: boolean } = {}
        const tc: HTMLTableColumn[] = []

        tc.push({ width: 15, kind: HTMLTableColumnType.Selection })
        tc.push({ width: 15, kind: HTMLTableColumnType.RowHeader })


        for (let section of sections) {
            if (section.kind == ViewSectionType.Category)
                tc.push({ width: 10, kind: HTMLTableColumnType.Category })

            if (section.kind == ViewSectionType.Normal) {
                const width = section.view_column.width == null ? 0 : section.view_column.width
                tc.push({ width: width, kind: HTMLTableColumnType.Normal })
            }
        }

        return tc
    }
}






