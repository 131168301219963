import React, { useState, useRef, useContext, Fragment } from 'react'
import { DBContext } from '../app/DBContext'
import { useRouter } from '../hooks/useRouter'
import { AppFrame,AppFrameLoading } from '../app-frame/AppFrame'
import {
	ComponentContainer, ComponentMenuTop, ComponentPanelLeft, 
	ComponentPanelExpand, ComponentPanelRight
} from '../app-frame/AppFrame'

import { isDragSource } from '../dnd/isDragSource'
import { SortListItem } from '../dnd/SortableList'

import { GUIController } from '../controller/guiController'
import { ControlledComponent } from '../controller/guiController'
import { GUIStateContext } from '../app/GUIContext'

import { arrayMoveImmutable } from 'array-move';

import {Form, TextBox, displayLineValue, DateTime} 			from '../form/Form'

import {post} 					from '../hooks/useDownloaded'
import {blank, fix_id} 					from '../utils/utils'

import {renderProcessFormEdit, 
	SPLineEdit, 
	stepParameterTypes} from './StepKinds'


const TestProcessForm = ({template}) =>
{

	const result = []
	template.params.forEach( (p, i ) => 
	{
		if (p.deleted)
			return
		const formControl = renderProcessFormEdit( p )

		result.push(<div 	key={`line-${i}`}
							className='line'>
						<div className='field-name'>{p.title}</div>
						<div className='control'>
							{formControl}
						</div>
						<div className='unit'>
							{p.unit}
						</div>
					</div>)
	})

	return 	<ComponentPanelRight key='blank' className={`step-edit-list wide process-details ${open ? 'open' : 'closed'}`}>
				<div className='head'>
					Test Form
				</div>
				<div className='body'>
					<div className='form-contents process-form'>
						<div className='formtable'>
							<Form data={ {} }>{result}</Form>
						</div>
					</div>
				</div>
			</ComponentPanelRight>
}



const StepEditor = ({ template, param, open, setOpen, position, onDrop}) => 
{
	const key = param.is_new ? param.open_key : `${template.id}:${param.id}`
	const is_open = open[key] == true || param.is_new && open[key] === undefined

	const toggle_open = (_) => setOpen( key, !is_open )

	const def 		= stepParameterTypes.get( param.kind )

	if (!def)
		return null

	const [{isDragging, item}, dragRef] = isDragSource({
		type: 'STEP-PARAMETER',
		name: `${param.name}`,
		indicator: 'step-kind',
		item: () => ({position: position, param, template})
	})
 

	const { used, deleted } = param

	const { controller } = useContext(GUIStateContext)
	const onChange = (value) => 
	{
		controller.setParameter( template, position, value )
	}

	const deleteStep = (e) => {
		controller.toggleDelete( template, position )
	}

//	const changeKind = (e) => {
//		const kind = e.target.value	
//		controller.changeKind( template, position, kind )
//	}
//

//
//	const options 	= stepParameterTypes.allTypesAsList().map( x => <option key={x.id} value={x.id}>{x.definition.name}</option>)
//	const selection = <select onChange={changeKind} value={param.kind}>{options}</select>
//
//	const kind_indicator = used ? param.kind : selection
	const kind_indicator 	= param.kind

	const icon 		= deleted ? 'deleted' : ''

	return 	<SortListItem key={key} accept='STEP-PARAMETER' position={position} onDrop={onDrop}>
				<div className={`step-section ${is_open ? 'open' : 'closed'} ${isDragging ? 'dragging' : ''} ${deleted ? 'deleted' : ''}`}>
					<div className='head'  ref={dragRef}>
						<div className='draghandle edit'>< span className=' fa-regular fa-grip-vertical'>&nbsp;</span></div>
						<div className='icon' onClick={toggle_open}>
							{!is_open && <div className='indicator open fa-caret-right fa' />}
							{ is_open && <div className='indicator open fa-caret-down fa' />}

						</div>
						<div className='content'>{param.title}&nbsp; {used}</div>
						<div className='kind'>{kind_indicator}</div>
						<div className={`delete  ${icon}`} onClick={deleteStep}>
							<span className='icon  field-clear-button'>✕</span>
						</div>
					</div>
					{is_open && 
						<div className={`body`}>

							<div className='form-contents details'>
								<div className='formtable'>

								{deleted && 
									<div className='warning'>
									{ used && <span>This parameter has been used in experiments. <br/>On save it will only be visible in experiments that have used it before.</span>}
									{!used && <span>This parameter has never been used in any experiment. <br/>On save it will be deleted.</span>}
									</div>
								}

								{def && def.renderParamEdit(template, param, onChange, used)}
								</div>
							</div>
						</div>
					}
				</div>
			</SortListItem >
}





const StepShow = ({ template, param, open, setOpen }) => 
{
	const key = `${template.id}:${param.id}`
	const is_open = open[key] != false

	const toggle_open = (_) => setOpen(key, !is_open)

	const def = stepParameterTypes.get(param.kind)

	if (!def)
		return null

	const { used, deleted } = param

	const icon = deleted ? 'deleted' : ''

	if (deleted)
		return null



	return <div className={`step-section ${deleted ? 'deleted' : ''}`}>
				<div className='head'>
					<div className='draghandle'>&nbsp;</div>
					<div className='icon' onClick={toggle_open}>
						{!is_open && <div className='indicator open fa-caret-right fa' />}
						{is_open && <div className='indicator open fa-caret-down fa' />}

					</div>
					<div className='content'>{param.title}</div>
					<div className='kind'>{param.kind}</div>
					<div className={`delete  ${icon}`}><span  /></div>
				</div>
				{is_open &&
					<div className={`body`}>
						<div className='form'>

							{def && def.renderParamShow(template, param)}
						</div>
					</div>
				}
			</div>
	}

const ProcessParameterEditor = ({template}) =>
{
	const {controller} = useContext(GUIStateContext)

	const change = (data) => {
		controller.setProcessParameter(template, data)
	}


	return 	<div className='step-section step-header'>
				<div className='body'>
			
					<Form onChange={change} data={template} key={template.id} >
						<SPLineEdit id='title' title='Title'>
							<TextBox name='title' required={true} disabled={template.used}/>
						</SPLineEdit>
						<SPLineEdit id='abbr' title='Short Title'>
							<TextBox name='abbr' required={true} min_length={3} max_length={6} />
						</SPLineEdit>
						<SPLineEdit id='abbr' title='Group Name'>
							<TextBox name='group_name' required={false} />
						</SPLineEdit>
					</Form>
				</div>
			</div>
}

const ProcessEditor = ({ subject, template, open, setOpen, ...props}) =>
{
	const router = useRouter()
	const result = []

	const {controller} = useContext(GUIStateContext)

	const onDrop = (item, position) => 
	{
		if (item.position != position)
			controller.moveParameterTo( template, item.position, position )
	}


	result.push( <ProcessParameterEditor key='master' template={template} />)

	result.push(<div key='ptitle' className='section'>
						<div className='content'>Parameters</div>
					</div>)

	const {success, errors} = controller.canSave( template )

	template.params.forEach((p, i) => 
	{		
		
		result.push(<StepEditor  position={i} key={`param-${p.id}-${i}`} onDrop={onDrop}
			param={p}
			template={template}
			open={open}
			setOpen={setOpen} />)
	})

	const onSave = async (e) => 
	{	
		const {id, errors} = await controller.saveProcess( template )		
		if  (id)
			router.goto( `/step/show/${id}`)
	}

	const onCancel = (e) => {
		router.goto(`/step/show/${template.id}`)
	}

	return 	<ComponentPanelExpand className={`step-edit-list parameters ${props.className}`}>
				<div className='head' >
					<span>{template.title}</span>
				</div>

				<div className='body'>
					<div className='form-contents'>
						{errors && errors.map( (e,i) => <div key={`e-${i}`} className='error'>{e}</div>)}
						{result}
					</div>
				</div>
				<div className='form-actions'>
					<button onClick={onSave} disabled={!success}>
						Save
					</button>

					<button onClick={onCancel}>
						Cancel
					</button>
				</div>
			</ComponentPanelExpand>
}



const ParamTableShow = ({ template, open, setOpen, ...props }) => {
	const result = []

	const router 	= useRouter()

	template.params.forEach((p, i) => {
		result.push(<StepShow position={i} key={`param-${p.id}-${i}`}
								param={p}
								template={template}
								open={open}
								setOpen={setOpen} />)
						}) 

	const onEdit = (e) => {
		router.goto( `/step/edit/${template.id}` )
	}


	return 	<ComponentPanelExpand className={`step-edit-list parameters ${props.className}`} key={template.id}>
				<div className='head' >
					<span>{template.title}</span>
				</div>

				<div className='body'>
					<div className='step-section step-header'>
						<div className='body'>
							<Form  data={template} key={template.id}>
								<SPLineEdit id='title' title='Title'>
									<TextBox disabled name='title' required={true} />
								</SPLineEdit>
								<SPLineEdit id='abbr' title='Short Title'>
									<TextBox disabled name='abbr' required={true} min_length={3} max_length={6} />
								</SPLineEdit>
								<SPLineEdit id='abbr' title='Group Name'>
									<TextBox disabled required={false} name='group_name' />
							</SPLineEdit>
							</Form>
						</div>
					</div>
					<div className='section'>
						<div className='content'>Parameters</div>
					</div>
					{result}
				</div>
				<div className='form-actions'>
					<button onClick={onEdit}>
						Edit
					</button>
				</div>
			</ComponentPanelExpand>
}

const StepButton = ({id, selected, select_step, active }) =>
{
	const processes = useContext(DBContext)?.processes
	if (!processes)
		return null
	const step 		= processes.allProcessTemplates[id]
	const select	= (e) => {if (active) select_step( id )}


	return <div onClick={select} 
				className={`button ${selected ? 'selected' : ''} step-button step-edit-button`}>
					<div className='title'>{step.title}</div></div>
}


const StepKindButton = ({step_kind, template}) =>
{
	const { controller } = useContext(GUIStateContext)
	const addParameter 	 = () =>
	{
		if (controller && template)
			controller.addParameter( template, step_kind.id )
	}

	return 	<div className={`button step-button step-kind-button`}>
				<div className='title'>{step_kind.definition.name}</div>
				<div className='arrow>' onClick={addParameter}><span className='fa-regular fa-angle-right'/></div>
			</div>
}


const StepKindTable = (props) => 
{
	return 	<ComponentPanelLeft className={`step-kind-list process-list  ${props.className}`}>
				<div className='head'>
					<span>Parameter Types</span>
				</div>
				<div className='body'>
					<div className='button-container'>
						{	
							stepParameterTypes.allTypesAsList().map( sk => 
									<StepKindButton key={`step-s${sk.id}`} step_kind={sk} template={props.template}/>
							)
						}
					</div>

				</div>
			</ComponentPanelLeft>
}





const StepTable = ({steplist, selected, select_step, active, create_step, ...props}) =>
{
	const exp = steplist.experiment
	const sol = steplist.solution

	return 	<ComponentPanelLeft className={`step-edit-list process-list ${props.className}`}>
				<div className='head'>
					<span>Steps</span>
				</div>
				<div className='body'>
					<div className='section'>
						<div className='content'>Experiments</div>
						<div className='icon' onClick={create_step( 'experiment')}><i className='fa-regular fa-plus' /></div>
					</div>
					<div className='button-container'>
						{exp.map( id => <StepButton key={`step-s${id}`}
												selected={selected == id} select_step={select_step} id={id} active={active} />)}
					</div>
					<div className='section'>
						<div className='content'>Solutions</div>
						<div className='icon' onClick={create_step( 'solution')}><i className='fa-regular fa-plus'/></div>
					</div>
					<div className='button-container'>
						{sol.map(id => <StepButton key={`step-s${id}`}
												selected={selected == id} select_step={select_step} id={id} active={active} />)}
					</div>
				</div> 
			</ComponentPanelLeft>
}



export const StepSubjectEditor = ({ params }) => 
{
	const router = useRouter()

	const [open, change_open] = useState({})
	const setOpen = (key, state) => {
		const new_open = { ...open }
		new_open[key] = state
		change_open(new_open)
	}

	const { id, subject } = params

	const processes = useContext(DBContext)?.processes
	if (!processes || !processes.allProcessTypesID)
		return <div />


	let component_type = 'show'
	const location = router.location.pathname
	if (location.startsWith('/step/edit/') && id)
		component_type = 'edit'
	else if (location.startsWith('/step/create') && subject)
		component_type = 'create'

	const steplist = processes.allProcessTypesID


	const { controller } = useContext(GUIStateContext)


	let template = null
	if (component_type == 'create')
		template = controller.newProcess(subject)
	else
		template = processes.allProcessTemplates[id]

	const set_step = (step_id) => router.goto( `/step/show/${step_id}` )
	const create_step = (subject) => (e) => router.goto(`/step/create/${subject}`)

	return 	<ComponentContainer className='l-r'>
				{template && (component_type == 'edit' || component_type == 'create') && 
						<StepKindTable template={template}/>}
				{component_type == 'show' && <StepTable  steplist={steplist} selected={id} 
							create_step={create_step}
							select_step={set_step}  step={id} active={component_type == 'show'}/>}
				{ template && (component_type == 'edit' || component_type == 'create') &&
						<ProcessEditor 
								key={`param-table`}
								template={template} 
								open={open} 
								setOpen={setOpen} /> }
				{template && component_type == 'show' &&
						<ParamTableShow
								key={`param-table${controller.count}`}
								template={template}
								open={open}
								setOpen={setOpen} />}
				{!template && <ComponentPanelExpand className={`step-edit-list parameters`}>
							<div className='head' >
								<span>Steps</span>
							</div>
							<div className='body'>
								<div className='helptext'>Select an step in the panel to edit</div>
							</div>
						</ComponentPanelExpand>
				}
				{template && <TestProcessForm template={template} />}
			</ComponentContainer>
				
}




export class StepController extends GUIController
{
	constructor( db_context, processes )
	{
		super()
		this.processes 			= processes
		this.db_context 		= db_context

		this.new_process 		= null
	}



	allProcessTypes 	= () 		=> this.processes.allProcessTypes
	processTemplates 	= (id) 		=> this.processes.allProcessTemplates[id]


	addParameter( template, kind )
	{
		if (!template)
			return

		const new_param = {
			name: 		'',
			title: 		'',
			kind: 		kind,
			subkind: 	'',
			required: 	false,
			unit: 		'',
			deleted: 	false, 
			is_new: 	true,
			open_key: 	`${new Date().getTime()}`
		}
		
		template.params.push( new_param )
		
		this.update()
	}

	setParameter( template, position, value )
	{
		template.params[position][value.name] = value.value
		this.update()
	}

	setProcessParameter( template, value )
	{
		template[value.name] = value.value
		this.update()
	}

	moveParameterTo( template, src_position, tgt_position )
	{
	
		if (src_position < tgt_position)
			template.params = arrayMoveImmutable( template.params, src_position, tgt_position - 1)
		else if (src_position > tgt_position)
			template.params = arrayMoveImmutable(template.params, src_position, tgt_position )
		
				template.params.forEach( (p,i) => p.sequence = i)
		this.update()
	}

	changeKind( template, position, kind )
	{
		const param = template.params[position]
		if (param)
			param.kind = kind


		this.update()
	}

	toggleDelete( template, position )
	{
		const param = template.params[position]
		if (param)
			param.deleted = !param.deleted

		this.update()
	}

	newProcess(subject)
	{
		if (!this.new_process)
			this.new_process = 	{
									title:	 '',
								 	abbr:	 '',
									group_name: '',
								 	id:	 	 '',
								 	subject: subject,
								 	params:	 [], 
									 
									used: 	 false
								}
		return this.new_process
	}

	canSave( template )
	{
		const errors 	= []
		let success 	= true

		template.params.forEach(p => {
			if (!p.used)
				p.name = fix_id(p.title)
		})

		if (template.params.length == 0)
			errors.push( "No parameters defined")

		if (blank( template.title ))
			success = false
		if (template.abbr && (template.abbr.length > 9 || template.abbr.length < 2))
			success = false
		if (template.group_name && (template.group_name.length < 3))
			success = false
		
		const all_param_titles = template.params.map( p => p.title )
		let found_duplicate = false

		
		all_param_titles.forEach( (p,i) => {
			for( let c = 0; c < all_param_titles.length; c++ )
				if (all_param_titles[c] == p && i != c)
					found_duplicate = true
		})

		const all_param_ids = template.params.map(p => p.name)
		all_param_ids.forEach((p, i) => {
			for (let c = 0; c < all_param_ids.length; c++)
				if (all_param_ids[c] == p && i != c)
					found_duplicate = true
		})

		
		if (found_duplicate)
			errors.push(`Duplicate Parameter Names`)



		template.params.forEach( (p,i) => 
		{
			if (blank( p.title ))
				success = false
		})


		if (errors.length > 0 || !success)
			return {success: false, errors}
		else
			return {success: true}

	}

	async saveProcess( template )
	{
		template.params.forEach( p => 
		{
			if (!p.used)
				p.name = fix_id( p.title )
		})



		const id 	= template.id ? `/${template.id}` : ''
		const url = `/step/save${id}`

		const result = await post( url, { step: template }) 

		this.db_context.reloadGlobals()

		if (result.data.success)
		{
			this.new_process = null

			return result.data
		}
		else
			return result.data

	}
}



export const StepFrame = () => 
{
	const {params} 						= useRouter()
	const [controller, set_controller] 	= useState(null)
	const dbc 							= useContext(DBContext)

	const processes = dbc?.processes
	if (!dbc || !processes || !processes.allProcessTypes)
		return <AppFrameLoading/>


	if (!controller) {
		set_controller(new StepController(dbc, processes))
		return <AppFrameLoading/>
	}

	return 	<AppFrame search_bar={true} highlight='step'>
				<ComponentMenuTop>
					<div className='main-section'>
					</div>
				</ComponentMenuTop>

				<ControlledComponent key='ctrl' controller={controller}>
					<StepSubjectEditor params={params}/>
				</ControlledComponent>
			</AppFrame>
}

