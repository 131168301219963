import React, { Component,Fragment }			from "react";

import {Tooltip} 	from 'antd';

import {DatePicker, Popconfirm} 	from 'antd'

import {blank, 			  null_or_undef, make_list, 
		isNum, fixDuration, formatTime, parseTime, timeRegex, formatTimeStamp,
		trimmed_or_blank, always_to_string}		from "../utils/utils"

import Dropzone from 'react-dropzone'

import ReactSelect from 'react-select';

import { FormContext } from "./FormContext";

import {Select as AntSelect} from "antd"
const { Option } = AntSelect;

import { Input } from 'antd'

import dayjs 	from 'dayjs'



const UP 		= 38
const DOWN 		= 40
const ENTER		= 13
const ESC 		= 27
const SPACE 	= 32


export function timeConverter(t) {
	if (t == null || t == undefined)
		return ''

	const p0 = (x) => `00${x}`.slice(-2)
	var a = new Date(t * 1000);
	var year = a.getFullYear();
	var month = p0(a.getMonth() + 1);
	var date = p0(a.getDate());
	var hour = p0(a.getHours());
	var min = p0(a.getMinutes());
	var sec = p0(a.getSeconds());
	var time = `${year}/${month}/${date} ${hour}:${min}:${sec}`;
	return time;
}



export const FOCUS_NORMAL 	= 0
export const FOCUS_ARROWS	= 1

export class FormComponent extends Component
{
	static contextType = FormContext;

	constructor(props)
	{
		super(props)

		this.state = {
			editing 	: false
		}

		this.focusRef 	= React.createRef()
	}


	isFocused()
	{
		if (this.focusRef && this.focusRef.current)
			return this.focusRef.current === document.activeElement
		else
			return undefined
	}

//	focusHTML()
//	{
//		if (this.focusRef && this.focusRef.current)
//			this.focusRef.current.focus()
//	}
//
//	componentDidUpdate( prevProps )
//	{
//		return
//		const form = this.context.form
//
//		if (this.focusModel !== FOCUS_NORMAL && form.props.autoFocus)
//		{	
//			if (this.props.autoFocus)
//				this.focusHTML()
////			else if (this.focusIndex === form.state.focusedIndex)
////				this.focusHTML()
//		}
//	}
//
//	componentWillMount()
//	{
//		return
//		if (!this.context)
//			return 
//		const form = this.context.form
//
//		this.focusIndex = form.addFocusableComponent(this)
//		this.focusModel = form.focusModel
//
//		if (this.props.autoFocus)
//			this.focusHTML()
//	}


	componentWillMount()
	{
		this.setInitialDefault()
	}


	setInitialDefault()
	{
		const form = this.context.form

		if (this.props.default_value !== null && this.props.default_value !== undefined)
			form.setInitialDefault(this.props.name, this.props.default_value)
	}

	setInitialRaw( raw )
	{
		const form = this.context.form
		form.setInitialRaw( this.props.name, raw, typeof(raw) )
	}



	keyHandler = (event) =>
	{
		const form = this.context.form
		const code = event.keyCode

		if (this.focusModel === FOCUS_ARROWS)
		{
			if (code === UP) //&& !this.state.editing)
			{
				//form.changeFocus( -1 )
			}
			if (code === DOWN) //&& !this.state.editing)
			{
				//form.changeFocus(  1  )
			}
			if (code === ENTER) 
			{
					form.changeFocus( 1 )
			}
			else if (code === ESC) 
			{
				event.preventDefault()
				event.stopPropagation()
				this.setState({ editing: false })
			}
		}
		else if (this.submitOnEnter)
		{
			if 		(code === ENTER)
			{
				const form = this.context.form
				form.manualSubmit()
			}
			else if (code === ESC)
			{
				const form = this.context.form
				form.manualCancel()
			}
		}
	}

	getKeyPressHandler()
	{
		if (this.focusModel != FOCUS_NORMAL)
			return this.keyHandler
		else
			return null 			// Browser handles the goods
	}


	focusHandler = (e) => 
	{
		const form = this.context.form
		form.setFocus( this.focusIndex)
	}


	blurHandler = (e) =>
	{
		const form = this.context.form
		form.setFocusBlurred(this.focusIndex)
		this.setState( {editing : false} )
	}


	transformValue(v)
	{
		if (this.props.transform && v !== null && v !== undefined)
			return this.props.transform(v)
		else
			return v
	}

	setFieldValue( value, raw_value = null, skipLive = false)
	{
		if (!this.context || !this.context.form || this.props.onChange)
		{
			console.log( "WE RUNNING ON onChange now", this.props.name )
			if (this.props.onChange)
				this.props.onChange(this.props.name, value, raw_value, skipLive)
			else
				console.warn( `Set Value on field ${this.props.name} not embedded in form`)
		}
		else
			this.context.form.setFieldValue(this.props.name, value, raw_value, skipLive )

	}


	getFieldValue( default_value = null)
	{
		let v = null

		// If the control is not in a form it uses the value and rawvalue properties
		if (!this.context || !this.context.form)
			v = this.props.value
		else
			v = this.context.form.getFieldValue( this.props.name, default_value )

		if (!v)
			return default_value

		return this.transformValue( v )
	}

	getRawValue()
	{	
		// If the control is not in a form it uses the value and rawvalue properties
		if (!this.context || !this.context.form)
			return this.props.rawValue || this.props.value
		else
			return this.context.form.getRawValue( this.props.name )
	}





	getClassName( classNames, disabled )
	{
		const hasErrors = this.context?.form ? this.context.form.fieldHasErrors( this.props.name ) : false

		const extra_classes = classNames ? classNames.filter( x => !blank(x)).join(" ")
									     : ''		
		const raw = this.getRawValue()
		const empty_error = blank(raw) && this.props.required

		const errorClass 	= hasErrors || empty_error? this.props.errorClass 		 || 'error' : ''
		const disableClass 	= disabled === true  ? this.props.disableClass || 'disabled' : ''
		const enableClass   = disabled === false ? this.props.enableClass  || 'enabled'  : ''
		const mainClass    = always_to_string( this.props.className )
		return `${mainClass} ${errorClass} ${enableClass} ${disableClass} ${extra_classes}`
	}


	evaluate_enabled_boolean( off_prop, on_prop, none_value = true)
	{
		if (!on_prop || !off_prop)
			return none_value

		if (off_prop == 'disabled' && this.props.disableOnError)
		{
			if (this.context.form.formHasErrors())
				return false
		}

		if (this.props[on_prop] == undefined && this.props[off_prop] == undefined)
			return none_value

		const invert 	= this.props[off_prop] !== undefined
		
		const prop = invert ? off_prop : on_prop
		let exp = this.props[prop]

		if (exp === undefined)
			return none_value
		if (exp === true)
			return !invert
		if (exp === false)
			return  invert

		if (typeof exp === 'string')
		{			
			if (exp == 'true')
				return !invert
			if (exp == 'false')
				return  invert


			const split = exp.split("=").map( x => trimmed_or_blank(x))

			if (split.length == 2)
				exp = (data) => (data[split[0]] === split[1] )
			else if (split.length == 1)
				exp = (data) => !!(data[split[0]])
			else
				exp = (x) => false
		}

		const shown = exp( this.context.form.getData())

		if (invert)
			return !shown
		else
			return  shown
	}

}


export class Error extends FormComponent
{
	render()
	{
		if (!this.context || !this.context.form)
			return <div></div>

		let {names, name}	= this.props
		if (blank( names ) && blank( name ))
			return <div></div>

		const all_errors 	= this.context.form.getErrors()
		if (!all_errors)
			return <div></div>

		if (name)
			names = [name]
		else
			names = make_list( names, [] )

		if (!names || names.length == 0)
			return <div></div>

		const errors = []
		names.forEach( (n) =>
		{
			if (all_errors[n])
			{
				all_errors[n].forEach( e => errors.push(e) )
			}
		})

		if (this.props.errors)
		{
			if (Array.isArray(this.props.errors))
				this.props.errors.forEach( e => errors.push(e))
			else
				errors.push(this.props.errors)
		}


		if (errors.length > 0 && false)
			return 	<div className={this.props.className || 'error'}>
						{[...new Set(errors)].map( (err,i) => <div key={`err-${i}`}>{err}</div>)}
					</div>
		else if (errors.length > 0)
		{
			const messages = [...new Set(errors)].map((err, i) => 
									<div key={`err-${i}`}>{err}</div>)

			return 	<div className='error'>
						<Tooltip placement='bottom' title={messages}  >
							<i className='fa-regular fa-triangle-exclamation'/>
						</Tooltip>
					</div>
		}
		else
			return 	<div className='error'>&nbsp;</div>
	}
}


export class HideShow  extends FormComponent
{
	constructor(props)
	{
		super(props)
	}

	render()
	{
		const show = this.evaluate_enabled_boolean( 'hide', 'show', true )

		return 	<Fragment>
					{show && this.props.children}
				</Fragment>
	}
}

export class CheckBox  extends FormComponent
{
	constructor(props)
	{
		super(props)
		this.html_id = props.id ?   `${props.id}` : 
									`${(new Date()).getTime()}${Math.random()}`.replace(".", "")
	}

	componentWillMount()
	{
		super.componentWillMount()
		const {required, name} 			= this.props
		const {form}					= this.context

		if (required)
			form.addRequired( name, this.props.name )
	}




	onChange = (event) => {
		const checked 	= event.target.checked
		this.setFieldValue(  checked, `${checked}`, this.props.liveSubmit !== false )
	}

	onClick = (event) => {
		event.stopPropagation()
		let checked = !this.getCheckboxValue()
		this.setFieldValue( checked, `${checked}`, this.props.liveSubmit !== false)
	}

	
	onKeyClick = (event) => {
		const code = event.keyCode


		if (code == ENTER || code == SPACE)
		{
			event.stopPropagation()
			event.preventDefault()
			this.onClick( event )
		}
		else
		{
			const kp = this.getKeyPressHandler()
			if (kp)
				kp(event)
		}

	}

	getCheckboxValue()
	{
		if (!this.context)
			return this.props.default_value

		let val = this.context.form.getFieldValue(this.props.name, null)

		if (val === null || val === undefined)
			return  this.props.default_value
		else
			return val == true
	}
	

	render()
	{
		const disabled 	= !this.evaluate_enabled_boolean( 'disabled', 'enabled', true )

		let checked = !disabled && this.getCheckboxValue()
		checked = !(checked == undefined || checked == null || checked == false)

		let classNames = []
		if (this.props.checkClass)
		{
			classNames = checked ? [this.props.checkClass,'active']
								 : [this.props.checkClass, 'inactive']
		}
		else if (this.props.activeClass || this.props.inactiveClass)
			classNames = checked ? [this.props.activeClass]
								 : [this.props.inActiveClass]
		else		
			classNames = checked ? ['fa-regular fa-check-square', 'checkbox']
								 : ['far fa-square',      'checkbox']

		let check_control = null
		if (this.props.type !== 'checkbox')
			check_control = 	<span  tabIndex='0' id={this.html_id}
									key={`check-${this.html_id}`}

									onKeyDown={this.onKeyClick}
									onFocus={this.focusHandler}
									onBlur={this.blurHandler}

									className={this.getClassName(classNames, disabled)} 
									onClick={disabled ? null : this.onClick }/>
		else
			check_control = <input 	name={this.props.name}
									className={this.getClassName([], disabled)}
									disabled={disabled}
									id={this.html_id}

									onKeyDown={this.getKeyPressHandler()}
									onFocus={this.focusHandler}
									onBlur={this.blurHandler}

									checked={checked}
									onChange={this.onChange}
									type='checkbox' />


		const labelClass = this.getClassName(["checkbox-label", always_to_string(this.props.labelClass)], disabled)

		let labelContents = null
		if (this.props.children) 
			labelContents = this.props.children
		else if (!blank(this.props.label))
			labelContents = this.props.label

		let label = null
		if (labelContents)
		{
			if (this.props.type === 'checkbox')
				label = <label  className={labelClass} 
								key={`label-${this.html_id}`} 
								htmlFor={this.html_id}>
							{labelContents}
						</label>
			else
				label = <span 	onClick={disabled ? null : this.onClick}
								className={labelClass}
								key={`label-${this.html_id}`}>
							{labelContents}
						</span>
		}


		return <Fragment>{check_control}{label}</Fragment>
	}
}



// We have multiple ways of creating selections, this is the base class of them all
class MultiSelector 	 extends FormComponent
{
	static contextType = FormContext;

	constructor(props) {
		super(props)
	}

	componentWillMount() 
	{ 
		super.componentWillMount()
		const { required, name } = this.props
		const form = this.context?.form

		if (required && form)
			form.addRequired(name, this.props.name)
		const multi = this.props.multiple == true

		let v 	= this.getFieldValue()



		if (!v)
		{
			this.json_value = this.props.multi === true || this.props.list === true
			this.setInitialRaw( multi ? [] : '')
		}
		else if (Array.isArray(v))
		{
			if (!multi)
				throw( `Multi is not set but input to select is an Array  ${this.props.name}`)

			const lv = v.map(e => trimmed_or_blank(e))
						.filter(e => e !== null)

			this.setInitialRaw( lv)
			this.json_value = true
		}

		// From here on the given value is a string

		else if (!multi)
		{	

			// If an array was given and multi is off, this is probably an error
			this.setInitialRaw(`${v}`.trim())
			this.json_value = false
		}
		else // Must be a multi string value
		{
			this.json_value = false

			v = `${v}`.trim()

			if (v.length == 0)
				this.setInitialRaw( [] )
			else
				this.setInitialRaw(v.split(",").map(e => trimmed_or_blank(e))
											   .filter( e => e !== null))
		}
	}



	setValueList( list )
	{
		if (!list)
			this.setValue( this.props.default_value )
		
		if (!this.json_value)
			this.setFieldValue( list.join(","), list)
		else
			this.setFieldValue( list, list)
	}




	makeOptionList(options) {
		if (!options)
			return []

		if (Array.isArray(options)) 
		{
			const result = []
			options.forEach(opt => {
				if (typeof opt == 'string') {
					const full_opt = trimmed_or_blank(opt, '' )
					if (full_opt)
						result.push({ label: full_opt, value: full_opt })
				}
				else {
					let label = opt.label
					let value = opt.value

					if (!blank(label) || !blank(value)) {
						if (blank(label))
							label = value
						if (blank(value))
							value = label

						result.push({
							label: trimmed_or_blank(label, '-'),
							value: trimmed_or_blank(value, '')
						})
					}
				}
			})
			return result
		}
		else if (typeof options == 'string') {
			return this.makeOptionList(options.split(","))
		}
		else {
			const result = []
			for (let value in options)
				result.push({ label: trimmed_or_blank(options[value], ''), value: value })
			return result
		}
		return []
	}



	createOptionList(options, never_empty = false) 
	{
		const optionlist = this.makeOptionList(options)
		const null_option_pos 	 = optionlist.indexOf( s => blank(s))

		if (!never_empty && !this.props.no_empty_option && null_option_pos < 0 && !this.props.multiple)
		{
			const empty_option = {
				label: this.props.empty_option_label || '--- Select ---',
				value: trimmed_or_blank( this.props.empty_value, '')
			}
			return [empty_option, ...optionlist]
		}
		else
			return optionlist
	}


	get_rendered_value() 
	{
		let value = this.getRawValue()

		if (value === null || value === undefined)
			value = this.props.default_value
		return value || ''
	}

	disabledValue(options, value)
	{
		const entry = options.find( e => e.value == value)
		if (entry)
			return `${entry.label}`
		else
			return "-"
	}

}


export class SelectMultiCheckbox extends MultiSelector 
{
	constructor(props) {
		super(props)
	}

	checkClassNames = (checked) => {
		let classNames = []
		if (this.props.checkClass) {
			classNames = checked ? [this.props.checkClass, 'active']
				: [this.props.checkClass, 'inactive']
		}
		else if (this.props.activeClass || this.props.inactiveClass)
			classNames = checked ? [this.props.activeClass]
				: [this.props.inActiveClass]
		else
			classNames = checked ? ['fa-regular fa-check-square', 'checkbox']
				: ['fa-regular fa-square', 'checkbox']

		return classNames.join( " ")
	}
	

	onClick = (opt, checked) => (e) => 
	{
		let list 	= this.getRawValue()
		

		list = list.filter(e => e != opt.value)

		if (checked)
			list.push( opt.value )
		
		this.setValueList( list )
	}


	render()
	{
		const options 	= this.createOptionList(this.props.options)
		const value 	= this.getRawValue()


		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)


		const classNamesOn 	= this.checkClassNames( true )
		const classNamesOff	= this.checkClassNames( false )
		const labelClass = this.getClassName(["checkbox-label", always_to_string(this.props.labelClass)], disabled)



		const controls 	= []
		options.forEach( (option,n) => 
		{
			const checked 		= value ? value.indexOf( option.value ) > -1 : false

//			console.log("OPT ", options, checked )


			const check_control = <span tabIndex='0' id={this.html_id}
										key={`check-${this.html_id}`}

										className={checked ? classNamesOn : classNamesOff}
										onClick={disabled ? null : this.onClick(option, !checked)} />			

			const label = <span onClick={disabled ? null : this.onClick(option, !checked)}
								className={labelClass}
								key={`label-${this.html_id}`}>
								{option.label}
							</span>

			controls.push( <div key={`${n}`} className='select-entry'>{check_control}{label}</div>)
		})

		return <div className='multi-check-select'>{controls}</div>
	}
}


export class SelectSingleReactSelect extends MultiSelector
{
	constructor(props) {
		super(props)
	}

	onChangeSingle = (data) => {
		if (!data) {
			this.setFieldValue( null, null)
			return
		}

		this.setFieldValue( data.value, data.value)
	}


	render() {
		const options = this.createOptionList(this.props.options)
		const value = this.get_rendered_value()

		const found_label = options.find(o => o.value == value)
		const label = found_label ? found_label.label : '---'
		const final = { value, label }

		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const hasErrors = this.context?.form ? this.context.form.fieldHasErrors(this.props.name) : false
		const errorStyle = hasErrors ? '1px solid indianred' : '1px solid #ffffff00'

		const styles = {
			control: (base) => ({
				...base,
				background: '#f0f0f0',
				borderRadius: 0,
				borderWidth: 0,
				borderColor: 'white',
				color: 'black',
				width: '100%',
				minHeight: '30px',
				boxShadow: '0px 0px 0px #ffffff00'
			}),
			container: (base) => ({
				...base,
				width: '100%',
				outline: errorStyle,
			}),
		}

			return <ReactSelect
						value={final}
						styles={styles}
						disabled={disabled}
						onChange={this.onChangeSingle}
						options={options} />
	}
}



export class SelectAnt extends MultiSelector {
	constructor(props) {
		super(props)
	}

	onChangeSingle = (value) => {
		if (!value) {
			this.setFieldValue( null, null)
			return
		}

		this.setFieldValue( value, value)
	}


	render() {
		const options = this.createOptionList(this.props.options)
		if (!options || options.length == 0)
			return null
		let value = this.get_rendered_value()

		const in_list = options.find( o => o.value == value )

		if (!in_list)
		{
//			console.log("Switch the option :", options[0].value)
			value == options[0].value
		}


		const found_label = options.find(o => o.value == value)
		const label = found_label ? found_label.label : '---'

		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const hasErrors = this.context?.form ? this.context.form.fieldHasErrors(this.props.name) : false
//		const errorStyle = hasErrors ? '1px solid indianred' : '1px solid #ffffff00'

		


//
//		if (disabled)
//			return <div className='disabled-control'>{this.disabledValue(options, value)}</div>
//		else
			return 	<AntSelect
						className={this.getClassName([], disabled)}
						mode={this.props.multiple ? 'multiple' : ''}
						style={{width: '100%', backgroundColor: '#f0f0f0', ...this.props.style||{}}}
						bordered={false}
						allowClear
						defaultValue={value}
						disabled={disabled}
						onChange={this.onChangeSingle}
						options={options}>
					{
					options.map((o, i) => <Option key={`opt-${i}`} value={o.value}>{o.label}</Option>)
					}
					</AntSelect>
	}
}


export class SelectMultiReactSelect extends MultiSelector 
{

	constructor(props) {
		super(props)
	}

	onChangeMulti = (data) => 
	{
		if (!data) 
			this.setValueList([])
		else
			this.setValueList(data.map(e => e.value))
	}


	render() {
		const options = this.createOptionList(this.props.options)
		const raw = this.getRawValue()

		let list = []
		if (raw)
		{
			list = raw.map(x => {
				const found = options.find(o => o.value == x)
				const label = found ? found.label : '---'
				return { value: x, label }
			})
		}
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const hasErrors = this.context?.form ? this.context.form.fieldHasErrors(this.props.name) : false
		const errorStyle = hasErrors ? '1px solid indianred' : '1px solid #ffffff00'


		const styles = {
			control: (base) => ({
				...base,
				background: '#f0f0f0',
				borderRadius: 0,
				borderWidth: 0,
				borderColor: 'white',
				color: 'black',
				width: '100%',
				minHeight: '30px',
				boxShadow: '0px 0px 0px #ffffff00'
			}),
			container: (base) => ({
				...base,
				outline: errorStyle,
				width: '100%',
			}),
		}

//		if (disabled)
//			return <div className='disabled-control'>{this.disabledValue(options, value)}</div>
//		else
			return <ReactSelect
							value={list}
							isMulti={true}
							styles={styles}
							disabled={disabled}
							onChange={this.onChangeMulti}
							options={options} />
	}
}


export class SelectHTML extends MultiSelector
{
	constructor(props) {
		super(props)
	}

	onChangeHTML = (event) => {
		const new_raw = event.target.value
		const new_data = this.transformValue(new_raw)

		this.setFieldValue( new_data, new_raw)
	}


	render() 
	{


		const options 	= this.createOptionList(this.props.options)
		let value 		= this.get_rendered_value()
		const disabled 	= !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		let className = this.getClassName([], disabled)
	
		const in_list = options.find(o => o.value == value)

//		if (!in_list) {
//			if (options && options.length > 0)
//			{
//
//				value == options[0].value
//			}
//			else
//				value =  null
//		}


//		if (disabled)
//			return <div className='disabled-control'>{this.disabledValue( options, value )}</div>
//		else
			return 	<select value={value}
						disabled={disabled}
						ref={this.focusRef}
						onKeyDown={this.getKeyPressHandler()}
						onFocus={this.focusHandler}
						onBlur={this.blurHandler}
						className={className}
						onChange={this.onChangeHTML}>
						{
							options.map((o, n) => <option key={`KEY-${n}`} value={o.value}>{o.label}</option>)
						}
				</select>
	}
}


export class SelectCycleButton extends MultiSelector
{
	constructor(props) {
		super(props)
	}

	next_in_cycle() 
	{
		const options = this.createOptionList(this.props.options, true)
		const raw = this.get_rendered_value()

		let index = 0
		for (let o of options) {
			if (o.value == raw)
				break
			index++
		}
		index = (index + 1) % options.length

		const new_raw 	= options[index].value
		const new_data 	= new_raw 

		this.setFieldValue( new_data, new_raw)
	}

	render() 
	{
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const options = this.createOptionList(this.props.options)
		const raw = this.get_rendered_value()

		let display = '--'
		for (let o of options)
			if (o.value == raw)
				display = o.label
		return 	<button className='cycle-button' 
						onClick={() => this.next_in_cycle()} 
						disabled={disabled}>
					{display}
				</button>
	}
}


export const Select = (props)  => 
{
//	static json( {options}) 
//	{
//		return {kind: 'select', options: this.makeOptionList( options )}
//	}


//	console.log( "SELECT ", props.html )

	if (props.cycle_button && !props.multiple)
		return <SelectCycleButton 	{...props}/>
	if (props.multiple && props.checkboxes)
		return <SelectMultiCheckbox {...props} />
	if (props.multiple && props.reactselect)
		return <SelectMultiReactSelect 	{...props} />
	if (props.html || !props.multiple)  
		return <SelectHTML 			{...props} />
	if (props.reactselect)
		return <SelectSingleReactSelect 	{...props} />

	return 		<SelectAnt 	{...props} />

}





export class FileUpload extends FormComponent {
	constructor(props) {
		super(props)
	}



	componentWillMount() {
		super.componentWillMount()
		const { required, name } = this.props
		const { form } = this.context

		if (required)
			form.addRequired(name, this.props.name)
	}


	render() 
	{
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)

		const raw = this.getRawValue() || ''

		return 	<Dropzone onDrop={this.props.onFileUpload}>
					{({ getRootProps, getInputProps }) => (
						<div className='dropzone'>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p>Drop Files here, or click to Select</p>
							</div>
						</div>
					)}
				</Dropzone>
	}
}





export class Time extends FormComponent {
	constructor(props) {
		super(props)
	}

	componentWillMount() {
		super.componentWillMount()
		const { required, name } = this.props
		const { form } = this.context

		if (required)
			form.addRequired(name, this.props.name)

		form.addVerifier(name, this.verifyTime)
		this.setInitialRaw(formatTime(this.getFieldValue()))
	}


	verifyTime = (form, data, raw_data, errors) => {
		const { name } = this.props
		const required = this.props.required == true
		const raw = form.getRawData(raw_data, this.props.name)

		const has_data = !blank(raw)
		if (!has_data)
			return false

		if (!timeRegex(raw))
			return form.addError(errors, name, 'Format xd hh:mm:ss or hh:mm:ss or mm:ss or ss')

		return false
	}


	onChange = (event) => {
		const new_raw = `${event.target.value}`

		const time = parseTime(new_raw)

		if (time == null || time == undefined)
			this.setFieldValue(null, new_raw)
		else
			this.setFieldValue(time, new_raw)
	}


	onBlur = () => {
		const val = this.getFieldValue()
		const fmt = formatTime(val)

		if (fmt)
			this.setFieldValue(val, fmt)
	}

	render() {
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)

		const className = this.getClassName(['timeinput'], disabled)

		const raw = this.getRawValue() || ''


//		if (disabled)
//			return <div className='disabled-control'>{raw}</div>

		return 	<div className='timecontrol'>
					<div className='fieldline num'>
						<input type="text"
							value={raw}
							ref={this.focusRef}
							disabled={disabled}
							placeholder={disabled ? '' : 'dd hh:mm:ss'}
							className={className}
							onBlur={this.onBlur}
							onChange={this.onChange} />

					</div>
				</div>
	}
}

export class DateTime extends FormComponent
{
	constructor(props) {
		super(props)
	}

	componentWillMount() 
	{
		super.componentWillMount()
		const { required, name } = this.props
		const { form } = this.context

		if (required)
			form.addRequired(name, this.props.name)

	}

	onChange = (date, dateString) => 
	{
		console.log( "---------------------------------")
		console.log( date )
		console.log( date.valueOf() )
		console.log( Date.now() )
		const seconds = date ? Math.floor( date.valueOf() / 1000)  : null

		if (seconds == null || date == null || date == undefined)
			this.setFieldValue( null, null)
		else
			this.setFieldValue( seconds, `${seconds}`)
	};




	setNow = (e) => 
	{
		const seconds = Math.floor(Date.now() / 1000 )

		this.setFieldValue( seconds, `${seconds}`)
	}





	render() 
	{
		const disabled 	= !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const raw 		= this.getRawValue() || ''
		const seconds 	= raw.length == 0 ? null : parseInt(raw, 10)
		const date 		= raw.length == 0 ? null : dayjs.unix( seconds )

		const nowKeyClick	= (e) => {if (e.keyCode == 13) this.setNow()}


//		if (disabled)
//			return <div className='disabled-control'>{formatTimeStamp(raw)}</div>

		return 	<div className='timecontrol'>
					<div className='fieldline date'>
						
						<DatePicker showTime={true}
									defaultValue={date}
									onOk={this.onChange} 
									onChange={this.onChange} 
									disabled={disabled}  />

					{!disabled &&	<button onClick={this.setNow} onKeyUp={nowKeyClick} className='formcontrol-button'>Now</button>}
					</div>
				</div>
	}





}

// <span className='unit'>DAYS d HH: MM: SS</span>
export class Duration extends FormComponent 
{
	constructor(props) {
		super(props)
	}

	componentWillMount() {
		super.componentWillMount()
		const { required, name } = this.props
		const { form } = this.context

		if (required)
			form.addRequired(name, this.props.name)

		//		let value_initial = this.getFieldValue()
		//
		//		if (value_initial === null || value_initial === undefined)
		//			value_initial = this.props.default_value
		//		const value = this.transformValue(value_initial)
		//		const raw = always_to_string(value)
		//
		//		form.setInitialValue( name, value, raw)
	}

	getValue    = (set_raw) => 
	{
		const v = this.getFieldValue()
		const f = fixDuration(v, set_raw)
		return f
	}

	createJSDate(date) {
		const pad10 = (i) => (i < 10 ? '0' : '') + i

		const YYYY = date.getFullYear()
		const MM = pad10(date.getMonth() + 1)
		const DD = pad10(date.getDate())
		const HH = pad10(date.getHours())
		const II = pad10(date.getMinutes())
		const SS = pad10(date.getSeconds())

		return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`
	}


	getJSValues = (value) => 
	{
		const start_valid 	= isNum( value.start) 
		const date_start 	= start_valid	? this.createJSDate(new Date(value.start * 1000)) : ''

		const end_valid 	= isNum( value.end) 
		const date_end 		= end_valid 	? this.createJSDate(new Date(value.end * 1000)) : ''

		return [date_start, date_end]
	}


	normalise_start_end = (value) =>
	{
		return 
		if(value.start > value.end)
		{
			const c = value.start
			value.start = value.end
			value.end = c
		}
	}



	onChangeDuration = (event) => 
	{
		const new_raw 		= event.target.value

		const value = this.getValue(false)
		value.duration_raw = new_raw

		let seconds = parseTime( new_raw )

		if (isNaN( seconds ) || seconds == null)
		{
			value.duration 		= null
		}
		else
		{
			value.duration = seconds
			if (isNum(value.start))
				value.end 		= value.start + seconds
			this.normalise_start_end(value)		
		}

		this.setFieldValue( value, value)
	}




	onChange = (kind) => (date, dateString) => 
	{
		const millis = date ? date.valueOf() : 0
		const seconds = Math.floor(millis / 1000)

		if (millis == null || date == null || date == undefined)
		{
			this.setFieldValue(null, null)
			return
		}


		const value = this.getValue(true)

		if (kind == 'start')
			value.start = seconds
		else if (kind == 'end')
			value.end = seconds

		this.normalise_start_end(value)

		if (isNum(value.end) && isNum(value.start))
			value.duration = value.end - value.start
		else
			value.duration = null

		value.duration_raw = formatTime(value.duration)


		this.setFieldValue(value, value)

	};





	setNow 	= (kind) => (e) => 
	{
		const seconds = Math.floor((new Date()).getTime() / 1000)
		const value = this.getValue(true)

		if (kind == 'start')
		{
			value.start = seconds
			value.end 	= null
		}
		else
			value.end 	= seconds

		this.normalise_start_end(value)

		if (isNum(value.end) && isNum(value.start))
		{
			value.duration = value.end - value.start
			value.duration_raw = formatTime(value.duration)
		}
		else if (value.duration !== undefined)
		{
			value.duration 		= null
			value.duration_raw = formatTime(value.duration)
		}


		this.setFieldValue( value, value)
	}



	render() {
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		const value = this.getValue(false)		

		if (value.duration && blank(value.duration_raw))
			value.duration_raw = `${value.duration}`.trim()

	
		const date_start 	= !value.start 	? null 	: dayjs(value.start * 1000)
		const date_end 		= !value.end 	? null	: dayjs(value.end 	* 1000)

		const nowKeyClick = (kind) => (e) => { console.log( "EH?!"); if (e.keyCode == 13) this.setNow(kind) }

		if (disabled)
			return 	<div className='timecontrol'>
						<div className='fieldline date'>
							<Input disabled={true} value={formatTimeStamp(value.start)} />
						</div>
						<div className='fieldline date'>
							<Input disabled={true} value={formatTimeStamp(value.end)} />
						</div>
						<div className='fieldline date'>
							<Input disabled={true} value={value.duration_raw} />
						</div>
					</div>

		return 	<div className='timecontrol'>
					<div className='fieldline date'>
						<DatePicker showTime
									value={date_start}
									disabled={disabled}		
									placeholder="Start"							
									onChange={this.onChange('start')}
									/>
						<button onClick={this.setNow( 'start')} 
								onKeyUp={nowKeyClick('start')}
								className='formcontrol-button'>Now</button>
					</div>

					<div className='fieldline date'>
						<DatePicker showTime
							value={date_end}
							disabled={disabled}
							placeholder="End"
							onChange={this.onChange('end')}
						/>
						<button onClick={this.setNow('end')}
								onKeyUp={nowKeyClick('start')} 
								className='formcontrol-button'>Now</button>
					</div>

					<div className='fieldline num'>
							<input  type='text'
									value={value.duration_raw || ''}
									placeholder="Duration DAYS d HH: MM: SS"
									className={this.getClassName(['duration'], disabled)}
							onChange={this.onChangeDuration} /> 
							<div className='dummybutton'>&nbsp;</div>
					</div>

				</div>
	}
}





export class TextBox extends FormComponent
{
	onChange = (event) => 
	{

		let raw 		= this.transformValue( event.target.value )
		let val 		= this.createData( raw )


		if (this.props.type == 'numeric' || this.props.type == 'int' || this.props.type == 'float') 
		{
			if (isNaN(val) || val == null || val == undefined) 
				val = null
			else if (this.props.type == 'int') 
				val = Math.round(val)
		}

		this.setFieldValue(  val, raw )
	}


	createData( raw_data )
	{
		const {type} 	= this.props

		if (type == 'numeric' || type == 'int' || type == 'float')
		{
			const x = parseFloat( `${raw_data}`.replace( ",", ".").trim() )
			if (isNaN(x))
				return null
			else
				return x
		}
		else
			return raw_data
	}


	onBlur = () => 
	{
		const { val, raw } = this.commitValue(this.transformValue(this.getRawValue()))
		this.setFieldValue( val, raw)
	}

	commitValue 	 = (new_raw) => 
	{
		let val = this.getFieldValue()
		let raw = new_raw
		if (this.props.type == 'numeric' || this.props.type == 'int' || this.props.type == 'float')
		{
			if (isNaN(val) || val == null || val == undefined)
			{
				raw = ''
				val = null
			}
			else if (this.props.type == 'int')
			{
				console.log( `Commit INT :${val}:${raw}:`)
				val = Math.round( val )
				raw = `${val}`
			}
			else
				raw = val


		}
		return {val,raw}
	}



	verifyNumeric = ( form, data, raw_data, errors ) =>
	{
		const {name}			= this.props
		const required 			= this.props.required == true
		const raw 				= form.getRawData( 	 raw_data,  this.props.name )
		const value 			= this.createData( raw )


		const has_data 	
				= !blank( raw )

		if (!required && !has_data)
			return false


		if (value != 'Unlimited')
		{
			if ( required && has_data && isNaN( value ))
				return form.addError( errors, name, 'Only numeric values' )
			else if (!required && has_data && isNaN( value ))
				return form.addError( errors, name, 'Only blank or numeric values' )

			if (this.props.type == 'int' && value != Math.round( value))
				return form.addError(errors, name, 'Only integer value')

			const min_value = this.props.min
			if (min_value != null && min_value != undefined)
			{
				if (value < min_value)
					return form.addError( errors, name, `< ${min_value}`)
			}

			const max_value = this.props.max
			if (max_value != null && max_value != undefined)
			{
				if (value > max_value)
					return form.addError( errors, name, `${value} > ${max_value}`)
			}

		}

		return false
	}






	componentWillMount()
	{
		super.componentWillMount()

		const {max_length, min_length}			= this.props
		const {verify}							= this.props
		const {required, name, type} 			= this.props
		const  form								= this.context?.form

		if (!form)
			return

		if (required)
			form.addRequired(  name, this.props.name )
		if (min_length)
			form.addMinLength( name, this.props.name, min_length, required )
		if (max_length)
			form.addMaxLength( name, this.props.name, max_length, required )
		if (type == 'numeric' || type == 'int' || type == 'float')
			form.addVerifier(  name,  this.verifyNumeric )

		if (verify && Array.isArray( verify ))
			verify.map( v => form.addVerifier( name, v ))
		else if (verify)
			form.addVerifier( name, verify )

		
//		form.addCommit( name, this.commitValue )


		this.setInitialRaw( `${this.getFieldValue() || ''}`)
	}


	get_rendered_value()
	{
		let value_initial = this.getRawValue()
		if (value_initial === null || value_initial === undefined)
			value_initial = this.props.default_value
			
		const value = this.transformValue(value_initial)

		if (this.props.type == 'numeric' && isNaN( value ))
			return ''
		else
			return always_to_string(value)
	}

	render()
	{

		let type 	= this.props.type
		if (type == 'numeric')
			type = 'text'
		else if (type == 'textarea' || this.props.textarea == true)
			type = 'textarea'
		else if (type == 'password')
			type = 'password'
		else
			type = 'text'


		const disabled 	= !this.evaluate_enabled_boolean( 'disabled', 'enabled', true )

		const raw 		= this.getRawValue() || ''
		if (type != 'textarea')
		{
				return 	<Input 
							type={type}
							value={raw}
							ref={this.focusRef}
							disabled={disabled}

//							onKeyDown={this.getKeyPressHandler()}
//							onFocus={this.focusHandler}
							onBlur={this.onBlur}

							placeholder={this.props.placeholder}
							className={this.getClassName([],disabled)}
							onChange={this.onChange} />
		}
		else
		{
			const  	rows = this.props.rows 	|| 8;

				return <textarea type={type}
								value={raw}
								ref={this.focusRef}
								disabled={disabled}

								rows={rows}

//								onKeyDown={this.getKeyPressHandler()}
//								onFocus={this.focusHandler}

								onBlur={this.onBlur}

								placeholder={this.props.placeholder}
								className={this.getClassName([], disabled)}
								onChange={this.onChange} />
		}

	}
}





export class SubmitButton extends FormComponent
{
	constructor(props)
	{
		super(props)
	}

	componentWillMount()
	{
		super.componentWillMount()
	}

	render()
	{
		const text = this.props.children ? null : (this.props.text || "Submit")
		const errors = this.context.form.formHasErrors()
		
		const onClick = (e) => this.context.form.handleSubmit(e)

		return 	<button 	disabled={errors} 

						onKeyDown={this.getKeyPressHandler()}
						onFocus={this.focusHandler}
						onBlur={this.blurHandler}
						onClick={onClick}>

						{text}
						{this.props.children}
				</button>
	}
}



export class ActionLink extends FormComponent
{
	constructor(props)
	{
		super(props)
	}
	componentWillMount()
	{
		super.componentWillMount()
	}

	trigger_action = (event) =>
	{
		event.preventDefault()
		event.stopPropagation()

		if (this.props.onClick)
			this.props.onClick( event )
	}


	render()
	{
		const errors = this.context.form.formHasErrors()
		return 	<div 

					onKeyDown={this.getKeyPressHandler()}
					onFocus={this.focusHandler}
					onBlur={this.blurHandler}
		
					className='action link' disabled={errors} onClick={this.trigger_action}>
					{this.props.icon && <span className={`icon fa-regular fa-${this.props.icon}`}/>}
					{!this.props.value && this.props.children}
					{ this.props.value && this.props.value}
				</div>
	}
}

export class ActionButton extends FormComponent
{
	constructor(props)
	{

		super(props)
	}

	trigger_action = (event) =>
	{
		event.preventDefault()
		event.stopPropagation()

		if (this.props.onClick)
			this.props.onClick(event, this.context.form, this.props.action_id )
	}


	render()
	{
		const propClass = this.props.className ? '' : this.props.className
		const className = `${propClass} action`
		const errors = this.context.form.formHasErrors()
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)

		return 	<button 

					onKeyDown={this.getKeyPressHandler()}
					onFocus={this.focusHandler}
					onBlur={this.blurHandler}
					disabled={disabled}
		
					className={className} disabled={disabled || errors} onClick={this.trigger_action}>
					{this.props.icon && <span className={`icon fa-regular fa-${this.props.icon}`} />}
					{this.props.children && this.props.children}
					{!this.props.children && (this.props.value || this.props.text)}
				</button>
	}
}


export class ClearButton extends FormComponent 
{
	constructor(props) {

		super(props)
	}

	onReset() {
		this.setFieldValue( null, ``)
	}

	render() {
		const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)
		
		const title = this.props.title || "Clear Entry?"

		if (disabled)
			return null

		return 	<Popconfirm	title = {title}
							onConfirm = {() => this.onReset()}
							okText = "Yes"
							cancelText = "No" >
					<span className='icon  field-clear-button'>✕</span>
				</Popconfirm>
	}
}



export class Derived extends FormComponent
{
	constructor( props )
	{
		super(props)
	}

	componentWillMount() 
	{
		super.componentWillMount()

		const form = this.context?.form

		if (this.props.live)
			form.addDerivedEntry((d) => {d[this.props.live] = this.props.fn(d)})

	}

	render()
	{

		const form = this.context?.form

		if (!form)
			return <div/>

		const {fn} = this.props
		if (!fn)
			return <div/>
		
		const className = this.getClassName([], false)
		const v = fn( form.state.data )

		return <div className={className}>{v}</div>
	}
}


export class Form extends Component
{
	constructor( props )
	{
		super( props )

		const {data,errors, id, verify} 	= this.props
		this.original_data 					= {...(data || {})}

		const initial_data 			= data || {}
		const raw 					= {}
		for( let k of Object.keys(initial_data) )
		{
			const v = initial_data[k]
			if (blank(v))
				raw[k] = ''
			else
				raw[k] = `${v}`.trim()
		}


		this.state 	= {
			settled 		: 	false,
			data 			: 	initial_data,
			raw_data 		: 	raw,
			focusedIndex	: 	0,
			errors 			: 	errors 	|| {}
		}

		this.verifiers 				= {form: this.form_verifiers(), field: {}}
		this.derived 				= []
		this.name 					= `Test Form ${id}`

		this.formRef 				= React.createRef()

		this.focusModel = this.props.focusModel || FOCUS_NORMAL
		this.focusable_components 	= []
	}



	

	getError 	  = (name) 	=> this.state.errors[name]
	getErrors 	  = () 		=> this.state.errors

	updateErrors( start_point, data, raw_data )
	{
		const errors = {}
		Object.keys(start_point || {}).forEach( k => errors[k] = [...start_point[k]])


		for( let field_name in this.verifiers.field )
		{
			for( let v of this.verifiers.field[field_name])
				v( this, data, raw_data, errors )
		}
		for( let v of this.verifiers.form)
			v( this, data, raw_data, errors )

		return errors
	}


	printErrors()
	{
		const errors = this.updateErrors(this.props.errors, this.state.data, this.state.raw_data)

		console.log( "ERR ", errors)
	}

	formHasErrors()
	{
		this.updateErrors( this.props.errors, this.state.data, this.state.raw_data )
		const errors = {}

		for( let field_name in this.verifiers.field )
		{
			for( let v of this.verifiers.field[field_name])
			{
				if (v( this, this.state.data, this.state.raw_data, errors ))
					return true
			}
		}
		for( let v of this.verifiers.form)
		{
			if (v( this, this.state.data, this.state.raw_data, errors ))
				return true
		}

		return false
	}

	fieldHasErrors( field_name )
	{
		if (!this.verifiers.field[field_name])
			return false

		const errors = {}
		for( let v of this.verifiers.field[field_name])
		{
			const verified = v(this, this.state.data, this.state.raw_data, errors)
			if (verified)
				return true
		}

		return false
	}


	addError(errors, n,e)
	{
		if (!n || !e)
			return false
		if (!errors[n])
			errors[n] = []
		errors[n].push(e)
		return true
	}

	form_verifiers()
	{
		const {verify} 	  = this.props
		let formverifiers = []
		if (verify)
		{
			if (Array.isArray( verify ))
				formverifiers = [...verify]
			else
				formverifiers.push( verify )
		}
		return  formverifiers
	}

	addVerifier(name, v)
	{
		if (!v || !name)
			return
		if (!this.verifiers.field[name])
			this.verifiers.field[name] = []
		this.verifiers.field[name].push(v)

	}

	addRequired(name, path )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{

			const v = this.getRawData(raw_data, path)


			const l = 	v == undefined 	? 0 :
						v == null 		? 0 : 
						v == [] 		? 0 :
										  `${v}`.trim().length
			if (name == '101549')
				console.log("CHECK REQ ", name, `-${v}-`, l )

			if (l == 0)
				return this.addError( errors, name, "Required" )
			return 
				false
		})
	}

	addMinLength(name, path, n, required )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{
			const v = this.getRawData(raw_data, path )
			const l = !v ? 0 : `${v}`.trim().length

			if (required !== true && l == 0)
				return false

			if (l < n)
				return this.addError(errors, name, `At least ${n} characters` )
			return false
		})
	}

	addMaxLength(name, path, n, required )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{
			const v = this.getRawData(raw_data, path )
			const l = !v ? 0 : `${v}`.trim().length

			if (required !== true && l == 0)
				return false

			if (l > n)
				return this.addError(errors, name, `At most ${n} characters` )
			return false
		})
	}


	searchPathTarget( start, path, end_offset )
	{
		if (!start || !path)
			return null
		const plen 	= path.length
		if (path.length == 0)
			return null
		let track 	= start
		for( let j = 0; j < plen - end_offset; j++ )
		{
			if (null_or_undef( track ))
				return null
			track = track[path[j]]
		}
		if (null_or_undef( track ))
			return null
		return track
	}

	getPathTargetSetter( start, path )
	{
		return this.searchPathTarget( start, path, 1 )
	}

	getPathTargetGetter( start, path )
	{
		 return this.searchPathTarget( start, path, 0 )
	}


	searchPathTargetV( start, path, end_offset )
	{
		if (!start || !path)
			return null
		const plen 	= path.length
		if (path.length == 0)
			return null
		let track 	= start
		for( let j = 0; j < plen - end_offset; j++ )
		{
			if (null_or_undef( track ))
				return null
			track = track[path[j]]
		}
		if (null_or_undef( track ))
			return null
		return track
	}

	getPathTargetSetterV( start, path )
	{
		return this.searchPathTargetV( start, path, 1 )
	}

	getPathTargetGetterV( start, path )
	{
		 return this.searchPathTargetV( start, path, 0 )
	}

	setInitialDefault( name, value )
	{
		if (this.state.data[name] === null || this.state.data[name] === undefined)
			this.state.data[name] = value
	}

	// This is called form componentWillMount so no point in redrawing the stuff
	setInitialRaw( name, raw )
	{
		this.state.raw_data[name] = raw
	}


	setFieldValues = (values) => 
	{
		const data 		= { ...this.state.data }
		const raw_data 	= { ...this.state.raw_data }

		for( let v of values )
		{
			const {name, value, raw} = v
			if (!name)
				continue

			const rv = raw ? `${raw}` : ''

			data[name] 			= value
			raw_data[name]		= rv
			
		}

		this.calculateDerivedData(data)
		const errors = this.updateErrors(this.props.errors, data, raw_data)
		this.setState({ errors, data, raw_data })

		if (this.props.onChange)
			this.props.onChange({ name, value, raw_value, data })

		return errors
	}


	setFieldValue = (name, value, raw_value = null, skipLive = false) =>
	{
		if (!name)
			return

		const raw 		= raw_value || ''
		const data 		= {...this.state.data, 		[name] : value}
		const raw_data 	= {...this.state.raw_data, 	[name] : raw}

		this.calculateDerivedData(data)
		const errors = this.updateErrors(this.props.errors, data, raw_data)


		if (this.props.onChange)
			this.props.onChange( {name, value, raw_value,data} )


		this.setState({ errors, data, raw_data }, () =>
		{
			if (this.props.onLiveSubmit && !skipLive)
				this.liveSubmit({name, value, raw_value})
		})
	
		return errors
	}


	getRawData = ( raw_data, name ) =>
	{
		const value = raw_data[name]
		return value
	}

	getRawValue = ( name ) => 
	{
		return this.getRawData( this.state.raw_data, name )
	}

	getData 	= () => this.state.data


	getFieldData = ( data, path_i, default_value = null) =>
	{
		if (data && path_i)
		{
			let path = path_i
			if (typeof path_i == 'string')
				path = path_i.split( "." ).map( x => `${x}`.trim() ).filter(x => !!x)

			const value = this.getPathTargetGetter( data, path )
			if (null_or_undef( value ))
					return default_value
			else	return value
		}
		else        return default_value
	}

	getFieldDataV = ( data, path_i, default_value = null) =>
	{
		console.log( "DEBUG ", path_i )
		console.log( data )
		if (data && path_i)
		{
			let path = path_i
			if (typeof path_i == 'string')
				path = path_i.split( "." ).map( x => `${x}`.trim() ).filter(x => !!x)
			console.log( "PATH", path )

			const value = this.getPathTargetGetterV( data, path )
			console.log( "PTG", value )
			if (null_or_undef( value ))
					return default_value
			else	return value
		}
		else        return default_value
	}

	getFieldValue  = ( name, default_value ) => this.getFieldData(  this.state.data, name, default_value = null )
	getFieldValueV = ( name, default_value ) => this.getFieldDataV( this.state.data, name, default_value = null )

	handleSubmit = (event = null) 	=>
	{
		if (event)
			event.preventDefault();
		const data = {...this.original_data, ...this.state.data}
		this.calculateDerivedData( data )

		if (this.props.onSubmit)
			this.props.onSubmit( event, data, false )
	}

	manualSubmit( submitted )
	{
		if (submitted)
			this.setFieldValues( submitted )

		const data = {...this.original_data, ...this.state.data}

		this.calculateDerivedData(data)
		if (this.props.onSubmit)
			this.props.onSubmit( null, data, false )
	}


	liveSubmit( field ) 
	{

		const data = { ...this.original_data, ...this.state.data }
		if (this.props.onLiveSubmit)
			this.props.onLiveSubmit(field, data, false)
	}

	manualCancel()
	{
		const data = {...this.original_data}

		if (this.props.onSubmit)
			this.props.onSubmit( null, data, true )
	}


	addFocusableComponent(c) 
	{
		this.focusable_components.push(c)
		return this.focusable_components.length - 1
	}

	changeFocus( increment ) 
	{		
		let new_index = this.state.focusedIndex + increment
		if (new_index < 0)
			new_index = 0
		if (new_index > this.focusable_components.length - 1 )
			new_index = this.focusable_components.length - 1

		this.setFocus( new_index )
	}

	setFocus( i )
	{
		const component = this.focusable_components[i]
		if (component)
		{
			if (!component.isFocused())
				component.focusHTML()
		}
		this.setState({ focusedIndex: i, focused: true })
	}

	setFocusBlurred( i )
	{
		this.setState({ focusedIndex: i, focused: false })
	}

	componentDidUpdate( prevProps  )
	{
		if (this.props.errors !== prevProps.errors)
		{
			this.calculateDerivedData(this.state.data)
			const errors = this.updateErrors( this.props.errors, this.state.data, this.state.raw_data )
			this.setState( {errors} )
		}

		if (this.props.data !== prevProps.data)
		{
//			console.log( "THE FORM GOT NEW DATA!")
//			console.log( prevProps.data)
//			console.log( this.props.data )
		}
	}

	addDerivedEntry( fn )
	{
		this.derived.push( fn )
	}

	calculateDerivedData( data_set )
	{
		if (this.props.derived)
			this.props.derived( data_set )
		this.derived.forEach( d => d( data_set ))
	}

	updateData( xform )
	{
		const newData = xform( this.state.data )
		this.calculateDerivedData(newData)
		const errors = this.updateErrors(this.props.errors, newData, this.state.raw_data)

		this.setState( {data: newData, errors})

		if (this.props.onChange)
			this.props.onChange({ data : newData })
	}



	render()
	{
		this.updateErrors(this.props.errors, this.state.data, this.state.raw_data)

		const checkEnter = (event) => 
		{
			if (event.key == 'Enter' && event.target.tagName != 'TEXTAREA') 
			{
				event.preventDefault()
				event.stopPropagation()
				return false
			}
			return true
		}

		return 	<FormContext.Provider value={{ form: this }}>
					<form 	onSubmit={this.handleSubmit}
							onKeyDown={checkEnter}
							ref={this.formRef}
							className={this.props.className || 'form'} >
						
							{this.props.children}					
					</form>
				</FormContext.Provider>
	}
}



export const formLine = (title, control, error = null) => 
{
	return <div className='line'>
				<div className='field-name'>{title}</div>
				<div className='control'>
					{control}
				</div>
				<div className='error'>
				{error}					
				</div>
			</div>
}




export const formLineBlank = (fieldname, title, control) => {
	// Stupid CSS does not allow colspan in divs??!!
	return <div className='line'>
		<div className='field-name'></div>
		<div className='control'>
		</div>
		<div className='error'>
		</div>
	</div>
}


export const formLineError = (fieldname, title, control) => {
	return <div className='line'>
		<div className='field-name'>{title}</div>
		<div className='control'>
			{control}
		</div>
		<Error names={fieldname} />
	</div>
}


export const displayLine = (object, title, fieldname, unitfield = null, icon = null, onClick = null) => 
{
	if (!object)
		return null
	const entry = object[fieldname]
	if (entry === undefined || entry === null)
		return null

	let unit = ''
	if (unitfield)
	{
		unit = object[unitfield]
		if (blank( unit ))
			unit = ''
	}

	const val = `${entry}`.trim()
	if (val.length > 0) {
		return 	<tr key={fieldname} onClick={onClick}>
					<td className='title'>{title}</td>
					<td className='entry'>{entry} {unit}</td>
					<td>
						{icon && <span className={`fa-regular fa-${icon}`} />}
					</td>
				</tr>
	}
	else
		return null
}


export const displayLines = (object, title, fieldname) => {
	if (!object)
		return null
	const entry = object[fieldname]
	if (entry === undefined || entry === null)
		return null

	const val = `${entry}`.trim()

	const lines = val.split( "\n").map( (l,i) => 
		<div className="line" key={`k-${i}`}>{l}</div>)

	if (val.length > 0) {
		return <tr key={fieldname}>
			<td className='title'>{title}</td>
			<td className='entry'>{lines}</td>
			<td>
			</td>
		</tr>
	}
	else
		return null
}



export const displayLineAlways = (object, title, field_name) => 
{
	const v = object[field_name]
	if (blank(v))
		return displayLineValue('-', title)
	else
		return displayLine(object, title, field_name)
}





export const displayLineValue = (value, title, unit, icon = null, onClick = null) => 
{
	if (value == null || value == undefined)
		return null

	return 	<tr key={title} className='line' onClick={onClick}>
				<td className='title'>{title}</td>
				<td className='entry'>{value} {unit}
				</td>				
				<td>
					{icon && <span className={`fa-regular fa-${icon}`} />}
				</td>
			</tr>
		}


export const displayLineAlwaysValue = (value, title, unit, icon = null, onClick = null) => {
	if (value == null || value == undefined)
		value = '-'

	return displayLineValue(value, title, unit, icon = null, onClick = null)
}



export const displayLineValueInputD = (value, title, unit, icon = null, onClick = null) => {
	if (value == null || value == undefined)
		return null

	return 	<tr key={title} className='line' onClick={onClick}>
				<td className='title'>{title}</td>
				<td className='entry'><input disabled value={value} />{unit}
				</td>
				<td>
					{icon && <span className={`fa-regular fa-${icon}`} />}
				</td>
			</tr>
}



export const Attachments = ({ root_url, title, value }) => 
{
	if (!value || value.length == 0)
		return null


	return 	<section className='attachments'>
				<h4>{title}</h4>
				<table>
					<tbody>
						{
							value.map(v => 
								<tr>
									<td className='icon'>
										<a href={`${root_url}/${v.link}`}>
											<span className='fa fa-file' />
										</a>
									</td>
									<td className='filename'>
											<a href={`${root_url}/${v.link}`}>{v.filename}</a>
									</td>
								</tr>)
						}
					</tbody>
				</table>
			</section>
}



export const Spacer = () => <div className='spacer'>&nbsp;</div>