import React, {useState, useContext, Component}    from 'react'
import { GUIController }                from '../controller/guiController'
import { ViewSchema }                   from '../table-editor/viewschema'
import { InfoBoxTabList }               from '../info/InfoBox'
import { DataModel }                    from '../table-editor/model/model'
import { ViewConfiguration }            from '../table-editor/ViewConfiguration'

import { ViewTable }                    from '../view/ViewTable'
import { ViewEditor }                   from '../filter/JSONViewEditor'
import { removeFilterMetaData }         from '../filter/Filter'
import { DBContext }                    from '../app/DBContext'

import { ControlledComponent }          from '../controller/guiController'

import { post }                         from '../hooks/useDownloaded'
import { generateUUID }                 from '../utils/utils'

import {
    toggleSubFilter, toggleFilterExpansion, transformFilterNode, findFilterNode,
        normalizeFilter, addTemplateToID, removeFilterFromTree } from '../filter/Filter'

import queryString from 'query-string'; 
import { string_default } from '../utils/utils'



export class ViewController extends GUIController
{
    constructor( context, table, def_columns, def_filter, def_sort, startRefetch )
    {
        super()

        this.context            = context
        const views 		    = context?.views
        this.def_columns        = def_columns
        this.def_filter         = normalizeFilter( def_filter )

        this.data_schema 	    = views ? views.data_schemas[table] : null
        this.data_model         = null

        this.uuid = generateUUID()



        this.table          = table
        this.startRefetch  = startRefetch

        this.focusTarget  = '0'

        this.default_view = {
            id:         0,
            title:      '<All Items>',
            private:    false,
            system:     true,
            columns:    def_columns,
            filter:     def_filter,
            sort:       def_sort,
            person_id:  1
        }

        this.resetView( this.default_view )       
    }


    resetView( view )
    {
        const {columns,filter,sort} = view

        this.view_schema = new ViewSchema(this.data_schema, columns, sort)
        this.filter      = filter
        this.view        = view

        this.uuid = generateUUID()

        this.viewConfiguration = new ViewConfiguration(this.view_schema)
    }


    setData( jsonData )
    {
        const dataModel = new DataModel(this.data_schema)
        dataModel.loadJSONData( jsonData )
        this.viewConfiguration.data_model = dataModel
    }


    getViewConfiguration()
    {
        return this.viewConfiguration
    }


    getSpecificSelected()
    {
        return this.viewConfiguration?.selection?.specificSelected()
    }

    getViews()
    {
        return this.context?.views?.views[this.table] || []
    }

    /// Columns
    addColumn( id )
    {
       this.view_schema.addViewColumn( id )
        this.refetch()
    }
    
    removeColumn( uuid )
    {
        this.view_schema.removeViewColumn( uuid )
        this.refetch()
    }

    moveColumn( src, tgt )
    {
        this.view_schema.moveViewColumn( src, tgt)
        this.refetch()
    }


    selectView( view_id )
    {
        const views     = this.getViews()


        const view      = view_id == 0  ? this.default_view 
                                        : views.find( v => v.id == view_id)

        if (!view)
            return 

        view.filter    = view.filter  ?? this.def_filter
        view.columns   = view.columns ?? this.def_columns
        view.sort      = view.sort    ?? {sort:[], categories:[]}

        this.resetView(  view )

        this.refetch( true )  // We have to force in case a view is double-clicked the query won't change
    }

    isSystemView()
    {
        return this.view && (this.view.id == 0 || this.view.system)
    }

    reloadViews( callback )
    {
        this.context.reloadViews( callback )
    }

    /// Filters
    getFilter(filterWrapper = null, strip = false) 
    {
        let f
        if (strip)
            f = this.filter
        else
            f = normalizeFilter(this.filter)

        if (filterWrapper)
            return {
                id: filterWrapper.filterId,
                subquery: true,
                table:  filterWrapper.wrapTable,
                subfilter: {
                    id: 'boolean',
                    invisible: true,
                    table: filterWrapper.mainTable,
                    operator: 'and', 
                    list: [f]
                }
            }
        else
            return f
    }


    setFilter( filter )
    {
        this.filter = filter
        this.refetch()
    }

    dropFilterTemplate( item, targetID ) 
    {
        this.filter = addTemplateToID( this.filter, item, targetID)
        this.refetch()
    }


    moveFilterNode(item, targetID) {
        this.filter = moveFilterNode(this.filter, item, targetID)
        this.refetch()
    }

    addFilter( table, template)
    {
        this.filter = addTemplateToID(this.filter, template, this.focusTarget)
        this.refetch()
    }

    removeFilter(target)
    {
        if (!target)
            return
        const parent        = target.parentFocusTarget
        this.filter         = removeFilterFromTree(this.filter, target.node_id)
        this.focusTarget    = parent
        this.refetch()
    }

    subFilterToggle(isSubFilter, sub, parameter)
    {
        if (isSubFilter)
        {
            const result = toggleSubFilter(this.filter, parameter, sub.node_id)
            this.filter = result.filter

            if (result.focusTarget)
                this.focusTarget = result.focusTarget
        }
        else
        {
            this.filter = toggleFilterExpansion( this.filter, sub.node_id )
        }

        this.refetch()
    }

    transformNode( node, fn  )
    {
        this.filter = transformFilterNode( this.filter, node.node_id, fn )
        this.setFocus( filter, true )
    }

    setFocus( targetID, force_refetch )
    {
        this.focusTarget = targetID

        if (force_refetch)
            this.refetch()
        else
            this.update()
    }

    getFocusedNode()
    {
        const {node} = findFilterNode( this.filter, this.focusTarget )
        if (!node)
            return this.filter
        else
            return node
    }

    getFocusedTable()
    {
        const node = this.getFocusedNode()
        let table =  node.subKind || node.indicator || node.table 
        if (table == 'boolean')
            return this.table
        else if (table == 'material')
            return 'solution'
        else
            return table
    }


    getColumns()
    {
        return this.view_schema.columns.map( vc => 
        {
            return  {   id:             vc.data_id,
                        server_id:      vc.getServerID(),
                        column_id:      vc.column_id,
                        visible:        vc.visible,
                        static_params:  vc.static_params,
                        sub_id:         vc.sub_id,
                        options:        vc.options
                    }
        })
    }


    getSortOrder()
    {
        const result = []
        if (this.view_schema?.sortOrders)
        {
            (this.view_schema?.sortOrders).forEach( vc =>
            {
                const col = this.view_schema.findColumn( vc.column_id )
                if (col)
                    result.push( {server_id: col.getServerID(), dir: vc.dir})
            })
        }
        return result
    }


    refetch( force = false )
    {
        this.update()
        this.startRefetch( force )
    }
}



export class ViewFrame extends Component
{
    static contextType = DBContext

    constructor(props)
    {
        super( props )
        this.state = {controller : null, count: 0}
//        props.ref.current = () => this.startRefetch()
    }

    queryParameters() 
    {
        const qp = { ...queryString.parse(location.search) }
        const flag  = (name, def = false) => {
            const v = qp[name]
            if (!string_default(v))
                return def
            else
                return v === '1' || v === 'true'
        }

        const useFts = flag("fts", true)
        const useFlt = flag("flt", true)

        const strippedFilter = removeFilterMetaData(this.state.controller.filter)


        const params = {
            fts:        useFts ? this.props.fts : null,
            query:      useFlt ? strippedFilter : null,
            columns:    this.state.controller.getColumns(),
            sortorder:  this.state.controller.getSortOrder()
        }
        return params
    }

    refetch( force = false )
    {
        if (!this.state.controller)
            return
        if (this.props.dataURL)
        {
            const qp = this.queryParameters()

            const qps = JSON.stringify( qp )
            if (qps == this.old_query && !force)
                return
            this.old_query = qps

            post( this.props.dataURL, qp )
            .then( (result) => 
            {
                console.log( result.data?.dataset)
                this.state.controller.setData(result.data)                
                this.setState( {count: this.state.count + 1} )
            })
            .catch( e => console.log( e ))
        }
    }

    startRefetch( force = false)
    {
        setTimeout(() => this.refetch( force ), 0)
    }

    refetchData()
    {
        this.startRefetch( true )
    }

    componentDidUpdate() 
    {
        this.refetch()
    }


    render()
    {
        const { table,  showFilter, rowID, setTableRow, children }  = this.props
        const { initialColumns, initialFilter, initialsort }        = this.props
        const { embedded }                                          = this.props
        const { filterWrapper }                                     = this.props
        const { active }                                            = this.props 

        const { dragItemDescriptor, tailClick, onTailClick}         = this.props
        
        const { dataDownloadAction }                                = this.props

        const viewTable     = this.props.viewTable || table

        if (!this.context?.views?.data_schemas)
            return null
        
        if (!this.state.controller)
        {
            setTimeout(() => {
                const controller = new ViewController(this.context, table, initialColumns, initialFilter, initialsort,
                                                        (force) => this.startRefetch(force))
                this.setState({ controller }, () => this.startRefetch())
            }, 0);

            return  null
        }
        

        const refetch = () => this.startRefetch()

        if (this.state.controller)
           this.state.controller.context = this.context

        return  <ControlledComponent key='ctrl' controller={this.state.controller}>
                    <ViewEditor showFilter={showFilter} table={table} filterWrapper={filterWrapper} />
                    <ViewTable  key={this.state.controller?.uuid}
                                table={viewTable}
                                setTableRow={setTableRow} 
                                embedded={embedded}
                                selected_id={rowID} 
                                dragItemDescriptor={dragItemDescriptor}
                                tailClick={tailClick}
                                active={active !== false}
                                onTailClick={onTailClick}  
                                dataDownloadAction={dataDownloadAction}
                                refetch={(force) => this.refetch(force)}/>

                    {children}
                    <InfoBoxTabList refetch={refetch}/>
                </ControlledComponent>
    }
}


