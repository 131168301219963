import React from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm, useBackendData } from '../hooks/useDownloaded'

import { post } from '../hooks/useDownloaded'

import {
    Form, TextBox,
    SubmitButton,
    displayLine,
    formLine
} from '../form/Form'




export const ResourceEditForm = ({ document, subject, onSubmit, has_code  }) => 
{
    const router = useRouter()




    const result_url = () => {
        let url
        if (document.id)
            url = `/resource/show/${document.resource_type_id}/${document.id}`
        else
            url = `/resource/list/${document.resource_type_id}`

        return url
    }

    const onCancel = (e) => 
    {
        e.stopPropagation()
        e.preventDefault()
        router.goto(result_url(), { focus: 'l' })
    }

    const submitChanges = async (e, data) => 
    {
        const url = document.id ? `resource/save/${document.id}`
                                : `resource/save`

        const result = await submitForm(url, data)

        if (onSubmit)
            onSubmit()

        if (result.data && result.data.success && result.data.id)
            router.goto(`/resource/show/${document.resource_type_id}/${result.data.id}`)
        else
            router.goto(result_url(), { focus: 'l' })
    }

    const errors = {}


    if (!document) {
        return <ComponentPanelRight className='wide document-details'>
            <div className='head'>
                <div className='title'>Edit</div>
            </div>
            <div className='body'>
            </div>
        </ComponentPanelRight>
    }

    return  <ComponentPanelRight className='document-details'>
                <Form key={`K${document.id}`} className='narrow form table'
                    data={document}
                    errors={errors} 
                    onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {document ? document.id : ''}</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents'>
                            <div className='formtable'>
                                {has_code && 
                                     formLine('Code',        <TextBox name='code' disabled={document.deleted}/>)}
                                    {formLine('Name', <TextBox name='title'  disabled={document.deleted} />)}
                            </div>
                        </div>
                    </div>
                    <div className='form-actions'>
                       {!document.deleted &&  <SubmitButton disabled={true}>Save</SubmitButton>}
                        {!document.deleted && <button onClick={onCancel}>
                            Cancel
                        </button>}
                    </div>
                </Form>
            </ComponentPanelRight>
        }


export const ResourceEditComponent = ({ id, subject, onSubmit }) => 
{
    const [document] = useBackendData(`resource/get/${id}`, null)

    return <ResourceEditForm    subject={subject} 
                                document={document} 
                                has_code={subject.has_code} 
                                onSubmit={onSubmit} />
}

export const ResourceCreateComponent = ({ subject, onSubmit }) => 
{
    const doc = { resource_type_id: subject.id }

    return <ResourceEditForm    subject={subject} 
                                document={doc}        
                                has_code={subject.has_code} 
                                onSubmit={onSubmit} />
}


export const Resource = ({ onSubmit, subject, id }) => 
{
    return <ResourceEditForm orig_id={id} subject={subject} document={{}} onSubmit={onSubmit} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>





const ResourceShow = ({ document }) => {

    if (!document)
        return null


    return (
        <div className = 'details'>
            <table className='document-show'>
                <tbody>
                    {displayLine(document, 'Code',         'code')}
                    {displayLine(document, 'Title',        'title')}
                </tbody>
            </table>
            {document.deleted && <div className='form-info'>This resource is deactivated and won't appear in selections lists anymore</div>}
        </div>)
}


export const ResourceShowComponent = ({ id, subject,onSubmit }) => 
{
    if (!id)
        return null

    const router = useRouter()

    const [document,refetch_doc] = useBackendData(`resource/get/${id}`, null)

    const edit = (e) => {
        router.goto(`/resource/edit/${subject.id}/${id}`)
    }

    const toggle_activation = () => {
        post(`/resource/delete/${id}`)
            .then(r => 
            {
                onSubmit()
                refetch_doc()
                setTimeout(() => router.goto(`/resource/show/${subject.id}/${id}`), 0);
            })
    }

    const closePanel = (e) => {
        router.goto(`/resource/list/${subject.id}`, { focus: "l", i: null })
    }

    let component_title = ''
    if (subject && document)
        component_title = `${document.title}`
    

    return <ComponentPanelRight className='document-details'>
        <div className='head'>
            <div className='title'>Resource</div>
            <div className='right button' onClick={closePanel}>
                <span className='close-button'>✕</span>
            </div>
        </div>
        <div className='body'>
            <ResourceShow document={document} />
        </div>
        <div className='form-actions'>
            {document && <button onClick={edit} disabled={document.deleted}>Edit</button>}
            {document && !document.deleted && <button onClick={toggle_activation} >Deactivate</button>}
            {document &&  document.deleted && <button onClick={toggle_activation}>Activate</button>}
        </div>

    </ComponentPanelRight>
}

// <button onClick={cloneThis(db, id)}>Create Copy</button>
