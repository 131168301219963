import React, { Component, useContext,useState } 	from 'react'
import { DBContext } from '../app/DBContext'

import { Fragment } 			from 'react';
import { GUIStateContext } from '../app/GUIContext'
import { ComponentPanelLeft } from '../app-frame/AppFrame'
import { ComponentPanelArrow } from '../app-frame/AppFrame';
import { useRouter } from '../hooks/useRouter';
import { JSONFilterEditor } from './JSONFilterEditor'

import { Popconfirm } from 'antd';

import { isDragSource } from '../dnd/isDragSource'
import { SortListItem } from '../dnd/SortableList'
import {
	Form, TextBox, CheckBox,
	SubmitButton,
	formLine
} from '../form/Form'

import { post } from '../hooks/useDownloaded';

import { IconButton } from '../gui/IconButton';


import { addFilterToGroup } from './Filter';

import { humanize } from '../utils/utils';

const TAB_FILTER 	= 'flt'
const TAB_COLUMNS 	= 'col'
const TAB_VIEW 		= 'view'



export const ColumnTemplateGroup = ({ group, mastertitle, controller }) => 
{
	const { filters, title } = group

	const [open, set_open] = useState(mastertitle == title)
	const toggle_open = () => set_open(!open)

	const addColumn = (id) => (e) => controller.addColumn(id)

	return 	<div className='filter-template-group'>
				<div className='head' onClick={toggle_open}>
					{open && <div className='fa-regular fa-caret-down icon' />}
					{!open && <div className='fa-regular fa-caret-right icon' />}
					<div className='content'>{title}</div>
				</div>
				{open &&
					<div className='body'>
						{
							filters.map((c, i) => 
							{
								const {id} = c

								return 	<div key={`vct-${id}-${i}`}
											className='filter-template-node' >
											<div className={`indicator ${c.indicator}-bg`} />
											<div className='title'>{c.displayTitle}</div>
											<div className='filter-add icon' onClick={addColumn(c.id)}>
												<span className='fa-regular fa-angle-right' />
											</div>
										</div>
							})
						}
					</div>
				}
			</div>
}



export const ViewColumnTemplateList = ({ table,  controller }) => 
{

	const { columns } = controller.data_schema

	const groups = {}
	const group_list = []

	let mastertitle = humanize(table)

	Object.keys(columns).map((id, i) => 
	{
		const c = columns[id]
		let gtitle = mastertitle

		c.displayTitle	= c.name
		const sp = `${c.name}`.split(":")
		if (sp.length == 2)
		{
			gtitle 			= sp[0]
			c.displayTitle 	= sp[1]
		}

		addFilterToGroup(groups, group_list, c, gtitle)
	})

	if (group_list.length == 0)
		return null;


	const rv = group_list.map((g, i) => <ColumnTemplateGroup key={`${g.title}`}
															 group={g}
															 controller={controller}
															 mastertitle={mastertitle}/>)

	return <div className='filter-templates-json'>{rv}</div>
}




const ViewColumnEntry = ({table, column, position, viewState, controller, selected, setSelected}) =>
{

	const [{ isDragging, item }, dragRef] = isDragSource({
		type: 'COLUMN',
		name: `${column.name}`,
		indicator: table,
		item: () => ({ position: position, column })
	})

	const selectClass 	= selected 	? "selected" : ""
	const onClick 		= (e) 		=> setSelected( column.uuid )
	const remove 		= (e) 		=> controller.removeColumn( column.uuid )
	const drop   		= (item) 	=> controller.moveColumn( item.position, position )
	const filter		= (e) 		=> controller.addFilter( column.auto_filter_id )
	
	const focusedTable 	= controller.getFocusedTable()

	return 	<SortListItem accept='COLUMN'  onDrop={drop}>
				<div className={`filter-template-node column ${selectClass}`} ref={dragRef} onClick={onClick}>
					<div className={`indicator ${column.indicator}-bg`}>
						<span className='fa-regular fa-grip-vertical' />
					</div>
					<div className='title'>{column.name}</div>

					{!viewState.edit	&& false &&
					  column.auto_filter_id 	&& 
					  focusedTable == table 	&& 
							<div onClick={filter} className='icon fa-regular fa-search' />}

					{ viewState.edit   	&& 
						<span className='icon  field-clear-button' onClick={remove} >✕</span>}

				</div>
			</SortListItem>
}



export const ViewColumnList = ({ table,  controller, viewState, selected, setSelected }) => 
{
	const columns 		= controller.view_schema.columns


	const cols = columns.map((column,i) => 
	{
		if (!column || !column.visible) 
			return null
		return <ViewColumnEntry key={`C${column.uuid}-${i}`}
								selected={selected == column.uuid}
								setSelected={setSelected}
								table={table}
								column={column} 
								position={i} 
								viewState={viewState} 
								controller={controller}/>

	}).filter( c => c != null)


	return 		<div className='column-list filter-json'>
					{cols}
				</div>
}



export const ViewColumnInfo = ({table,column}) =>
{
	if (!column)
		return <div className='column-info filter-json'>&nbsp;</div>
		
	return 	<div className='column-info filter-json'>
				<div className="type">{humanize(`${column.indicator || table}`)}</div>
				<div className="title">{column.name}</div>
			</div>
}


export const ViewColumnEditor = ({ table, viewState }) => 
{
	const { controller } = useContext(GUIStateContext)
	const { open, edit, active, state_class } = viewState

	const columns = controller?.view_schema?.columns 

	const firstColumn = columns && columns.length > 0 ? columns[0].uuid  : null
	const [selected, setSelected] 	= useState( firstColumn )
	const selectedColumn 			= selected ? columns.find( c => c.uuid == selected ) : null

//	console.log( selected, selectedColumn )

	return 	<div className={`column-editor filter-body-json ${state_class}`}>
				{edit && <ViewColumnTemplateList  	table={table} 
													controller={controller} 
													viewState={viewState} />}

				<ViewColumnList 					table={table} 	
													selected={selected}
													setSelected={setSelected}
													controller={controller} 
													viewState={viewState}/>

				{selectedColumn && <ViewColumnInfo 	table={table} 
													column={selectedColumn} />}
			</div>
}




const RemoveViewButton = ({controller, view, title}) => 
{

	const onConfirm = (e) => 
	{
		console.log( "Removing ", view )
		post(`/view/remove/${view.id}`)
		.then((data) => {
			console.log( data.result )
			controller.selectView(0)
		})
	}


	if (!title)
		title = "Remove View?"

	return 	<Popconfirm title={title}
				onConfirm={() => onConfirm()}
				okText="Yes"
				cancelText="No" >
				<span>✕</span>
			</Popconfirm>
}


export const ViewEntry = ({controller, table, view, viewState, user}) =>
{
	const select = (e) => { e.stopPropagation(); controller.selectView( view.id ) }

	const selected 		= controller.view?.id == view.id ? 'selected' : ''
	const can_delete  	= user.isadmin || (view.is_private && user.id == view.person_id)

	return 	<div className='list-sort-wrapper'>
				<div className={`filter-template-node view ${selected}`} onClick={select}>
					<div className={`indicator ${table}-bg`} />
					<div className='title'>{view.title}</div>
					{viewState.edit && !view.system &&
						<div className='right button'>
							{can_delete && <RemoveViewButton controller={controller} view={view} />}
						</div>}
				</div>
			</div>
}



export const DbViewEditor = ({ table, viewState }) => 
{
	const context 	= useContext(DBContext)
	const user 		= context?.current_user

	const { controller } 	= useContext(GUIStateContext)

	if (!user)
		return null

	const views = controller.getViews() || []

	const list = []
	
	const default_view = controller.default_view

	list.push( <ViewEntry 	key={`V-default`}
							table={table}
							view={default_view}
							position={0}
							viewState={viewState}
							user={user}
						
							controller={controller} />)

	views.forEach((view, i) => 
	{
		const visible = user && (user.isadmin || (view.private && user.id == view.person_id))

		if (visible)
		{
			list.push (<ViewEntry 	key={`V${view.id}-${i}}`}
									table={table}
									view={view}
									position={i+1}
									viewState={viewState}
									controller={controller} 
									user={user}/>)
		}
	})

	return  <div className='view-editor filter-body-json'>
				<div className='column-list filter-json'>
					{list}
				</div>
			</div>
}



const ViewSaveDialog = ({ controller, table, onClose }) =>
{

	const context = useContext(DBContext)
	const user = context?.current_user

	const system = controller.view.id == 0 || controller.view.system

	const save = async (copy, data) => 
	{		
		let 	url 	= ''
		if (system || copy)
			url = `/view/save`
		else
			url = `/view/save/${controller.view.id}`


		const savedata = 
		{
			subject: 	table,
			title: 	 	data.title,			
			filter: 	controller.filter,
			columns: 	controller.view_schema.columnSpecJSON(),
			sort: 		controller.view_schema.sortSpecJSON()
		}

		savedata.public = data.private === undefined ? false : !data.private

		console.log( user, user.isadmin )
		if (user.isadmin)
			savedata.private = !data.public

		console.log( savedata )

		const result = await post( url, savedata )
		
		setTimeout(() => controller.reloadViews( () => 
		{
			controller.selectView( result?.data?.id )
		}), 0 )
		onClose()
	}

	const saveCopy 	= async ( event, form ) =>
	{
		save( true, form.data )
	}

	const submit 	= async ( event, data ) => 
	{
		save( false, data )
	}


	let view = controller.view
	if (view.system)
	{
		view = {...view}
		view.id 		= null
		view.title 		= '',
		view.system 	= false

	}

	view.public = view.private == false

	console.log( view )

	return 	<div className="view-save-dialog component-panel">
				<Form data={view} onSubmit={submit}>

					<div className='background-fade'></div>
					<div className='dialog'>
						<div className='head'>
							<div className='title'>&nbsp;</div>
							<div className='spacer' />
							<div className='right button' onClick={onClose}>
								<span className='close-button'>✕</span>
							</div>
						</div>
						<div className='body'>
								{formLine('Name', 	 <TextBox name='title' required={true} />)}
								{user.isadmin && formLine('Public', <CheckBox name='public' label='Visible for everyone' />)}
						</div>
						<div className='form-actions'>
							<SubmitButton text='Save'		disabled={system} />
							<div className='spacer'/>
							<SubmitButton text='Save Copy' 	onclick={saveCopy} />
						</div>
					</div>
				</Form>
			</div>
}


export const ViewEditor = ({ table, initial_filter, force_closed, filterWrapper, ...props }) => 
{	
	const [saving,set_saving] 	= useState(false)
	const { controller } 	= useContext(GUIStateContext)
	const router 			= useRouter()
	const selected_tab		= router.param('view', 'flt')
	const set_tab 			= (t) => () => router.setFlag( {view: t} )

	const saveView 			= (e) => set_saving( true )
	const closeSave 		= (e) => set_saving( false)

	const TabButton = ({ id, children, disabled }) => 
	{
		const clazz = selected_tab == id ? 'selected' : ''
		return <button disabled={disabled} className={clazz} onClick={set_tab(id)}>{children}</button>
	}

	const toggleEdit = (_) => router.setFlag({ fe: !edit, focus: edit ? 'r' : 'l' })
	const toggleOpen = (_) => router.setFlag({ fo: !open, focus: open ? 'r' : 'l', view: 'col' })

	const active = props.showFilter !== false
	const edit = router.flag("fe") && active
	const open = router.flag("fo") && active 

	const state_class = open ? (edit ? 'edit' : 'view') : 'closed'
	const viewState = { open, edit, active, state_class }


	return 	<Fragment>

				{saving && <ViewSaveDialog controller={controller} table={table}  onClose={closeSave}/>}

				<ComponentPanelLeft className={`filter-master ${state_class}`} >
					<div className='head'>
						{!open && <div onClick={toggleOpen} className='button hideshow' />}
						{open && <div className='title'>Views</div>}
						<div className='filler' />
						{open && active && !edit && <IconButton onClick={toggleEdit} alignment='left' icon="cog" tooltip='Edit View'/>}
						{open && active &&  edit && <IconButton onClick={toggleEdit} alignment='left' icon="caret-left" tooltip='Edit View' />}
					</div>					
					<div className='body'>
						<div className='tab-bar'>
							{open && <TabButton id={TAB_FILTER}>Filter</TabButton>}
							{open && <TabButton id={TAB_COLUMNS}>Columns</TabButton>}
							{open && <TabButton id={TAB_VIEW}>Views</TabButton>}
						</div>						
						{open && selected_tab == TAB_FILTER && 
							<JSONFilterEditor 	table={table}
												viewState={viewState}
												filterWrapper={filterWrapper}
												initial_filter={initial_filter}
												editing={edit} {...props} />}
						{open && selected_tab == TAB_COLUMNS && <ViewColumnEditor
							table={table}
							viewState={viewState}
							{...props} />}
						{open && selected_tab == TAB_VIEW && <DbViewEditor
								table={table}
								viewState={viewState}
								{...props} />}
					</div>
					{open && <div className='form-actions'>
						<button onClick={saveView}>Save View...</button>
					</div>}					
				</ComponentPanelLeft>
				<ComponentPanelArrow onClick={toggleOpen}>
					{active && open && <div className='button fa-regular fa-caret-left  hideshow' />}
					{active && !open && <div className='button fa-regular fa-caret-right hideshow' />}
				</ComponentPanelArrow>
			</Fragment>
}

