import React, { useContext, useState } 	from 'react';
import {Form,  CheckBox, Select} 		from '../form/Form';

import { useDrop } 						from 'react-dnd'

import { DBContext } 					from '../app/DBContext';

import { filterHasComplexControls } 	from './Filter'
import { filterToForm, formToFilter } 	from './Filter'

import { JSONFilterParameter } 			from './JSONFilterParameter';
import { addFilterToGroup } 			from './Filter'


import { isDragSource } 				from '../dnd/isDragSource'

export const JSONFilterBody = ({controller, filter, template}) => 
{
	if (!filter || !template.parameters || template.parameters.length == 0)
		return null


	const setFocus = (e) => {
		e.stopPropagation()
		controller.setFocus( filter.parentFocusTarget )
	}

	return 	<div className='filter-body multi-parameter' onClick={setFocus}>
			{
				template.parameters.map( (p,i) => 
				{
					return <JSONFilterParameter 
										controller={controller}
										key={`p-${i}-${p.id}`}
										inHeader={false}
										filter={filter}
										parameter={p} />
				})
			}
			</div>
}





const JSONFilterHeader	= ( {filter, template, parameter, controller, dragRef})  =>
{
		
	let indicator 	= `${filter.indicator || filter.table || filter.title}`.split("/")[0].toLowerCase()

	const editing   = controller.editing
	const dragIndicator = 
		<div className={`indicator ${indicator}-bg ${editing ? 'editing' : ''}`} ref={editing ? dragRef : null}>
			{false && editing && <span className='fa-regular fa-grip-vertical' />}
		</div>

	const active_name	= `ACT-${filter.uuid}`

	const onClick 		= (e) => 
	{
		e.stopPropagation()
		controller.setFocus( filter.focusTarget, false )
	}

	const on_remove 	= (e) =>
	{
		e.stopPropagation()
		controller.removeFilter( filter )
	}


	if (filter.id == 'boolean')
	{
		if (filter.hidden)
			return null
		return 	<div className='filter-header boolean' onClick={onClick}>
					{dragIndicator}
					<div className='active-selector'>
						<CheckBox name={active_name} default_value={true} />
					</div>
					<div className='title expand'>
						<Select options={[{label: 'Any', value: 'or'}, {label: 'All', value: 'and'}]} 
								name={`OP-${filter.uuid}`} 
								default_value='and'
								cycle_button={true} /> 
								<i>of the following</i>
					</div>
					{controller.editing && !filter.is_root && <div onClick={on_remove} className='icon clear-button'>✕</div>}
				</div>
	}
	else if (parameter && parameter.kind == 'boolean')
	{
		const isisnot = <Select options={[{label: 'Is', value: 'is'}, {label: 'Is Not', value: 'isnot'}]} 
								name={`P-${filter.uuid}:${parameter.id}`}
								default_value='is'
								cycle_button={true} /> 

		return <div className='filter-header' onClick={onClick}>
					{dragIndicator}
					<div className='active-selector'>
						<CheckBox name={active_name} default_value={true} />
					</div>
					<div className='title expand'>{isisnot}&nbsp;{template.title}</div>
					{controller.editing && <div onClick={on_remove} className='icon clear-button'>✕</div>}
				</div>
	}
	else
	{
		const pk = parameter ? parameter.kind : ''
		if (pk == 'chemical' || pk == 'solution' || pk == 'person' || pk == 'experiment' || pk == 'substrate')
			indicator = pk
		return 	<div className='filter-header' onClick={onClick}>
					{dragIndicator}
					<div className='active-selector'>
						<CheckBox name={active_name} default_value={true} />
					</div>
					<div className='title'>{template.title}</div>
						<div className='parameter'>
							{parameter && 
								<JSONFilterParameter 	controller={controller}
														inHeader={true}
														filter={filter} 
														parameter={parameter}  />}
							{!parameter && <span>&nbsp;</span>}
						</div>
					{controller.editing && <div onClick={on_remove} className='icon clear-button'>✕</div>}
				</div>
	}
}




const JSONFilterGroup = ({ group,   controller}) => 
{
	const { filters, title } = group

	const [open, set_open] = useState(true)
	const toggle_open = () => set_open(!open)

	if (!title || title == '')
		return 	<div className='filter-template-group'>
					<div className='body'>
						{filters.map((l, i) => <JSONFilterNode key={`${i}`}
							filter={l}							
							controller={controller}
							 />)}
					</div>
				</div>
	else
		return 	<div className='filter-template-group'>
					<div className='head' onClick={toggle_open}>
						{open && <div className='fa-regular fa-caret-down icon' />}
						{!open && <div className='fa-regular fa-caret-right icon' />}
						<div className='content'>{title}</div>
					</div>
					{open &&
						<div className='body'>
							{filters.map((l, i) => <JSONFilterNode 	
														key={`${i}`} filter={l}	controller={controller} />)}
						</div>}
				</div>
}


export const JSONFilterNode = ({filter,  controller }) =>
{
	const context = useContext(DBContext)
	if (!context?.views?.queries)
		return null

	const templates = context?.views?.queries[filter.table]
	if (!filter)
		return null
	if (!templates && filter.id != 'boolean')
		return null
	const template = templates ? templates[filter.id] : null
	if (!template && filter.id != 'boolean')
		return null;

	const title = template ? template.title : 'Filter'

	const [{ isOverDropTarget }, dropRefDropTarget] = useDrop(() =>
	({
		accept: ['FILTER', 'FILTERTEMPLATE'],
		collect: (monitor) => ({ isOverDropTarget: monitor.isOver() }),
		drop: ({ item }, monitor) => 
		{
			if (monitor.getItemType() == 'FILTERTEMPLATE')
				controller.dropFilterTemplate( item, filter.uuid )
		}
	}))


	const [{ isOverFilter }, dropRefFilter] = useDrop(() =>
	({
		accept: ['FILTER', 'FILTERTEMPLATE'],
		collect: (monitor) => ({ isOverFilter: monitor.isOver() }),
		drop: ({ item }) => {
			console.log("Drop on Filter ")
		}
	}))


	const [{ isDragging, draggedFilter }, dragRefFilterHead] = isDragSource(
		{
			type: 'FILTER',
			name: title,
			indicator: filter.indicator || filter.table,
			item: filter
		})

	const dragStateClass = 	isDragging 	 ? 'dragging' : 
							isOverFilter ? 'drag-over' :
							''

	const isEditing = controller.focusTarget == filter.focusTarget && controller.editing


//	console.log("FILTER IE", isEditing, "CTFT", controller.focusTarget, "FLF", filter.focusTarget, "E", controller.editing)

	const editingClass = isEditing ? 'editing' : 'viewing'

	if (filter.id == 'boolean')
	{
		const groups = {}
		const group_list = []

		filter.list.forEach(f => 
		{
			let g = `${f.group}`
			if (!f.group || g == 'Boolean' || g == 'Properties' || g.toUpperCase() == `${f.table}`)
				g = ''
			addFilterToGroup( groups, group_list, f, g )
		})

		if (group_list.length == 0 && !controller.editing)
			return null;

//		console.log( "BOOL", filter.node_id, "CF", controller.focusTarget, "FF", filter.focusTarget )

		const focusTable 	= controller.getFocusedTable()


		return <div key={filter.uuid} className={`${dragStateClass} filter-node boolean ${editingClass}`} ref={dropRefFilter}>
					<JSONFilterHeader 	filter={filter} 
										controller={controller}
										dragRef={dragRefFilterHead}
											 />
					<div className='body'>
						{ 
							group_list.map( (g,i) => 
								<JSONFilterGroup 	key={`${i}`} 
													group={g}
													controller={controller}
									 />)
						}

						{		isEditing && 
								<div  className={`dropzone ${isOverDropTarget ? 'drag-over' : ''}`} ref={dropRefDropTarget}>
									Filters for <span className='subtable-name'>{focusTable}...</span>
								</div>
						}
					</div>
				</div>
	}

	




	const params = template.parameters

	let header_parameter 	= true
	if (filter.expanded)
		header_parameter 	= false
	else if (filter.subfilter)
		header_parameter 	= false
	else if (params && params.length == 1 && filterHasComplexControls(filter, params[0]))
		header_parameter 	= false
	else if (params && params.length > 1)
		header_parameter	= false

	const param1 			= params && params.length > 0 ? params[0] : null




	if (!params || params.length == 0)
	{
		return <div key={filter.uuid} className={`${dragStateClass} filter-node no-parameter ${editingClass}`} ref={dropRefFilter}>
					<JSONFilterHeader 	template={template} 
										filter={filter} 
										controller={controller}
										dragRef={dragRefFilterHead}
										/>
				</div>
	}
	else if (header_parameter)
	{
		return <div key={filter.uuid} className={`${dragStateClass} filter-node single-parameter ${editingClass}`} ref={dropRefFilter}>
					<JSONFilterHeader 		template={template} 
											filter={filter} 											
											parameter={param1} 
											controller={controller} 
											dragRef={dragRefFilterHead}/>
				</div>
	}
	else if (filter.subfilter || filter.subquery || 
				(filter.kind !== undefined && param1.kind != filter.kind))
	{
		const on_click = (e) => 
		{
			e.stopPropagation()
			controller.setFocus(filter.focusTarget, true)
		}

		return <div key={filter.uuid} className={`${dragStateClass} filter-node multi-parameter ${editingClass}`} ref={dropRefFilter}>
					<JSONFilterHeader 		template={template} filter={filter} 
											onClick={on_click}
											parameter={param1}
											controller={controller}
											dragRef={dragRefFilterHead}
											 />
					{filter.subfilter && filter.subquery  && 
						<div className='body multi-parameter subquery' onClick={on_click}>
							<JSONFilterNode filter={filter.subquery}
											controller={controller}
											 />
						</div>}
				</div>		
	}
	else
	{
		return <div key={filter.uuid} className={`${dragStateClass} filter-node multi-parameter ${editingClass}`} ref={dropRefFilter}>
					<JSONFilterHeader 		template={template} 
											filter={filter}
											parameter={param1}
											controller={controller}
											dragRef={dragRefFilterHead}
											 />
					<JSONFilterBody 		controller={controller}
											template={template} 
											filter={filter} 
											 />
				</div>
	}
}



export const JSONFilter = ({filter, controller}) =>
{
	const context = useContext(DBContext)
	if (!context?.views?.queries)
		return null

	const table_queries	= context?.views?.queries

	const data 			= filterToForm(table_queries, filter )
	const newFilter 	= formToFilter(table_queries, filter, data)
	


	const update_data   = ({data}) => 
	{
		const spec = formToFilter(table_queries, filter, data )
		controller.setFilter( spec )
	}

	return 	<div className='filter-json'>
				<Form data={data} onChange={update_data}>
					<JSONFilterNode filter={newFilter} 
									controller={controller}  />
				</Form>
			</div>
}