import {ViewSchema} 	from './viewschema'
import {DataModel} 		from './model/model'
import { DataIndexTree } from './model/model'

export enum ViewSelectionType { None, Some, All }

export class ViewSelection
{
	viewConfiguration : ViewConfiguration
	node_keys: 	{ [index: string]: ViewSelectionType }
	records: 	{ [index: number]: boolean }

	constructor(viewConfiguration : ViewConfiguration)
	{
		this.viewConfiguration 	= viewConfiguration
		this.node_keys 			= { }
		this.records 			= { }
	}

	update( viewIndex : DataIndexTree)
	{
		const walk_index = (node: DataIndexTree) => {
			if (node.records.length > 0)
				return
			let any = false
			let all = node.children.length > 0
			node.dependents.forEach(d => { if (this.records[d]) { any = true } else { all = false } })

			if (any && !all)
				this.node_keys[node.id] = ViewSelectionType.Some
			else if (all)
				this.node_keys[node.id] = ViewSelectionType.All
			else
				this.node_keys[node.id] = ViewSelectionType.None

			node.children.forEach(n => walk_index(n))
		}

		if (viewIndex)
			walk_index(viewIndex)
	}

	selectAll()
	{
		const root_sel = this.node_keys[""]
		const new_val = root_sel == ViewSelectionType.Some || root_sel == ViewSelectionType.None

		if (this.viewConfiguration.data_model)
		{
			for (let entry of this.viewConfiguration.data_model.data_set) 
			{
				this.records[entry.key] = new_val
			}
		}
	}

	
	specificSelected(all_if_nothing : boolean = false) : [number] | null
	{
		let rv : number[] = []

		console.log( this.records )
		console.log( this )

		Object.keys( this.records ).forEach( id => 
		{
			if (this.records[id] == true)
				rv.push( id )
		})

		console.log( rv, all_if_nothing )
		if (rv.length == 0 && all_if_nothing)
		{
			const set = this.viewConfiguration.data_model?.data_set || {}
			for( let k of Object.keys(set))
				rv.push( `${set[k]?.key}` )
		}

		console.log( rv )

		return rv.length > 0 ? rv : null
	}
}


export class ViewConfiguration 
{
	schema: 	ViewSchema
	selection: 	ViewSelection
	data_model: DataModel | null


	constructor(schema: ViewSchema, data_model: DataModel | null = null) 
	{
		this.schema 		= schema
		this.selection 		= new ViewSelection(this)
		this.data_model 	= data_model
	}
}
