import React, {useState} from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm, useBackendData, fetchMulti } from '../hooks/useDownloaded'
import { usePersonSelection } from '../hooks/usePersons'

import { EntityButton } from '../navigation/EntityButton'
import { MetaData } from '../metadata/MetaData'
import { SubstrateMaterialEmbedded } from './SubstrateMaterialEditor'


import {
    Form, TextBox, CheckBox, DateTime,
    SubmitButton, HideShow, Select, 
    formLineError, 
    displayLineAlways
} from '../form/Form'


import { Modal } from 'antd';



export const SubstrateBatchCreateComponent = ({onSubmit}) => 
{
    const data = { kind: "C", amount: 10 }
    const errors = {}
    const router = useRouter()

    const [person_options] = usePersonSelection()

    const onCancel = (e) => 
    {
        e.preventDefault()
        e.stopPropagation()
        router.goto( "/substrate-batch", { focus: 'l' })
    }


    const submitChanges = async (e, data) => 
    {
        const url = `substrate_batch/create`

        const result = await submitForm(url, data)

        console.log( result )

        if(result?.data?.id)
            router.goto(`/substrate-batch/show/${result?.data?.id}`, { focus: 'r', i: null })
        else
            router.goto(`/substrate-batch`, { focus: 'l' })

        if (onSubmit)
            onSubmit()
    }

    const [materials] = useBackendData(`substrate_material/all`, null)
    let matoptions = []
    if (materials)
        matoptions = materials.dataset.map( m => {return {value: m.key, label: m.fields.title || '-- Unknown Material --'}} )


    const [kindresult] = useBackendData(`substrate_kind/all`, null)
    let kindOptions = []
    if (kindresult?.dataset)
        kindOptions = kindresult.dataset.map(k => { return { value: k.fields.id, label: k.fields.title } })



    return  <ComponentPanelRight className='substrate-create wide'>
                <Form data={data} errors={errors} onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Register Substrate Batch</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents details'>
                            {formLineError('kind',                      'Type',             
                                    <Select name='kind_id'               options={kindOptions} required={true} />)}
                            {formLineError('operator_id',               'Operator',         <Select name="operator_id"           options={person_options} required={true}/>)}
                            {formLineError('substrate_material_id',     'Material',         <Select name='substrate_material_id' options={matoptions} required={true}/>)}
                            {formLineError('amount',                    'Amount', <TextBox name='amount' type='numeric' min={1} required={true}/>)}
                            {formLineError('edge_treatment',            'Edge Treatment', <Select name='edge_treatment' options={['Beveled', 'Sharp']} required={true} />)}
                        </div>

                        <div className='form-actions'>
                            <SubmitButton>Save</SubmitButton>
                            <button onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ComponentPanelRight>
        }






export const SubstrateBatchShow = ({ batch, refetch }) => {
    if (!batch)
        return null



    const { supervisor, operator } = batch


    const router = useRouter()


    return (<div className='body'>
                <MetaData key={`B-${batch.id}`}
                    indicator='substrate-batch' document={batch} refetch={refetch} />
                <div className='details'>
                    <table className='document-show'>
                        <tbody>
                            {displayLineAlways(batch, 'Kind', 'kind')}
                            <tr key='range'>
                                <td className='title'>Substrates</td>
                                <td className='entry'>{batch.start_id} - {batch.end_id}</td>
                            </tr>
                            {displayLineAlways(batch, 'Operator', 'operator_name')}
                            {displayLineAlways(batch, 'Edge Treatment', 'edge_treatment')}
                        </tbody>
                    </table>

                    <section>
                        <h4>Material</h4>
                        <SubstrateMaterialEmbedded substratematerial={batch.substrate_material} />
                    </section>

                    <section>
                        <h4>Substrates</h4>
                        {!batch.substrates || batch.substrates.length == 0 &&
                            <div key='nosteps'><i>No substrates. This is probably an error.</i></div>}

                        {batch.substrates && batch.substrates.map((s, i) => 
                                <EntityButton key={`E-${i}`} kind='substrate' entity={s} />)}
                    </section>
                </div>
            </div>)
}


export const SubstrateBatchShowComponent = ({ id, onSubmit }) => 
{

    const router = useRouter()

    const [batch, refetchComponent] = useBackendData(`substrate_batch/get/${id}`, null)


    console.log( "Subbatch", id )


    const refetch = fetchMulti(refetchComponent, onSubmit)

    if (!id)
        return null



    const closePanel = (e) => {
        router.goto(`/substrate-batch`, { focus: "l", i: null })
    }

    return  <ComponentPanelRight className='batch-details wide'>
                <div className='head'>
                    <div className='title'></div>
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
                <SubstrateBatchShow batch={batch} refetch={refetch}/>
            </ComponentPanelRight>
}
