import {GUIController}      from '../controller/guiController'
import axios                from 'axios'
import { backend_server }   from '../config/runconfig'
import { Experiment }       from '../model/experiment'
import { TableController }  from '../table/tableController'
import { create_root_layout } from '../dock/dock'

/** Template Controller  */
export class WorkspaceController extends GUIController 
{
    constructor(router) 
    {
        super()

        this.router              = router
        this.gui_controllers     = {} 
        this.gui_controller      = null
 
        this.experiment_ids   = []
        this.experiment_store = {}

        //experiment_ids.forEach( (experiment_id) => this.addExperiment( experiment_id ) )

        this.layout         = {}
        this.layout_ready   = false
        setTimeout(() => this.fetchLayout(), 0); 
    }


    transformLayout( fn )
    {
        this.layout = fn( this.layout )
        axios.post( `${backend_server.server}/workspace/set`, {layout: this.layout })

        this.update()
    }

//    const layout = create_root_layout(
//        {
//            kind: "horizontal",
//            parts: [
//                {
//                    kind: "vertical",
//                    parts: [
//                        { kind: "dock", ids: [101765] },
//                        { kind: "dock", ids: [101859, 101728] },
//                        { kind: "dock", ids: [101600, 101697] }
//                    ]
//                },
//                {
//                    kind: "vertical",
//                    parts: [
//                        { kind: "dock", ids: [101494] },
//                        { kind: "dock", ids: [101649] },
//                    ]
//                }
//            ]
//        })

    async fetchLayout()
    {
        const url = `${backend_server.server}/workspace/get`
        const result = await axios.get( url )

        if (result && result.data)
        {
            if (result.data && result.data.success && result.data.workspace)
            {
                this.workspace  = result.data.workspace
                this.layout     = this.workspace?.layout

                if (!this.layout)
                    this.layout = { kind: "dock", ids: [], main: true }

                this.layout = create_root_layout(this.layout)

                this.layout_ready = true
                this.update()
            }
        }
    }


    getLayout()
    {
        if (!this.layout || Object.keys( this.layout ).length == 0)
            this.layout =  create_root_layout({ kind: "dock", ids: [], main: true } )
        return this.layout
    }



    addExperiment( experiment_id )
    {
        if (this.experiment_ids.indexOf( experiment_id ) >= 0 )
            return

        this.experiment_ids.push( experiment_id)
        this.gui_controllers[experiment_id] = new TableController(this, experiment_id )
        if (!this.focused_experiment_id)
        {
            this.focused_experiment_id = experiment_id
            this.gui_controller        = this.gui_controllers[experiment_id]
        }
        this.fetchExperiment( experiment_id )
    }

    addExperiments( list )
    {
        list.forEach( e => this.addExperiment( e ))
    }

    removeExperiment( eid )
    {
        const update_db = async (e) => 
        {
            const url = `${backend_server.server}/workspace/remove_experiment/${eid}`
            await axios.post(url)
            console.log( "Start Reload" )
            this.reload_workspace()
        }


        const experiment_id = `${eid}`


        const index             = this.experiment_ids.indexOf( eid )
        if (index < 0)
            return
        this.experiment_ids     = this.experiment_ids.filter( e => `${e}`               != experiment_id )
        delete this.gui_controllers[ experiment_id]
        delete this.experiment_store[experiment_id]

        update_db()

        if (this.focused_experiment_id == experiment_id) 
        {
            if (this.experiment_ids.length == 0)
                this.setFocusedExperiment(null)
            else if (index >= this.experiment_ids.length)
                this.setFocusedExperiment( this.experiment_ids[this.experiment_ids.length - 1] )
            else
                this.setFocusedExperiment(this.experiment_ids[index])
                
        }
        else
            setTimeout(() => this.update(), 0);
    }


    experiment          = (eid) => this.experiment_store[eid]
    getExperiment       = (eid) => this.experiment_store[eid]
    getGuiController    = (eid) => this.gui_controllers[eid]


    setFocusedObject( {kind, id}, update = true )
    {
        const old = this.focused_object ? `${this.focused_object.kind} - ${this.focused_object.id}` : ''
        const now = `${kind} - ${id}`
        this.focused_object = {kind,id}

        if (now != old && update)
            setTimeout(() => this.update(), 0);
    }

    focusedKind()
    {
        if (!this.focused_object)
            return ""
        else
            return this.focused_object.kind
    }

    switchToNewFocusedExperiment( experiment_id )
    {
        this.router.goto(`/workspace/${experiment_id}`)
    }

    setFocusedExperiment( experiment_id, update = true )
    {
        const old = this.focused_experiment_id

        this.focused_experiment_id = experiment_id
        if (experiment_id != null)
            this.gui_controller = this.gui_controllers[experiment_id]
        else
            this.gui_controller = null

        if (!this.focused_object || 
             this.focused_object?.kind != 'experiment' || 
             old != experiment_id)
        {
            this.focused_object = {kind: 'experiment', id: experiment_id}
            if (update)
                setTimeout(() => {
                    this.update()
                }, 0);
            
        }
    }


    getFocusedExperiment()
    {
        if (!this.focused_experiment_id)
            return null
        return this.experiment( this.focused_experiment_id )
    }


    getFocusedExperimentID() 
    {
        return this.focused_experiment_id
    }


    fetchExperiment = (experiment_id, setFocus = false ) => 
    {

        const eid = experiment_id
        if (!eid)
        {
            console.error( "Experiment ID not specified in fetchExperiment")
            return
        }

        const url = `${backend_server.server}/experiment/get/${eid}`

//        console.log( "Getting ", experiment_id )
        axios.get(url)
            .then(result => 
            {
                const json = result.data
                const experiment = new Experiment()

                experiment.create_from_serverjson(json)
                this.experiment_store[experiment.id] = experiment

                const gui_controller = this.getGuiController( experiment_id )
                gui_controller.updateSelection()

               //setFocusedExperiment( experiment_id, false )
                this.update()
            })
    }


    fetchSubstrates()
    {
        if (this.subSelectorRef?.current)
            this.subSelectorRef.current.startRefetch( true )
    }

    fetchMaterials() 
    {
        if (this.materialSelectorRef?.current)
            this.materialSelectorRef.current.reload()
    }

    focusedExperiment = (def = null) => 
    {
        if (this.focused_experiment_id)
            return this.experiment( this.focused_experiment_id )
        else if (def)
            return this.experiment( def )
        else
            return null
    }


    update_sequence = (experiment_id) => 
    {
        const experiment    = this.experiment( experiment_id )
        const seq           = experiment.dbStepMap()
        const url           = `${backend_server.server}/experiment/set_sequence/${experiment.id}`

        axios.post(url,
            { steps: seq })
            .then(result => {
                experiment.setDBStepIDList(result.data.steps)
                this.fetchExperiment(experiment_id, true)
            })
    }


    insertTemplate = (template_id, step) => 
    {
        const experiment = this.getFocusedExperiment()
        if (!experiment)
            return

        const url = `${backend_server.server}/experiment/insert_template/${experiment.id}`

        let sequence = null
        if (step)
            sequence = step.sequence
        else
        {
            const gui_controller = this.gui_controllers[experiment.id]
            const focused_id = gui_controller?.gui_state?.selection?.focused_step
            if (focused_id)
            {
                const tpl   = experiment.getStep( focused_id )
                console.log( tpl )
                sequence    = tpl.sequence + 1
            }
        }

        axios.post(url, {template_id, sequence})
            .then(result => this.fetchExperiment(experiment.id), true)
    }



    insertStep = (step_kind_id, step) =>
    {
        const experiment = this.getFocusedExperiment()
        if (!experiment)
            return          
        experiment.insertStep(step_kind_id, step )
        this.update_sequence( experiment.id )
    }


    moveStep = (experiment_id, source_step, step_id) =>
    {
        if (!experiment_id)
            return
        const experiment = this.experiment(experiment_id)
        if (experiment == null)     return
        experiment.moveStep(source_step, step_id )
        this.update_sequence( experiment_id )
    }


    removeStep = (experiment_id, step_id) =>
    {
        if (!experiment_id)
            return
        const experiment = this.experiment( experiment_id )
        if (experiment == null)     return
        experiment.removeStep(step_id)
        this.update_sequence( experiment_id )
    }

    selectEntireStep = ( experiment_id, step_id ) =>
    {
        const gui_controller    = this.getGuiController( experiment_id )
        gui_controller.selectEntireStep( step_id )    
        this.switchToNewFocusedExperiment( experiment_id )    
        this.update()
    }



    // Substrates
    addSubstrate = (experiment, sub_ids) =>
    {   
        // sub ids can be nulle, it indicates a blank substrate
        if (!experiment || experiment.subject !== 'experiment')
            return

        const experiment_id = experiment.id


        console.log( "ADD ", sub_ids)

        if (sub_ids && sub_ids.length == 1 && typeof sub_ids[0] !== 'string')
        {
            console.log( "Convert ", sub_ids)
            sub_ids = [`${sub_ids[0].item_key}`]
        }

        axios.post(`${backend_server.server}/experiment/add_substrate/${experiment.id}`,
                    { substrate_ids: sub_ids })
                .then((result) => 
                {
                    if (result && result.data)    
                        console.log( result.data )
                    this.fetchExperiment(experiment_id, true)
                    this.fetchSubstrates()
                })
    }


    removeSubstrate = (experiment, substrate) =>
    {
        if (!experiment || experiment.subject !== 'experiment')
            return

        const experiment_id = experiment.id

        if (!experiment_id || !substrate)
            return

        axios.post(`${backend_server.server}/experiment/remove_substrate/${experiment_id}`,
            { substrate_id: substrate.id })
            .then(() => 
            {
                this.fetchExperiment(experiment_id, true )
                this.fetchSubstrates()
            })
    }


    async assignSubstrateToExperiment(experiment, sub_id) 
    {
        if (!experiment || experiment.subject !== 'experiment' || !sub_id)
            return

        const experiment_id = experiment.id

        const url = `${backend_server.server}/substrate/transfer_to_experiment`

        await axios.post(url, { id: sub_id, experiment_id })
            .then(() => {
                this.fetchExperiment(experiment_id, true)
                this.fetchSubstrates()
            })
    }



    async assignSubstrateToSubstrate(experiment, tgt, source_id)
    {

        if (!experiment || experiment.subject !== 'experiment')
            return

        const experiment_id = experiment.id

        if (!experiment_id)
            return

        const url = `${backend_server.server}/substrate/transfer_to_substrate`
        await axios.post(url, { source_id, target_id: tgt.id, experiment_id: experiment_id })
            .then(() => {
                this.fetchExperiment(experiment_id, true)
                this.fetchSubstrates()
            })
    }


    startBatchUpdate(experiment_id) 
    {
        const queue = this.experiment(experiment_id).popCommitQueue()
        
        axios.post(`${backend_server.server}/experiment/commit_changes`, { changes: queue })
            .then(_ => {
                setTimeout(() => 
                {
                    this.fetchExperiment( experiment_id )
                    this.fetchMaterials()
                    this.fetchSubstrates()
                }, 0 )
            })
    }



    addMaterial = (material_data, experiment_id = null) => {
        let gui_controller = null
        let experiment = null
        if (experiment_id) {
            gui_controller = this.gui_controllers[experiment_id]
            experiment = this.experiment(experiment_id)
        }
        else {
            experiment = this.getFocusedExperiment()
            if (!experiment)
                return
            gui_controller = this.gui_controllers[experiment.id]
        }

        gui_controller.addMaterial(material_data)
    }




    updateData()
    {
        return
        const exp = this.getFocusedExperiment()
        if (!exp)
            return
        const controller = this.gui_controllers[exp.id]            
        if (!controller)
            return

        controller.update()
    }
}
