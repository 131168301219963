import React from 'react'
import { CSSProperties, FC } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'

const layerStyles: CSSProperties = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
}

function getItemStyles(
	initialOffset: XYCoord | null,
	currentOffset: XYCoord | null,
	clientOffset:  XYCoord | null
) {
	if (!initialOffset || !clientOffset) {
		return {
			display: 'none',
		}
	}

///	console.log( clientOffset, clientOffset)
	let { x, y } = clientOffset

	const transform = `translate(${x-50}px, ${y-25}px)`
	return {
		transform,
		WebkitTransform: transform,
	}
}

export interface CustomDragLayerProps {
}

export const CustomDragLayer: FC<CustomDragLayerProps> = (props) => 
{
	const { isDragging, dragData, initialOffset, currentOffset, clientOffset } =
		useDragLayer((monitor) => ({
			dragData: monitor.getItem(),
			itemType: monitor.getItemType(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			clientOffset:  monitor.getClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging(),
		}))

	if (!isDragging) {
		return null
	}

	const { _, indicator, name } = dragData || { indicator: "red", name: "" }

	return 	<div style={layerStyles}>
				<div style={getItemStyles(initialOffset, currentOffset, clientOffset)}>
					<div className={`dragged-item ${indicator}-brd`}>{name}</div>
				</div>
			</div>

}