import React, {useState} from 'react'
import { useRouter }    from '../hooks/useRouter'
import { useContext }   from 'react'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { post, submitForm, useBackendData, fetchMulti } from '../hooks/useDownloaded'
import colors                               from 'nice-color-palettes/500'

import { MetaData } from '../metadata/MetaData'
import { notify, notifyResult } from '../notify/Notification'
import Avatar from "boring-avatars";



import {
    Form, TextBox, Select,
    SubmitButton, ActionButton,
    formLineError, displayLine, formLineBlank,
    formLine
} from '../form/Form'
import backend_server from '../config/runconfig'




export const PersonEditForm = ({ orig_id, person, refetch }) => 
{

    const [errors,set_errors]                   = useState({})
    const [avatar_style,set_ava_style]          = useState(person?.avatar_style)
    const [avatar_palette, set_ava_palette]     = useState(person?.avatar_palette)



    const router = useRouter()

    const result_url = () => {
        let url
        if (person.id)
            url = `/person/show/${person.id}`
        else if (orig_id)
            url = `/person/show/${orig_id}`
        else
            url = "/person"

        return url
    }



    const onCancel = (e) => {
        router.goto(result_url(), { focus: 'l' }) 
    }

    const submitChanges = async (e, data) => 
    {

        const url = person.id ? `person/save/${person.id}` : `person/save`


        data.isadmin = data.admin_select == 'admin'
        data.deleted = data.active_select == 'inactive'

        const result = await submitForm(url, data)

        notifyResult(result?.data)

        if (result?.data?.success)
        {

            if (refetch)
                refetch()

            if (result.data && result.data.id)
                router.goto(`/person/show/${result.data.id}`, { focus: 'r', i: null })
            else
                onCancel()
        }
        else
        {
            console.log( "ERRORS ", result.data.errors )
            set_errors( {...result.data.errors} )
        }
    }


    if (!person) {
        return  <ComponentPanelRight className='wide person-details'>
                    <div className='head'>
                        <div className='title'>Edit</div>
                    </div>
                    <div className='body'>&nbsp;
                    </div>
                </ComponentPanelRight>
    }


    const admin_options = [
        {label: 'Normal User', value: 'normal'},
        { label: 'Developer', value: 'developer' },
        {label: 'Admin User', value: 'admin'}
    ]

    person.admin_select = person.isadmin ? 'admin' : 'normal'


    const active_options = [
        { label: 'Active',      value: 'active' },
        { label: 'Inactive',    value: 'inactive' }
    ]

    person.active_select = person.deleted ? 'inactive' : 'active'

    const avatar_options = [
            {label: "Marble",    value: "marble"},
            {label: "Beam",      value: "beam"},
            {label: "Pixel",     value: "pixel"},
            {label: "Sunset",    value: "sunset"},
            {label: "Ring",      value: "ring"},
            {label: "Bauhaus",   value: "bauhaus"}
    ]

    const set_avatar = ({name, data }) => 
    {
        if (name == 'avatar_style')
            set_ava_style(     data.avatar_style )

        if (name == 'avatar_palette')
            set_ava_palette(    data.avatar_palette )
    }


    const change_color = (e,f) => {
        const idx = Math.round(Math.random() * 800)
        f.setFieldValue( "avatar_palette", colors[idx] )
    }


    return  <ComponentPanelRight className='wide person-details'>
                <Form className='narrow form table'
                    key={`form-${person.id}`}
                    data={person}
                    errors={errors} 
                    onChange={set_avatar}
                    onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {person ? person.loginname : ''}</div>
                    </div>
                    <div className='body'>
                        <MetaData key={`C-${person.id}`} indicator="person"
                            document={person} refetch={refetch} />
                        <div className='form-contents details'>

                            <div className='avatar'>
                                <Avatar
                                    size={70}
                                    square={true}
                                    name={`${person.id}-${person.loginname}`}
                                    variant={avatar_style}
                                    colors={avatar_palette}
                                />
                            </div>

                            <div className='formtable'>
                                {!person.system && formLineError('loginname',     'Login ID',     
                                    <TextBox required={false} name='loginname' placeholder="Auto-assigned if blank"/>)}
                                { person.system && formLineError('loginname', 'Login ID', <div>{person.loginname}</div>)}
                                {formLineError('firstname',     'First Name',   <TextBox required={true} name='firstname'   />)}
                                {formLineError('lastname',      'Last Name',    <TextBox required={true} name='lastname'   />)}
                                {formLineError('email',         'EMail',        <TextBox required={true} name='email'       />)}
                                {formLineBlank()}
                        {formLineBlank()}
                        {formLineBlank()}

                                {formLineBlank()}
                                {formLineError('avatar_style', 'Avatar', <Select name="avatar_style" required={true} options={avatar_options}/>)}
                                {formLine('', <ActionButton onClick={change_color}>Change Colors</ActionButton> )}

                        {formLineBlank()}
                                {formLineBlank()}
                                {formLineBlank()}                                
                                {formLineBlank()}
                                {!person.system && formLineError('admin_select',  'Roles',    
                                                <Select options={admin_options} name='admin_select'/>)}
                                { person.system && formLineError('admin_select', 'Roles',
                                                <i>{person.isadmin ? 'Always Admin Rights' : 'Never Admin Rights'}</i>)}
                            
                                {!person.system && formLineError('active_select', 'Active',   
                                                <Select options={active_options} name='active_select' />)}
                                { person.system && formLineError('active_select', 'Active',
                                               <div><i>Cannot be deactivated</i></div>)}

                                {formLineBlank()}
                                {formLineBlank()}                                
                                {formLineBlank()}
                                {person.id !== null && formLineError('passwd1', 'Change Password', <TextBox type='password' 
                                        min_length={5}
                                        name='passwd1' />)}
                                {person.id !== null &&  formLineError('passwd2', '', 
                                    <TextBox type='password' name='passwd2' min_length={5}  />)}

                            </div>
                        </div>
                        <div className='form-actions'>
                            <SubmitButton>Save</SubmitButton>
                            <button onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ComponentPanelRight>
}


export const PersonEditComponent = ({ person, refetch }) => 
{
    return <PersonEditForm person={person} refetch={refetch} />
}


export const PersonCreateComponent = ({ refetch  }) => {
    return <PersonEditForm person={{}} refetch={refetch} />
}


export const Person = ({ refetch, id }) => {

    return <PersonEditForm orig_id={id} person={{}} refetch={refetch} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>







export const PersonShow = ({ person, refetch }) => 
{
    if (!person)
        return null

        

    return (    
                <div className='body'>
                <MetaData key={`C-${person.id}`}
                        document={person} refetch={refetch} />
                    <div className='details'>
                        <div className='avatar'>
                            <Avatar
                                size={70}
                                square={true}
                                name={`${person.id}-${person.loginname}`}
                                variant={person.avatar_style}
                                colors={person.avatar_palette}
                            />
                        </div>
                        <table className='document-show'>
                            <tbody>
                                {person.loginname && 
                                    displayLine( person, 'Login ID',            'loginname')}
                                {displayLine( person,   'First Name',       'firstname')}
                                {displayLine(person,    'Last Name',        'lastname')}
                                {displayLine(person,    'EMail',            'email')}                                
                                <tr>
                                    <td className='title'>Roles</td>
                                    { person.isadmin && <td className='entry'>Admin User</td>}
                                    {!person.isadmin && <td className='entry'>Normal User</td>}
                                </tr>

 
                            </tbody>
                        </table>


                {person.deleted &&
                            <div className='helptext'>
                                <b>Inactive account</b> 
                                <p>Any records created by this account will remain in the system but will not be available to select for any future activities.</p>
                            </div>

                }

                {person.isadmin && !person.deleted &&
                    <div className='helptext'>
                        <b>Administrative account</b>
                        <p>This account cannot be deactivated, please remove admin rights first.</p>
                        <p>Administrative accocunts can see all configuration options, create and deleted users and change passwords.</p>
                    </div>

                }

                    </div>
                </div>)                
}


export const PersonShowComponent = ({ person, refetch }) => 
{
    if (!person)
        return null

    const {id}  = person

    const router = useRouter()


    const edit = (e) => {
        router.goto(`/person/edit/${id}`, { focus: 'r', i: null })
    }


    const send_password_mail = (e) => 
    {
        if (person)
            post( "/person/send_password_change_mail", {key: person.key})
    }

    const toggle_delete = (e) => 
    {
        if (person)
        {
            post("/person/delete", { key: person.key })
            .then( result => {

                console.log( result.data )
                if (result.data.success)
                {
                    if (result.data.deleted)
                        notify( 'success', `${person.loginname} deactivated`)
                    else
                        notify('success', `${person.loginname} reactivated`)
                }
                else
                {
                    notifyResult( result.data )
                }
                refetch()
            })
        }
    }

    const closePanel = (e) => {
        router.goto(`/person`, { focus: "l", i: null })
    }

    return  <ComponentPanelRight className='wide person-details'>
                <div className='head'>
                    <div className='title'>{false && person ? `${person.firstname} ${person.lastname} (${person.loginname})`  : ''}</div>
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
                <div className='body'>
                    <PersonShow person={person} refetch={refetch}/>
                </div>
                <div className='form-actions'>
                    <button onClick={edit} disabled={person?.deleted}>Edit</button>
                    {false && <button onClick={send_password_mail} disabled={person?.deleted}>EMail Login Details</button>}
                    <div className='spacer'/>
                    {person && <button onClick={toggle_delete} disabled={person?.isadmin}>
                        {person && person.deleted && 'Re-activate'}
                        {person && !person.deleted && 'Deactivate'}
                    </button>}
                </div>

            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, id)}>Create Copy</button>
