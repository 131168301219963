import { notification } from 'antd'

import { blank } from '../utils/utils';



export const notify = (type, message, duration = 1) => 
{
	if (!message)
		return

	if (Array.isArray( message ))
	{
		message.forEach( m => notify(type, m, duration))
		return
	}
	
	if (blank(message))
		return

	const args = {
		message: message,
		duration
	}
	notification.open(args)
}



export const notifyResult = (result, default_success = null, default_error = null ) =>
{	
	console.log( result )
	if (!result)
		return
	if (result.success)
	{
		if (result.messages && result.messages.length > 0)
			notify( 'success',  result.messages)
		else
			notify( 'success',  default_success)
	}
	else
	{
		if (result.error)
			notify( 'error', result.error)

		if (result.messages && result.messages.length > 0)
			notify( 'error', result.messages)
		else if (default_error)
			notify( 'error', default_error)

	}

}

