import React, {useContext} from 'react'
import { useRouter } from '../hooks/useRouter'
import { DBContext } from '../app/DBContext'
import { Tooltip } from 'antd'


const AppMenuEntry = ({ icon, id, url, highlight, tooltip }) =>
{
    const   router      = useRouter()
    const   hiclass     = highlight == id ? `${id}-bg` : '' 

    const   click = (e) => router.push(`${url}`)
    return  <Tooltip placement="right" title={tooltip}>
                <div className={`icon  ${hiclass}`} onClick={click} >
                    <div className={`fa-regular fa-${icon}`}/>
                </div>
            </Tooltip>
}


export const AppMenu = (props) =>   
{ 
    const context       =    useContext(DBContext)
    const is_logged_in  = context?.is_logged_in
    const is_admin      = context?.current_user?.isadmin

    return  <div className='app-menu'>
                {is_logged_in && <AppMenuEntry  icon='project-diagram'   
                                                url='/experiment'   
                                                tooltip='Experiments'   
                                                id='experiment' highlight={props.highlight} />}

                {is_logged_in && <AppMenuEntry  icon='vial'              
                                                url='/solution'     
                                                tooltip='Solutions'     
                                                id='solution'   highlight={props.highlight}/>}

                {is_logged_in && <AppMenuEntry  icon='flask'               
                                                url='/chemical'     
                                                tooltip='Chemicals'     
                                                id='chemical'   highlight={props.highlight} />}


                {is_logged_in && <AppMenuEntry  icon='solar-panel'        
                                                url='/substrate'    
                                                tooltip='Substrates'    
                                                id='substrate'  highlight={props.highlight}/>}

                {is_logged_in && <AppMenuEntry  icon='ruler-horizontal'
                                                url='/measurement'
                                                tooltip='Measurements'
                                                id='measurement' highlight={props.highlight} />}


                <div className='spacer nonexpand'>&nbsp;</div>

                {is_logged_in && <AppMenuEntry  icon='object-group'       
                                                url='/workspace'    
                                                tooltip='Current Workspace'    
                                                id='workspace'  highlight={props.highlight} />}

                <div className='expand'>&nbsp;</div>
                
                {is_admin     && <AppMenuEntry  icon='bars'               
                                                url='/resource'     
                                                tooltip='Edit Resources'     
                                                id='resource'   highlight={props.highlight} />}
                {is_admin     && <AppMenuEntry  icon='user'             
                                                url='/person'       
                                                tooltip='Users and Rights'       
                                                id='person'     highlight={props.highlight} />}
                {is_admin && <AppMenuEntry      icon='edit'                  
                                                url='/step' 
                                                tooltip='Edit Process Steps' 
                                                id='step' highlight={props.highlight} />}
            </div>
}
