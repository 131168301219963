import React, {useState,Fragment} from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm, useBackendData, fetchMulti } from '../hooks/useDownloaded'

import { MetaData } from '../metadata/MetaData'
import { EntityButton } from '../navigation/EntityButton'

import { humanize } from '../utils/utils'

import {
    Form, TextBox,
    SubmitButton,
    formLine, displayLine, FileUpload,
} from '../form/Form'


import backend_server from '../config/runconfig'
import axios from 'axios'




export const MeasurementEditForm = ({ orig_id, measurement, refetch }) => 
{
    const router = useRouter()
    const [uploads, set_uploads] = useState({})
    const [error,   set_error]   = useState(null)

    const result_url = (id) => {
        let url
        if (id)
            url = `/measurement/show/${id}`
        else if (orig_id)
            url = `/measurement/show/${orig_id}`
        else
            url = "/measurement"

        return url
    }

    const showResult = (id) => router.goto(result_url(id || measurement?.id), { focus: 'l' })
    const onComplete = (e)  => showResult(measurement?.id)


    const submitChanges = async (e, data) => {
        console.log("Submitted", data)

        const url = measurement.id ? `measurement/save/${measurement.id}`
            : `measurement/save`

        const result = await submitForm(url, data)

        if (refetch)
            refetch()

        if (result.data && result.data.success && result.data.id)
            showResult( result.data.id )
        else
            showResult( measurement?.id )
    }

    const errors = {}



    const update_progress = (filename, progress, message) => {
        if (progress == null)
            delete uploads[filename]
        else {
            if (!uploads[filename])
                uploads[filename] = {}

            uploads[filename].progress = progress
            uploads[filename].message = message
        }
        set_uploads(uploads)
    }


    const upload_file = (files) => {
        files.forEach(file => {
            update_progress(file.name, 0)

            const form_data = new FormData();

            
            let url = `${backend_server.server}/measurement/upload_file`
            if (measurement && measurement.id)
                url = `${url}/${measurement.id}`

            form_data.append("file", file)
            const req = axios.post(url, form_data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: (progress_event) => {
                        let percent = 0
                        if (progress_event.total > 0)
                            percent = Math.floor(progress_event.loaded * 100 / progress_event.total)
                        update_progress(file.name, percent)
                    }
                })

            req.catch(err => {
                //                    setState({
                //                        status: "error",
                //                        message: "Connection Failure"
                //                    })

                if (refetch)
                    refetch()

            })

            req.then(response => {
                const { data } = response
                if (data) {

                    console.log( data )

                    if (data.success) 
                    {
                        showResult( data.id )
                        update_progress(file.name, 1000)
                        setTimeout(() => update_progress(file.name, null), 5000)
                    }
                    else 
                    {
                        set_error( data.error )
                        update_progress(file.name, -1, data.error)
                    }
                }

                if (refetch)
                    refetch()
            });
        });
    }



    console.log( error )


    if (!measurement) 
    {
        return <ComponentPanelRight className='wide measurement-details'>
                    <div className='head'>
                        <div className='title'>Upload</div>
                    </div>
                    <div className='body'>
                        {error && <div className='error'>{error}</div>}
                    </div>
                </ComponentPanelRight>
    }

    return  <ComponentPanelRight className='measurement-details'>
                <Form className='narrow form table'
                    data={measurement}
                    errors={errors} 
                    onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {measurement ? measurement.id : ''}</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents'>
                            {error && <div className='error'>{error}</div>}

                            <div className='formtable'>
                                {measurement && formLine('File',
                                    <FileUpload onFileUpload={upload_file} name='file' />)}
                            </div>
                        </div>

                        <div className='form-actions'>
                            <button onClick={onComplete}>
                                Complete
                            </button>
                        </div>
                    </div>
                </Form>
            </ComponentPanelRight>
}


export const MeasurementEditComponent = ({ measurement, refetch }) => 
{
    return <MeasurementEditForm measurement={measurement} refetch={refetch} />
}


export const MeasurementCreateComponent = ({ refetch }) => 
{
    return <MeasurementEditForm  measurement={{}} refetch={refetch} />
}



export const Measurement = ({ refetch, id }) => {

    return <MeasurementEditForm orig_id={id} measurement={{}} refetch={refetch} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>


const MFields = ({ measurement }) => {
    if (!measurement || !measurement.metadata)
        return null

    const rv = []

    measurement.metadata.forEach(section => 
    {

        const rows = []
        Object.keys(section.entries).forEach(key => 
        {
            const val = section.entries[key]
            if (val !== null)
            {
                rows.push(
                    <tr key={key}>
                        <td className='title'>{humanize(key)}</td>
                        <td className='entry'>{`${val}`}</td>
                        <td />
                    </tr>
                )
            }
        });

        
        rv.push(    <section key={section.name}>
                        <h4>{humanize(section.name)}</h4>
                        <table className='document-show'>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </section>)
    });

    return <Fragment>{rv}</Fragment>

}



const MeasurementShow = ({ measurement, refetch }) => {
    if (!measurement)
        return null

    return <div className = 'body'>
                <MetaData key={`M-${measurement.id}`} 
                    indicator='substrate'
                    subtitle={measurement.kind}
                    subtitle_left={measurement.fields?.substrat_name}
                    title={measurement.full_id}
                     document={measurement} refetch={refetch} />

                <div className='details'>
                    <MFields measurement={measurement} />

                    <section>
                        <h4>Attachment</h4>

                        <Attachment value={measurement.file} />
                    </section>
            <section>
                <h4>Substrate</h4>
                {measurement.substrate && <EntityButton kind='substrate' entity={measurement.substrate} />}
            </section>                    

                </div>
            </div>
}




export const MeasurementShowComponent = ({ measurement, refetch }) => 
{
    if (!measurement)
        return null

    const {id}  = measurement

    const router = useRouter()


    const editSubstrate = (e) => router.goto(`/measurement/edit/${id}`, { focus: 'r', i: null })
    const closePanel    = (e) => router.goto(`/measurement`, { focus: "l", i: null })
    

    return <ComponentPanelRight className='measurement-details'>
                <div className='head'>
                    <div className='title'>{measurement ? measurement.id : ''}</div>
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
            
                <MeasurementShow measurement={measurement} refetch={refetch} />

                <div className='form-actions'>
                    {false && <button onClick={editSubstrate}>Upload</button>}
                </div>

            </ComponentPanelRight>
}




const Attachment = ({ value }) => {
    if (!value || value.length == 0)
        return null

    return  <div className='attachment'>
                <a href={`${backend_server.server}/${value.link}`}>{value.filename}</a>
            </div>
}