import React, { useState, useRef, useContext } from 'react'

import { DBContext } from '../app/DBContext'
import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'

import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer, ComponentMenuTop } from '../app-frame/AppFrame'

import { SubstrateComponentTop } from './SubstrateComponentTop'


import {
    SubstrateKindCreateComponent,
    SubstrateKindEditComponent,
    SubstrateKindShowComponent
} from './SubstrateKindEditor'




const initialFilter = {
    table: "boolean",
    id: "boolean",
    operator: "and",
    list: [
        {
            table: "substrate_kind",
            id: "title",
            value: ""
        }
    ]
}


export const SubstrateKindFrame = (props) => {

    const context = useContext(DBContext)
    const is_admin = context?.current_user?.isadmin

    const router = useRouter()
    const initialColumns = ["title", "created_at"]
    const [fts, setFts] = useState("")

    const { id } = router.params

    const [substrate_kind, refetchComponent] = useBackendData(`substrate_kind/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }

    const setTableRow = (id) => {
        router.goto(`/substrate-kind/show/${id}`, { focus: 'r', i: null })
    }

    const create = (e) => {
        router.goto(`/substrate-kind/create`, { fe: false, fo: false, focus: 'r', i: null })
    }


    let component_type = null
    const location = router.location.pathname

    if (location.startsWith('/substrate-kind/edit') && id)
        component_type = 'edit'
    if (location.startsWith('/substrate-kind/create'))
        component_type = 'create'
    else if (location.startsWith('/substrate-kind/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)


    const showFilter = component_type == null || component_type == 'show'

    return <AppFrame search_bar={true} highlight='substrate_kind' onSearchChange={setFts} layout_flag={component_type} title="Substrate Type">

                <ComponentMenuTop>
                    <SubstrateComponentTop editing={editing} />
                    <div className='filler' />
                    <div className='main-section'>
                        <div className='section'>
                            <button onClick={create}  disabled={editing || !is_admin}>
                                <span className='fa fa-wand-magic-sparkles' />
                                &nbsp;
                                Create...
                            </button>
                        </div>
                    </div>

                    <div className='metadata-section'>
                        <MetaDataMenu table='substrate_kind' view={viewRef} refetch={refetch} />
                    </div>                    

                </ComponentMenuTop>
        

                <ComponentContainer className='l-r'>
                    <ViewFrame ref={viewRef}
                        table="substrate_kind"
                        initialColumns={initialColumns}
                        initialFilter={initialFilter}
                        fts={fts}
                        dataURL='substrate_kind/get_filter'
                        rowID={id}
                        setTableRow={setTableRow}
                        showFilter={showFilter}>


                        {
                            component_type == 'show' && id &&
                            <SubstrateKindShowComponent     substrate_kind={substrate_kind} 
                                                            refetch={refetch} />
                        }
                        {
                            component_type == 'edit' && id &&
                            <SubstrateKindEditComponent     substrate_kind={substrate_kind} 
                                                            refetch={refetch} />
                        }
                        {
                            component_type == 'create' &&
                            <SubstrateKindCreateComponent   substrate_kind={substrate_kind} 
                                                            refetch={refetch} />
                        }
                    </ViewFrame>
                </ComponentContainer>

            </AppFrame>
}

