import { useDrag } from "react-dnd"
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useEffect }	from 'react'

export function isDragSource( props ) 
{
	const { type, item, name, indicator, item_key } = props

	const item_fn = () =>
	{
		const type_ = type || `NONE`

		let item_value 	= item
		if (typeof item_value == 'function')
			item_value 		= item_value(item_key)

		const multiple = Array.isArray(item_value)
		
		let n = null
		
		if (!multiple || multiple && item_value.length == 0)
			n = name
		if (multiple && item_value.length == 1)
			n = name || (item_value[0] && item_value[0].name)
		if (multiple && item_value.length > 1)
			n = `${item_value.length} ${type.substring( 0,1 ).toUpperCase()}${type_.substring(1).toLowerCase()}s`


		if (!n && !multiple)
			n = item_value && item_value.name
		if (!n)
			n = `${type_[0].toUpperCase()}${type_.substring[1].toLowerCase()}`

		let i = indicator
		if (!i && !multiple)
			i = item_value?.data?.indicator_class
		if (!i && multiple && item_value.length > 0)
			i = item_value[0]?.data?.indicator_class
		if (!i)
			i = 'dragging'

		const ic = multiple ? item_value.length : undefined
		return { name: n, indicator: i, item: item_value, item_key, multiple, item_count: ic }
	}



	const [collected, ref, preview] = useDrag(() => 
	({
		type: type || 'NONE',
		item: item_fn,
		collect: (monitor) => {
			return { 
				isDragging: monitor.isDragging(), 
				itemType: 	monitor.getItemType(),
				item: 		monitor.getItem()
			}
		}
	}))

	useEffect(() => { preview(getEmptyImage(), { captureDraggingState: true }) }, [])

	return [collected, ref, preview]
}

