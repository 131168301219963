import React, { useState } from 'react'
import { post } from '../hooks/useDownloaded';
import { Tooltip } from 'antd';

import './Colors.scss'

export const all_colors = [
	{ value: "non", label: "-None-" },
	{value: "red", label: "Red"},
	{value: "vio", label: "Violet"},
	{value: "blu", label: "Blue"},
	{value: "cya", label: "Cyan"},
	{value: "grn", label: "Green"},
	{value: "yel", label: "Yellow"}
]

export const ColorSelector = ({selected, onColorChange, table, id, expanded }) => 
{
	const [expanded_state, set_is_expanded] = useState(expanded);
	const [color_state,set_color] 			= useState(selected);

	const is_expanded = expanded_state || expanded == true

	const color = color_state ? color_state : 'non'


	const trigger_change = (c) => {
		set_color(c)
		setTimeout(() => {
			if (onColorChange)
				onColorChange(c)
		}, 100);
	}


	const click_select = (c) => (e) => 
	{ 
		e.stopPropagation(); 
		if (is_expanded)
		{
			if (table && id)
			{
				post( "/color/set", {obj_table: table, obj_id: id, color: c})
				.then( (result) => trigger_change(c) )					
			}
			else trigger_change(c)

			set_is_expanded( false )
		}
		else
			set_is_expanded( true )
	}

	return 	<div className={`color-selector ${is_expanded ? `is-expanded ` : ''}`}>
			{
			all_colors.map( (entry,i) => 
				{
					const c  	= entry.value 
					const show 	= is_expanded || c == color
					const active = c == color || c == 'non'

					if (show)
						return  <div key={c} className={`color-indicator ${active} col-${c} mark-${c}-fg`}  
							onClick={click_select(c)}>⬤</div>
					else
						return null
				}).filter( x => x)
			}
			</div>
}