import axios from 'axios'
import entry from 'react-datetime-picker'
import { backend_server } from '../config/runconfig'

import React, {useContext}  from 'react'
import { DBContext } from '../app/DBContext'

export class ResourceLoader
{
    constructor()
    {
        this.resource_types             = {}
        this.resource_types_by_subject  = {}
        this.resource_type_list         = []
        this.resources                  = {}
        this.optionlists                = {}
        this.ready                      = false
    }

    load( callback )
    {
        const url = `${backend_server.server}/resource_type/resource_tree?api_key=caa9041159f0b845c2902a2b705e889`
        console.log( url )
        axios.get( url )
             .then( result => {
                if (!result?.data)
                    throw "Resource load error"


                const map = result.data.dataset

                this.resource_types     = map
                Object.keys( map ).forEach( k => 
                {
                    const r = map[k]
                    const rs = r.resources

//                    r.resources.forEach( rr => r.has_code = r.has_code )
                    this.resource_types_by_subject[r.name]     = rs

                    this.resource_type_list.push( {id: r.id, 
                                        has_code: r.has_code, 
                                        title: r.title, name: r.name, description: r.description, resources: rs})

                    rs.forEach(rs => {
                        this.resources[rs.id] = rs
                    })

                    this.optionlists[r.id] = rs
                    .filter( r => !r.deleted )
                    .map( e =>
                    {
                        let desc = e.code ? `${e.code} ${e.title}` : e.title
                        if (desc.length > 40)
                            desc = `${desc.substring(0, 40)}...`

                        return {
                            value: e.id,
                            label: desc
                        }
                    })
                })                

                this.ready = true
                if (callback)
                    callback( {success: true})
             })

    }


    list( subject )
    {
        if (!subject || !this.resource_types_by_subject)
            return []
        
        return this.resource_types_by_subject[subject] || []
    }

    options( subject )
    {
        if (!subject || !this.optionlists)
            return []
        let entry = this.resource_type_list.find( t => t.name == subject )

        if (!entry)
            entry = this.resource_type_list.find(t => t.id == subject)

        if (!entry)
            return []

        return this.optionlists[entry.id] || []
    }


    kind_for(id) {
        if (!id)
            return null

        return this.resources_types[id]
    }


    value_for( id )
    {
        if (!id)
            return null

        return this.resources[id]
    }


    string_for(id) {
        if (!id)
            return null

        return this.resources[id]
    }

}



export const Resource = ({id}) => 
{
    const context = useContext( DBContext)
    if (!context || !context.resources)
        return <span>-</span>

    const resources = context.resources
    const entry     = resources.value_for( id )

    return <span>{entry?.title || '-'}</span>
}