import {processTemplates}	from '../model/process'





export class Experiment
{
	constructor()
	{
		this.name 			= name
		this.substrates		= []
		this.step_ids 		= []
		this.steps 			= {}
		this.db_step_ids 	= {}

		this.data			= {}
		this.step_counter 	= {}

		this.commit_queue	= []
	}


	create_from_serverjson( json )
	{
		this.id 		= json.id
		this.title 		= json.title
		this.timestamp  = Date.now()
		this.template 	= json.template
		this.planning 	= json.planning
		this.subject 	= json.subject
		this.bottle 	= json.bottle
		this.cap 		= json.cap
		this.materials 	= json.materials
		this.full_id 	= json.full_id

		this.updated_at = json.updated_at
		this.created_at = json.created_at 

		this.serial 	= Date.now()

		this.color 		= json.color

		this.indicator 	= 	this.subject == 'solution' 	? 'solution' :
							this.planning 				? 'planning' :
							this.template 				? 'template' :
						  								  'experiment'

		this.descriptor = 	this.subject == 'solution' 	? `S${this.id}` :
							this.planning 				? `E${this.id} (planned)` :
							this.template 				? `T${this.id}` :
														  `E${this.id}`

		if (`${this.title}`.trim().length == 0)
			this.title 	= this.descriptor


		json.process_steps.forEach( s => s.local_id = this.addStep( s, s.id ) )
		json.substrates.forEach(    s => s.local_id = this.addSubstrate( s ) )
		for( let sub_id in json.data )
		{
			const substrate = json.substrates.find( x => x.id == `${sub_id}` )
			for( let step_id in json.data[sub_id] )
			{
				const step 			= json.process_steps.find(x => x.id == `${step_id}`)
				const dataset 		= json.data[sub_id][step_id]

				this.updateProcessData( substrate, step.local_id, dataset.values, 
											dataset.completed, dataset.completed_at, dataset.skipped, dataset.completed_by )
			}
		}
		set_substrate_completion_status( json )

		// After updating on a load the commit queue should be empty
		this.commit_queue = []
	}


	addSubstrate( s )
	{
		if (!s.title)
			s.display_name = `${this.substrates.length + 1}`.replace(".", "").slice(-8)
		else
			s.display_name = s.title

		this.substrates.push( s )
	}


	create_step_id()
	{
		return this.convert_to_internal_id( this.step_ids.length + 1 )
	}

	convert_to_internal_id( s )
	{
		if (!s)
			return null
		if (typeof s == 'string')
			return s
		if (s.local_id)
			return s.local_id
		return "S" + `000000${s}`.slice(-7)
	}

	stepCountersDirty()
	{
		this.stepTitles = null
	}

	updateStepCounters()
	{
		if (this.stepTitles)
			return 

		this.stepTitles = {}
		this.step_ids.map((step_id) => 
		{
			const step = this.getStep(step_id)
			if (!step)
				return

			if (!this.stepTitles[step.step_kind_id])
				this.stepTitles[step.step_kind_id] = 0

			const n = this.stepTitles[step.step_kind_id] + 1

			this.stepTitles[step.id] = n			
			this.stepTitles[step.step_kind_id] 	= n
		})
	}

	getStepCounter( step )
	{
		if (!this.stepTitles)
			this.updateStepCounters()

		if (!step)
			return ''

		const n =  this.stepTitles[step.id]

		return `#${n}`

	}

	addStep( step, id  )
	{
		if (!step)
			return

		const step_id 		= id ? `S${id}` : this.create_step_id()

		if (id)
			this.db_step_ids[step_id] = step

		this.step_ids.push( step_id )
		this.steps[step_id] = step

		this.stepCountersDirty()

		return step_id
	}

	insertStep( step_kind_id, target_id )
	{
		const iit = this.convert_to_internal_id(target_id)

		const process 	= processTemplates[step_kind_id]
		const step_id 	= this.create_step_id()
		const position 	= this.step_ids.indexOf( iit )

		if (position < 0)
		{
			this.step_ids.push( step_id )
		}
		else
		{
			const p = position 
			this.step_ids.splice( p < 0 ? 0 : p, 0, step_id )
		}

		this.steps[step_id] = {step_kind_id, name: process.name, local_id: step_id}

		this.stepCountersDirty()

		return step_id
	}


	moveStep(source_id, target_id ) 
	{
		const iis = this.convert_to_internal_id(source_id)
		const iit = this.convert_to_internal_id(target_id)

		if (!iis)
			return

		this.step_ids = this.step_ids.filter( s => s != iis )
		const tposition = this.step_ids.indexOf(iit)

		console.log( this.step_ids )

		if (tposition < 0) {
			this.step_ids.push(iis)
		}
		else {
			const p = tposition 
			this.step_ids.splice(p < 0 ? 0 : p, 0, iis)
		}

		this.stepCountersDirty()

		console.log( this.step_ids )
	}

	removeStep( step_id )
	{
		const iid = this.convert_to_internal_id( step_id )
		this.step_ids = this.step_ids.filter( s => s != iid )

		this.stepCountersDirty()
	}

	dbStepMap()
	{
		const new_steps = this.step_ids.map((i,n) => 
		{ 
			const db 	= this.db_step_ids[i]
			if (db)
				return 	{ 	sort: 				"step", 	
						 	id: 				db.id, 
						 	internal_step_id: 	i  
						} 
			else
				return 	{ 	sort: 				"new", 	
							kind: 				this.steps[i].step_kind_id, 
							internal_step_id: 	i 
						}
		})

		return new_steps
	}


	// step_id can be a number (the id of a step)
	// the local string id 
	// or a step, in which case the local id is directly used
	getStep 		= (step_or_id) => 
	{

		if (!step_or_id)
			return null

		if (typeof step_or_id == 'string')
			return this.steps[step_or_id]

		if (typeof step_or_id == 'number')
			return this.steps[this.convert_to_internal_id(step_or_id)]


		if (step_or_id.local_id)
			return this.steps[step_or_id.local_id]


		return null
	}


	getStepIndex 	= (step_id) => step_id ? this.step_ids.indexOf(step_id) : -1

	getStepTemplate		= (step_or_id) => 
	{
		const step = this.getStep( step_or_id)

		if (!step)
			return null

		const sk_id = step.step_kind_id
		if (sk_id)
			return processTemplates[sk_id]
		else
			return null
	}

	getStepIndexFromParamIndex = (pi) 	=>
	{
		let c = 0
		for( let step_id of this.step_ids )
		{
			const step = this.steps[step_id]
			const template = processTemplates[step.step_kind_id]
	
			if (!template)
			{
				console.error( "Template not found ", step_id )
			}
			else
			{
				if (c + template.params.length > pi)
					return {step_id, parameter: template.params[pi - c], all_params: template.params}
				c += template.params.length
			}
		}
		return []
	}



	getRowCount = () => this.substrates.length

	getColCount	= () => 
	{
		let c = 0
		for( let step_id of this.step_ids )
		{
			const step = this.steps[step_id]

			const template = processTemplates[step.step_kind_id]
			if (template)
				c += template.params.length
			else
				console.error( "Template not found ", step.step_kind_id)
		}
		return c
	}


	make_key = ( substrate, step_id ) => `${substrate.id}-${step_id}`


//
//	setProcessData( sub_id, step_id, data )
//	{
//		if (!sub_id || !step_id || !data)
//			return
//		const key 	= this.make_key( sub_id, step_id )
//		this.data[key] = {completed: false, skipped: false, ...data}
//		return this.data[key]
//	}


	updateProcessData( substrate, step_id, data, completed = null, completed_at = null, skipped = null, completed_by = null )
	{
		if (!substrate || !step_id || !data)
			return
		const key 	= this.make_key( substrate, step_id )


		const template = this.getStepTemplate(step_id)
		if (!template)
		{
			console.log( "ERROR: Step Definition not found ", step_id)
			return
		}

		let transfer_data
		if (!this.data[key])
			transfer_data = {skipped: false}
		else
			transfer_data = {...this.data[key]}
		
		if 		(completed == true)
			transfer_data.completed = true
		else if (completed == false)
			transfer_data.completed = false

			
		transfer_data.completed_at 	= completed_at 
		transfer_data.completed_by 	= completed_by 	

		if (skipped == true)
			transfer_data.skipped = true
		else if (skipped == false)
			transfer_data.skipped = false

		for( let k of Object.keys(data))
		{
			const d = data[k]
			if (d !== undefined)
				transfer_data[k] = d
		}

		this.setupCompletionStatus( template, transfer_data )
		this.data[key] = transfer_data

		const command = {
			kind: "data-update",
			experiment_id: this.id,
			key: key,
			step_id: this.db_step_ids[step_id].id,
			sub_id: substrate.id,
			completed: transfer_data.completed,
			completed_at: transfer_data.completed_at,
			completed_by: transfer_data.completed_by,
			skipped: transfer_data.skipped,
			data: { ...this.data[key] }
		}

		this.commit_queue.push( command )
		return this.data[key]
	}


	setupCompletionStatus( template, data_set )
	{
		if (!data_set || !template)
			return
		data_set._all_required 	= true
		data_set._has_data 		= false

		template.params.forEach(p => {
			const data_point = data_set[p.id]
			const has_data = (data_point !== null && data_point !== undefined)

			if (p.required && !has_data)
				data_set._all_required = false

			if (has_data)
				data_set._has_data = true
		})
	}

	popCommitQueue()
	{
		const keys 			= {}
		const result 		= []
		let l = this.commit_queue.length
		for( let i = l-1; i >=0; i--)
		{
			const q = this.commit_queue[i]
			// This will squash the queue entries
			if (keys[q.key])
				continue
			keys[q.key] = true

			result.unshift( q )
		}
		this.commit_queue 	= []
		return result
	}


	toggleActive( sub_id, step_id )
	{
		if (!sub_id || !step_id)
			return
		const key 	= this.make_key( sub_id, step_id )
		if (!this.data[key])
			this.data[key] = {skipped: true, completed: false}
		else if (this.data[key].skipped === true)
			this.data[key].skipped = true
		else
			this.data[key].skipped = false
	}




	isActive( sub_id, step_id )
	{
		if (!sub_id || !step_id)
			return
		const key 	= this.make_key( sub_id, step_id )

		if (!this.data[key])
			return false
		else if (this.data[key].skipped !== true)
			return true
		else
			return false
	}

	filledState( substrate, step_id )
	{
		if (!substrate || !step_id)
			return
		const key 	= this.make_key( substrate, step_id )

		if (!this.data[key])
			return 'empty'
		else if (this.data[key].skipped !== true)
			return 'active'
		else
			return 'inactive'
	}



	getProcessData( substrate, step_id )
	{
		const template = this.getStepTemplate(step_id)
		if (!template || !substrate)
			return null

		const key 		= this.make_key( substrate, step_id )

		const data 		= this.data[key]
		this.setupCompletionStatus(template, data)

		return data
	}


	hasDataForStep( step_id )
	{
		const template = this.getStepTemplate(step_id)
		if (!template )
			return false

		let has_data = false
		for( let s of this.substrates)
		{
			const key 		= this.make_key( s, step_id )
			const data 		= this.data[key]
			if (data)
			{			
				this.setupCompletionStatus(template, data)
				has_data = has_data || data._has_data
			}
		}

		return has_data
	}

	setCompletion( substrate, step_id, completed )
	{
		if (!substrate || !step_id)
			return
		const key = this.make_key( substrate, step_id )
		if (this.data[key])
			this.data[key].completed = completed
	}


	getCompletion(substrate, step_id) 
	{
		if (!substrate || !step_id)
			return false
		const key = this.make_key(substrate, step_id)
		if (this.data[key])
			return this.data[key].completed
		else
			return false
	}


	setDBStepIDList( list )
	{
		for( let entry of list )
			this.db_step_ids[entry.internal_step_id] = entry
	}
}


export function getMaterialFrom( exp, mat )
{

	if (!exp || !exp.materials)
		return null

	const key = mat && mat.subject == 'chemical' ? `CHEM-${mat.id}` :
		mat && mat.subject == 'solution' ? `SOL-${mat.id}` :
			null

	if (!key)
		return null
	else
		return exp.materials[key]
}



export function set_substrate_completion_status(substrate)
{

	substrate.substrates.forEach(sub => {
		if (substrate.data && substrate.data[sub.id]) {
			const sub_data = substrate.data[sub.id]
			sub.contains_data = false
			sub.contains_completed = false

			Object.keys(sub_data).forEach(k => {
				const step = sub_data[k]
				if (step.values && Object.keys(step.values).length > 0)
					sub.contains_data = true
				if (step.completed)
					sub.contains_completed = true
			})

		}
	})
}



