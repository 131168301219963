import React, { Component } from "react"
import {DBContext} 			from "./DBContext"

import {MasterRouter} 		from '../router/MasterRouter'

import { ResourceLoader} 	from "../resource/resource"
import { ProcessLoader } 	from "../model/process"
import { ViewRegistry } 	from "../view/ViewRegistry"
import { KeywordRegistry }	from "../metadata/KeywordLoader"

import axios from 'axios'
import backend_server from "../config/runconfig"
import { NavLink } from "react-router-dom"

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { CustomDragLayer } from '../dnd/CustomDragLayer'

import ActionCable from "actioncable"
export class App extends Component
{
	constructor( props )
	{
		super(props)

		this.inflight_user 	= false
		this.state = {
			auth_token: 	null, 
			api_key: 		null,
			current_user: 	null, 
			resources: 		null,
			processes: 		null,
			views: 			null
		}

		this.setupAxios()
		this.setupWebSocket()

		this.callbacks 	= {}

		this.registerProcessCallbacks()
	}

	getAuthToken() {
		let token = this.state.auth_token || localStorage.getItem('auth-token')
		if (token == 'null')
			return null
		return token
	}

	getAPIKey()
	{
		return this.state.api_key 
	}

	getCurrentUser() {
		const user = localStorage.getItem('current-user')
		if (user == 'null')
			return null
		if (user)
			return JSON.parse( user )
		else
			return null
	}

	setupWebSocket()
	{	

		console.log( "SETUP WebSocket")
		const url = `${backend_server.websocket}/cable`

		this.ac_consumer = ActionCable.createConsumer( url )
		const sub = this.ac_consumer.subscriptions.create("StatusChannel",
		{
			received: (data) => this.handleCallWSCall( data )
		})

		return url
	}


	registerProcessCallbacks( )
	{
		this.registerWSCallback( "process-change", 	() => this.loadProcesses())
		this.registerWSCallback( "resource-change", () => this.loadResources())
		this.registerWSCallback( "view-change", 	() => this.loadViews())
	}

	setupAxios() 
	{
		axios.interceptors.request.use(config => 
		{
//			console.log( config.url )
			const auth_token = this.getAuthToken()
			if (auth_token) 
			{
				config.headers.Authorization = `Bearer ${auth_token}`
			}
			return config;
		},
			error => {
				console.log("Promise was rejected")
				return Promise.reject(error);
			});


		axios.interceptors.response.use(response => 
		{
			if (response.data &&
				response.data.status &&
				response.data.status == 'login') 
			{
				console.log("User not logged in")
				console.log( response.data )
				localStorage.setItem('auth-token', 		null)
				localStorage.setItem('current-user', 	null)
				localStorage.setItem('api-key', 		null)


				this.setState({ force_login: true, auth_token: null, current_user: null, api_key: null })
			}


			return response
		},
			error => {
				return Promise.reject(error);
			});
	}


	async fetchCurrentUser()
	{
		localStorage.setItem( "current-user", "")

		if (this.inflight_user)
			return

		this.inflight_user = true
		const result = await axios.get( `${backend_server.server}/login/loggedin_user`)
		if (result)
		{
			this.inflight_user = false
			const {data} 	= result

			if (data.success)
			{
				localStorage.setItem("current-user", JSON.stringify( data.person ))
				this.setState({ force_login: false })
			}
			else
			{
				localStorage.setItem("current-user", 	null)
				localStorage.setItem("auth-token", 		null)
				this.setState( {force_login: true})
			}
		}
	}

	reloadGlobals()
	{
		this.loadResources()
		this.loadProcesses()
		this.loadViews()
		this.loadKeywords()
	}


	loadResources()
	{
		const resources = new ResourceLoader()
		resources.load( result => {
			if (result.success)
				this.setState( {resources})
			else
			{
				console.error( "Error Loading Resources")
				this.setState({ error: "Network Error" })		
			}
		})
	}

	loadProcesses()
	{
		const processes = new ProcessLoader()
		processes.load(result => 
		{
			if (result.success)
				this.setState({ processes })
			else
			{
				console.error("Error Loading Processes")
				this.setState({ error: "Network Error" })
			}	
		})
	}

	loadViews( callback ) 
	{
		const views = new ViewRegistry()
		
		console.log( "LOADING VIEWS")

		views.load("caa9041159f0b845c2902a2b705e889", result => 
		{
			const cb = callback ? () => callback(result) : null
			if (result.success)
				this.setState({ views }, cb )
			else
			{
				console.error( "Error Loading Views")
				this.setState({ error: "Network Error" })		
			}

		})
	}

	loadKeywords(callback) {
		const keywords = new KeywordRegistry()

		keywords.load(result => {
			const cb = callback ? () => callback(result) : null
			if (result.success)
				this.setState({ keywords }, cb)
			else
			{
				console.error( "Error Loading Keywords")
				this.setState({ error: "Network Error" })		
			}

		})
	}



	componentDidMount()
	{
		this.reloadGlobals()
	}

	userController = 
	{
		logout: () => {
			localStorage.setItem("current-user", null)
			localStorage.setItem("auth-token", null)
			this.setState({ force_login: true })
		}
	}


	registerWSCallback( fn, action )
	{
		if (!action || !fn || `${fn}`.length == 0)
			return

		this.callbacks[fn] = action
	}




	
	handleCallWSCall( data )
	{
		const {fn,params} = data
		if (!fn || !data)
			return

		const callback = this.callbacks[fn]
		if (callback)
			callback( params )
	}

	render()
	{
		const is_login_page = window.location.pathname.startsWith( "/login")
		const token 		= this.getAuthToken()
		const current_user 	= this.getCurrentUser()

		if (!is_login_page)
		{
			if (!token || this.state.force_login)
			{
				window.location = '/login'
				return <div/>
			}
			else if (token && !current_user)
			{
				setTimeout(() => {this.fetchCurrentUser()}, 0);
				return <div/>
			}

			if (!this.state.resources || !current_user)
				return <div/>
		}


		const reloadViews = (c) => 
		{
			this.loadViews(c)
		}

		const reloadKeywords = (c) => 
		{
			this.loadKeywords(c)
		}

		const reloadGlobals = (c) =>
		{
			this.reloadGlobals(c)
		}

		const ready = 	this.state.resources?.ready == true
					&& 	this.state.processes?.ready == true
					&& 	this.state.views?.ready 	== true
					&& 	this.state.keywords?.ready 	== true


		if (ready)
			this.state.views.createDataSchemas( this.state.processes )


		const context_state = {
			ready 				: 	ready,
			resources			: 	this.state.resources,
			processes 			: 	this.state.processes,
			views 				: 	this.state.views,
			keywords 			: 	this.state.keywords,
			
			current_user 		: 	current_user,
			is_logged_in 		: 	!!current_user,
			userController		: 	this.userController,
			
			registerWSCallback 	: 	(fn, action) 	=> this.registerWSCallback( fn,action ),
			reloadGlobals		: 	() 			 	=> reloadGlobals(),
			reloadViews 		: 	(c) 		 	=> reloadViews(c),
			reloadKeywords 		: 	(c) 			=> reloadKeywords(c),

		}


		return  <DBContext.Provider value={context_state}>
					<DndProvider backend={HTML5Backend}>
						<CustomDragLayer/>
						<MasterRouter />
					</DndProvider>
				</DBContext.Provider>
	}
}


