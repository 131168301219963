import React, { Component, Fragment, useContext } from 'react'


import { AppFrame } from '../app-frame/AppFrame'
import {
    ComponentContainer, ComponentMenuTop,
    ComponentPanelRight
} from '../app-frame/AppFrame'

import { useRouter }            from '../hooks/useRouter'
import { useBackendData }       from '../hooks/useDownloaded'

import { SubstrateBatchTable }  from './SubstrateBatchTable'
import { SubstrateBatchCreateComponent, SubstrateBatchShowComponent}  from './SubstrateBatchEditor'
import { SubstrateComponentTop } from './SubstrateComponentTop'

import { InfoBoxTabList } from '../info/InfoBox'


export const SubstrateBatchFrame = (props) => 
{
    const router = useRouter()
    const [substrate_batches, refetch] = useBackendData(`substrate_batch/all`, [])

    const { id } = router.params
    const batch_id = id
    
    const reloadList = () => refetch()

    const set_batch = (id) => {
        router.goto(`/substrate-batch/show/${id}`, { fe: false, focus: 'r', i: null, fo: false })
    }




    let component_type = null
    const location = router.location.pathname

    component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)



    return  <AppFrame search_bar={true} highlight='substrate' layout_flag={component_type}>
                <ComponentMenuTop>
                    <SubstrateComponentTop editing={editing}/>
                    <div className='filler'/>
                </ComponentMenuTop>

                <ComponentContainer className='l-r'>
                    <SubstrateBatchTable data={substrate_batches} onClick={set_batch} selected_id={id}/>

                    {
                        component_type == 'show' && batch_id &&
                        <SubstrateBatchShowComponent id={batch_id} onSubmit={refetch} />
                    }
                    {
                        component_type == 'create' &&
                        <SubstrateBatchCreateComponent onSubmit={reloadList} onSubmit={refetch} />
                    }
                    <InfoBoxTabList refetch={refetch} />
                </ComponentContainer>
            </AppFrame>
}

