



const merge_layers = ( node, kind ) => 
{
	if (node.kind != kind)
		return node
	
	if (!node.parts || node.parts.length == 0)
		return null

	const new_parts = []
	for( let p of node.parts )
	{
		const new_node = merge_layers( p, kind )
		if (new_node == null)
		{}  //ignore
		else if (new_node.kind == kind)
			new_node.parts.forEach( p => new_parts.push( p ))
		else
			new_parts.push( new_node )
	}
	
	if (new_parts.length == 0)	
		return null
	if (new_parts.length == 1 && node.kind != 'root')	
		return new_parts[0]

	node.parts = new_parts
	return node
}


export const renumber_nodes = ( node, path = '0', n=0, depth = 0) =>
{
	node.depth = depth
	node.id    = path

	if (node.parts)
		node.parts.forEach((n, i) => renumber_nodes(n, `${path}/${i}`, i, depth + 1))
}


export const clear_blank_nodes = ( node ) =>
{
	if (!node || !node.kind)
		return null
	
	const {kind, ...rest}	= node

	let new_node = { kind }
	if (node.kind == 'dock')
	{
		if (!node.ids || (node.ids && node.ids.length == 0))
			return null

		new_node.ids = node.ids

		if (!node.selected_id || node.ids.indexOf( node.selected_id ) < 0)
			new_node.selected_id = node.ids[0]
		else
			new_node.selected_id = node.selected_id

	}

	if (node.parts)
	{
		new_node.parts = node.parts.map((n, i) => clear_blank_nodes(n))
												.filter( p => p !== null)

		return merge_layers( new_node, new_node.kind )
	}

	return new_node
}


export const setup_layout = (node, path = '', n = 0, depth = 0) =>
{
	let tree = clear_blank_nodes( node, path, n, depth )
	if (!tree)
		tree =  { kind: 'root', parts: [{kind: 'dock', ids: []}] }
	renumber_nodes( tree )

	return tree
}

export const create_root_layout = (child) =>
{
	return setup_layout( {kind: 'root', parts: [child]} )
}


export const find_part = ( root, fn, parent = null ) =>
{
	if (fn(root))
		return {part: root, parent}

	if (root.parts)
	{
		for( let p of root.parts )
		{
			const found = find_part( p, fn, root )
			if (found)
				return found
		}
		return null
	}
	else 
		return null
}


export const selectableSibling = ( root, id ) =>
{
	const srch = (node) => node.kind == 'dock'
		&& node.ids
		&& node.ids.indexOf(id) > -1

	const src = find_part(root, srch)

	if (!src)
		return null

	const ids = src.part.ids

	console.log( ids )

	const i = ids.indexOf( id )
	if (i == 0 && ids.length == 0)
		return null
	else if (i == 0)
		return ids[1]
	else
		return ids[i - 1]
}



export const removeTab = ( root, id ) => 
{
	const srch = (node) => node.kind == 'dock'
		&& node.ids
		&& node.ids.indexOf(id) > -1

	const src = find_part(root, srch)

	if (!src)
		return

	src.part.ids = src.part.ids.filter(p => p !== id)
	return setup_layout(root)
}



export const moveTab = ( root, id, tgt_tab_id ) =>
{
	const srch 	= (node) 	=> node.kind == 'dock' 
							&& node.ids 
							&& node.ids.indexOf( id ) > -1

	const src 	= find_part( root, srch )
	const tgt 	= find_part(root, (n) => n.id === tgt_tab_id)

	if (src)
		src.part.ids = src.part.ids.filter( p => p !== id )
	if (tgt)
		tgt.part.ids.push( id  )


	return setup_layout( root )
}


export const splitTab = (root, id, tgt_tab_id, kind ) =>
{
	const srch = (node) => node.kind == 'dock'
		&& node.ids
		&& node.ids.indexOf(id) > -1

	const tgt = find_part(root, (n) => n.id === tgt_tab_id)
	const src = find_part(root, srch)

	if (!tgt || !src)
		return root

	
	const src_part 				= src.part
	src_part.ids 				= src_part.ids.filter( p => p !== id )

	const tgt_part 				= tgt.part
	const tgt_copy 				= { ...tgt_part }
	const new_node 				= {kind: "dock", ids: [id]}

	
	if (kind == 'top')
	{
		tgt_part.kind 			= 'vertical'
		tgt_part.parts 			= [new_node, tgt_copy ]
	}

	if (kind == 'bottom')
	{
		tgt_part.kind 			= 'vertical'
		tgt_part.parts 			= [tgt_copy, new_node]
	}

	if (kind == 'left') 
	{
		tgt_part.kind 			= 'horizontal'
		tgt_part.parts 			= [new_node, tgt_copy]
	}

	if (kind == 'right') 
	{
		tgt_part.kind 			= 'horizontal'
		tgt_part.parts 			= [tgt_copy, new_node]
	}

	const final = setup_layout(root)


	return final
}




export const setFocusedExperiment = ( root, id ) => 
{
	const srch = (node) => node.kind == 'dock'
		&& node.ids
		&& node.ids.indexOf(id) > -1

	const src = find_part(root, srch)


	if (!src)
		return root

	src.part.selected_id = id 

	const final = setup_layout(root)
	return final
}



