import React, {Fragment, useContext}                from 'react'
import { useRouter }                    from '../hooks/useRouter'
import { ComponentPanelRight }          from '../app-frame/AppFrame'

import { ExperimentDataTimeLine }               from '../experiment/ExperimentData'

import { EntityButton }                 from '../navigation/EntityButton'

import { MetaData }                     from '../metadata/MetaData'

import { Download, DownloadJSON } from '../download/Download'

import { displayLine, displayLineValue } from '../form/Form'

export const SubstrateCreateComponent = ({refetch, substrate_id}) => 
{

    return <SubstateEditForm orig_substrate_id={substrate_id} substrate={ {} } refetch={refetch} />

}

// <button onClick={cloneThis(db, substrate_id)}>Create Copy</button>





export const SubstrateShow = ({ substrate, refetch }) => 
{
    const router = useRouter()
    if (!substrate)
        return null

    const link_to_batch     = () => { router.pushInfoBox('substratebatch',      substrate.substrate_batch.id) }
    const link_to_material  = () => { router.pushInfoBox('substratematerial',   substrate.substrate_material.id) }



//    const pin_substrate = async () => {
//        if (!substrate)
//            return
//
//        const url = '/material/toggle_pin'
//
//        const result = await post(url, {
//            subject: 'substrate',
//            subject_id: substrate.id
//        })
//
//        const args_active = {
//            message: 'Solution Pinned',
//            duration: 1,
//        };
//        const args_inactive = {
//            message: 'Solution Unpinned',
//            duration: 1,
//        };
//
//        const args = result.data.id ? args_active : args_inactive
//
//
//        notification.open(args);
//
//        refetch()
//    }

//    let pinpart = ''
//    if (substrate.pinned)
//        pinpart = <div>
//            <span className='fa-regular fa-thumbtack icon'></span>
//            <span onClick={pin_substrate}>Unpin from Workspace</span>
//        </div>
//    if (!substrate.pinned)
//        pinpart = <div>
//            <span className='fa-regular fa-thumbtack icon'></span>
//            <span onClick={pin_substrate}>Pin to Workspace</span>
//        </div>


    return (    <div className='body'>
                    <MetaData key={`C-${substrate.id}`}
                              document={substrate} 
                              refetch={refetch}>
                    </MetaData>

                    <div className='details'>

                        {substrate.notinuse && 
                            <div className='info'><i>Placeholder substrate on an experiment</i></div>}

                        {!substrate.notinuse &&
                            <table className='document-show'>
                                <tbody>
                                    {displayLine(substrate, 'Title', 'title')}
                                    {displayLine(substrate, 'Operator', 'operator_name')}

                                    {displayLine(substrate, 'Type',     'substratetype_name')}
                                    
                                    {substrate.substrate_batch &&
                                        displayLineValue(substrate.substrate_batch.display_title, 'Batch',
                                                            null, 'eye', link_to_batch)}
                                                            
                                    {substrate.substrate_material &&
                                        displayLineValue(substrate.substrate_material.display_title, 'Material',
                                                            null, 'eye', link_to_material)}
                                </tbody>
                            </table>}

                        
                        <section>
                            {!substrate.experiments || substrate.experiments.length == 0 &&
                                <div key='nosteps'><i>Substrate has not been used in an experiment.</i></div>}
                            
                            <ExperimentDataTimeLine  substrate={substrate} />
                        </section>


                        {!substrate.notinuse &&
                            <section>
                                <h4>Measurements</h4>
                                {!substrate.measurements || substrate.measurements.length == 0 &&
                                    <div key='nosteps'><i>No measurements have been uploaded</i></div>}
                                {substrate.measurements && substrate.measurements.map((m, i) => {
                                    return <EntityButton key={`eb-${i}-${m.id}`} kind='measurement' entity={m} />
                                })}
                            </section>}

                    </div>
                </div>)
}


export const SubstrateShowComponent = ({ substrate, refetch }) => 
{
    if (!substrate)
        return null

    console.log( substrate )

    const substrate_id = substrate.id

    const router = useRouter()


    const closePanel = (e) => 
    {
        router.goto(`/substrate`, {focus: "l", i: null}) 
    }

    return  <ComponentPanelRight className='wide substrate-details'>
                <div className='head'>
                    <div className='title'>{false && substrate_id}</div>
                    <DownloadJSON link={`substrate/get/${substrate_id}`} />
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
                <SubstrateShow substrate={substrate} refetch={refetch} />
            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, substrate_id)}>Create Copy</button>



