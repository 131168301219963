import { processTemplates } 	from '../model/process.js'
import {post} 					from '../hooks/useDownloaded'

export class TableController
{
	SELECT_NONE 			= 0
	SELECT_CELL 			= 1
	SELECT_BLOCK			= 2
	SELECT_COLUMN 			= 3
	SELECT_ROW 				= 4

	SELECT_CREATING 		= 5
	SELECT_MOUSEDOWN 		= 6


	constructor( parent, experiment_id )
	{
		this.uuid 			= Date.now()
		this.experiment_id 	= experiment_id
		this.parent 		= parent
		this.count 			= 1000


		// Later on we will make this user dependent, so for now we will use a default
		this.editable 		= !this.view_only

		this.gui_state 		= {
			parent: 				parent.gui_state,
			focused_process: 		null,
			focused_batch: 			null,
			closed_steps: 			{},
			edit_coordinates: 		{},
			last_edit_coordinates:	{},
			cell_editing: 			false,
			selection: 				{count: 0, status: this.SELECT_CELL, c:0, r:0},
			multi_ranges: 			[], 
			block_list: 			[],
			make_visible: 			false,
			table_focused: 			false
		}

		const cellDown = (experiment, row, column)  => 
							this.cellDown(experiment, row, column)
		const mouseUp  = (experiment, row, column)  => 
							this.mouseUp(experiment, row, column)
		const keyDown  = (experiment, row, column, canEdit) => 
							this.keyDown(experiment, row, column, canEdit)

		this.navigationEvents = { cellDown, mouseUp, keyDown }
	}


	experiment = () => 
	{
		if (this.parent)
			return this.parent.getExperiment( this.experiment_id )
		else
			null
	}







	normalize_selection(status, options)
	{
		if (!this.experiment())
			return

		const max_cols 	= this.experiment().getColCount()
		const max_rows 	= this.experiment().getRowCount()

		const fix_rows 	= (x) => Math.max( 0, Math.min( x, max_rows - 1) )
		const fix_cols 	= (x) => Math.max( 0, Math.min( x, max_cols - 1) )


		options.c = fix_cols(options.c)
		options.r = fix_rows(options.r)


		if (status == this.SELECT_CREATING)
		{
			if (options.cc0 === undefined || options.cc0 === null)
				options.cc0 = options.c
			if (options.cc1 === undefined || options.cc1 === null)
				options.cc1 = options.c

			if (options.rc0 === undefined || options.rc0 === null)
				options.rc0 = options.r
			if (options.rc1 === undefined || options.rc1 === null)
				options.rc1 = options.r
		}

		if (status == this.SELECT_COLUMN)
		{
			options.rc0 = 0
			options.rc1 = max_rows - 1
		}

		if (status == this.SELECT_ROW)
		{
			options.cc0 = 0
			options.cc1 = max_columns - 1
		}

		const {rc0,cc0, rc1,cc1} = options

		if (cc0 != undefined && cc1  != undefined )
		{
			options.c0 = fix_cols( Math.min( cc0, cc1 ) )
			options.c1 = fix_cols( Math.max( cc0, cc1 ) )
		}
		if (rc0 != undefined && rc1 != undefined )
		{
			options.r0 = fix_rows( Math.min( rc0, rc1 ) )
			options.r1 = fix_rows( Math.max( rc0, rc1 ) )
		}

		if (status == this.SELECT_COLUMN)
		{
			options.c   = fix_cols( options.c0 )
			options.r   = -1
		}

		if (status == this.SELECT_ROW)
		{
			options.r   = fix_rows( options.r0 )
			options.c   = -1
		}

		options.status = status
	}


	get_next_coordinate( editc, lastc )
	{
		const lr = lastc.r
		const lc = lastc.c
		const er = editc.r
		const ec = editc.c

		if (er - lr == 0 && ec - lc == 1)
			return { r: er, c: ec + 1 }
		else if (er - lr == 0 && ec - lc == -1)
			return { r: er, c: ec - 1 }
		else if (er - lr == 1 && ec - lc == 0)
			return { r: er + 1, c: ec }
		else if (er - lr == -1 && ec - lc == 0)
			return { r: er - 1, c: ec }
		else
			return { r: er + 1, c: ec}
	}


	set_cell_editing = ( new_status, cancelled, r,c ) =>
	{
		console.log( "EDITABLE ", this.editable )
		if (!this.editable)
			return

		this.gui_state.cell_editing = new_status
		let new_coordinate = null

		if (new_status)
			this.gui_state.edit_coordinates 		= {r,c}
		else
		{
			const editc = this.gui_state.edit_coordinates
			const lastc = this.gui_state.last_edit_coordinates

			if (lastc && editc && !cancelled)
				new_coordinate = this.get_next_coordinate( editc, lastc )

			this.gui_state.last_edit_coordinates = {...editc}
			this.gui_state.edit_coordinates = null
		}

		if (new_coordinate)
			this.setupSelection(this.SELECT_CELL, new_coordinate)
		
		console.log( this.gui_state.cell_editing )

		this.parent.update()
	}

	update_data 	 = () => this.parent.update()

	

	update_focus_target( kind, {r,c})
	{
		this.gui_state.focus_target = {kind,r,c}
	}

	setupFocusTarget(new_status = null, new_options = null, focus_target = null )
	{
		if (new_options && new_status) {
			const { r, c } = new_options
			if (focus_target)
				this.update_focus_target(new_status, focus_target)
			else
				this.update_focus_target(new_status, { r, c })
		}
	}


	updateSelection = (keep_visible = false, new_status = null, new_options = null, focus_target = null) => 
	{
		this.setupSelection( new_status, new_options )
		this.setupFocusTarget( new_status, new_options, focus_target )
		this.gui_state.keep_visible = keep_visible

		this.parent.update()
	}


	setupSelection = (new_status = null, new_options = null) => 
	{
		const 	options	= new_options || this.gui_state.selection || {}

		let 	status 	= new_status
		if (status == null)
			status = this.gui_state.selection.status || this.SELECT_NONE

		this.normalize_selection( status, options )

		this.update_options_with_selection( options )

		this.count ++
		this.gui_state.selection = {count: this.count, status, ...options}

		this.setup_blocklist()
	}



	setup_blocklist = () => 
	{
		const push_block = (s) => 
		{
			const block = {
				selected_batches: 	s.selected_batches,
				selected_step_ids:  s.selected_step_ids,
				focused_params: 	s.focused_params
			}

			if (!block.selected_batches  || block.selected_batches.length == 0)
				return
			if (!block.selected_step_ids || block.selected_step_ids.length == 0)
				return
			this.gui_state.block_list.push( block )
		}

		this.gui_state.block_list = []

		const {selection} = this.gui_state

		if (   this.gui_state.multi_ranges.length == 0 && selection.single_cell_select
			|| selection.focused_closed)
		{
			if (selection.selected_step_ids.length > 1)
			{
				this.gui_state.block_list = []
			}
			else
			{
				this.gui_state.block_list = [
					{
						selected_batches 	: [...selection.selected_batches],
						selected_step_ids 	: [selection.selected_step_ids[0]],
						focused_params		: (selection.all_params_for_focus || []).map( p => 
							{
						{
						}
							return { step_id: selection.focused_step, parameter: p }
						})
					}
				]
			}

			return
		}

		push_block( this.gui_state.selection )
		this.gui_state.multi_ranges.forEach( r => push_block(r) )
	}



    update_options_with_selection 	= (selection) =>
    {
    	let  {c,r,c0,c1, r0,r1} 	= selection
    	const exp 					= this.experiment()


		if (r0 === undefined || r1 === undefined)
		{
			r0 = r
			r1 = r
		}
		if (c0 === undefined || c1 === undefined) {
			c0 = c
			c1 = c
		}


    	if (c !== undefined)
    		selection.focused_step  = exp.getStepIndexFromParamIndex( c ).step_id
    	else
    		selection.focused_step  = undefined

    	if (r !== undefined)
    	{
    		selection.focused_batch = exp.substrates[r]
    		selection.selected_batches	= []

    		for( let rr = r0; rr <= r1; rr++)
			{
				const sub = exp.substrates[rr]
				if (sub)
    				selection.selected_batches.push( sub )
			}
       	}
    	else
    	{
    		selection.focused_batch 	= undefined
    		selection.selected_batches	= undefined
    	}

    	selection.multi_step_focus 	= false
    	if (c0 !== undefined && c1 !== undefined)
    	{
    		const focus0 			= exp.getStepIndexFromParamIndex( c0 ).step_id
    		const focus1 			= exp.getStepIndexFromParamIndex( c1 ).step_id

    		if (focus0 != focus1)
    			selection.multi_step_focus = true
    	}


 		selection.focused_params 	= undefined
 		selection.selected_step_ids	= []
 		selection.contains_open  	= false
 		selection.contains_closed  	= false
 		selection.focused_closed 	= this.is_closed( exp.id, selection.focused_step )

		// If the mouse has just been pressed and we are still in the first cell of a drag we should
		// keep the whole process selected otherwise the process editor jumps quite a bit

		const range_r 	= r1 !== undefined && r0 !== undefined ? r1 - r0 : 0
		const range_c	= c1 !== undefined && c0 !== undefined ? c1 - c0 : 0

		selection.single_cell_select = range_r == 0 && range_c == 0 // && status == this.SELECT_MOUSEDOWN

 		let step_id = null
		for( let cc = c0; cc <= c1; cc++ )
		{
    		const focused_id = exp.getStepIndexFromParamIndex( cc ).step_id
    		if (focused_id != step_id)
    		{
    			step_id = focused_id
    			selection.selected_step_ids.push( step_id )
    		}

			if (this.is_closed( exp.id, focused_id ))
				selection.contains_closed = true
			else
				selection.contains_open   = true
		}

    	if (!selection.multi_step_focus)
    	{
			if (selection.focused_closed)
			{
				const step_data = exp.getStepIndexFromParamIndex(c)
				selection.focused_params = step_data.all_params.map(p => {
					return { step_id: selection.focused_step, parameter: p }
				})
			}
			else
			{
				selection.focused_params = []
				for (let cc = c0; cc <= c1; cc++) {
					const focused = exp.getStepIndexFromParamIndex(cc)
					selection.focused_params.push(focused)
				}
			}
    	}

		const focused_single = exp.getStepIndexFromParamIndex(c)
		selection.all_params_for_focus = focused_single.all_params

    	selection.multi_batch_focus = selection.selected_batches && selection.selected_batches.length > 0
    	selection.focused_active 	= exp.isActive( selection.focused_batch, selection.focused_step)
    }



	startRangeSelect( r,c )
	{
		const {gui_state} 	= this
		const selectionmode = this.editable ? this.SELECT_MOUSEDOWN : this.SELECT_CELL
		gui_state.last_selection 	= {...gui_state.selection}
		gui_state.select_start		= {r,c}

		this.updateSelection( true, selectionmode, { 	c:   c, r:  r,
														cc0: c, rc0: r,
 														cc1: c, rc1: r })
	}



	// stopRangeSelect is called at the END of the selection process to fix the selection
	// to update the selection duringcreation use updateSelection
	stopRangeSelect()
	{
		if (!this.editable)
			return
		const {selection}	 		= this.gui_state

		const {cc0,rc0, cc1,rc1} 	= selection

		const cs0 = Math.min(cc0,cc1)
		const rs0 = Math.min(rc0,rc1)
		const cs1 = Math.max(cc0,cc1)
		const rs1 = Math.max(rc0,rc1)


		if (cs0 == cs1 && rs0 == rs1)
			this.updateSelection( true, this.SELECT_CELL,    {c: cc0,r: rs0})
		else
			this.updateSelection( true, this.SELECT_BLOCK,  	{	c  : cc1,		r  : rc1,
																	c0 : cs0,		r0 : rs0,
																	c1 : cs1,		r1 : rs1,
																})
	}



	selectHandleDown 	= (r,c) => (e) => {
			e.preventDefault()
			e.stopPropagation()
			const {selection} 			= this.gui_state
			const {c,r,c0,r0,c1,r1} 	= selection

			console.log("SHD ", { c, r, c0, r0, c1, r1 })

			this.updateSelection( false, this.SELECT_CREATING, { 	c:   c, r:   r,
																	cc0: c0, rc0: r0,
 																	cc1: c1, rc1: r1 }) }


	setFocusedExperiment( exp = null )
	{
		if (exp == null)
			this.parent.setFocusedExperiment( this.experiment_id )
		else
			this.parent.setFocusedExperiment( exp.id )
	}

	cellDown 	= (exp, r,c) => (e) =>
	{
		const {selection} 	= this.gui_state


		if ((e.shiftKey || e.altKey || e.ctrlKey) && (selection.status === this.SELECT_BLOCK || 
														selection.status == this.SELECT_CELL))
		{
			const new_selection = {...selection}
			this.update_options_with_selection( new_selection )
			this.gui_state.multi_ranges.push( new_selection )
		}
		else
			this.gui_state.multi_ranges = []


		this.setFocusedExperiment( exp )
		if (this.gui_state.cell_editing)
			return

		e.preventDefault()
		e.stopPropagation()
		e.target.focus()

		this.startRangeSelect( r,c )
	}

	mouseUp		= (exp, r,c) => (e) => 
	{ 
		const {selection} 	= this.gui_state
		const {status} 		= selection

		if (status == this.SELECT_CREATING || status == this.SELECT_MOUSEDOWN)
			this.stopRangeSelect()

		e.preventDefault()
		e.stopPropagation()			
	}


	mouseEnter 	= (exp, r,c) => (e) =>
	{
		if (!this.editable)
			return
		const {selection} 	= this.gui_state
		const {status} 		= selection

		if (status == this.SELECT_CREATING || status == this.SELECT_MOUSEDOWN)
			this.updateSelection( false, this.SELECT_CREATING, {...selection, cc1: c, rc1: r}, {r,c})
	}

	mouseLeave 	= (exp, r, c) => (e) =>
	{
//		this.setFocusedExperiment(exp)
	}


	focusTable = (e) =>
	{
		const inside = e.currentTarget.contains(e.relatedTarget)
		if (inside)
			return
		else
		{
			console.log( "Actual Focus")
			this.gui_state.table_focused = true
			this.parent.update()
		}
	}

	blurTable = (e) => 
	{
		const inside = e.currentTarget.contains(e.relatedTarget)
		if (inside) 
			return
		else {
			console.log( "Leave")
			this.gui_state.table_focused = true
			this.parent.update()
		}
	}

	mouseLeaveTable = (e) =>
	{
		const { selection } = this.gui_state
		const { status } = selection

		e.stopPropagation()
		e.preventDefault()

		this.panelElement = e.target

		if (status != this.SELECT_CREATING || this.isScrollingOutside)
			return

		this.setOutsideScrollSpeed(e, true)
		setTimeout(() => this.outsideScrollTimer(this), 100);

		this.outsideScrollEventMove 	= (e) => this.outsideScrollMove(e)
		this.outsideScrollEventMouse 	= (e) => this.outsideScrollMouseUpFinishRangeSelect(e)
			
		
		this.isScrollingOutside = true
		document.addEventListener( "mouseup", 	 this.outsideScrollEventMouse    )
		document.addEventListener("mousedown", 	this.outsideScrollEventMouse	 )
		document.addEventListener( "click", 	 this.outsideScrollEventMouse	 )
		document.addEventListener( "rightclick", this.outsideScrollEventMouse	 )
		document.addEventListener("contextmenu", this.outsideScrollEventMouse	 )
		document.addEventListener( "mousemove",  this.outsideScrollEventMove     )
	}


	// This function HAS to be of the form (e) => xxx due to JS this handling
	mouseReEnterTable = (e) => 
	{
		// We don't stop here, unline with a mouseUp since the selection just continues.
		if (this.isScrollingOutside)
			this.removeOutSideScrollHandlers()
	}

	setOutsideScrollSpeed( e, start = false )
	{
		const { clientX, clientY } = e

		// this could come from mouseMove in which case the target in the event is wrong
		if (start)
		{
			const { x, y, width, height } = e.target.getBoundingClientRect()
			this.minX = x
			this.minY = y
			this.maxX = x + width
			this.maxY = y + height
		}


		this.xSpeed 		= 0
		this.ySpeed 		= 0


		if (clientX > this.maxX)
			this.xSpeed = clientX - this.maxX
		else if (clientX < this.minX)
			this.xSpeed = clientX - this.minX

		if (clientY > this.maxY)
			this.ySpeed = clientY - this.maxY
		else if (clientY < this.minY)
			this.ySpeed = clientY - this.minY

		this.isScrollingOutside = true
	}

	outsideScrollMove = (e) => 
	{
		this.setOutsideScrollSpeed(e, false)
	}

	outsideScrollTimer = (controller) =>
	{
		if (controller.isScrollingOutside)
		{
			const { selection } 		 = this.gui_state

			const { cc1, rc1 } = selection
			
			let cTgt = cc1
			if (this.xSpeed < 0)
				cTgt = this.columnLeft(cc1)
			else if (this.xSpeed > 0)
				cTgt = this.columnRight(cc1)
			
			let rTgt = rc1
			if (this.ySpeed < 0)
				rTgt = rc1 - 1
			else if (this.ySpeed > 0)
				rTgt = rc1 + 1

			this.updateSelection(false, this.SELECT_CREATING, { ...selection, cc1: cTgt, rc1: rTgt })

			const exp  	  = this.experiment()
			const dom_id  = `${exp.id}-${rTgt}-${cTgt}`
			const element = document.getElementById(dom_id)
			if (element)
				element.scrollIntoView()
			
			setTimeout(() => controller.outsideScrollTimer(controller), 100);
		}
	}

	// This function HAS to be of the form (e) => xxx due to JS this handling
	removeOutSideScrollHandlers = (e) =>
	{
		document.removeEventListener("mouseup", 	this.outsideScrollEventMouse)
		document.removeEventListener("mousedown", 	this.outsideScrollEventMouse)
		document.removeEventListener("click", 		this.outsideScrollEventMouse)
		document.removeEventListener("rightclick", 	this.outsideScrollEventMouse)
		document.removeEventListener("contextmenu", this.outsideScrollEventMouse)

		document.removeEventListener("mousemove", 	this.outsideScrollEventMove)

		this.isScrollingOutside = false
	}

	// This function HAS to be of the form (e) => xxx due to JS this handling
	outsideScrollMouseUpFinishRangeSelect = (e) =>
	{
		console.log( "Finish" )
		this.stopRangeSelect()
		this.removeOutSideScrollHandlers()
	}

	outsideScrollMouseUpCancelRangeSelect = (e) => {
		console.log( "Cancel")
		this.stopRangeSelect()
		this.removeOutSideScrollHandlers()
	}



	inlineFormChange = ({data})	=>
	{
//		console.log( "Form change")
//		console.log( data )
	}


  	inlineFormSubmit = (event, data, cancelled = false)  =>
  	{
  		if (!cancelled)
  		{
  			const transfer  = {}
  			transfer[data.field.name] 	= data.value
  			this.experiment().updateProcessData( data.substrate, data.step_id, transfer, false, false )
			this.parent.startBatchUpdate(this.experiment().id)
		}

  		this.set_cell_editing( false, cancelled )
	}


	columnLeft( c )
	{
		const exp = this.experiment()
		const step_id = exp.getStepIndexFromParamIndex(c).step_id
		const template = processTemplates[step_id]

		if (c <= 0)
			return 0

		let target = c
		const step_data = exp.getStepIndexFromParamIndex(c - 1)
		if (this.is_closed(exp.id, step_data.step_id)) 
		{
			const l = template.all_params.length
			target = c - l
		}
		else
			target = c - 1

		if (target < 0)
			return 0
		else
			return target
	}

	columnRight( c )
	{
		const exp = this.experiment()
		const step_id = exp.getStepIndexFromParamIndex(c).step_id
		const template = processTemplates[step_id]
		const maxCols  = exp.getColCount()

		let target = c
		if (this.is_closed(exp.id, step_id)) 
		{
			const l = template.all_params.length
			target 	= c + l
		}
		else
			target 	= c + 1

		if (target >= maxCols)
			return maxCols - 1
		else
			return target
	}

	handleKeyboardEvent = ( event, row, column, cmd, startLetter ) =>
	{
		event.preventDefault()
		event.stopPropagation()

		const {selection} 	= this.gui_state
		const {c,r,status}	= selection
		const exp 			= this.experiment()

		if (event.shiftKey)
		{
			if (status == this.SELECT_CREATING)
				this.stopRangeSelect()
			else
				this.startRangeSelect(  r,c )
		}
		else
		{
			if 		(cmd == 'esc')
			{
				if (this.is_cell_editing)
				{
					this.set_cell_editing( false, true, r,c )
				}
				return
			}

			if 		(cmd == 'enter')
			{
				console.log( "Enter ", this.is_cell_editing )

				if (this.is_cell_editing)
				{
					console.log( "STOP" )
					this.set_cell_editing( false, false, r,c )
				}
				else
				{
					const step_id 	= exp.getStepIndexFromParamIndex( c ).step_id
					console.log("SET ", step_id )

					if (this.is_closed( exp.id, step_id ))
						return
					this.set_cell_editing( true, false, r,c )

				}
			}
			else if (cmd == 'up')
				this.updateSelection( true, this.SELECT_CELL, { c: c, r: r - 1 })
			else if (cmd == 'down')
				this.updateSelection( true, this.SELECT_CELL, { c: c, r: r + 1 })
			else if (cmd == 'left')
				this.updateSelection( true, this.SELECT_CELL, { c: this.columnLeft(c),  r: r })
			else if (cmd == 'right')
				this.updateSelection( true, this.SELECT_CELL, { c: this.columnRight(c), r: r })
		}		
	}

	keyDown = (exp, r,c, canEdit) => (event) =>
	{
		this.setFocusedExperiment(exp)
		
		const code = event.keyCode
		console.log( r,c, code, event.ctrlKey, event.altKey, event.shiftKey, event.metaKey )

		if 		(code == '13' &&  canEdit)
			this.handleKeyboardEvent( event, r,c, 'enter' )
		else if (code == '13' && !canEdit)
			return
		else if (code == '27')
			this.handleKeyboardEvent( event, r,c, 'esc' )
		else if (code == '38')
			this.handleKeyboardEvent( event, r,c, 'up' )
	    else if (code == '40')
	    	this.handleKeyboardEvent( event, r,c, 'down' )
	    else if (code == '37')
	    	this.handleKeyboardEvent( event, r,c, 'left' )
	    else if (code == '39')
	    	this.handleKeyboardEvent( event, r,c, 'right' )
		else if (code == '33')
			this.handleKeyboardEvent( event, r,c, 'pgup' )
		else if (code == '34')
			this.handleKeyboardEvent( event, r,c, 'pgdn' )
		else if (code == '35')
			this.handleKeyboardEvent( event, r,c, 'end' )
		else if (code == '36')
			this.handleKeyboardEvent( event, r,c, 'home' )
		else if ("01234567890-+E".indexOf( event.key ) > -1)
		{
//			console.log( "Start with number " + event.key )
		}
	}







	selectColumn = (cc0, cc1) 	=> (e) =>
	{
		this.updateSelection( false, this.SELECT_COLUMN, 	{cc0, cc1}, {r:-1, c:cc1} )
	}

	selectRow 	 = (rr0, rr1) 	=> (e) =>
	{
		this.updateSelection(false, this.SELECT_ROW, { rr0, rr1 }, { r:rr0, c:-1 } )
	}



	selected_type_for_selection = (selection, row, column) => {
		const { status, c, r, c0, r0, c1, r1 } = selection

		if (status == this.SELECT_CREATING &&
			row >= r0 && row <= r1 && column >= c0 && column <= c1)
			return this.SELECT_CREATING

		if (status == this.SELECT_MOUSEDOWN &&
			row >= r0 && row <= r1 && column >= c0 && column <= c1)
			return this.SELECT_CELL


		if (status == this.SELECT_COLUMN &&
			c0 == column)
			return this.SELECT_CELL

		if (status == this.SELECT_BLOCK &&
			row >= r0 && row <= r1 && column >= c0 && column <= c1)
			return this.SELECT_CELL

		if (status == this.SELECT_CELL &&
			row == r && column == c)
			return this.SELECT_CELL

		return this.SELECT_NONE
	}


	selected_type = (row, column) => {
		const stype = this.selected_type_for_selection(this.gui_state.selection, row, column)
		for (let rng of this.gui_state.multi_ranges) {
			const mrv = this.selected_type_for_selection(rng, row, column)
			if (mrv != this.SELECT_NONE && stype == this.SELECT_NONE)
				return mrv
		}
		return stype
	}

	is_selected = ( row, column ) => this.selected_type( row,column ) > 0

	is_main_selected = (experiment, row,column) =>
	{
		if (!this.gui_state.table_focused)
			return false;

		const exp = this.experiment()
		if (!exp || experiment.id != exp.id || exp.id != this.parent.focused_experiment_id)
			return false

		const {status, c,r} 	= this.gui_state.selection
		if (status != this.SELECT_CELL)
			return false

		return c == column && r == row
	}


	get_col_selection_data = ({experiment, col}) =>
	{
		if (!this.experiment() || experiment.id != this.experiment().id ||
			!this.gui_state.table_focused)
			return false

		const {status, c, c0, c1} 	= this.gui_state.selection

		if (status == this.SELECT_CREATING && col >= c0 && col <= c1)
			return this.SELECT_CREATING

		if (status == this.SELECT_MOUSEDOWN && col >= c0 && col <= c1)
			return this.SELECT_CELL

		if (status == this.SELECT_COLUMN && col == c0)
			return this.SELECT_CELL

		if (status == this.SELECT_ROW)
			return this.SELECT_CELL

		if (status == this.SELECT_BLOCK && col >= c0 && col <= c1)
			return this.SELECT_CELL

		if (status == this.SELECT_CELL && col == c)
			return this.SELECT_CELL

		return this.SELECT_NONE
	}


	get_row_selection_data = ({experiment, row}) =>
	{
		if (	!this.experiment() || 
				experiment.id != this.experiment().id || 
				!this.gui_state.table_focused)
			return false

		const {status, r, r0, r1} 	= this.gui_state.selection

		if (status == this.SELECT_ROW && row == r0)
			return this.SELECT_CELL

		if (status == this.SELECT_COLUMN)
			return this.SELECT_CELL

		if (status == this.SELECT_CREATING && row >= r0 && row <= r1)
			return this.SELECT_CREATING

		if (status == this.SELECT_MOUSEDOWN && row >= r0 && row <= r1)
			return this.SELECT_CELL

		if (status == this.SELECT_BLOCK && row >= r0 && row <= r1)
			return this.SELECT_CELL

		if (status == this.SELECT_CELL && row == r)
			return this.SELECT_CELL

		return this.SELECT_NONE
	}

	isActive = () => {
		const exp = this.experiment()
		return exp && exp.id == this.parent.focused_experiment_id 
					&& this.parent.focusedKind() == 'experiment'
	}

	get_selection_data = ({experiment, row,col}) =>
	{
		const exp 		= this.experiment()
		const active 	= this.isActive()

		if (!exp || experiment.id != exp.id || !active )
			return {selected_type: this.SELECT_NONE, is_main_selected: false, select_class: [], active }

		if (col === undefined)
			return this.get_row_selection_data( {experiment, row} )



		const selected_type 	= 	this.selected_type( row, col )
		const is_main_selected	= 	this.is_main_selected( experiment, row, col ) && this.gui_state.table_focused
		const select_class 		= 	is_main_selected &&  this.gui_state.table_focused ? ['selected', 'main'] :
									is_main_selected && !this.gui_state.table_focused ? ['selected'] :		
									!this.gui_state.table_focused			? [] :
									selected_type == this.SELECT_CELL 		? ['selected']  :
								  	selected_type == this.SELECT_CREATING 	? ['selecting'] :
									selected_type == this.SELECT_MOUSEDOWN  ? ['selected'] :
										  								   	[]

		const rv = {selected_type, is_main_selected, select_class}
		const {c0,r0,c1,r1} = this.gui_state.selection

		if (selected_type != this.SELECT_NONE)
		{
			rv.lft_edge = col == c0
			rv.rgt_edge = col == c1
			rv.top_edge = row == r0
			rv.bot_edge = row == r1
		}

		if (row == 0) 	 rv.select_class.push('first-row-edge')
		if (rv.top_edge) rv.select_class.push('top-edge')
		if (rv.bot_edge) rv.select_class.push('bot-edge')
		if (rv.lft_edge) rv.select_class.push( 'lft-edge')
		if (rv.rgt_edge) rv.select_class.push( 'rgt-edge')

		rv.select_handle = 	is_main_selected || 
							rv.bot_edge && rv.rgt_edge 				&& 
							selected_type != this.SELECT_CREATING 	&&
							selected_type != this.SELECT_MOUSEDOWN

		return rv
	}

	toggleOpenClose 	= (experiment_id, step_id) =>
						{
							const {closed_steps} 	= this.gui_state
							closed_steps[`${experiment_id}:${step_id}`] 	= !this.is_closed( experiment_id, step_id )

							this.parent.update()
						}

	is_closed			= (experiment_id, step) => {
							const {closed_steps} 	= this.gui_state
							const is_set 			= closed_steps[`${experiment_id}:${step}`]
							let rv = false
							if (is_set === undefined || is_set == null)
								 rv =  (this.gui_state.default_closed || false)
							else rv =  is_set
						//	console.log("Is closed? ", experiment_id, step, rv)

							return rv
						}


	selectEntireStep  	= (step_id) =>
	{	
		const exp = this.experiment()

		if (!exp)
			return

		const max_rows = exp.getRowCount()

		let cc0 = 0;
		for( let sid of exp.step_ids )
		{
			if (sid == step_id)
				break

			const template = exp.getStepTemplate(sid)
			if (!template)
				return
			cc0 += template.params.length
		}

		const template = exp.getStepTemplate(step_id)
		if (!template)
			return

		const cc1 	   = cc0 + template.params.length - 1


		this.setFocusedExperiment()

		this.updateSelection(true, this.SELECT_BLOCK, 
		{
			c: 	 cc0, 	r: 0,
			cc0, 		rc0: 0,
			cc1, 		rc1: max_rows
		})
	}


	addMaterial( material_row )
	{
		const exp = this.experiment()
		if (!exp || !material_row)
			return

		const material_data = { id: material_row.id.value, subject: material_row.subject.value }

		if (this.gui_state.block_list)
		{
			this.gui_state.block_list.forEach( b => 
			{
				b.focused_params.forEach( param => 
				{
					if (param.parameter.kind != 'material')
						return

					const data = {}
					data[param.parameter.id] = material_data

					b.selected_batches.forEach( batch => 
					{
						if (!exp.getCompletion( batch, param.step_id ))
							exp.updateProcessData( batch, param.step_id, data)					
					})
				})
			})
			this.parent.startBatchUpdate( exp.id )
		}

		const klass = material_data.subject == 'solution' ? 'Experiment' : 'Chemical'
		post('/history/visit', { klass: klass, id: material_data.id })		
	}
}

