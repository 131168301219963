import React from 'react'
import { useRouter }    from '../hooks/useRouter'
import { useContext }   from 'react'
import { useState } from 'react'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { post, submitForm } from '../hooks/useDownloaded'
import axios from 'axios'

import { DBContext } from '../app/DBContext'
import { MetaData } from '../metadata/MetaData'
import './chemical.scss'

import {
    Form, TextBox, CheckBox,
    SubmitButton,
    Select,
    FileUpload,
    Attachments,
    formLine, displayLineAlways, displayLineValue,
    Spacer
} from '../form/Form'
import backend_server from '../config/runconfig'

import {Tooltip, notification}   from 'antd'

import {Resource}                from '../resource/resource'
import { Download, DownloadJSON } from '../download/Download'
import { blank } from '../utils/utils'

export const ChemicalEditForm = ({ orig_id, chemical, refetch }) => 
{
    const [uploads, set_uploads] = useState({})

    const context = useContext(DBContext)
    const router = useRouter()


    if (!context || !context.resources || !chemical)
        return <ComponentPanelRight className='wide chemical-details'>
                    <div className='head'>
                        <div className='title'>Edit</div>
                    </div>
                    <div className='body'>
                    </div>
                </ComponentPanelRight>

    const resources = context.resources

    const result_url = () => {
        let url
        if (chemical.id)
            url = `/chemical/show/${chemical.id}`
        else if (orig_id)
            url = `/chemical/show/${orig_id}`
        else
            url = "/chemical"

        return url
    }



    const onCancel = (e) => {
        router.goto(result_url(), { focus: 'l' })
    }

    const submitChanges = async (e, data) => {
        console.log("Submitted", data)

        const url = chemical.id ? `chemical/save/${chemical.id}`
            : `chemical/save`

        const result = await submitForm(url, data)

        if (refetch)
            refetch()

        if (result.data && result.data.success && result.data.id)
            router.goto(`/chemical/show/${result.data.id}`, { focus: 'r', i: null })
        else
            onCancel()
    }

    const errors = {}


    if (!chemical) {
        return <ComponentPanelRight className='wide chemical-details'>
            <div className='head'>
                <div className='title'>Edit</div>
            </div>
            <div className='body'>
            </div>
        </ComponentPanelRight>
    }


    const hazards       = resources.options('hazard')
    const h_phrases     = resources.options('h-phrase')
    const p_phrases     = resources.options('p-phrase')
    const rooms         = resources.options('room')
    const purposes      = resources.options('purpose')
    const locations     = resources.options('location')

    const status        = resources.options('chem-status')


    const cmr           = resources.options('cmr')
    const wgk           = resources.options('wgk')
    const toxicity      = resources.options('toxicity')
    const lgk           = resources.options('lgk')
    


    const update_progress = (target, filename, progress, message) =>
    {
        if (progress == null)
            delete uploads[filename]
        else {
            if (!uploads[filename])
                uploads[filename] = {}

            uploads[filename].progress  = progress
            uploads[filename].message   = message
            uploads[filename].target    = target
        }
        console.log( uploads )
        set_uploads(uploads)
    }


    const upload_file = (target) => (files) => 
    {
        files.forEach(file => 
                {
                update_progress(target, file.name, 0)

                const form_data = new FormData();

                const url = `${backend_server.server}/chemical/upload_datasheet/${chemical.id}`

                form_data.append( "kind", target )
                form_data.append("file", file)
                const req = axios.post(url, form_data,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: (progress_event) => {
                            let percent = 0
                            if (progress_event.total > 0)
                                percent = Math.floor(progress_event.loaded * 100 / progress_event.total)
                            update_progress(target, file.name, percent)
                        }
                    })

                req.catch(err => {
//                    setState({
//                        status: "error",
//                        message: "Connection Failure"
//                    })
                })

                req.then(response => {
                    const { data } = response
                    if (data) {
                        console.log( data )
                        if (data.success) 
                        {
                            update_progress(target, file.name, 1000)
                            setTimeout(() => update_progress(file.name, null), 5000)
                        }
                        else {
                            const error = data.error
                            update_progress(target, file.name, -1, error)
                        }
                    }
                });
            });
        }


    return <ComponentPanelRight className='wide chemical-details'>
        <Form className='narrow form table'
            data={chemical}
            errors={errors} onSubmit={submitChanges} >
            <div className='head'>
                <div className='title'>Edit {chemical ? chemical.id : ''}</div>
            </div>
            <div className='body'>
                <div className='form-contents details'>
                    <div className='formtable'>

                        {formLine('Product Name',   <TextBox name='product_name'/>)}
                        {formLine('Name Alternatives', <TextBox name='name_alternatives' type='textarea'/>)}

                        {formLine('In Stock',       <CheckBox name='in_stock' />)}
                        {formLine('Purpose',        <Select name='purpose' options={purposes} />)}

                        {formLine('Manufacturer',   <TextBox name='manufacturer' />)}
                        {formLine('Item Number',    <TextBox name='item_number' />)}
                        {formLine('CAS Number',     <TextBox name='cas_number' />)}
                        {formLine('Product Name',   <TextBox name='product_name' />)}
                        {formLine('Supplier',       <TextBox name='supplier' />)}

                        <div className='line'>
                            <div className='field-name'>Amount</div>
                            <div className='control'>
                                <div className='with-unit'>
                                    <TextBox className='inputcontrol'   name='amount' kind="numeric" />
                                    <TextBox className='unit'           name='unit' />
                                </div>
                            </div>
                            <div className='error'>

                            </div>
                        </div>

                        {formLine('Room',           <Select  name='room'                options={rooms}/>)}
                        {formLine('Location',       <Select  name='location'            options={locations} />)}
                        {formLine('Delivery Date',  <TextBox name='delivery_date'       kind="datetime" />)}
                        {formLine('Batch Number',   <TextBox name='batch_number' />)}


                        {formLine('Gestis Link',    <TextBox name='gestis_link'  />)}

                        {formLine('Self-Igniting',  <Select  name='self_igniting'       options={['Yes', 'No']} />)}


                        
                        {formLine('CMR Category',   <Select name='cmr'                  options={cmr} />)}
                        {formLine('Acute Toxicity', <Select name='toxcicity'            options={toxicity} />)}
                        {formLine('WGK',            <Select name='water_class'          options={wgk} />)}
                        {formLine('LGK',            <Select name='storage_class'        options={lgk} />)}
                        {formLine('UN Number',      <TextBox name='un_number' />)}
                        {formLine('Hazard Class (ADR)',   <TextBox name='hazard_class' />)}
                        {formLine('Packing Group (ADR)',  <TextBox name='packing_group' />)}

                        {formLine('Hazard Codes',                   <Select name='hazard_codes' multiple={true} options={hazards} />)}
                        {formLine('Hazard Statements',              <Select name='h_phrases'    multiple={true} options={h_phrases} />)}
                        {formLine('Precautionary Statements',       <Select name='p_phrases'    multiple={true} options={p_phrases} />)}


                        {chemical && chemical.id && formLine('Mat. Safety DataSheets',
                            <FileUpload onFileUpload={upload_file("datasheet_m")} name='datasheet_m' />)}
                        {chemical && chemical.id && formLine('Technical DataSheets',
                            <FileUpload onFileUpload={upload_file("datasheet_t")} name='datasheet_t' />)}
                    </div>
                </div>
                <div className='form-actions'>
                    <SubmitButton>Save</SubmitButton>
                    <button onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </Form>
    </ComponentPanelRight>
}


export const ChemicalEditComponent = ({ chemical, refetch }) => 
{
    return <ChemicalEditForm chemical={chemical} refetch={refetch} />
}


export const ChemicalCreateComponent = ({ refetch  }) => {
    return <ChemicalEditForm chemical={{}} refetch={refetch} />
}


export const Chemical = ({ refetch, id }) => {

    return <ChemicalEditForm orig_id={id} chemical={{}} refetch={refetch} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>




const CodeList = (params) =>
{
    const { title, chemical, field, subject, icons } = params
    const resources = useContext(DBContext)?.resources    

    if (!resources || !chemical || !chemical[field] || `${chemical[field]}`.length == 0)
        return <div/>

    const values = chemical[field]


    const phraselist = values.map( (p,i) => 
    {
        const entry = resources.list(subject).find( r => r.id == p)

        if (!entry)
            return null

        return  <tr key={`row-${i}`}>
                    {entry.code && <td className='code'>{entry.code}</td>}
                    <td>{entry.title}</td>
                </tr>
    })

    let iconlist = []
    if (icons)
        iconlist = values.map((p, i) => 
        {
            const entry = resources.list(subject).find(r => p == r.id)

            if (entry)
                return <img key={i} className='hazard-icon' src={`${backend_server.server}/icons/${entry.code}.png`} />
            else
                return null
        })

    
    return  <div className='phrase-entry'>
                <h4>{title}</h4>
                {icons && <div className='icon-list'>{iconlist}</div>}
                <table>
                    <tbody>
                        {phraselist}
                    </tbody>
                </table>
            </div>
}






export const ChemicalShow = ({ chemical, refetch }) => 
{
    if (!chemical)
        return null


    const pin_material = async () => {
        if (!chemical)
            return

        const url = '/material/toggle_pin'

        const result = await post(url, {
            subject: 'chemical',
            subject_id: chemical.id
        })
        const args_active = {
            message: 'Chemical Pinned',
            description: `Chemical added to materials list in workspace`,
            duration: 1,
        };
        const args_inactive = {
            message: 'Chemical Unpinned',
            description: `Chemical removed from materials list in workspace`,
            duration: 1,
        };

        const args = result.data.id ? args_active : args_inactive


        notification.open(args);

        if (refetch)
            refetch()
    }


    let pinpart = ''
    if (chemical.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_material}>Unpin from Material List in Workspace</span>
        </div>

    if (!chemical.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_material}>Pin to Material List in Workspace</span>
        </div>


    const displayLineResource = ( title, field_name) =>
    {
        const v = chemical[field_name]
        if (!v)
            return displayLineValue("-", title)
        else
            return displayLineValue(<Resource id={chemical[field_name]} />, title)
    }



    const displayLineTextBox = (title, field_name) => 
    {
        const v = chemical[field_name]
        if (blank(v))
            return displayLineValue('-', title)
        else
        {
            const splitLines = v.split("\n").map((l, i) => <div key={`k=${i}`}>{l}</div>)
            return displayLineValue(<div>{splitLines}</div>, title)
        }
    }




    return  <div className='body'>
                <MetaData key={`C-${chemical.id}`}
                        document={chemical} refetch={refetch}
                        subtitle={chemical.full_id}>
                    <div className='metadata-section'>
                        <Tooltip placement='bottom' title="The chemical will appear in the Pinned section of the material list in the workspace">
                            {pinpart}
                        </Tooltip>
                    </div>
                </MetaData>
                    <div className='details'>
                        <table className='document-show'>
                            <tbody>
                                {displayLineAlways(chemical,  'Product Name',             'product_name')}
                                {displayLineTextBox( 'Name Alternatives',        'name_alternatives')}

                                {displayLineAlways(chemical,  'In Stock',         'in_stock')}

                                {displayLineResource( 'Purpose', 'purpose')}

                                {displayLineAlways(chemical,  'Manufacturer', 'manufacturer')}
                                {displayLineAlways(chemical,  'Item Number',           'item_number')}
                                {displayLineAlways(chemical,  'CAS Number',            'cas_number')}
                                {displayLineAlways(chemical,  'Supplier',              'supplier')}

                                {displayLineAlways(chemical,  'Amount',                'amount', 'unit')}
                                {displayLineResource( 'Room',                  'room')}
                                {displayLineResource( 'Location',              'location')}
                                {displayLineAlways(chemical,  'Delivery Date',         'delivery_date')}
                                {displayLineAlways(chemical,  'Batch Number',          'batch_number')}                        


                                {displayLineAlways(chemical,  'Gestis Link',            'gestis_link')}
                                {displayLineAlways(chemical,  'Self Igniting',          'self_igniting')}

                                {displayLineResource( 'CMR Category',          'cmr')}
                                {displayLineResource( 'Acute Toxicity',        'toxcicity')}
                                {displayLineResource( 'WGK',                      'water_class')}
                                {displayLineResource( 'LGK',                      'storage_class')}

                                {displayLineAlways(chemical,  'UN Number',             'un_number')}

                                {displayLineAlways(chemical,  'Hazard Class (ADR)',    'hazard_class')}
                                {displayLineAlways(chemical,  'Packing Group (ADR)',    'packing_group')}

                            </tbody>
                        </table>

                        <CodeList title='Hazard Codes' field='hazard_codes' subject='hazard' chemical={chemical} icons={true}/>
                        <CodeList title='Hazard Statements'       field='h_phrases'    subject='h-phrase' chemical={chemical}  />
                        <CodeList title='Precautionary Statements'     field='p_phrases'    subject='p-phrase' chemical={chemical} />


                        <Attachments title="Mat. Safety Datasheets"     value={chemical.datasheets_m} 
                                     root_url={backend_server.server}/>
                        <Attachments title="Technical Datasheets"           value={chemical.datasheets_m} 
                                     root_url={backend_server.server}/>
                    </div>
                </div>
}


export const ChemicalShowComponent = ({ chemical, refetch }) => {
    if (!chemical)
        return null

    const id = {chemical}

    const router = useRouter()

    
    const edit = (e) => {
        router.goto(`/chemical/edit/${id}`, { focus: 'r', i: null })
    }


    const closePanel = (e) => {
        router.goto(`/chemical`, { focus: 'l', i: null })
    }

    return  <ComponentPanelRight className='wide chemical-details'>
                <div className='head'>
                    <div className='title'>{false && chemical ? chemical.product_name : ''}</div>
                      <DownloadJSON link={`chemical/get/${id}`} />
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
                <ChemicalShow chemical={chemical} refetch={refetch} />
                <div className='form-actions'>
                    <button onClick={edit}>Edit</button>
                </div>

            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, id)}>Create Copy</button>
