import React, { FunctionComponent, Component, ReactElement } from 'react'
import { DataModel, DataModelSchema, DataModelColumn, DataType } from '../table-editor/model/model'
import { SortDir } from '../table-editor/model/model'
import { ViewSchema } from '../table-editor/viewschema'
import { View } from '../table-editor/View'
import { ViewConfiguration } from '../table-editor/ViewConfiguration'
import { ComponentPanelLeft } from '../app-frame/AppFrame'

const create_data_schema_sub = () => {
    const dcolumns = [
        new DataModelColumn("kind",    "Type",       DataType.String, true, true),

        new DataModelColumn("range",    "Start-End", DataType.String, false, false),

        new DataModelColumn("start_id", "Start",    DataType.Number),
        new DataModelColumn("end_id",   "End",      DataType.Number),
        new DataModelColumn("amount",   "Amount",   DataType.Number),

        new DataModelColumn("experiments", "Experiments", DataType.String),
        
        new DataModelColumn("created_at",  "Created",  DataType.TimeStamp),
    ]

    const data_schema = new DataModelSchema()
    dcolumns.forEach(col => data_schema.addColumn(col))

    return data_schema
}


const create_view_schema_sub = (data_schema: DataModelSchema) => {
    const view_schema = new ViewSchema(data_schema)

    view_schema.addViewColumn("kind")

    view_schema.addViewColumn("range")
    view_schema.addViewColumn("amount")

    view_schema.addViewColumn("created_at")

    view_schema.addViewColumn("experiments")

    view_schema.addCategory("kind",     SortDir.Up)
    view_schema.addSort(    "end_id",   SortDir.Up)

    return view_schema
}

const data_schema = create_data_schema_sub()
const view_schema = create_view_schema_sub(data_schema)


export const SubstrateBatchTable = (props: any) => 
{
    const { data } = props
    const data_model = new DataModel(data_schema)

    if (!data || !data.dataset)
        return  <ComponentPanelLeft className='experiment-list'>
                    <div className='head' />
                    <div className='body' />
                </ComponentPanelLeft>


    const select_batch = ( batch_id ) => 
    {
        if (props.onClick)
            props.onClick( batch_id )
    }

    if (data && data.dataset)
        data.dataset.forEach(row => {
            row.indicator_class = 'substrate'
            data_model.push(row)
        })

    const viewConfig = new ViewConfiguration(view_schema, data_model)
    return <ComponentPanelLeft className='experiment-list'>
        <div className='head'>
            <span>{data.count}/{data.full_count} entries</span>
        </div>
        <div className='body'>
            <View viewConfig={viewConfig} active={true} onClick={select_batch} selected_id={props.selected_id} />
        </div>
    </ComponentPanelLeft>
}


