import React, { useState, useRef } from 'react'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'


import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'

import { ComponentContainer } from '../app-frame/AppFrame'

import {
    SolutionCreateComponent,
    SolutionEditComponent,
    SolutionCloneComponent,
    SolutionCloneTemplateComponent,
    SolutionShowComponent
} from '../solution/SolutionEditor'




const initialFilter = {
    table: "boolean",
    id: "boolean",
    operator: "and",
    list: [
        {
            table: "solution",
            id: "title",
            value: ""
        }
    ]
}




const initialColumns = ["identifier", "title", "operator_name", "description"]


export const SolutionFrame = (props) => {

    const router = useRouter()
    const [fts, setFts] = useState("")

    const { id } = router.params


    const [solution, refetchComponent] = useBackendData(`experiment/get/${id}`, null)
    const viewRef = useRef()
    const refetch = (fetch_component) => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent && fetch_component)
            refetchComponent()
    }



    const setTableRow = (id) => {
        router.goto(`/solution/show/${id}`, { focus: 'r', i: null })
    }


    const createSolution = (_) => {
        router.goto(`/solution/create`, { focus: 'r', i: null })
    }

    const createTemplate = (_) => {
        router.goto(`/solution/create-template`, { focus: 'r', i: null })
    }    

    let component_type = null
    const location = router.location.pathname
    
    if (location.startsWith('/solution/edit/') && id)
        component_type = 'edit'

    else if (location.startsWith('/solution/edit') && !id)
        component_type = 'create'

    else if (location.startsWith('/solution/clone-template') && id)
        component_type = 'clone-template'

    else if (location.startsWith('/solution/clone') && id)
        component_type = 'clone'

    else if (location.startsWith('/solution/create-template') && !id)
        component_type = 'create-template'

    else if (location.startsWith('/solution/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)


    const showFilter = component_type == null || component_type == 'show'

    return <AppFrame search_bar={true} highlight='solution' onSearchChange={setFts} layout_flag={component_type}>
                <ComponentMenuTop>
                    <div className='filler'/>
                    <div className='main-section'>
                        <div className='section'>
                            <button onClick={createSolution} disabled={editing}>
                                <span className='fa fa-wand-magic-sparkles' />
                                        &nbsp;    Create Solution...
                            </button>
                        </div>
                    </div>

                    <div className='main-section'>
                        <div className='section'>
                            <button onClick={createTemplate} disabled={editing}>
                                <span className='fa fa-wand-magic-sparkles' />
                                        &nbsp;    Create Template...
                            </button>
                        </div>
                    </div>
                    <div className='metadata-section'>
                        <MetaDataMenu table='solution' view={viewRef} refetch={refetch} />
                    </div>
                </ComponentMenuTop>


                <ComponentContainer className='l-r'>
                    <ViewFrame ref={viewRef}
                        table="solution"
                        initialColumns={initialColumns}
                        initialFilter={initialFilter}
                        fts={fts}
                        dataURL='solution/get_filter'
                        rowID={id}
                        setTableRow={setTableRow}
                        showFilter={showFilter}>

                        {
                            component_type == 'show' && id &&
                            <SolutionShowComponent solution={solution} refetch={refetch} />
                        }
                        {
                            component_type == 'edit' && id &&
                            <SolutionEditComponent solution={solution} refetch={refetch} />
                        }
                        {
                            component_type == 'clone-template' && id &&
                            <SolutionCloneTemplateComponent solution={solution} refetch={refetch} />
                        }
                        {
                            component_type == 'clone' && id &&
                            <SolutionCloneComponent solution={solution} refetch={refetch} />
                        }
                        {
                            component_type == 'create' &&
                            <SolutionCreateComponent solution={solution} refetch={refetch} template={false} />
                        }

                        {
                            component_type == 'create-template' &&
                            <SolutionCreateComponent solution={solution} refetch={refetch} template={true} />
                        }
                    </ViewFrame>
                </ComponentContainer>
            </AppFrame>
}


