import axios from 'axios'
import { backend_server } from '../config/runconfig'

import { DataModelSchema, DataModelColumn, DataType }
	from '../table-editor/model/model'



export class ViewRegistry 
{
	constructor() {
		this.columns 		= {}
		this.queries 		= {}
		this.views 			= {}
		this.query_index	= []
		this.data_schemas 	= {}
	}


	createViewSchema(  column_json, processes, table )
	{
		const data_schema = new DataModelSchema(table)
		data_schema.setupFromColumnSpec( column_json, processes, table )

		return data_schema
	}


	createDataSchemas( processes )
	{
		if (this.data_schemas != null || !this.columns)
			return 

		this.data_schemas = {}
		Object.keys(this.columns).forEach(table => 
		{
			const columnset 		 = this.columns[table]
			this.data_schemas[table] = this.createViewSchema(columnset, processes, table)
		})
	}


	load(api_key, callback) 
	{
		const url = `${backend_server.server}/view/columns?api_key=${api_key}`
		console.log(url)
		axios.get(url)
			.then(result => 
			{
				if (!result?.data?.columns)
					throw "Columns load error"
				this.columns 		= result.data.columns
				this.data_schemas 	= null


				if (!result?.data?.queries)
					throw "Queries load error"
				this.queries = result.data.queries

				if (!result?.data?.views)
					throw "View load error"
				this.views = result.data.views


				this.ready = true
				if (callback)
					callback({ success: true })
			})
//			.catch(e => {
//				console.log(e)
//				this.ready = false
//				if (callback)
//					callback({ success: false, error: e })
//			})
	}
}