import React, { useState, useRef } from 'react'

import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer } from '../app-frame/AppFrame'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'


import { ChemicalCreateComponent, ChemicalShowComponent, ChemicalEditComponent } from './ChemicalEditor'





const initialFilter = {
    table: "boolean",
    id: "boolean",
    active: true,
    operator: "and",
    list: [
        {
            table: "chemical",
            active: true,
            id: "product_name",
            value: ""
        }
        
//        {
//            table: 'chemical', id: 'delivery_date'
//        }
    ]
}





export const ChemicalFrame = (props) => 
{

    const router = useRouter()
    const initialColumns = ["identifier", "product_name", "status", "manufacturer", "supplier"]
    const [fts, setFts] = useState("")

    const { id } = router.params

    const [chemical, refetchComponent] = useBackendData(`chemical/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }

    const setTableRow = (id) => {
        router.goto(`/chemical/show/${id}`, { focus: 'r' })
    }

    const create = (e) => {
        router.goto(`/chemical/create`, { fe: false, lf: false, fo: false })
    }


    let component_type = null
    const location = router.location.pathname

    if (location.startsWith('/chemical/edit') && id)
        component_type = 'edit'
    if (location.startsWith('/chemical/create'))
        component_type = 'create'
    else if (location.startsWith('/chemical/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)


    const showFilter = component_type == null || component_type == 'show'

    return  <AppFrame search_bar={true} highlight='chemical' onSearchChange={setFts}>
                <ComponentMenuTop>
                    <div className='filler' />
                    <div className='main-section'>
                        <div className='section'>
                           <button onClick={create} disabled={editing}>
                            <span className='fa fa-wand-magic-sparkles' />
                            &nbsp;
                            Create...
                        </button>
                        </div>
                    </div>
                    <div className='metadata-section'>
                        <MetaDataMenu table='chemical' view={viewRef} refetch={refetch} />
                    </div>
                </ComponentMenuTop>

                <ComponentContainer className='l-r'>
                    <ViewFrame  ref={viewRef}
                                table="chemical"
                                initialColumns={initialColumns}
                                initialFilter={initialFilter}
                                fts={fts}
                                dataURL='chemical/get_filter'
                                rowID={id}
                                setTableRow={setTableRow}
                                showFilter={showFilter}
                                dataDownloadAction='get_data_xlsx'>
                        {
                            component_type == 'show' && id &&
                            <ChemicalShowComponent chemical={chemical}  refetch={refetch} />
                        }
                        {
                            component_type == 'edit' && id &&
                            <ChemicalEditComponent chemical={chemical}  refetch={refetch} />
                        }
                        {
                            component_type == 'create' &&
                            <ChemicalCreateComponent        refetch={refetch} />
                        }
                    </ViewFrame>
                </ComponentContainer>
            </AppFrame>
}
