import React, {useContext} from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm, post } from '../hooks/useDownloaded'
import axios from 'axios'
import { backend_server } from '../config/runconfig'
import { usePersonSelection } from '../hooks/usePersons'

import { DBContext } from '../app/DBContext'
import { MetaData } from '../metadata/MetaData'

import { SolutionDataTimeLine } from '../experiment/ExperimentData'

import {Resource}   from '../resource/resource'

import {
    Form, TextBox, CheckBox, DateTime,
    SubmitButton, HideShow, Select, Error, displayLineAlways, displayLineAlwaysValue, displayLines
} from '../form/Form'

import {Download, DownloadJSON} from '../download/Download'

import { Tooltip } from 'antd'
import { notification } from 'antd'


export const SolutionEditForm = ({ orig_solution_id, solution, refetch, template, clone, verb }) => 
{
    const [person_options] = usePersonSelection()


    const resource = useContext(DBContext)
    if (!resource)
        return <ComponentPanelRight className='chemical-details'>
            <div className='head'>
                <div className='title'>Edit</div>
            </div>
            <div className='body'>
            </div>
        </ComponentPanelRight>


    const router = useRouter()

    const result_url = () => {
        let url
        if (solution.id)
            url = `/solution/show/${solution.id}`
        else if (orig_solution_id)
            url = `/solution/show/${orig_solution_id}`
        else
            url = "/solution"

        return url
    }

    const onCancel = (e) => {
        router.goto(result_url(), { focus: 'l' })
    }

    const submitChanges = async (e, data) => {
        const url = solution.id && !clone ? `solution/save/${solution.id}`
            : `solution/save`

        if (clone) {
            data.id = null
            data.template_id = solution.id
        }

        if (template == true && verb == 'Create')
            data.template = true;
        else if (verb == 'Clone')
            data.template = false
        else if (verb == 'CloneTemplate')
            data.template = true


        console.log("TPL ", template, data.template, verb)
        console.log(data)

        const result = await submitForm(url, data)

        if (refetch)
            refetch(verb == 'Edit')

        if (result.data && result.data.success && result.data.id)
            router.goto(`/solution/show/${result.data.id}`, { focus: 'r', i: null })
        else
            onCancel()
    }


    const errors = {}


    if (!solution) {
        return <ComponentPanelRight className='solution-details'>
            <div className='head'>
                <div className='title'>Edit</div>
            </div>
            <div className='body'>
            </div>
        </ComponentPanelRight>
    }


    const bottle    = resource.resources.options('bottle')
    const cap       = resource.resources.options('cap')

    return  <ComponentPanelRight className='§details'>
                <Form data={solution} errors={errors} onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {solution ? solution.id : ''}</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents details'>
                            <div className='formtable'>
                                <div className='line'>
                                    <div className='field-name'>Title</div>
                                    <div className='control'>
                                        <TextBox name="title" required={true} />
                                    </div>
                                </div>

                                <div className='line'>
                                    <div className='field-name'>Description</div>
                                    <div className='control'>
                                        <TextBox type='textarea' name="description" />
                                    </div>
                                </div>

                                <div className='line'>
                                    <div className='field-name'>Bottle Type</div>
                                    <div className='control'>
                                        <Select name='bottle' options={bottle} required={true} />
                                    </div>
                                </div>


                                <div className='line'>
                                    <div className='field-name'>Cap/Seal</div>
                                    <div className='control'>
                                        <Select name='cap' options={cap} required={true} />
                                    </div>
                                </div>

                                <div className='line'>
                                    <div className='field-name'>Operator</div>
                                    <div className='control'>
                                        <Select name="operator_id" options={person_options} required={true} />
                                    </div>
                                </div>


                                <div className='line'>
                                    <div className='field-name'>Notes</div>
                                    <div className='control'>
                                        <TextBox name='notes' type='textarea' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-actions'>
                            <SubmitButton>Save</SubmitButton>
                            <button onClick={onCancel}>
                                Cancel
                            </button>
                        </div>                        
                    </div>
                </Form>
            </ComponentPanelRight>
}



export const SolutionEditComponent = ({ solution, refetch }) => {
    if (!solution)
        return null
    return <SolutionEditForm solution={solution} clone={false} refetch={refetch} verb='Edit' />
}


export const SolutionCloneComponent = ({ solution, refetch }) => {
    if (!solution)
        return null
    const cloned_solution = { ...solution }
    cloned_solution.title = null
    return <SolutionEditForm solution={cloned_solution} clone={true} refetch={refetch} verb='Clone' />
}


export const SolutionCloneTemplateComponent = ({ solution, refetch }) => {
    if (!solution)
        return null
    const cloned_solution = { ...solution }
    cloned_solution.title = null
    return <SolutionEditForm solution={cloned_solution} clone={true} refetch={refetch} verb='CloneTemplate' />
}


export const SolutionCreateComponent = ({ refetch, solution, template }) => {
    if (!solution)
        return null

    return <SolutionEditForm orig_solution_id={solution?.id}
        template={template}
        solution={{}}
        clone={false}
        verb='Create'
        refetch={refetch} />

}



export const SolutionShow = ({ solution, refetch }) => 
{
    if (!solution)
        return null




    const pin_material = async () => {
        if (!solution)
            return

        const url = '/material/toggle_pin'

        const result = await post(url, {
            subject: 'solution',
            subject_id: solution.id
        })

        const args_active = {
            message: 'Solution Pinned',
            duration: 1,
        };
        const args_inactive = {
            message: 'Solution Unpinned',
            duration: 1,
        };

        const args = result.data.id ? args_active : args_inactive


        notification.open(args);

        refetch()
    }


    let pinpart = ''
    if (!solution.template && solution.pinned)
        pinpart = <div>
                        <span className='fa-regular fa-thumbtack icon'></span>
                        <span onClick={pin_material}>Unpin from Material List in Workspace</span>
                    </div>
    if (!solution.template && !solution.pinned)
        pinpart = <div>
                        <span className='fa-regular fa-thumbtack icon'></span>
                        <span onClick={pin_material}>Pin to Material List in Workspace</span>
                    </div>
        

    if (solution.template && solution.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_material}>Remove from Step Editor in Workspace</span>
        </div>
    if (solution.template && !solution.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_material}>Add to Step Editor in Workspace</span>
        </div>        

    const subtitle = solution.template ? 'Template' :
                     solution.planning ? 'In Planning' : null                     

    return  <div className='body'>

                <MetaData key={`C-${solution.id}`} indicator={`${solution.template ? 'stemplate' : 'solution'}`}
                     document={solution} refetch={refetch} subtitle={subtitle}>
                    <div className='metadata-section'>
                        <Tooltip placement='bottom' title="The solution will appear in the pinned section of the workspace">
                            {pinpart}
                        </Tooltip>
                    </div>
                </MetaData>

                <div className='details'>
                    <table className='document-show'>
                        <tbody>
                            {displayLineAlways(solution, 'Description',  'description')}
                            {displayLineAlways(solution, 'Operator',    'operator_name')}
                            {displayLineAlwaysValue( <Resource id={solution.bottle}/>, 'Bottle Type')}
                            {displayLineAlwaysValue(<Resource id={solution.cap} />, 'Cap/Seal')}
                            {displayLines(solution, 'Notes', 'notes')}
                        </tbody>
                    </table>



                    {!solution.process_steps || solution.process_steps.length == 0 &&
                        <div className='helptext' key='nosteps'><i>No steps have been added to the solution</i></div>
                    }

                    {solution.substrates && <SolutionDataTimeLine solution={solution} />}
                </div>
            </div>
}


export const SolutionShowComponent = ({ solution, refetch }) => {

    const solution_id = solution?.id
    if (!solution_id)
        return null

    const router = useRouter()

    
    const editSolution = (e) => {
        router.goto(`/solution/edit/${solution_id}`)
    }



    const closePanel = (e) => {
        router.goto(`/solution`, { focus: "l", i: null })
    }




    const add_to_workspace = async (e) => {
        const url = `${backend_server.server}/workspace/add_experiment/${solution_id}`
        await axios.post(url)
        window.location = '/workspace'
    }

    const create_clone = async (e) => {
        router.goto(`/solution/clone/${solution.id}`, { focus: 'r', i: null })
    }

    let title = null
    if (solution && solution.template)
        title = `Template ST${solution.id}`

    if (solution && !solution.template) 
        title = `Solution S${solution.id}`

    return  <ComponentPanelRight className='solution-details'>
                <div className='head'>
                    <div className='title'></div>
                    <DownloadJSON link={`experiment/get/${solution_id}`} />
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>

                </div>
                {!solution && <div className='body'></div>}
                {solution && <SolutionShow solution={solution} refetch={refetch} />}
                <div className='form-actions'>
                    <button onClick={editSolution} disabled={!solution}>Edit Metadata</button>


                    {solution && solution.template &&
                        <button onClick={create_clone}>Create Solution...</button>
                    }

                    <div className='spacer'>&nbsp;</div>

                    <button onClick={add_to_workspace}>Add to Workspace...</button>
                </div>

            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, solution_id)}>Create Copy</button>
