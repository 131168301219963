import React, { FunctionComponent, Component, ReactElement } from 'react'

import { ViewSelectionType, ViewConfiguration } from './ViewConfiguration'
import { DataIndexTree, DataIndexTreeKind } 	from './model/model'
import { View, getWidthCSS } 		 			from './View'
import { SortDir } from '../table-editor/model/model'
import {
	ViewSchema,
	ViewColumn,
} from './viewschema'



const setWidthCSS = (schema: ViewSchema, target: HTMLElement) => 
{
	const styles = getWidthCSS(schema)

	const root: HTMLElement | null = target.closest(".view-root")
	const styleText = Object.keys(styles).map(k => `${k}: ${styles[k]};`).join(" ")

	if (root)
		root.style.cssText = styleText
}


export class ViewTableController 
{

	viewConfig: ViewConfiguration
	closedSections: { [index: string]: boolean }

	viewIndex: DataIndexTree | null

	widthDragging: boolean
	widthDragStartX: number
	widthDragStartWidth: number
	widthDragID: number
	lastDragTime: number

	lastSelectedIndex: number | null

	viewTableRef: React.Ref<HTMLDivElement>

	viewRowHeadRefs: { [id: string]: React.Ref<HTMLElement> }
	viewRowBodyRefs: { [id: string]: React.Ref<HTMLElement> }
	viewRowTailRefs: { [id: string]: React.Ref<HTMLElement> }

	refresh: () => number
	refetch: (force: boolean) => void

	constructor( viewConfig: ViewConfiguration, refresh: () => number, refetch: (boolean) => void) 
	{
		this.viewConfig = viewConfig
		this.refresh = refresh
		this.refetch = refetch
		this.viewIndex = null

		this.closedSections = {}

		this.viewTableRef = React.createRef()
		this.viewRowHeadRefs = {}
		this.viewRowBodyRefs = {}
		this.viewRowTailRefs = {}

		this.widthDragging = false
		this.widthDragStartX = -1
		this.widthDragStartWidth = -1
		this.widthDragID = 0
		this.lastDragTime = 0

		this.lastSelectedIndex = null
	}


	update() 
	{
		this.viewConfig.schema.update()
		this.viewIndex = this.viewConfig.data_model.createIndex(this.viewConfig.schema, this.viewConfig.schema.sortOrders)
		this.updateSelection()
	}


	setViewConfiguration(view: ViewConfiguration) {
		this.viewConfig = view
	}

	repaint() {
		setTimeout(() => {
			this.refresh()
		}, 0);
	}

	
	updateSelection() 
	{
		if (this.viewIndex)
			this.viewConfig.selection.update( this.viewIndex )
	}

	selectAll(e: Event) 
	{
		this.viewConfig.selection.selectAll()
		this.updateSelection()
		this.repaint()
	}




	firstRecord(): number | null 
	{
		if (!this.viewConfig.data_model || this.viewConfig.data_model.recordSeq.length == 0)
			return null
		const idx = this.viewConfig.data_model.recordSeq[0]
		return this.viewConfig.data_model.data_set[idx]?.key
	}


	recordOffset( key : string | null | undefined, offset : -1 | 1 ) : number | null
	{

		if (!this.viewConfig.data_model || this.viewConfig.data_model.recordSeq.length == 0)
			return null

		const recordSeq = this.viewConfig.data_model.recordSeq
		
		const idx = this.indexOfID( key )

		if (idx === undefined || idx == null)
			return null

		let newIndex = idx + offset
		if (newIndex < 0)
			newIndex = 0
		if (newIndex >= recordSeq.length )
			newIndex = recordSeq.length - 1

		return this.viewConfig.data_model.data_set[recordSeq[newIndex]].key
	}


	indexOfID(key: string | null | undefined ) : number | null
	{
		if (!this.viewConfig.data_model || this.viewConfig.data_model.recordSeq.length == 0)
			return null

		if (key == null || key == undefined)
			return null
		
		const recordSeq = this.viewConfig.data_model.recordSeq

		for (let ii = 0; ii < recordSeq.length; ii++) {
			const ri = this.viewConfig.data_model.recordSeq[ii]
			const row = this.viewConfig.data_model.data_set[ri]
			if (row && `${row.key}` == key)
				return ii
		}

		return null
	}



	selectionChange(e: Event, node: DataIndexTree, row_id: number | null = null, shifted: boolean = false,
		start_index: number | null = null, end_index: number | null = null) 
	{
		if (!this.viewConfig.data_model)
			return


		const selection = this.viewConfig.selection

		if (shifted && this.lastSelectedIndex !== null && end_index !== null && row_id !== null) 
		{
			const start = Math.min(this.lastSelectedIndex, end_index)
			const end = Math.max(this.lastSelectedIndex, end_index)

			const newValue = !(selection.records[row_id] == true)

			for (let i = start; i <= end; i++) {
				const row = this.viewConfig.data_model.data_set[this.viewConfig.data_model.recordSeq[i]]
				if (row)
					selection.records[row.key] = newValue
			}
		}
		else if (row_id != null) {
			const newValue = !(selection.records[row_id] == true)
			selection.records[row_id] = newValue
			this.lastSelectedIndex = start_index
		}
		else {
			let cat_sel = selection.node_keys[node.id] || ViewSelectionType.None
			const new_val = cat_sel == ViewSelectionType.Some || cat_sel == ViewSelectionType.None
			node.dependents.forEach(d => selection.records[d] = new_val)
		}

		this.updateSelection()

		this.repaint()
	}





	sortColumn(column: ViewColumn, reset: boolean = false) {
		this.viewConfig.schema.toggleSort(column.column_id, false)
		this.refetch(true)
	}


	addCategory(column: ViewColumn) {
		this.viewConfig.schema.addCategory(column.column_id, SortDir.Up)
		this.refetch(true)
	}


	removeCategory(column: ViewColumn) {
		this.viewConfig.schema.removeCategory(column.column_id)
		this.refetch(true)
	}


	setSection(column: ViewColumn) {
		this.viewConfig.schema.setSection(column.column_id)
		this.repaint()
	}

	isClosed(node_key: string) : boolean {
		return this.closedSections[node_key] === true
	}

	toggleOpenClose(node_key: string) {

		if (!this.closedSections[node_key])
			this.closedSections[node_key] = false
		this.closedSections[node_key] = !(this.closedSections[node_key])
		this.repaint()
	}

	colWidthDragStart(clientX: number, colID: number) {
		const cols = this.viewConfig.schema.columns

		this.widthDragging = true
		this.widthDragStartX = clientX
		this.widthDragID = colID
		if (cols && cols.length > this.widthDragID)
			this.widthDragStartWidth = cols[colID].width
	}

	colWidthDragRun(e: { clientX: number, target: HTMLElement }) 
	{
		const { clientX, target } = e

		const { schema } = this.viewConfig
		const cols = schema.columns
		if (!cols || cols.length <= this.widthDragID)
			return

		// This is a hack, but it is because the browser set clientX to 0 BEFORE running dragEnd for
		// reasons unbeknownst. Since this is used it view Header it can really never happen in any case
		if (clientX == 0)
			return

		const oldWidth = cols[this.widthDragID].width
		let newWidth = clientX - this.widthDragStartX + this.widthDragStartWidth
		if (newWidth < 10)
			newWidth = 10

		if (newWidth == oldWidth)
			return

		//        console.log("DR ", "CX", clientX, "WDSX", this.widthDragStartX, "SWID ", this.widthDragStartWidth, newWidth, oldWidth)
		//        console.log(clientX - this.widthDragStartX)


		if (cols.length > this.widthDragID)
			cols[this.widthDragID].width = newWidth

		setWidthCSS(schema, target)

		this.lastDragTime = Date.now()
	}

	colWidthDragStop(clientX: number) {
		const cols = this.viewConfig.schema.columns
		if (!cols || cols.length <= this.widthDragID)
			return


		this.widthDragging = false
		this.repaint()
	}


	setViewColumnOption( vc: ViewColumn, id: string, value: any )
	{
		console.log( "Set Option ", id, value )
		vc.setViewColumnOption( id, value )
		this.refetch(true)
	}
}

