import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useBackendData, post } from '../hooks/useDownloaded'
import { ComponentPanelLeft } from "../app-frame/AppFrame";
import { MaterialTable } from "./MaterialTable";
import { Spacer } from '../form/Form'

import { notification } from 'antd'

const MAT_TAB_PINNED = 2
const MAT_TAB_RECENT = 3
const MAT_TAB_EXPERIMENT = 4
const MAT_TAB_WORKSPACE = 5


export const MaterialSelector = forwardRef((props, ref) => {
	const { experiment } = props
	const { fts } = props

	const searchTerm = !fts || `${fts}`.trim().length == 0 ? null : fts
	const fts_disabled = fts != null

	const [material, set_material] = useState(null)
	const [selected_tab, set_tab] = useState(MAT_TAB_PINNED)

	let query = null
	if (searchTerm)
		query = `/material/query?fts=${encodeURIComponent(searchTerm)}`
	else if (selected_tab == MAT_TAB_PINNED)
		query = `/material/pinned`
	else if (selected_tab == MAT_TAB_RECENT)
		query = `/material/recent`
	else if (selected_tab == MAT_TAB_EXPERIMENT && experiment)
		query = `/experiment/get_materials/${experiment.id}`
	else if (selected_tab == MAT_TAB_WORKSPACE)
		query = `/workspace/get_materials`

	const [materials, reload_materials] = useBackendData(query, null)


	const material_object = !materials?.dataset || !material?.id?.value ? null
		: materials.dataset.find(m => m.fields.id == material.id.value)?.fields

	useImperativeHandle(ref, () => ({
		reload() { reload_materials() }
	}))


	const handleRowSelect = (id, material, double) => 
	{
		if (props.onMaterialSelect)
			props.onMaterialSelect(id, material, double)
		set_material(material)
		if (double)
			onTailClick( id,material )
	}


	const clear_recents = async () => {
		const url = '/material/clear_recent'
		await post(url)
		reload_materials()
	}


	const onTailClick = (id, data) => {
		if (data?.id?.value && data?.kind?.value) {
			const klass = data.kind.value == 'Solution' ? 'Experiment' : 'Chemical'

			post('/history/visit', { klass: klass, id: data?.id?.value })
		}

		if (props.onTailClick)
			props.onTailClick(id, data)
	}


	const TabButton = ({ id, children, disabled }) => {
		const clazz = selected_tab == id ? 'selected' : ''
		return <button disabled={disabled} className={clazz} onClick={() => set_tab(id)}>{children}</button>
	}

	const exp_disabled = experiment && experiment.subject == 'solution'
	const desc = experiment && experiment.subject == 'solution' ? 'Solution' : 'Experiment'
	const selected_id = (props.focused && material_object) ? material_object.id : -1

	return 	<ComponentPanelLeft actions={true} className='tabbed experiment-list material-selector' >
				<div className='head'>
					<div className='title'>Materials</div>
				</div>
				<div className='tab-bar'>
					<TabButton disabled={fts_disabled} id={MAT_TAB_RECENT}>Recent</TabButton>
					<TabButton disabled={fts_disabled} id={MAT_TAB_PINNED}>Pinned</TabButton>
					{experiment &&
						<TabButton disabled={fts_disabled} id={MAT_TAB_WORKSPACE}>Workspace</TabButton>}
					{experiment &&
						<TabButton disabled={fts_disabled || exp_disabled} id={MAT_TAB_EXPERIMENT}>{desc}</TabButton>}
				</div>
				<div className='body'>
					<div className='results contents materialquery'>
						<MaterialTable data={materials}
							onMaterialSelect={handleRowSelect} 
							active={true}
							selected_id={selected_id}
							dragItemDescriptor={props.dragItemDescriptor}
							tailClick={props.tailClick}
							onTailClick={onTailClick} />
					</div>
				</div>
				<div className='form-actions'>
					{<button onClick={clear_recents} disabled={selected_tab != MAT_TAB_RECENT}>Clear Recents</button>}
				</div>
			</ComponentPanelLeft>
})