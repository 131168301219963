import React, { useState, Fragment } 	from 'react'
import { backend_server } 				from '../config/runconfig'
import { removeFilterMetaData } 		from '../filter/Filter'
import { downloadBinary } 						from '../hooks/useDownloaded'

export const DownloadJSON = ({link}) => 
{
	return null
	const [downloading, setDownloading] = useState( false )
	const startDownload = () => 
	{
		if (downloading)
			return
		setDownloading( true )

		setTimeout(() => setDownloading( false), 100);
	}

	const style = { 
		display: "hidden",
		width: "10px",
		height: "10px",
		opacity: 1,
		position: "fixed"
	
	}

//	return <a href={`${backend_server.server}/${link}`} target="_download">
//			<span className='fa-light fa-arrow-down-to-line'/>-
//		   </a>
		

	const api = 'caa9041159f0b845c2902a2b705e889'
	const url = `${link}?api_key=${api}`


	return 	<div className='right button'>
				<div className='download' onClick={startDownload}>
					<b><span className='fa-light fa-arrow-down-to-line'/></b>
				</div>
				{downloading && <iframe style={style} src={url} />}
			</div>
}



export const DownloadDocumentXLSX = ({ link }) => 
{
	const [downloading, setDownloading] = useState(false)



	const startDownload = () => 
	{
		if (downloading)
			return
		setDownloading(true)

		setTimeout(() => setDownloading(false), 10000);
	}

	const style = {
		display: "hidden",
		width: "10px",
		height: "10px",
		opacity: 1,
		position: "fixed"

	}

	//	return <a href={`${backend_server.server}/${link}`} target="_download">
	//			<span className='fa-light fa-arrow-down-to-line'/>-
	//		   </a>


	const api = 'caa9041159f0b845c2902a2b705e889'
	const url = `${link}?api_key=${api}`


	return 	<div className='right button'>
				<div className='download' onClick={startDownload}>
					<span className='fa-light fa-arrow-down-to-line' />
				</div>
				{downloading && <iframe style={style} src={url} />}
			</div>
}






export const DownloadViewXLSX = ({controller, action}) =>
{	
	const view_action = action || 'get_view_xlsx'

	const {table} 	= controller

	const [downloading, setDownloading] = useState(false)
	const startDownload = () => 
	{
		if (downloading)
			return

		const strippedFilter = removeFilterMetaData(controller.filter)

		const params = {
			query: 		strippedFilter,
			columns: 	controller.getColumns(),
			sort: 		controller.getSortOrder(),
			selected: 	controller?.viewConfiguration?.selection.specificSelected(true)
		}

		const api = 'caa9041159f0b845c2902a2b705e889'
		const url = `/${table}/${view_action}?api_key=${api}`

		console.log( url )
	
		downloadBinary(url, params)
			.then((response) => 
			{
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${table}.xlsx`); //or any other extension
				document.body.appendChild(link);
				link.click();

				setTimeout(() => setDownloading(false), 100);
			})
			.catch(e => console.log(e))

		//setDownloading(true)
		//setTimeout(() => setDownloading(false), 100);
	}

	const style = {
		display: "hidden",
		width: "10px",
		height: "10px",
		opacity: 1,
		position: "fixed"

	}





	return 		<div className='download' onClick={startDownload}>
					<span className='fa-light fa-arrow-down-to-line' />					
					&nbsp;&nbsp;
					{action != null ? 'Data' : 'View'}
					{downloading && <iframe style={style} src={url} />}
				</div>

}




export const DownloadWorkspaceExperiment = ({ experiment }) => 
{
	const [downloading, setDownloading] = useState(false)
	const startDownload = () => {
		if (downloading)
			return

		const url = `experiment/get_doc_xlsx/${experiment.id}`


		downloadBinary(url, {})
			.then((response) => {
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `experiment-${experiment.id}.xlsx`); //or any other extension
				document.body.appendChild(link);
				link.click();
				setTimeout(() => setDownloading(false), 100);
			})
			.catch(e => console.log(e))

		//setDownloading(true)
		//setTimeout(() => setDownloading(false), 100);
	}

	const style = {
		display: "hidden",
		width: "10px",
		height: "10px",
		opacity: 1,
		position: "fixed"

	}





	return 	<div className='download' onClick={startDownload}>
				<span className='fa-light fa-arrow-down-to-line' />
				{downloading && <iframe style={style} src={url} />}
			</div>

}