import React                    from 'react'
import { useState, useContext } from 'react'
import { ComponentPanelLeft, ComponentPanelDocked } from '../app-frame/AppFrame'

import { GUIStateContext }      from '../app/GUIContext'

import { useRouter }            from '../hooks/useRouter'

import classnames from 'classnames'
import { Popconfirm } from 'antd'

import { isDragSource } from '../dnd/isDragSource'
import { SortListItem } from '../dnd/SortableList'
import { useDrop }      from 'react-dnd'

//
//const dropHandler = (e) => {
//    e.preventDefault()
//    e.stopPropagation()
//    const data = e.dataTransfer.getData("text/plain");
//    const sp = data.split(":")
//
//    if (sp[0] == 'process-step') {
//        const source_id = parseInt(sp[2], 10)
//        controller.insertStep(source_id, step, open)
//        dragLeave(e)
//    }
//    else if (sp[0] == 'template-step') {
//        const source_id = parseInt(sp[2], 10)
//        controller.insertTemplate(source_id, step, open)
//        dragLeave(e)
//    }
//    else {
//        const source_step = sp[2]
//        controller.moveStep(experiment.id, source_step, step.local_id, open)
//        dragLeave(e)
//    }
//}


const StepButton = ({ step, experiment, editing, i }) => 
{
    const process = experiment.getStepTemplate( step )
    const { controller } = useContext(GUIStateContext)
    const guiController = controller.getGuiController(experiment.id)



    const counter = experiment.getStepCounter( step )


    const removeHandler = (e) => 
    {
        controller.removeStep(experiment.id, step.local_id)
    }

    const dragStart = (e) => 
    {
        e.dataTransfer.setData("text/plain", "step:move:" + step.local_id);
        e.dataTransfer.dropEffect = "move";
        set_dragging(true)
    }

    const selectStep = () => 
    {
        controller.selectEntireStep( experiment.id, step.local_id )
        const header_el = document.getElementById( `step-header-${step.local_id}`)
        if (header_el)
            header_el.scrollIntoView()
    }

    const openCloseStep   = () => 
    {
        if (guiController)
            guiController.toggleOpenClose( experiment.id, step.local_id )
    }
    const startHighLight  = () => {}
    const stopHighLight   = () => {}


    const is_closed       = guiController && guiController.is_closed( experiment.id, step.id )
    const oc_indicator    = guiController ? (is_closed ? 'fa-caret-right' : 'fa-caret-left') : ''

    const bg_class = guiController?.gui_state?.selection?.focused_step == step.local_id ? 'selected' : ''

    const can_delete = !experiment.hasDataForStep( step.local_id )
    const drop = (item, _, item_type) => 
    {
        if      (item_type == 'STEP')
            controller.moveStep(experiment.id, item.local_id, step.local_id)
        else if (item_type == 'STEP-TEMPLATE')
            controller.insertStep(item.id, step)
        else if (item_type == 'EXP-TEMPLATE' || item_type == 'MATERIAL')
            controller.insertTemplate(item.id, step)
    }

    const [{ isDragging, item }, dragRef] = isDragSource({
        type: 'STEP',
        name: `${process?.title || 'STEP'}`,
        indicator: 'step',
        item: () => step
    })

    return  <SortListItem accept={['STEP', 'EXP-TEMPLATE', 'STEP-TEMPLATE', 'MATERIAL']} onDrop={drop}>
                <div className={classnames('button-container')}>
                    <div className={`button step-button ${bg_class}`}
                        onClick={selectStep}
                        onDoubleClick={openCloseStep}
                        onMouseEnter={startHighLight}
                        onMouseLeave={stopHighLight} >
                        {editing && <div className='fa-regular fa-grip-vertical drag-grip step-bg' ref={dragRef} />}
                        {!editing && <div className='drag-grip step-bg' />}
                        <div className='title'>
                            {i !== undefined && <span className='ctr'>{i}</span>}
                            <span>{process?.title}</span>&nbsp;
                            <span>{counter}</span>
                        </div>
                        { can_delete && editing && 
                            <span className='icon  field-clear-button' onClick={removeHandler}>✕</span>}

                        {!can_delete && editing &&
                            <Popconfirm	title = "This step contains data. Are you sure you wish to delete it?"
                                onConfirm = {removeHandler}
                                okText = "Yes"
                                cancelText = "No" >
                                <span className='icon  field-clear-button'>✕</span>
                            </Popconfirm>
                            }

                    </div>
                </div>
            </SortListItem>
}







export const StepComponent = (props) => 
{
    const router            = useRouter()
    const { controller }    = useContext(GUIStateContext)
    const experiment        = controller.focusedExperiment()

    const drop = (item, item_type) => 
    {
        if (item_type == 'STEP')
            controller.moveStep(experiment.id, item.local_id)
        else if (item_type == 'STEP-TEMPLATE')
            controller.insertStep(item.id)
        else if (item_type == 'EXP-TEMPLATE')
            controller.insertTemplate(item.id)
        else if (item_type == 'MATERIAL')
            controller.insertTemplate(item.id)            
    }

    const [{ isOver }, dropRef] = useDrop(() =>
    ({
        accept: ['STEP', 'EXP-TEMPLATE', 'STEP-TEMPLATE', 'MATERIAL'],
        collect: (monitor) => ({ isOver: monitor.isOver(), item: monitor.getItem() }),
        drop: ({ item }, monitor) => {
            if (!monitor.didDrop()) 
                drop(item, monitor.getItemType())
        }
    }))

    const hasSteps = experiment && experiment.step_ids?.length > 0
    const mode_lft     = router.param("mode_lft", hasSteps ? 'stp' : 'stpedit')
    const editing   = mode_lft == 'stpedit'

    const toggleEditState = () =>
    {
        if (editing)
            router.setFlag({ mode_lft: 'stp' })
        else
            router.setFlag({ mode_lft: 'stpedit' })
    }


    if (!experiment)
        return  <ComponentPanelLeft className={`template-list step-list`} key={`no-experiment`} >
                    <div className='head'>
                        <div className='title'>Process Steps</div>
                    </div>
                    <div className='body'>
                        <div className='helptext'>
                            Select an experiment to view the steps
                        </div>
                    </div>
                </ComponentPanelLeft >


    
    const titles = {}


    return (
        <ComponentPanelLeft className={`template-list step-list `} key={`${experiment.id}`}>
            <div className='head'>
                <div className='title'>Process Steps</div>
                <div className='icon fa-regular fa-cog' onClick={toggleEditState} />
            </div>
            <div className='body' ref={dropRef}>
                {experiment &&
                    (!experiment.step_ids || experiment.step_ids.length == 0) &&
                    <div className='helptext'>Add steps to the experiment by clicking on the gear icon above</div>}
                {
                    (experiment &&
                        experiment.step_ids.map((sid,i) => {
                            const step = experiment.getStep(sid)
                            return <StepButton 
                                        key={sid}
                                        controller={controller}
                                        editing={editing}
                                        experiment={experiment}
                                        step={step}
                                        i={i+1} />
                        }))
                }
            </div>
        </ComponentPanelLeft >)
}

