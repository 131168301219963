import axios from 'axios'
import { backend_server } from '../config/runconfig'




export class KeywordRegistry 
{
	constructor() {
		this.keywords		= {}
		this.keywordHash 	= {}
	}


	load(callback) {
		const url = `${backend_server.server}/keyword/all?api_key=caa9041159f0b845c2902a2b705e889`
		console.log( url )
		axios.get(url)
			.then(result => {
				if (!result?.data?.keywords)
					throw "Keywords load error"
				this.keywords = result.data.keywords

				Object.keys( this.keywords ).forEach( table =>
				{
					this.keywords[table].forEach(kw => this.keywordHash[kw.id] = kw)
				})

				this.ready = true
				if (callback)
					callback({ success: true })
			})
			.catch( e => {
				callback( {success: false, error: e})
			})
	}


	all_for( table )
	{
		const t = `${table}`.toLowerCase()
		return this.keywords[t] || []
	}


	options_for(table) 
	{
		const t = `${table}`.toLowerCase()
		return (this.keywords[t] || []).map( k => { return {value: k.id, label: k.keyword}})
	}

}