import React from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm, useBackendData } from '../hooks/useDownloaded'


import {
	Form, TextBox, CheckBox,
	SubmitButton,
	displayLine,
	displayLineValue, 
	formLine
} from '../form/Form'




export const ResourceTypeEditForm = ({ id, document, subject, onSubmit, has_code, active }) => {
	const router = useRouter()

	const result_url = () => {
		let url
		if (document.id)
			url = `/resource_type/edit/${document.id}`
		else
			url = `/resource_type/list/${id}`

		return url
	}

	const onCancel = (e) => {
		router.goto(result_url(), { focus: 'l' })
	}

	const submitChanges = async (e, data) => 
	{
		const url = document.id ? `resource_type/save/${document.id}`
			: `resource_type/save`

		const result = await submitForm(url, data)

		if (onSubmit)
			onSubmit()

		console.log( "RES", result )
		console.log(`/resource_type/list/${result.data.id}`)

		onSubmit()
		.then( () => {
			setTimeout(() => {
				if (result.data && result.data.success && result.data.id) {
					router.goto(`/resource_type/list/${result.data.id}`)
				}
				else
					onCancel()				
			}, 100);
		})
	}

	const errors = {}


	if (!document) {
		return <ComponentPanelRight className='wide document-details'>
			<div className='head'>
				<div className='title'>Edit</div>
			</div>
			<div className='body'>
			</div>
		</ComponentPanelRight>
	}

	return <ComponentPanelRight className='document-details'>
				<Form key={`K${document.id}`} className='narrow form table'
					data={document}
					errors={errors}
					onSubmit={submitChanges} >
					<div className='head'>
						<div className='title'>Edit {document ? document.id : ''}</div>
					</div>
					<div className='body'>
						<div className='form-contents'>
							<div className='formtable'>
								{formLine('Name', <TextBox 
														required={true}
														name='title' 
														disabled={document.deleted} />)}
								{formLine('Description', <TextBox 
														name='description' type='textarea' 
														disabled={document.deleted} />)}
								{formLine('Has Code', <CheckBox 
														name='has_code'  
														disabled={document.deleted} />)}
							</div>
						</div>
					</div>
					<div className='form-actions'>
						{!document.deleted && <SubmitButton disabled={true}>Save</SubmitButton>}
						{!document.deleted && <button onClick={onCancel}>
							Cancel
						</button>}
					</div>
				</Form>
			</ComponentPanelRight>
}


export const ResourceTypeEditComponent = ({ id, subject, has_code, onSubmit, active }) => {
	const [document] = useBackendData(`resource_type/get/${id}`, null)

	return <ResourceTypeEditForm subject={subject}
		document={document}
		has_code={has_code}
		active={active}
		onSubmit={onSubmit} />
}

export const ResourceTypeCreateComponent = ({ subject, has_code, onSubmit }) => {
	return <ResourceTypeEditForm subject={subject}
		document={{}}
		has_code={has_code}
		onSubmit={onSubmit} />
}


export const ResourceType = ({ onSubmit, subject, id }) => {
	return <ResourceTypeEditForm orig_id={id} subject={subject} document={{}} onSubmit={onSubmit} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>





const ResourceTypeShow = ({ document }) => 
{
	if (!document)
		return null

	return (
			<div className='details'>
				<table className='document-show'>
					<tbody>
						{displayLine(document, 'Title', 'title')}
						{displayLine(document, 'Internal ID', 'name')}
						{displayLine(document, 'Description', 'description')}
						{false && displayLineValue(document.has_code ? 'Has Code' : 'No Code', 'Has Code', 'has_code')}
					</tbody>
				</table>
			</div>)
}


export const ResourceTypeShowComponent = ({ id, subject, active }) => {
	if (!id)
		return null

	const router = useRouter()

	const [document] = useBackendData(`resource_type/get/${id}`, null)

	const edit = (e) => {
		router.goto(`/resource_type/edit/${id}`)
	}

	const closePanel = (e) => {
		router.goto(`/resource_type`, { focus: "l", i: null })
	}

	return <ComponentPanelRight className='document-details narrow'>
				<div className='head'>
					<div className='title'>{document? document.title : 'Resource Type'}</div>
				</div>
				<div className='body'>
					<ResourceTypeShow document={document} />
				</div>
				<div className='form-actions'>
					{document && !document.deleted && <button disabled={!active} onClick={edit}>Edit</button>}
				</div>

			</ComponentPanelRight>
}

// <button onClick={cloneThis(db, id)}>Create Copy</button>
