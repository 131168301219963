import React, { useState, useRef } from 'react'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'

import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer } from '../app-frame/AppFrame'


import { PersonCreateComponent, PersonShowComponent, PersonEditComponent } from './PersonEditor'





const initialFilter = {
    table: "boolean",
    id: "boolean",
    operator: "and",
    list: [
        {
            table: "person",
            id: "lastname",
            value: ""
        },
        {
            table: "person",
            id:   "deleted",
            value: false,
            inverted: true
        }
    ]
}





export const PersonFrame = (props) => 
{
    const router = useRouter()
    const initialColumns = ["loginname", "lastname", "firstname", "role"]
    const [fts, setFts] = useState("")

    const { id } = router.params

    const [person, refetchComponent] = useBackendData(`person/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }



    const setTableRow = (id) => {
        router.goto(`/person/show/${id}`, { focus: 'r', i: null })
    }

    const create = (e) => {
        router.goto(`/person/create`, { fe: false, lf: false, fo: false })
    }


    let component_type = null
    const location = router.location.pathname

    if (location.startsWith('/person/edit') && id)
        component_type = 'edit'
    if (location.startsWith('/person/create'))
        component_type = 'create'
    else if (location.startsWith('/person/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)


    const showFilter = component_type == null || component_type == 'show'

    return <AppFrame search_bar={true} highlight='person' onSearchChange={setFts}>
        <ComponentMenuTop>
            <div className='filler'></div>

            <div className='main-section'>
                <div className='section'>
                    <button onClick={create} disabled={editing}>
                        <span className='fa fa-wand-magic-sparkles' />
                        &nbsp;                        
                        Create...
                    </button>
                </div>

                <div className='metadata-section'>
                    <MetaDataMenu table='person' view={viewRef} refetch={refetch} />
                </div>                
            </div>
        </ComponentMenuTop>

        <ComponentContainer className='l-r'>
            <ViewFrame ref={viewRef}
                table="person"
                initialColumns={initialColumns}
                initialFilter={initialFilter}
                fts={fts}
                dataURL='person/get_filter'
                rowID={id}
                setTableRow={setTableRow}
                showFilter={showFilter}>
                {
                    component_type == 'show' && id &&
                    <PersonShowComponent person={person} refetch={refetch}/>
                }
                {
                    component_type == 'edit' && id &&
                    <PersonEditComponent person={person} refetch={refetch} />
                }
                {
                    component_type == 'create' &&
                    <PersonCreateComponent refetch={refetch} />
                }
            </ViewFrame>
        </ComponentContainer>
    </AppFrame>
}
