import {useMemo}  from 'react'
import { useParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import {string_default}   from '../utils/utils'

function query_stringify( params )
{

  if (params == null || params == undefined)
    return ''
  
  const p = {}
  for( let k of Object.keys(params)) 
  {
      let v = params[k]
      if (v == null || v == undefined)
      {}
      else
      {
        v = `${v}`.trim()
//        if (v !== 'false' && v !== 'off')
        p[k] = v
      }
  }

  return queryString.stringify( p )
}


export function useRouter()
{
  const params    = useParams();
  const location  = useLocation();
  const navigate  = useNavigate();
  

  const qp =  {...queryString.parse(location.search)}

  // Memoize so that a new object is only returned if something changes
  return useMemo(() => 
  {
    return {
      // For convenience add push(), replace(), pathname at top level
      replace:    (target) => navigate(target, {replace: true}),
      pathname:   location.pathname,

      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      all_query: {
        ...qp,
        ...params
      },

      qp: qp,

      flag: (name, def = false) => 
      {
        const v = qp[name]
        if (!string_default( v))
          return def
        else
          return v === '1' || v === 'true'
      },

      flags: () =>
      {
        return Object.keys(qp).filter( k => qp[k] === '1' || qp[k] === 'true')
      },

      flag_classes: (prefix = 'layout-') =>
      {
        const flags = Object.keys(qp)
                           .filter(k => qp[k] === '1' || qp[k] === 'true')
                           .map( f => `${prefix}${f}`)

        const focus = qp.focus ? [`focus-${qp.focus}`] : ['focus-c']

        return [...flags,...focus].join( " ")
      },

      param: (name, def = false) => {
        const v = qp[name]
        if (!string_default(v))
          return def
        else
          return v
      },

      goto: (path = null, params = {}) => 
      {
        try {
          const newQuery = query_stringify({ ...qp, ...params })
          const currentpath = path || location.pathname
          const targetURI = `${currentpath}?${newQuery}`

          navigate(targetURI, { replace: true })          
        } catch (error) {}
      },

      push: (path = null, params = {}) => {
        const newQuery = query_stringify({ ...qp, ...params })
        const currentpath = path || location.pathname
        const targetURI = `${currentpath}?${newQuery}`

        navigate(targetURI)
      },

      setFlag: (params = {}) => {
        const newQuery = query_stringify({ ...qp, ...params })
        const currentpath = location.pathname
        const targetURI = `${currentpath}?${newQuery}`

        navigate(targetURI, {replace: true})
      },

      toggleFlag: (flagname = null, def = true) => 
      {
        if (!flagname)
          return

        const setting = {}
        if (qp[flagname] == null)
          setting[flagname] = def
        else
        { 
          const v = qp[flagname]
          setting[flagname] = (v === '1' || v === 'true') ? 'false' : 'true'
        }

        const newQuery = query_stringify({ ...qp, ...setting })
        const currentpath = location.pathname
        const targetURI = `${currentpath}?${newQuery}`

        navigate(targetURI, {replace: true})
      },

      indicatorClasses: () => {
        return Object.keys(qp).map( k => `${k}-${(qp[k]=='1'||qp[k]=='true') ? 'on' : 'off'}`).join( " ")
      },

      setFlagLater: (params = {}) => {
        setTimeout( () => 
        {
          const newQuery = query_stringify({ ...qp, ...params })
          const currentpath = location.pathname
          const targetURI = `${currentpath}?${newQuery}`

          navigate(targetURI, {replace: true})
        }, 0);
      },

      infoBoxList: () => {
        const ib = qp['i']
        if (!ib || ib.length == 0)
          return null
        
        const sp = ib.split(",")
        const rv = []
        sp.forEach( i => 
        {
          const s = `${i}`.trim().toUpperCase()
          if (s.length > 3)
          {
            const id = parseInt(s.substring( 1 ), 10 )
            if (isNaN(id))
            {}
            else if (s[0] == 'E')
              rv.push( {kind: 'experiment', id})
            else if (s[0] == 'T')
              rv.push({ kind: 'substrate',  id })
            else if (s[0] == 'C')
              rv.push({ kind: 'chemical',   id })
            else if (s[0] == 'S')
              rv.push({ kind: 'solution',   id })
            else if (s[0] == 'B')
              rv.push({ kind: 'substratebatch', id })              
            else if (s[0] == 'M')
              rv.push({ kind: 'substratematerial', id })                  
          }
        })

        return rv
      },

      popInfoBox: () => 
      {
        const ib = qp['i']
        if (!ib || ib.length == 0)
          return null

        const sp = ib.split(",")
        
        let newInfo = null
        if (sp.length > 0)
        {
          sp.pop()
          newInfo   = {i: sp.join(",")}
          const domNode = document.getElementById(`infobox`)
          if (domNode)
            domNode.scrollIntoView()
        }
  
        const newQuery = query_stringify({ ...qp, ...newInfo})
        const currentpath = location.pathname
        const targetURI = `${currentpath}?${newQuery}`

        navigate(targetURI, {replace: true})
      },

      pushInfoBox: (kind,id) => 
      {
        let ib = qp['i']
        if (!ib || ib.length == 0)
          ib = ""

        const sp = [] //ib.split(",")
        if (kind == 'experiment')         sp.push(`E${id}` )
        if (kind == 'substrate')          sp.push(`T${id}`)
        if (kind == 'chemical')           sp.push(`C${id}`)
        if (kind == 'solution')           sp.push(`S${id}`)
        if (kind == 'substratebatch')     sp.push(`B${id}`)
        if (kind == 'substratematerial')  sp.push(`M${id}`)

        const newInfo = {i: sp.join(","), fo: false, focus: "r"}
        const newQuery = query_stringify({ ...qp, ...newInfo })
        const currentpath = location.pathname
        const targetURI = `${currentpath}?${newQuery}`

        navigate(targetURI, {replace: true})

        const domNode = document.getElementById(`infobox`)
        if (domNode)
          domNode.scrollIntoView()
      },

      hasInfoBox: () =>
      {
        if (!qp['i'])       return false
        if (qp['i'] == '')  return false
        return true
      },

      goBack: () => {navigate(-1)},
      
      // Include params, location, navigate objects so we have
      // access to extra React Router functionality if needed.
      params,
      location,
      navigate
    };
  }, [params, location, navigate]);
}
