import React, {useState} from "react";
import { DataModel, DataModelSchema, DataModelColumn, DataType } from '../table-editor/model/model'
import { SortDir } from '../table-editor/model/model'
import { ViewSchema } from '../table-editor/viewschema'
import { View } from '../table-editor/View'
import { ViewConfiguration } from '../table-editor/ViewConfiguration'

const create_data_schema = () => {
	const dcolumns = [
		new DataModelColumn("full_id", 			"ID", 			DataType.String, true, 	true),
		new DataModelColumn("kind", 			"Kind", 		DataType.String, false,	true),
		new DataModelColumn("display_title", 	"Title", 		DataType.String, true, 	true),
		new DataModelColumn("batch_number", 	"Batch", 		DataType.String),
		new DataModelColumn("manufacturer", 	"Manufacturer", DataType.String),
	]

	const data_schema = new DataModelSchema()
	dcolumns.forEach(col => data_schema.addColumn(col))

	return data_schema
}


const create_view_schema = (data_schema: DataModelSchema) => {
	const view_schema = new ViewSchema(data_schema)

	view_schema.addViewColumn("kind")
	view_schema.addViewColumn("display_title")
	view_schema.addViewColumn("batch_number")
	view_schema.addViewColumn("manufacturer")
	view_schema.addViewColumn("full_id")

	view_schema.addCategory("kind", SortDir.Down)
	view_schema.addSort("title", SortDir.Down)

	return view_schema
}


const data_schema = create_data_schema()
const view_schema = create_view_schema(data_schema)




export const MaterialTable = (props : any) => 
{
	const { data, active } = props
	const { selected_id } = props
	const data_model = new DataModel(data_schema)

	if (!data || !data.dataset)
		return <div></div>


	data.dataset.forEach(row => 
	{
		row.indicator_class = row.subject
		row.fields.kind  	= row.subject == 'solution' ? 'Solution' : 'Chemical'
		data_model.push(row)
	})

	
	const viewConfig = new ViewConfiguration(view_schema, data_model)

	return <View 	key='viewConfig' 
					viewConfig={viewConfig} 
					active={active}
					selected_id={selected_id} 
					onClick={(id,m) => props.onMaterialSelect(id,m,false)}
					onDblClick={(id,m) => props.onMaterialSelect(id,m,true)}
					tailClick={props.tailClick} 
					onTailClick={props.onTailClick}
					hasRowHead={props.hasRowHead}
					dragItemDescriptor={props.dragItemDescriptor} />
}

