import React                            from 'react'
import { useRouter }                    from '../hooks/useRouter'
import { ComponentPanelRight }          from '../app-frame/AppFrame'
import { submitForm, useBackendData, 
            fetchMulti }                from '../hooks/useDownloaded'
import axios                            from 'axios'
import { backend_server }               from '../config/runconfig'
import classNames                       from 'classnames'
import { usePersonSelection }           from '../hooks/usePersons'
import { MetaData }                     from '../metadata/MetaData'

import { EntityButton } from '../navigation/EntityButton'

import {
    Form, TextBox, CheckBox, DateTime,
    SubmitButton, HideShow, Select, Error, displayLineAlways, displayLineAlwaysValue
} from '../form/Form'

import { Tooltip } from 'antd'
import { notification } from 'antd'

import { post } from '../hooks/useDownloaded'

import { DownloadDocumentXLSX, DownloadJSON } from '../download/Download'

export const ExperimentEditForm = ({orig_experiment_id, experiment, refetch, template, clone, verb}) => 
{        
    const router = useRouter()

    const [person_options] = usePersonSelection()

    const result_url_cancel = () =>
    {
        console.log( orig_experiment_id, experiment?.id )
        let url
        if (experiment?.id)
            url   =  `/experiment/show/${experiment.id}`
        else if (orig_experiment_id)
            url   =  `/experiment/show/${orig_experiment_id}`
        else
            url   =  "/experiment"

        console.log( url )

        return url
    }

    const onCancel = (e) => 
    { 
            router.goto(result_url_cancel(), {focus :'l'})
    }

    const submitChanges = async ( e, data ) => 
    {
        const url = experiment.id && !clone ? `experiment/save/${experiment.id}`
                                            : `experiment/save`
        
        if (clone)
        {
            data.id             = null
            data.template_id    = experiment.id
        }

        if (template == true && verb == 'Create')
            data.template   = true;
        else if (verb == 'Clone')
            data.template   =  false
        else if (verb == 'CloneTemplate')
            data.template   = true

        const result = await submitForm( url, data )
        
        if (refetch)
            refetch( verb == 'Edit'  )

        if (result.data && result.data.success && result.data.id)
            router.goto( `/experiment/show/${result.data.id}`, { focus: 'r', i: null })
        else
            onCancel()
    }

    const errors = {}


    if (!experiment)
    {
        return <ComponentPanelRight className='experiment-details wide'>
                    <div className='head'>
                        <div className='title'>Edit</div>
                    </div>
                    <div className='body'>
                    </div>
                </ComponentPanelRight>
    }

    if (experiment.planning === null || experiment.planning === undefined)
        experiment.planning = !template
    if (template)
        experiment.planning = false

    const is_template = experiment.template || template


    if (verb != 'Edit' && verb != 'Clone' && !experiment.substrate_count)
        experiment.substrate_count = 0




    return  <ComponentPanelRight className='experiment-details wide'>
                <Form data={experiment} errors={errors} onSubmit={submitChanges} >
                    <div className={classNames( 'head')}>
                        { is_template && <div className='title'>{verb} Template   {experiment && experiment.id ? experiment.id : ''}</div>}
                        {!is_template && <div className='title'>{verb} Experiment {experiment && experiment.id ? experiment.id : ''}</div>}
                    </div>
                    <div className='body'>
                        <div className= 'form-contents details'>
                            <div className='formtable'>

                                <div className='line'>
                                    <div className='field-name'>Name</div>
                                    <div className='control'>
                                        <TextBox name="title" required={true}/>
                                    </div>
                                </div>

                                <div className='line'>
                                    <div className='field-name'>Objectives</div>
                                    <div className='control'>
                                        <TextBox type='textarea' name="description"/>
                                    </div>
                                </div>

                                {!template && <div className='line'>
                                    <div className='field-name'>
                                        Planning
                                    </div>
                                    <div className='control'>
                                        <CheckBox disabled='template' name='planning' label="In Planning" />
                                    </div>
                                </div>}

                                {!template && 
                                <div className='line'>
                                    <div className='field-name'>Supervisor</div>
                                    <div className='control'>
                                        <Select name="supervisor_id" options={person_options} required={true}/>
                                    </div>
                                </div>}


                                <div className='line'>
                                    <div className='field-name'>Operator</div>
                                    <div className='control'>
                                        <Select name="operator_id" options={person_options} />
                                    </div>
                                </div>

                                <div className='line'>
                                    <div className='field-name'></div>
                                    <div className='control'>
                                    </div>
                                </div>

                                {   (verb !== 'Edit' && verb !== 'Clone') &&
                                    <div className='line'>
                                        <div className='field-name'>Substrates to add</div>
                                        <div className='control'>
                                            <TextBox name='substrate_count' type='numeric' min_value={0} max_value={100} />
                                        </div>
                                    </div>
                                }


                            </div>

                        </div> 
                    </div>
                    <div className='form-actions'>
                        <SubmitButton>Save</SubmitButton>
                        <button onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </Form>
            </ComponentPanelRight>
}


export const ExperimentEditComponent = ({ experiment, refetch }) => 
{
    if (!experiment)
        return null
    return <ExperimentEditForm experiment={experiment} clone={false} refetch={refetch} verb='Edit'/>
}


export const ExperimentCloneComponent = ({ experiment, refetch }) => 
{
    if (!experiment)
        return null
    const cloned_experiment = { ...experiment }
    cloned_experiment.title = null
    return <ExperimentEditForm experiment={cloned_experiment} clone={true} refetch={refetch} verb='Clone' />
}


export const ExperimentCloneTemplateComponent = ({ experiment, refetch }) => 
{
    if (!experiment)
        return null
    const cloned_experiment = {...experiment}
    cloned_experiment.title = null
    return <ExperimentEditForm experiment={cloned_experiment} clone={true} refetch={refetch} verb='CloneTemplate' />
}


export const ExperimentCreateComponent = ({refetch, experiment, template}) => 
{
    if (!experiment)
        return null

    return <ExperimentEditForm  orig_experiment_id={experiment?.id} 
                                template={template} 
                                experiment={ {} } 
                                clone={false}
                                verb='Create'
                                refetch={refetch} />

}

// <button onClick={cloneThis(db, experiment_id)}>Create Copy</button>





export const ExperimentShow = ({ experiment, refetch }) => 
{
    if (!experiment)
        return null


    const pin_template = async () => {
        if (!experiment)
            return

        const url = '/material/toggle_pin'

        const result = await post(url, {
            subject: 'experiment',
            subject_id: experiment.id
        })
        const args_active = {
            message: 'Template Pinned',
            description: `Template added to step editor in workspace`,
            duration: 1,
        };
        const args_inactive = {
            message: 'Template Unpinned',
            description: `Template removed from step editor in workspace`,
            duration: 1,
        };

        const args = result.data.id ? args_active : args_inactive

        notification.open(args);
        refetch()
    }


    let pinpart = ''
    if (experiment.template && experiment.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_template}>Unpin from Step Editor in Workspace</span>
        </div>
    if (experiment.template && !experiment.pinned)
        pinpart = <div>
            <span className='fa-regular fa-thumbtack icon'></span>
            <span onClick={pin_template}>Pin to Step Editor in Workspace</span>
        </div>

    const subtitle = experiment.template ? 'Template' :
        experiment.planning ? 'In Planning' : null                     

    return (
            <div className='body'>
                <MetaData key={`C-${experiment.id}`} subtitle={subtitle}
                     document={experiment} refetch={refetch}>
                    {experiment.template && <div className='metadata-section'>
                        <Tooltip placement='bottom' 
                        title="The experiment will appear in step editor in the workspace and may be added to any experiment">
                            {pinpart}
                        </Tooltip>
                    </div>}
                </MetaData>
                <div className='details'>
                    <section>
                        <table className='document-show'>
                            <tbody>
                                {displayLineAlways(experiment, 'Name',          'title')}
                                {displayLineAlways(experiment, 'Objectives',    'description')}
                                {displayLineAlways(experiment, 'Status',        'mode')}
                                {displayLineAlways(experiment, 'Operator',      'operator_name')}
                                {displayLineAlways(experiment, 'Supervisor',    'supervisor_name')}


                            </tbody>
                        </table>
                    </section>


                    <section>
                        <h4>Steps</h4>
                        {!experiment.process_steps || experiment.process_steps.length == 0 &&
                            <div className='helptext' key='nosteps'><i>No steps have been added to the experiment</i></div>}
                        {
                            experiment.process_steps && experiment.process_steps.map((s, i) => {
                                return <div key={`el-${i}`} className='entity-link'>
                                    <div className={`indicator step-bg`} />
                                    <div className='title'>{`${s.name}`.replace(/_/g, " ")}</div>
                                </div>
                            })}
                    </section>

                    <section>
                        <h4>Substrates</h4>
                        {!experiment.substrates || experiment.substrates.length == 0 &&
                            <div key='nosteps'><i>No substrates have been assigned yet</i></div>}
                        {experiment.substrates && experiment.substrates.map( (s,i) => {
                            return <EntityButton key={`el-${i}`}  kind='substrate' entity={s} />
                        })}
                    </section>

                    {experiment.materials && Object.keys( experiment.materials ).length > 0 &&
                        <section>
                            <h4>Materials Used</h4>
                            {Object.keys( experiment.materials ).sort().map((k, i) => 
                            {
                                const mat = experiment.materials[k]
                                return <EntityButton key={`el-${i}`} kind={mat.subject} entity={mat} />
                            })}
                        </section>
                    }
                </div>
            </div>)
}


export const ExperimentShowComponent = ({ experiment, refetch }) => 
{
    if (!experiment)
        return null

    const router = useRouter()
    

    const editExperiment = (e) => 
    { 
        router.goto(`/experiment/edit/${ experiment.id }`, { focus: 'r', i: null }) 
    }



    const add_to_workspace = async (e) =>
    {
        const url = `${backend_server.server}/workspace/add_experiment/${experiment.id}` 
        await axios.post( url )
        window.location = `/workspace/${experiment.id}`
    }


    const create_clone = async (e) =>
    {
        router.goto(`/experiment/clone/${experiment.id}`, { focus: 'r', i: null })
    }

    const closePanel = (e) => 
    {
        router.goto(`/experiment`, { focus: "l", i: null }) 
    }

    let title       = null
    let button_type = null
    if (experiment &&  experiment.template)
    {
        button_type = 'template'
        title       = `Template T${experiment.id}`
    }

    if (experiment && !experiment.template)
    {
        button_type = 'experiment'
        title = experiment.planning ? `Experiment in Planning E${experiment.id}` : `Experiment E${experiment.id}`
    }
    
    const head_class = classNames('head')//, experiment && experiment.template ? 'template-bg' : experiment && experiment.planning ? 'planning-bg' : 'experiment-bg')

    return  <ComponentPanelRight className='experiment-details wide'>
                <div className={head_class}>
                    <div className='title'>{false && title}</div>
                    {experiment && <DownloadDocumentXLSX link={`experiment/get_doc_xlsx/${experiment?.id}`}/>}
                    <div className='right button' onClick={closePanel}>
                          <span className='close-button'>✕</span>
                    </div>
                </div>
                <ExperimentShow experiment={experiment} refetch={refetch}/>
                <div className='form-actions'>
                    {button_type && <button onClick={editExperiment}>Edit Metadata</button>}
                    {button_type == 'template' &&
                        <button onClick={create_clone}>Create Experiment...</button>
                    }
                    {button_type != 'template' &&
                        <button onClick={create_clone}>Copy Experiment...</button>
                    }

                    <div className='spacer'>&nbsp;</div>

                    {(button_type == 'experiment' || button_type == 'template') &&
                        <button onClick={add_to_workspace}>Add to Workspace...</button>
                    }

                </div>

            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, experiment_id)}>Create Copy</button>
