import React, { Component } from "react";
import { FormComponent } from '../form/Form'

import axios from 'axios'
import { backend_server }               from '../config/runconfig'
import { post }                         from '../hooks/useDownloaded'

import { ChemicalShow } from '../chemical/ChemicalEditor'
import { SolutionShow } from '../solution/SolutionEditor'

import { MaterialTable } from "./MaterialTable";


import { getMaterialFrom } from "../model/experiment";
import { useRouter } from "../hooks/useRouter";



const MAT_TAB_NONE          = 0
const MAT_TAB_ALL           = 1
const MAT_TAB_PINNED        = 2
const MAT_TAB_RECENT        = 3
const MAT_TAB_EXPERIMENT    = 4
const MAT_TAB_WORKSPACE     = 5
export class MaterialQuery extends React.Component 
{
    constructor(props) {
        super();

        this.last_message = 0;

        this.state = {
            materials:      [],
            selected:       null,
            query:          props.search_term || '',
            material:       props.material_id,  
            subject:        props.subject,
            select_first:   true,
            selected_tab:   MAT_TAB_ALL
        }
    }

    run_query = () => 
    {
        const material_id  = this.props.material?.id
        const q = `${this.state.query}`.trim()

        let query = null
        if (q.length > 0 || this.state.selected_tab == MAT_TAB_ALL)
        {
            if (q.length == 0 && !material_id)
            {
                this.setState({ materials: {}, selected: null, material: null, subject: null })
                return
            }
            else if (q.length > 0)
                query = `${backend_server.server}/material/query?fts=${encodeURIComponent(q)}`
            else
                query = `${backend_server.server}/material/query?fts=${encodeURIComponent(material_id)}`

        }
        else if (this.state.selected_tab == MAT_TAB_PINNED)
            query = `${backend_server.server}/material/pinned`       
        else if (this.state.selected_tab == MAT_TAB_RECENT)
            query = `${backend_server.server}/material/recent`       
        else if (this.state.selected_tab == MAT_TAB_EXPERIMENT && this.props.experiment)
            query = `${backend_server.server}/experiment/get_materials/${this.props.experiment.id}`       
        else if (this.state.selected_tab == MAT_TAB_WORKSPACE)
            query = `${backend_server.server}/workspace/get_materials`       

        

        if (query != null)
        {
            axios.get(query)
                .then(result => 
                {
                    if (result.data && result.data.kind == 'Material')
                    {
                        if (this.state.select_first && this.props.material)
                            this.get_material( this.props.material )
                        this.setState({ select_first: false, error: false, materials: result.data })
                    }
                    else {
                        this.setState({ error: true, error_msg: "Error in Query", materials: [] })
                    }
                })
        }
    }


    get_material = (material, closeAfter = false) => 
    {        
        if (!material)
            return
        let target_url      = null
        let material_id     = null
        let subject         = null

        if (material.subject == 'solution')
        {
            target_url      = `${backend_server.server}/solution/get/${material.id}`
            subject         = 'solution'
            material_id     = material.id
        }
        if (material.subject == 'chemical')
        {
            target_url      = `${backend_server.server}/chemical/get/${material.id}}`
            subject         = 'chemical'
            material_id     = material.id
        }

        const message_id = Date.now()
        this.last_message = message_id

        if (!target_url)
            return

        axios.get( target_url )
             .then( result => 
            {
                if (this.last_message > message_id)
                    return
                const material = result.data

                this.setState( {material, subject, material_id},
                    () => {
                        if (closeAfter)
                            this.handleSelect( false )
                    })
             })
        this.setState( {selected: material_id})
    }






    clear_recent = async () => 
    {
        const url = `${backend_server.server}/material/clear_recent`
        await axios.post(url)
        setTimeout(() => this.run_query(), 0)
    } 

    handleQuery = (e) => {
        this.setState({ query: e.target.value })
        setTimeout(() => this.run_query(), 0)
    }


    handleRowSelect = (id, row, double) => 
    {
        this.get_material( {id: row.id?.value, subject: row.subject?.value}, double )
    }


    handleSelect = (use_search_term = false) => 
    {
        const q = `${this.state.query || ''}`.trim()
        const has_query = q.length > 0

        if (this.props.onMaterialSelect) 
        {
            const mat           = this.state.material
            const search_term   = has_query ? q : undefined

            if (use_search_term)
            {
                this.props.onMaterialSelect(this.state.selected, null, search_term, true )
            }             
            else if (mat)
            {
                this.props.onMaterialSelect(this.state.selected, mat, search_term, false )

                const klass = this.state.material.subject == 'solution' ? 'Experiment' : 'Chemical'
                post( '/history/visit', {klass: klass, id: this.state.material.id})
            }
        }
    }

    componentDidMount()
    {
        this.run_query()
    }



    render() 
    {
        const material = this.state.material
        const selected_id = material ? material.id : null
        const q = `${this.state.query || ''}`.trim()
        const has_query = q.length > 0

        const all_label = has_query ? "All" : "Selected"

        const {experiment} = this.props

        const set_tab           = (id) => (_) => 
        { 
            this.setState({ selected_tab: id })
            setTimeout(() => this.run_query(), 0)
        }
        const virtual_selected  = has_query ? MAT_TAB_ALL : this.state.selected_tab

        const TabButton = ({ id, children }) => 
        {
            const clazz     = virtual_selected == id ? 'selected' : ''
            const disabled  = id != MAT_TAB_ALL && has_query 
            return <button disabled={disabled} className={`tab ${clazz}`} onClick={set_tab(id)}>{children}</button>
        }

        const refetch_current_material = () => 
        {
            this.get_material(this.state.material)
            this.run_query()
        }


        const materials_found = this.state.materials?.count
        const mtype = this.state.subject

        const selectSearchActive = experiment && (experiment.template || experiment.planning)
        const selectChemActive = true


        const handleSelectSearch    = () => this.handleSelect( true )
        const handleSelectMat       = () => this.handleSelect( false )

        const mat_label = mtype == 'chemical' ? 'Chemical' : mtype == 'solution' ? 'Solution' : 'Material'

        const checkEscape = (e) => 
        {
            if (e.keyCode == 27)
                this.props.closeCallback()
        }

        return (
            <div className="material-query-dialog dialog" onKeyUp={checkEscape}>
                <div className='background-fade'></div>
                <div className='dialog'>
                    <div className='head'>
                        <div className='spacer'/>
                        <span className='close-button' onClick={this.props.closeCallback}>✕</span>
                    </div>
                    <div className='searchbox'>
                        <input  name='query' tabIndex={1} 
                                value={this.state.query} 
                                onChange={this.handleQuery} 
                                autoFocus
                                onKeyUp={checkEscape}
                                placeholder="Search for Chemical or solution..."/>
                        <span className='icon fa-regular fa-search' />
                    </div>
                    <div className='tab-bar'>
                        <TabButton id={MAT_TAB_ALL}>{all_label}</TabButton>
                        <TabButton id={MAT_TAB_RECENT}>Recent</TabButton>
                        <TabButton id={MAT_TAB_PINNED}>Pinned</TabButton>
                        {experiment && <TabButton id={MAT_TAB_EXPERIMENT}>Experiment</TabButton>}
                        {experiment && <TabButton id={MAT_TAB_WORKSPACE}>Workspace</TabButton>}
                    </div>
                    <div className='results materialquery'>
                        <div className='material-list'>
                            {!materials_found && q.length == 0 &&
                                    <div className='helptext'> Please type a search term in the box above</div>}
                            {!materials_found && q.length > 0 &&
                                    <div className='helptext'>No materials found</div>}
                            { (materials_found > 0) && 
                                    <MaterialTable  data={this.state.materials} 
                                                    selected_id={selected_id} 
                                                    hasRowHead={false}
                                                    onMaterialSelect={this.handleRowSelect} 
                                                    active={true}/>}
                        </div>
                        
                        <div className='material-display'>
                            {mtype == 'chemical' && <ChemicalShow chemical={this.state.material} refetch={refetch_current_material} /> }
                            {mtype == 'solution' && <SolutionShow solution={this.state.material} refetch={refetch_current_material} /> }
                        </div>
                    </div>
                    <div className='form-actions'>
                        {selectChemActive   && <button onClick={handleSelectMat} disabled={!material}>Select {mat_label}</button>}
                        {selectSearchActive && <button onClick={handleSelectSearch} disabled={!has_query}>Select Search</button>}

                        {this.state.selected_tab == MAT_TAB_RECENT && <button className='right' onClick={this.clear_recent}>Clear Recent</button>}
                    </div>
                </div>
            </div>
            );
    }
}


export const MaterialIndicator = ({material, disabled, className, details, onClick, context}) =>
{
    const router = useRouter()

    const name = () => 
    {
        if (details && details.display_title)
            return <div className='name'>{details.display_title}</div>
        else if (material && material.display_title)
            return <div className='name'>{material.display_title}</div>
        else if (material && material.search_term)
            return <div className='name'><i>{material.search_term}</i></div>
        else if (material)
            return <div className='name'>{material.id}</div>
        else
            return <div className='name'></div>
    }

    const onKeyUp = (e) =>
    {
        if (e.keyCode == 13)
        {
            e.stopPropagation()
            onClick( e )
        }
    }


    const editClass = disabled ? 'display' : 'edit'

    const openInfo = () => { 
        router.pushInfoBox(material.subject, material.id) 
    }


    if (!material && disabled)
        return  <button className={`${className || ''} material-formelement ${editClass}`} 
                        onClick={onClick ? onClick : openInfo} 
                        onKeyUp={onKeyUp} >
                    {context == 'form' && <div className='icon'></div>}
                </button>  

    if (material && disabled)
        return  <button className={`${className || ''} material-formelement ${editClass}`}
                           onClick={openInfo}>
                    {details.display_title}
                    {context == 'form' && <div className='icon fa fa-eye'></div>}
                </button>  


    if (material && material.subject == 'solution')
    {
        return <button className={`${className || ''} material-formelement ${editClass} solution` } 
                        onClick={onClick} 
                        onKeyUp={onKeyUp} >
                    <div className='title'>{name()}</div>
                    {context == 'form' && <div className='icon'><span className='fa-regular fa-vial' /></div>}
                </button>
    }


    if (material && material.subject == 'chemical') 
    {
        return  <button className={`${className || ''} material-formelement ${editClass} chemical`} 
                        onClick={onClick} 
                        onKeyUp={onKeyUp} >
                    <div className='title'>{name()}</div>
                    {context == 'form' && <div className='icon'><span className='fa-regular fa-flask' /></div>}
                </button>
    }
    
    return      <button className={`${className || ''} material-formelement ${editClass} `} 
                    disabled={disabled}
                        onClick={onClick} 
                        onKeyUp={onKeyUp} >
                    <div className='title'>{name()}</div>
                    {context == 'form' && <div className='icon'><span className='fa-regular fa-search' /></div>}
                </button>
    

}

export class MaterialFormElement extends FormComponent 
{
    constructor(props) {
        super(props)
        this.state = {active:     this.props.active === true}
    }

    get_rendered_value() 
    {
        const val = this.getFieldValue()

        if (val === null || val === undefined)
            return this.props.default_value

        return val
    }

    render() 
    {
        const material          = this.get_rendered_value()



        const { experiment }    = this.props
        const { include_notes } = this.props
        const template = experiment?.template
        const exp_mat = experiment && getMaterialFrom( experiment, material )


        const disabled = !this.evaluate_enabled_boolean('disabled', 'enabled', true)

        if (disabled)
            return <MaterialIndicator material={material} details={exp_mat} template={template} disabled={true} context='form'/>


        const close     = () => this.setState({ active: false })
        const activate  = () => this.setState({ active: true })


        const className = this.getClassName([], disabled)

        const setValue = (id, material, search_term, use_search ) => 
        {
            const mat       = this.get_rendered_value() || {}
            const comment   = mat?.comment

            if (experiment && material && id)
                experiment.materials[id] = material
            

            let setval =  {}

            if (use_search)
            {
                setval =    {
                                search_term: search_term ? search_term : null,
                                comment: comment
                            }
            }
            else
            {
                setval =    {
                                id:             material?.id, 
                                subject:        material?.subject, 
                                display_title:  material?.display_title,
                                search_term:    search_term ? search_term : null, 
                                comment:        comment
                            }
            }


            this.context.form.setFieldValue(this.props.name, setval, setval)
            close()
        }

        const changeComment = (e) => 
        {
            const mat = this.get_rendered_value() || {}

            mat.comment = e.target.value

            this.context.form.setFieldValue(this.props.name, mat, mat )
        }

        if (this.state.active) 
        {
            return <MaterialQuery   closeCallback={close} 
                                    template={template}

                                    material_id={material?.id} 
                                    subject={material?.subject}
                                    search_term={material?.search_term}

                                    material={exp_mat} 
                                    disabled={false}
                                    experiment={experiment}
                                    onMaterialSelect={setValue} />
        }
        else
        {
            return  <div className={`material-template`}>
                                <MaterialIndicator 
                                        onClick={activate} 
                                        material={material} 
                                        context="form"
                                        className={className}
                                        details={exp_mat}
                                        details={exp_mat} disabled={false} />
                                {include_notes && <textarea 
                                    className={`material-comment ${className}`}
                                    onChange={changeComment}
                                    placeholder="Material Notes"
                                    value={material?.comment || ''}
                                />}
                    </div>

        }
    }
}



export class MaterialTableElement extends Component
{
    constructor(props) {
        super(props)
        this.state = { active: this.props.active === true }
    }

    render() {
        const {material}        = this.props
        const { experiment }    = this.props

        const template          = experiment?.template
        const exp_mat           = experiment && getMaterialFrom(experiment, material)

        const {disabled}          = this.props

        if (disabled)
            return <MaterialIndicator material={material} details={exp_mat} template={template} disabled={true} context="table" />


        const close = () => this.setState({ active: false })
        const activate = () => this.setState({ active: true })


        const className = this.getClassName([], disabled)

        const setValue = (id, material, search_term, use_search) => {
            close()
        }


        if (this.state.active) {
            return <MaterialQuery closeCallback={close}
                template={template}

                material_id={material?.id}
                subject={material?.subject}
                search_term={material?.search_term}

                material={exp_mat}
                disabled={false}
                experiment={experiment}
                onMaterialSelect={setValue} />
        }
        else {
            return <MaterialIndicator onClick={activate} material={material}
                    context="table"
                    className={className}
                    
                    details={exp_mat} disabled={true} />

        }
    }
}


export function getMaterialDisplayValue( experiment, value )
{

    const mat = getMaterialFrom(experiment, value)

    if (mat && mat.display_title)
        value = mat.display_title
    else if (mat && mat.full_id)
        value = mat.full_id
    else if (value.comment && value.comment.length > 0) {
        if (value.comment.length > 25)
            value = `${value.comment.substring(0, 25)}...`

        else
            value = value.comment

    }
    else if (value.search_term)
        value = `${value.search_term}`.trim()

    else
        value = '?'

    if (value.length > 20)
        value = `${value.substring(0, 19)}...`

    return value
}