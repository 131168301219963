import React 					from 'react'
import { useContext  } 			from 'react'
import { View } 				from '../table-editor/View'
import { ComponentPanelLeft } 	from '../app-frame/AppFrame'
import { DownloadViewXLSX}			from '../download/Download'

import { useRouter } 				from '../hooks/useRouter';

import { GUIStateContext } 			from '../app/GUIContext'
import { ViewConfiguration } 		from '../table-editor/ViewConfiguration'

import { IconButton } 				from '../gui/IconButton';



type ViewTableHeaderProps = { 
	viewConfig: ViewConfiguration, 
	embedded: boolean, 
	controller: any, 
	dataDownloadAction : string | null,
	ftsActive : string
}


export const ViewTableHeader = (props: ViewTableHeaderProps) => 
{
	const { viewConfig, controller } 	= props
	const { embedded } 			= props
	const { ftsActive } 		= props
	const router = useRouter()

	const fts = router.flag("fts", true)
	const flt = router.flag("flt", true)
	const fvw = router.param( "view", '')
 
	const open = router.flag("fo", false)

	


	const toggleEdit = (_) => {
		if (open && fvw !== 'col')
			router.setFlag({ fo: true,  fe: true, focus: 'l', view: 'col' })
		else
			router.setFlag({ fo: !open, fe: !open, focus: open ? 'r' : 'l', view: 'col' })
	}


	const toggleFts = () => { router.setFlag({ fts: !fts }) }
	const toggleFlt = () => { router.setFlag({ flt: !flt }) }
	const counts 	= viewConfig.data_model?.getCounts() || {count: 0, fullCount: 0}


//	console.log( counts )


	return <div className='head list-head'>
		{!embedded &&
			 <div className='section button-group'>
				<div className={`indicator ${fts}`} onClick={toggleFts}>&nbsp;</div>
				<button className={`${fts} indicator-label`} onClick={toggleFts}>Search</button>
			</div>}
		{!embedded &&
			<div className='section button-group'>
				<div className={`indicator ${flt}`} onClick={toggleFlt}>&nbsp;</div>
				<button className={`${flt} indicator-label`} onClick={toggleFlt}>Filter</button>
			</div>}
		{counts.fullCount > 0 && 
				<div className='title'>
					{counts.count < counts.fullCount && <span>{counts.count}&nbsp;shown&nbsp;of&nbsp;{counts.fullCount}</span>}
					{counts.count == counts.fullCount && <span>{counts.count}&nbsp;found</span>}
				</div>}

		<div className='filler'>&nbsp;</div>
		{!embedded &&<div className='section'>
			<DownloadViewXLSX controller={controller}/>
			{ props.dataDownloadAction && 
				<DownloadViewXLSX controller={controller} action={props.dataDownloadAction} />
			}
		</div>}

		<IconButton onClick={toggleEdit} alignment='left' icon={open ? null : "gear"} tooltip='Edit View'>
		</IconButton>
	</div>
}




export const ViewTable = (props: any) => 
{
	const { controller } 				= useContext( GUIStateContext )
	const { data_schema, view_schema } 	= controller

	const embedded 						= props.embedded ? 'embedded' : ''

	const {focused, tailClick, onTailClick, dragItemDescriptor} = props
	const {refetch} 											= props

	const { selected_id } 		= props
	const {dataDownloadAction} 	= props

	const viewConfig = controller.getViewConfiguration()

	if (!data_schema || !viewConfig.data_model)
		return 	<ComponentPanelLeft className={`view-component`}>
					<div className='head' />
					<div className='body' />
				</ComponentPanelLeft>	

	const select = (id) => 
	{
		if (props.setTableRow)
			props.setTableRow(id)
	}



	return 	<ComponentPanelLeft className={`view-component ${embedded}`}>
				<ViewTableHeader 
					viewConfig={viewConfig} 
					embedded={embedded} 
					dataDownloadAction={dataDownloadAction}
					controller={controller} />
				<div className='body'>
					<View 	key={controller?.uuid}
							viewConfig={viewConfig} 
							active={true} 
							selected_id={selected_id} 
							focused={focused !== false}

							dragItemDescriptor={dragItemDescriptor}

							onClick={select} 
							tailClick={tailClick} 
							onTailClick={onTailClick}							
							refetch={refetch}
							/>
				</div>
			</ComponentPanelLeft>
}