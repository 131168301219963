import React, { useState } from 'react'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import backend_server from '../config/runconfig'
import { submitForm} from '../hooks/useDownloaded'
import { usePersonSelection } from '../hooks/usePersons'
import { useRouter } from '../hooks/useRouter'
import { MetaData } from '../metadata/MetaData'
import { notifyResult } from '../notify/Notification'
import axios from 'axios'
import { useBackendData } from '../hooks/useDownloaded'


import {
    Attachments,
    FileUpload,
    Form,
    Select,
    SubmitButton,
    TextBox,
    displayLineAlways,
    displayLines,
    formLine,
    formLineError
} from '../form/Form'






export const SubstrateMaterialCreateBatchForm = ({ substrate_material, refetch }) => 
{


    const data = { amount: 10 }
    const errors = {}
    const router = useRouter()

    const [person_options] = usePersonSelection()

    const onCancel = (e) => {
        e.preventDefault()
        e.stopPropagation()
        router.goto(`/substrate-material/${substrate_material?.id}`, { focus: 'l' })
    }




    const submitChanges = async (e, data) => {
        const url = `substrate_batch/create`

        data.substrate_material_id = substrate_material?.id
        const result = await submitForm(url, data)
        

        notifyResult(result?.data)

        console.log(`/substrate-material/show-batch//${substrate_material?.id}/${result?.data?.id}` )

        if (result?.data?.id)
            router.goto(`/substrate-material/show-batch/${substrate_material?.id}/${result?.data?.id}`, { focus: 'r', i: null })
        else
            router.goto(`/substrate-material/${substrate_material?.id}`, { focus: 'l' })

        if (refetch)
            refetch()
    }

    const [kindresult] = useBackendData(`substrate_kind/all`, null)



    let kindOptions = []
    if (kindresult?.dataset)
        kindOptions = kindresult.dataset.map(k => {return  {value: k.fields.id, label: k.fields.title}})


    if (!substrate_material) 
    {
        return  <ComponentPanelRight className='substrate_material-details'>
                    <div className='head'>
                        <div className='title'>Create Batch</div>
                    </div>
                    <div className='body'>
                    </div>
                </ComponentPanelRight>
    }

    return  <ComponentPanelRight className='substrate-create wide'>
                <Form data={data} errors={errors} onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Register Substrate Batch</div>
                    </div>
                    <div className='body'>
                        <div className='helptext'>
                            This will create a batch of substrates with material 
                            <br/>
                            <br/>
                            <b>{substrate_material.title}</b>
                        </div>
                        <div className='form-contents details'>
                            {formLineError('kind_id', 'Type',
                                <Select name='kind_id' options={kindOptions} required={true} />)}
                            {formLineError('operator_id', 'Operator', <Select name="operator_id" options={person_options} required={true} />)}
                            {formLineError('amount', 'Amount', <TextBox name='amount' type='numeric' min={1} required={true} />)}
                            {formLineError('edge_treatment', 'Edge Treatment', <Select name='edge_treatment' options={['Beveled', 'Sharp']} required={true} />)}
                        </div>

                        <div className='form-actions'>
                            <SubmitButton>Create Batch</SubmitButton>
                            <div className='spacer'>&nbsp;</div>
                            <button onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ComponentPanelRight>
}




export const SubstrateMaterialCreateBatchComponent = ({substrate_material, refetch }) => 
{
    return <SubstrateMaterialCreateBatchForm substrate_material={substrate_material} refetch={refetch} />
}




export const SubstrateMaterialEditForm = ({ orig_id, substrate_material, refetch }) => 
{
    const router = useRouter()

    const [uploads, set_uploads] = useState({})    

    const [errors, set_errors] = useState({})
    const [person_options] = usePersonSelection()

    const result_url = () => {
        let url
        if (substrate_material.id)
            url = `/substrate-material/show/${substrate_material.id}`
        else if (orig_id)
            url = `/substrate-material/show/${orig_id}`
        else
            url = "/substrate-material"

        return url
    }

    const onCancel = (e) => {
        router.goto(result_url(), { focus: 'l' })
    }

    const submitChanges = async (e, data) => 
    {
        console.log("Submitted", data)

        const url = substrate_material.id ? `substrate_material/save/${substrate_material.id}`
            : `substrate_material/save`

        console.log( url)

        const result = await submitForm(url, data)

        if (result?.data?.success)
        {
            if (result.data && result.data.id)
                router.goto(`/substrate-material/show/${result.data.id}`, { focus: 'r', i: null })
            else
                onCancel()

            if (refetch)
                refetch()
        }
        else 
        {
            set_errors({ ...result.data.errors })
        }
    }


    if (!substrate_material)
        return null

    const update_progress = (target, filename, progress, message) => {
        if (progress == null)
            delete uploads[filename]
        else {
            if (!uploads[filename])
                uploads[filename] = {}

            uploads[filename].progress = progress
            uploads[filename].message = message
            uploads[filename].target = target
        }
        console.log(uploads)
        set_uploads(uploads)
    }

    const upload_file = (target) => (files) => {
        files.forEach(file => {
            update_progress(target, file.name, 0)

            const form_data = new FormData();

            const url = `${backend_server.server}/substrate_material/upload_datasheet/${substrate_material.id}`

            form_data.append("kind", target)
            form_data.append("file", file)
            const req = axios.post(url, form_data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: (progress_event) => {
                        let percent = 0
                        if (progress_event.total > 0)
                            percent = Math.floor(progress_event.loaded * 100 / progress_event.total)
                        update_progress(target, file.name, percent)
                    }
                })

            req.catch(err => {
                //                    setState({
                //                        status: "error",
                //                        message: "Connection Failure"
                //                    })
            })

            req.then(response => {
                const { data } = response
                if (data) {
                    console.log(data)
                    if (data.success) {
                        update_progress(target, file.name, 1000)
                        setTimeout(() => update_progress(file.name, null), 5000)
                    }
                    else {
                        const error = data.error
                        update_progress(target, file.name, -1, error)
                    }
                }
            });
        });
    }




    return  <ComponentPanelRight className='wide substrate_material-details'>
                <Form className='narrow form table'
                    data={substrate_material}
                    errors={errors} onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {substrate_material ? substrate_material.id : ''}</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents details'>
                            <div className='formtable'>                        
                                {formLineError('title',                 'Title',        <TextBox required={true} name='title'/> )}
                                {formLineError('description',           'Description',  <TextBox name='description' type='textarea'/> )}
                                {formLineError('deliverydate',          'Delivery Date', <TextBox name='deliverydate'/> )}
                                {formLineError('operator',              'Operator',     <Select name="operator_id" options={person_options} /> )}
                                {formLineError('ordernumber',           'Order Number', <TextBox name='ordernumber'/> )}
                                {formLineError('material',              'Material',     <TextBox required={true}  name='material'/> )}
                                {formLineError('layerstack',            'Layer Stack',  <TextBox name='layerstack'/> )}
                                {formLineError('thicknesses',           'Thicknesses',  <TextBox name='thicknesses'/> )}
                                {formLineError('resistance',            'Sheet Resistance',   <TextBox name='resistance'/> )}
                                {formLineError('structuring_method',    'Structuring Method', <TextBox name='structuring_method'/> )}
                                {formLineError('lot_number',            'Lot Number',   <TextBox name='lot_number'/> )}
                                {formLineError('supplier',              'Supplier',     <TextBox name='supplier'/> )}
                                {substrate_material && substrate_material.id && formLine('DataSheet',
                                        <FileUpload onFileUpload={upload_file("datasheet")} name='datasheet' />)}                                   
                            </div>

                            <div className='helptext'>
                                <b>Warning</b> 
                                <p>All substrates created with this material will reflect the
                                changes made here. 
                                If you wish to make changes for a material for future substrates you need to create a <i>new</i> material
                                and assign that to the new substrates.</p>


                            </div>
                        </div>
                    </div>
                    <div className='form-actions'>
                        <SubmitButton>Save</SubmitButton>
                        <button onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </Form>
            </ComponentPanelRight>
}


export const SubstrateMaterialEditComponent = ({ substrate_material, refetch }) => {
    return <SubstrateMaterialEditForm substrate_material={substrate_material} refetch={refetch} />
}

export const SubstrateMaterialCreateComponent = ({ id, refetch }) => {
    
    return <SubstrateMaterialEditForm orig_id={id}  substrate_material={{}} refetch={refetch} />
}



export const SubstrateMaterialCopyComponent = ({ substrate_material, refetch }) => 
{
    if (substrate_material)
    {
        substrate_material.id = null
        return <SubstrateMaterialEditForm substrate_material={substrate_material} refetch={refetch} />
    }
    else
    {
        return null
    }
}


export const SubstrateMaterial = ({ refetch, id }) => {

    return <SubstrateMaterialEditForm orig_id={id} substrate={{}} refetch={refetch} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>






export const SubstrateMaterialEmbedded = ({ substrate_material }) => {
    if (!substrate_material)
        return null


    return (

                <table className='document-show'>
                    <tbody>
                        {displayLineAlways(substrate_material, 'Title', 'title')}
                        {displayLines(substrate_material, 'Description', 'description')}
                        {displayLineAlways(substrate_material, 'Delivery Date', 'deliverydate')}
                        {displayLineAlways(substrate_material, 'Operator', 'operator_name')}
                        {displayLineAlways(substrate_material, 'Order Number', 'ordernumber')}
                        {displayLineAlways(substrate_material, 'Material', 'material')}
                        {displayLineAlways(substrate_material, 'Layer Stack', 'layerstack')}
                        {displayLineAlways(substrate_material, 'Thicknesses', 'thicknesses')}
                        {displayLineAlways(substrate_material, 'Resistance', 'resistance')}
                        {displayLineAlways(substrate_material, 'Structuring Method', 'structuring_method')}
                        {displayLineAlways(substrate_material, 'Lot Number', 'lot_number')}
                        {displayLineAlways(substrate_material, 'Supplier', 'supplier')}                  
                    </tbody>
                </table>)
}


export const SubstrateMaterialShow = ({ substrate_material, refetch }) => {
    if (!substrate_material)
        return <div className='body'></div>

    const { supervisor, operator } = substrate_material



    return (
                <div className='body'>
                    <MetaData key={`C-${substrate_material.id}`}
                     indicator='substrate_material' document={substrate_material} refetch={refetch} />
                    <div className='details'>
                        <SubstrateMaterialEmbedded substrate_material={substrate_material} />
                        <Attachments title="Datasheets" value={substrate_material.datasheets}
                            root_url={backend_server.server} />                           
                    </div>
                </div>)
}


export const SubstrateMaterialShowComponent = ({ substrate_material, refetch }) => 
{
    if (!substrate_material)
        return null
    const {id} = substrate_material

    const router = useRouter()


    const editSubstrate = (e) => {
        router.goto(`/substrate-material/edit/${id}`, { focus: 'r', i: null })
    }

    const createBatch = (e) => {
        router.goto(`/substrate-material/create-batch/${id}`, { focus: 'r', i: null })
    }

    const createCopy = (e) => {
        router.goto(`/substrate-material/copy/${id}`, { focus: 'r', i: null })
    }


    const closePanel = (e) => {
        router.goto(`/substrate-material`, { focus: "l", i: null })
    }

    return <ComponentPanelRight className='wide substrate_material-details'>
                <div className='head' >
                 <div className='title'>{false && substrate_material.name} <i></i></div>
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
                 <SubstrateMaterialShow substrate_material={substrate_material} refetch={refetch} />
                <div className='form-actions'>
                    <button onClick={editSubstrate}>Edit</button>
                    <button onClick={createCopy}>Create Copy</button>
                    <div className='spacer'>&nbsp;</div>
                    <button onClick={createBatch}>Create Batch</button>
                </div>

            </ComponentPanelRight>
}

// <button onClick={cloneThis(db, id)}>Create Copy</button>
