import React, { useState, useRef } from 'react'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'

import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer } from '../app-frame/AppFrame'
import { ColorSelector } from '../metadata/Color'

import './Measurement.scss'

import {
    MeasurementCreateComponent,
    MeasurementEditComponent,
    MeasurementShowComponent
} from '../measurement/MeasurementEditor'




const initialFilter = {
    table: "boolean",
    id: "boolean",
    active: true,
    operator: "and",
    list: [
        {
            group: "Properties",
            table: "measurement",
            id: "identifier",
            active: true,
        }
    ]
}



const initialColumns = ["identifier", "display_title", "mode", "supervisor_name",
                        "operator_name", "description"]


export const MeasurementFrame = (props) => {

    const router = useRouter()
    const [fts, setFts] = useState("")
    const [display_mode, setDisplayMode] = useState('measurement')

    const { id } = router.params

    const [measurement, refetchComponent] = useBackendData(`measurement/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }


    const setTableRow = (id) => {
        router.goto(`/measurement/show/${id}`, { focus: 'r', i: null })
    }


    const createMeasurement = (_) => {
        router.goto(`/measurement/create`, { focus: 'r', i: null })
    }


    let component_type = null
    const location = router.location.pathname



    if (location.startsWith('/measurement/edit/') && id)
        component_type = 'edit'

    else if (location.startsWith('/measurement/edit') && !id)
        component_type = 'create'

    else if (location.startsWith('/measurement/create') && !id)
        component_type = 'create'

    else if (location.startsWith('/measurement/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == null)

    const showFilter = component_type == null || component_type == 'show'



    return  <AppFrame search_bar={true} highlight='measurement' onSearchChange={setFts} component_type={component_type}>
                <ComponentMenuTop>
                    <div className='filler'/>
                    <div className='main-section'>
                        <div className='section'>
                            <button onClick={createMeasurement} disabled={editing}>
                                <span className='fa fa-wand-magic-sparkles' />
                                &nbsp;     
                                Create Measurement...</button>
                        </div>
                    </div>

                    {false && <div className='main-section left'>
                        <div className='section'>
                            <button className={`mode border-measurement ${display_mode == 'measurement' ? 'active' : 'inactive'} `} onClick={() => setDisplayMode('measurement')}>Measurements</button>
                            <button className={`mode border-measurement ${display_mode == 'substrate' ? 'active' : 'inactive'}`} onClick={() => setDisplayMode('substrate')}>Substrates</button>
                        </div>
                    </div>}

                    <div className='metadata-section'>
                        <MetaDataMenu table='measurement' view={viewRef} refetch={refetch} />
                    </div>                    

                </ComponentMenuTop>

                <ComponentContainer className='l-r'>
                    <ViewFrame ref={viewRef}
                        table="measurement"
                        viewTable={display_mode}
                        filterWrapper={null}
                        initialColumns={initialColumns}
                        initialFilter={initialFilter}
                        fts={fts}
                        dataURL='measurement/get_filter'
                        rowID={id}
                        setTableRow={setTableRow}
                        showFilter={showFilter}>

                        {
                            component_type == 'show' && id &&
                            <MeasurementShowComponent measurement={measurement} refetch={refetch} />
                        }
                        {
                            component_type == 'edit' && id &&
                            <MeasurementEditComponent measurement={measurement} refetch={refetch} />
                        }
                        {
                            component_type == 'create' &&
                            <MeasurementCreateComponent refetch={refetch} />
                        }

                    </ViewFrame>
                </ComponentContainer>
            </AppFrame>
}


