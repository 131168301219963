import React, { Fragment, useImperativeHandle, forwardRef, useContext } from 'react'
import { useState } from 'react'
import { GUIStateContext } from '../app/GUIContext'

import {humanize} 	from '../utils/utils'

import { ComponentPanelRight } from '../app-frame/AppFrame'
import { fetchMulti, useBackendData } from '../hooks/useDownloaded'

import { ExperimentShow } from '../experiment/ExperimentEditor'
import { SubstrateShow } from '../substrate/SubstrateEditor'
import { SolutionShow } from '../solution/SolutionEditor'
import { ChemicalShow  } from '../chemical/ChemicalEditor'
import { SubstrateBatchShow } from '../substrate/SubstrateBatchEditor'
import { SubstrateMaterialShow } from '../substrate/SubstrateMaterialEditor'

import { useRouter } from '../hooks/useRouter'


export const InfoBoxShow = ({onClose}) => 
{
	const { controller } = useContext(GUIStateContext)

	const { kind, id } = controller?.focused_object || { kind: 'none' }
	return <InfoBoxPanel kind={kind} id={id} onClose={onClose}/>
}



export const InfoBoxTabList = () => {
	const router 	= useRouter()

	const ibl 		= router.infoBoxList()

	if (!ibl || ibl.length == 0)
		return null
	const last = ibl.pop()

	return <InfoBoxPanel kind={last.kind} id={last.id} />
}



export const InfoBoxPanel = ({kind, id, refetch, onClose}) => 
{
	
	let url = '/experiment/none'
	if (kind == 'experiment')
		url = `experiment/get/${id}`
	else if (kind == 'solution')
		url = `solution/get/${id}`
	else if (kind == 'chemical')
		url = `chemical/get/${id}`
	else if (kind == 'substrate')
		url = `substrate/get/${id}`
	else if (kind == 'substratebatch')
		url = `substrate_batch/get/${id}`
	else if (kind == 'substratematerial')
		url = `substrate_material/get/${id}`

	const [dataset, refetchComponent] = useBackendData(url, null)
	const router  	= useRouter()

	const refetchAll = fetchMulti(refetchComponent, refetch)

	const closePanel = () => {
		router.popInfoBox()
		if (onClose)
			onClose()
	}


	if (!dataset)
		return null
//		return 	<ComponentPanelRight className={`template-list step-list info-box`} key={`no-experiment`} >
//					<div className='head'>
//						<div className='title'></div>
//						<div className='right button' onClick={closePanel}>
//							<span className='close-button'>✕</span>
//						</div>
//					</div>
//					<div className='body'>
//						<div className='helptext'>
//							Select an experiment, solution or material
//						</div>
//					</div>
//				</ComponentPanelRight >


	let title = ''
	if (kind == 'experiment' && dataset && dataset.subject == 'experiment')
		title = 'Experiment'
	if (kind == 'experiment' && dataset && dataset.subject == 'solution')
		title = 'Solution'
	else
		title = humanize(kind)

	return <ComponentPanelRight id='infobox' className={`template-list step-list info-box`} key={`${kind}-${id}`}>
			<div className='head'>
				<div className='title'>{title}</div>
				<div className='right button' onClick={closePanel}>
					<span className='close-button'>✕</span>
				</div>
			</div>
			{kind == 'experiment' 			&& <ExperimentShow experiment={dataset} 				refetch={refetchAll}/>}
			{kind == 'solution' 			&& <SolutionShow solution={dataset}  					refetch={refetchAll}/>}
			{kind == 'chemical' 			&& <ChemicalShow chemical={dataset}  		refetch={refetchAll}/>}
			{kind == 'substrate' 			&& <SubstrateShow substrate={dataset}  		refetch={refetchAll}/>}
			{kind == 'substratebatch' 		&& <SubstrateBatchShow batch={dataset}  				refetch={refetchAll}/>}
			{kind == 'substratematerial' 	&& <SubstrateMaterialShow substratematerial={dataset}  refetch={refetchAll}/>}
		</ComponentPanelRight >
}


