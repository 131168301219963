import React, { useContext } from 'react'


import { useRouter } from '../hooks/useRouter'


export const SubstrateComponentTop = ({editing}) =>
{

	const router 			= useRouter()

	const gotoSubstrates 	= (_) => router.goto(`/substrate`, 			{ focus: 'l' })
	const gotoBatches 		= (_) => router.goto(`/substrate-batch`, 	{ focus: 'l' })
	const gotoMaterials 	= (_) => router.goto(`/substrate-material`, { focus: 'l' })
	const gotoTypes 		= (_) => router.goto(`/substrate-kind`, 	{ focus: 'l' })


	const location = router.location.pathname.split("/")[1]


	return	<div className='icon-section'>
				<div className='section button-group'>

					<button className={`tab ${location == 'substrate' ? 'active' : ''}`} 
							disabled={editing}
							onClick={gotoSubstrates}>Substrates</button>


						<button disabled={editing} className={`tab ${location == 'substrate-batch' ? 'active' : ''}`} 
							disabled={editing}
							onClick={gotoBatches}>Batches</button>

						<button className={`tab ${location == 'substrate-material' ? 'active' : ''}`}
							disabled={editing}
							onClick={gotoMaterials}>Materials</button>

						<button className={`tab ${location == 'substrate-kind' ? 'active' : ''}`}
							disabled={editing}
							onClick={gotoTypes}>Types</button>
				</div>
			</div>


}