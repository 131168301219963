import React, { useContext, useState} from 'react';
import { isDragSource } from '../dnd/isDragSource'
import { DBContext } from '../app/DBContext';

import { addFilterToGroup } from './Filter'



export const JSONFilterTemplate = ({ filter, controller }) => {
	const { title, id } = filter

	let indicator = filter.indicator
	if (!indicator)
		indicator = `${filter.table || filter.title}`.split("/")[0].toLowerCase()

	const separator_class = filter.separator ? 'separator' : ''

	return 	<div  className={`filter-template-node ${separator_class}`}>
				<div className={`indicator ${indicator}-bg`}>
				</div>
				<div className='title'>{title}</div>
				<div className='filter-add icon' onClick={() => controller.addFilter(filter.table, filter)}>
			<span className='fa-regular fa-angle-right' />
				</div>
			</div>
}


export const JSONFilterTemplateGroup = ({group, controller}) => 
{
	const {filters,title} = group

	const [open,set_open] 	= useState( title == 'Properties' )
	const toggle_open  		= () => set_open(!open)

	return 	<div className='filter-template-group'>
				<div className='head' onClick={toggle_open}>
					{ open && <div className='fa-regular fa-caret-down icon' />}
					{!open && <div className='fa-regular fa-caret-right icon' />}
					<div className='content'>{title}</div>					
				</div>
				{open && 
					<div className='body'>
						{filters.map( (filter,i) => <JSONFilterTemplate key={`jft-${i}`} {...{filter, controller}}/>)}
					</div>}
			</div>
}


export const JSONFilterTemplateList = ({table, controller }) => 
{
	const context = useContext(DBContext)

	if (!context?.views?.queries)
		return <div />

	let t = table
	if (table == 'material')
		t = ['chemical', 'solution']
	else
		t = [table]
	

	const groups = {}
	const group_list = []
	
	addFilterToGroup(
		groups, group_list,
		{
			group:	"Boolean",
			id:		'boolean',
			table:	'boolean',
			title:	'And/Or',
			hidden: false,
			list: 	[]
		})


	let i = 0
	t.forEach( tab => 
	{
		i++
		const table_filters = context.views.queries[tab]
		if (table_filters)
		{
			for (let k of Object.keys(table_filters))
				addFilterToGroup(groups, group_list, table_filters[k])
		}
	})


	const rv = group_list.map((g,i) => <JSONFilterTemplateGroup key={`${g.title}`} 
																group={g}
																controller={controller} /> )

	return <div className='filter-templates-json'>{rv}</div>
}

