import React, { Component, Fragment, useContext, useState } from 'react'

import './Resource.scss'

import { post, useBackendData } from '../hooks/useDownloaded'

import { AppFrame } from '../app-frame/AppFrame'
import {
    ComponentContainer, ComponentMenuTop,
    ComponentPanelLeft, ComponentPanelArrow
} from '../app-frame/AppFrame'

import { useRouter } from '../hooks/useRouter'

import {
    ResourceCreateComponent,
    ResourceEditComponent,
    ResourceShowComponent
} from './ResourceEditor'


import {
    ResourceTypeCreateComponent,
    ResourceTypeEditComponent,
    ResourceTypeShowComponent
} from './ResourceTypeEditor'


import { DBContext } from '../app/DBContext'
import { cp } from 'fs'




//
//export const ResourceSubjectSelector2 = ({subjects, subject, onChange}) =>
//{
//
//    const on_change = (e) => 
//    {
//        console.log(e.target.value)
//        if (onChange)
//            onChange( e.target.value )
//    }
//
//    const options = subjects.map( (s,i) => {
//            return <option key={`R-${i}`} id={s.subject}  value={s.subject}>{s.name}</option>
//    })
//
//    return <select onChange={on_change}>{options}</select>
//}
//


const ResourceButton = ({ subject, selected, onChange }) => 
{
    const select = (e) => onChange(subject.id) 

    return  <div onClick={select}
                className={`button ${selected ? 'selected' : ''} step-button step-edit-button`}>
                    <div className='title'>{subject.title}</div>
            </div>
}


export const ResourceSubjectSelector = ({ subjects, selected, onChange, className }) => 
{


    const router = useRouter()
    const create_resource_type = () => {
        router.goto( '/resource_type/create')
    }

    return  <ComponentPanelLeft className={`step-edit-list process-list ${className} view`}>
                <div className='head'>
                    <div className='title'>Resources</div>
                    <div className='icon' onClick={create_resource_type}>
                        <span className='fa-regular fa-plus' />
                    </div>
                </div>
                <div className='body'>
                    <div className='button-container'>
                        {subjects.map((s,i) => 
                            <ResourceButton key={`step-${s.id}-${i}`} 
                                                    selected={`${s.id}` == selected} 
                                                    subject={s} onChange={onChange}/>)}
                    </div>
                </div>
            </ComponentPanelLeft>
}



const SubjectDataEntry = ( {resource, subject, onClick, selected, idx, active}) =>
{  
    const has_code = subject.has_code
    const click_select = () => {if (active) onClick(resource.id)}

    const code_class        = has_code                  ? 'has-code'    : ''
    const deleted_class     =  resource.deleted         ? 'deleted'     : ''
    const selected_class    = resource.id == selected   ? 'selected'    : ''
    const even_odd_class    = idx % 2 == 0 ? 'even' : 'odd'

    const klasses = `subject-entry view-row ${code_class} ${deleted_class} ${selected_class} ${even_odd_class}`

    return  <div className={klasses} onClick={click_select}>
                <div className='indicator'>&nbsp;</div>
                <div className='indicator-margin'>&nbsp;</div>
                {has_code && <div className='code'>{resource.code}</div>}
                <div className='title'>{resource.title}
                    {resource.deleted && <span className='error'>  (Inactive)</span>}
                </div>
            </div>
}


const SubjectEditor = ({onClick, selected, subject, active}) =>
{
    const resources = subject ? subject.resources : []
    if (!resources || resources.length == 0)
        return <div className='helptext'>No Entries have been defined</div>


    const sortres = resources.sort( (a,b) => 
    {

        const title_cp = (a,b) => 
        {
            if (subject.has_code)
            {
                if (`${a.code}` > `${b.code}`)
                    return 1
                else
                    return `${a.title}` > `${b.title}` ? 1 : -1
            }

            if (`${a.title}` > `${b.title}`)
                return 1
            else
                return -1
        }

        if (a.deleted && b.deleted)
            return  title_cp( a,b )
        if (b.deleted)
            return -1
        if (a.deleted)
            return 1
        return title_cp( a,b)
    })

    return  <div className='resource-entry-list'>
            {
                sortres.map( (r,i) => <SubjectDataEntry key={`${i}${r.key}`}
                                                        idx={i}
                                                        active={active}
                                                        selected={selected}
                                                        resource={r} 
                                                        subject={subject}
                                                        onClick={onClick} />)
            }
            </div>

}


const SubjectEditorComponent = (props) => 
{
    const {subject} = props
    const router = useRouter()
    const create_resource = () => router.goto(`/resource/create/${subject.id}`)

    return  <ComponentPanelLeft className='view-component'>
                <div className='head'>
                    <div className='title'>Resources</div>
                    {subject && <div className='icon fa fa-plus' onClick={create_resource}/>}
                </div>
                <div className='body'>
                    <SubjectEditor {...props} onClick={props.onClick} />
                </div>
                <div className='form-actions'>&nbsp;</div>
            </ComponentPanelLeft>
}


export const ResourceFrame = (props) => 
{
    const router        = useRouter()
    const db_context    = useContext(DBContext)


    const open = router.flag("fo", true)

    const refetchList  = async () =>
    {
        setTimeout(() => {
            db_context.reloadGlobals()
        }, 1000);
    } 

    const { id, subject_id } = router.params

    const all_resources  = db_context?.resources
    const subject = all_resources ? all_resources.resource_types[subject_id] : null

    if (!all_resources)
    {
        return  <AppFrame search_bar={true} highlight='resource'>
                    <ComponentMenuTop/>
                    <ComponentContainer className='l-r'/>
                </AppFrame>
    }

    let component_type = null
    const location = router.location.pathname

    if (location.startsWith('/resource/edit/') && id)
        component_type = 'edit'
    else if (location.startsWith('/resource/create') && !id)
        component_type = 'create'
    else if (location.startsWith('/resource/show'))
        component_type = 'show'

    if (location.startsWith('/resource_type/edit') && subject_id)
        component_type = 'edit_type'
    else if (location.startsWith('/resource_type/create'))
        component_type = 'create_type'
    else if (location.startsWith('/resource_type/show'))
        component_type = 'show_type'



    if (!subject && component_type != 'create_type')
    {
        setTimeout( () => router.goto( `/resource/list/${all_resources.resource_type_list[0].id}`), 0 )

        return  <AppFrame search_bar={true} highlight='resource'>
                    <ComponentMenuTop />
                    <ComponentContainer className='l-r' />
                 </AppFrame>
    }


    const resources = subject ? subject.resources : []

    const select_document = (id) => router.goto(`/resource/show/${subject_id}/${id}`, {focus: 'r', i: null })

    const select_subject    = (s) =>   router.goto(`/resource/list/${s}`)
    const is_editing        = component_type ? component_type.indexOf("edit") >=0 : false
    const display_master    = component_type == null && open && !is_editing

    const show_left        = is_editing ? false : display_master 
    const show_right       = is_editing ? false : !show_left


    const set_res_list = (tgt) => (e) => 
    {
        if (component_type == 'show' && open && id)
            router.goto( `/resource/list/${subject_id}`)
        else
            router.setFlag({ fo: tgt }) 
    }


    return  <AppFrame search_bar={true} highlight='resource'>
                <ComponentMenuTop>
                    <div className='main-section'>
                        <div className='section'>
                        </div>
                    </div>
                </ComponentMenuTop>


                <ComponentContainer className='l-r'>
                    {   display_master && 
                        <ResourceSubjectSelector 
                                            onChange={select_subject} 
                                            subjects={all_resources.resource_type_list} 
                                            selected={subject_id}/>
                    }

                    {   !display_master  && 
                        <ComponentPanelLeft className={`filter-master closed tabbed  actions`}>
                            <div className='head'>&nbsp;</div>
                        </ComponentPanelLeft>
                    }

                    {  
                        <ComponentPanelArrow onClick={set_res_list(show_right)}>
                            {show_right &&
                                <div className='button fa fa-caret-right hideshow' />}
                            {show_left &&
                                <div className='button fa fa-caret-left hideshow' />}
                        </ComponentPanelArrow>

                    }

                    {
                        component_type == 'edit_type' && subject_id &&
                        <ResourceTypeEditComponent
                            id={subject_id}
                            onSubmit={refetchList} />                   
                    }
                    {
                        component_type == 'create_type' &&
                        <ResourceTypeCreateComponent
                            onSubmit={refetchList} />
                    } 
                    {

                        component_type != 'create_type' && component_type != 'edit_type' &&
                        <ResourceTypeShowComponent
                            active={id == null}
                            id={subject_id} />
                    } 

                    <SubjectEditorComponent data={resources} 
                                            subject={subject}
                                            onClick={select_document} 
                                            active={component_type != 'edit_type' && component_type != 'create_type'}
                                            selected={id} />

                    {
                        component_type == 'show' && id &&
                        <ResourceShowComponent
                            id={id}
                            subject={subject}
                            onSubmit={refetchList} />
                    }                                            
                    {
                        component_type == 'edit' && id &&
                        <ResourceEditComponent      
                                            id={id} 
                                            subject={subject} 
                                            onSubmit={refetchList} />
                    }
                    {
                        component_type == 'create' &&
                        <ResourceCreateComponent
                            subject={subject}
                            onSubmit={refetchList} />
                    }                                       
                </ComponentContainer>
            </AppFrame>
}

