import React, { useState } from 'react'


import { 	AppFrame } 				from '../app-frame/AppFrame'
import { 	ComponentContainer, 
			ComponentMenuTop, 
			ComponentPanelRight } 	from '../app-frame/AppFrame'

import { useRouter } 				from '../hooks/useRouter'
import { post } 					from '../hooks/useDownloaded'

import config				 		from '../config/runconfig'

import {
	Form, TextBox,
	SubmitButton, ActionLink, 
	formLineError
} 									from '../form/Form'


import { backend_server } from '../config/runconfig'

const LoginComponent = (props) => 
{
	const router 				= useRouter()
	const [error, set_error] 	= useState(null)

	const url = `/login/login_user`

	const login = async (e, login_data) => 
	{
		const result = await post(url, login_data)

		const { data } = result
		if (data.success)
		{
			const token 		= data.token
			const loginname		= data.loginname

			if (!token)
				set_error( "Authentication error")
			else
			{
				const port 	   = window.location.port

				const targethost 	= config.client.replace( "%user%", loginname)
				const addport 	  	= port != '' ? `:${port}` : ''
				const newhostname 	= `${targethost}${addport}`
				const target = `${newhostname}/login/authenticate/${token}`
				window.location = target
			}
		}
		else
			set_error(data.error)
	}


	return 	<ComponentPanelRight className='person-details login'>
				<Form data={ {} } onSubmit={login} className='narrow form password'>
					<div className='head'>
					</div>
					<div className='body'>
						<div className='form-contents'>
							{error && <div className='error'>{error}</div>}

							<div className='formtable'>
								{formLineError('loginname', 'Login Name', <TextBox name='loginname' />)}
								{formLineError('password', 	'Password', 		 <TextBox name='password' type='password' />)}
							</div>

						</div>
						<div className='form-actions'>
							<SubmitButton>Login</SubmitButton>
						</div>
					</div>
				</Form>
			</ComponentPanelRight>
		}


//							<ActionLink>I forgot my password</ActionLink>


const LoginChangePassComponent = ({person_key}) =>
{
	const [error, set_error]	= useState( null )

	const url 		= `/login/set_password?key=${person_key}`
	const new_pass  = {password1: '', password2: ''}
	const router 	= useRouter()

	const submitChanges = async (e, new_pass) => 
	{
		const result = await post( url, new_pass)

		console.log( result.data )
		const {data} = result
		if (data.success)
			router.goto( '/login' )
		else
			set_error( data.error )
	}
	

	return <ComponentPanelRight className='person-details login'>
				<Form data={new_pass} onSubmit={submitChanges} className='narrow form password'>
					<div className='head'>
							Password Change
					</div>
					<div  className='body'>
						<div className='form-contents'>							
							{error && <div className='error'>{error}</div>}
							{formLineError('password1', 'New Password', <TextBox required={true} name='password1' type='password' />)}
							{formLineError('password1', 'Repeat Password', <TextBox required={true} name='password2' type='password' />)}
						</div>
						<div className='form-actions'>
							<SubmitButton>Set Password</SubmitButton>
						</div>
					</div>
				</Form>
			</ComponentPanelRight>		
}


export const LoginPage = (props) => 
{
	const router = useRouter()

	const { key } = router.params

	const location = router.location.pathname
	const changepass 	= location.startsWith('/login/changepassword')
	const authenticate 	= location.startsWith('/login/authenticate')

	if (authenticate)
	{
		console.log( "AUTHENTICATE ", key )
		localStorage.setItem('auth-token', key)
		router.goto( "/" )
		return <div/>
	}
	else
	{
		return <AppFrame search_bar={false} highlight='person' loginScreen={false}>
			<ComponentMenuTop>
				<div className='filler'></div>
			</ComponentMenuTop>


			<ComponentContainer className='dialogfront l-r'>
				{ changepass && <LoginChangePassComponent person_key={key} />}
				{!changepass && <LoginComponent />}
			</ComponentContainer>
		</AppFrame>
	}
}

