import React, { Component, Fragment, useContext, useEffect } from 'react'
import classnames   from 'classnames'
import {AppMenu}    from './AppMenu'
import { DBContext } from '../app/DBContext'
import Avatar from "boring-avatars";
import { Tooltip } from 'antd';




import 'antd/./dist/antd.js';
import './AppFrame.scss'
import '../styles/Form.scss'
import '../styles/Details.scss'

import {useRouter}  from '../hooks/useRouter'
import { humanize } from '../utils/utils';
import { backend_server } from '../config/runconfig'

export const AppFrameReady = (props) =>
{
    const context           = useContext(DBContext)
    const router            = useRouter()

    const flags             = router.flag_classes()
    const editorflags       = props.component_type ? `component-type-${props.component_type} layout-details-open`    
                                                   : 'layout-details-closed'





    const has_info          = router.hasInfoBox()
    const info_class        = has_info ? 'info-box-frame' : ''

    const {search_bar}      = props
    const search_class      = search_bar ? 'has-search-bar' : ''
    const clazz             = classnames( props.className, search_class, info_class )
    const search_bar_active = props.search_bar_active !== false

    const placeholder       = props.placeholder || 'Search...'
    const goto_home         = (_) => router.goto( "/" )

    const {ready}           = context



    useEffect( () => {
        let initials 
        if (current_user)
        {
            const fn = `${current_user.firstname} `[0]
            const ln = `${current_user.lastname} `[0]
            initials = `(${fn}${ln})`
        }
        else
            initials = ''

        document.title = `${current_user ? current_user.loginname : ''}`

        const favicon = document.getElementById("favicon");
        if (favicon)
            favicon.href = `${backend_server.server}/icons/favicon/${props.highlight}.ico`

        if (has_info)
        {
            const domNode = document.getElementById(`infobox`)
            if (domNode)
                domNode.scrollIntoView()
        }
    })


    const searchChange = (e) => {
        if (props.onSearchChange)
            props.onSearchChange(e.target.value, e)
    }

    const logout = () => {
        if (context.userController)
            context.userController.logout()
    }



    const current_user = context.current_user
    const username = current_user ? current_user.loginname : ''

    const avatar = current_user ? <Avatar
        size={50}
        square={true}
        name={`${current_user.id}-${current_user.loginname}`}
        variant={current_user.avatar_style}
        colors={current_user.avatar_palette}
    /> : null



    return  <div className={`app-frame ${clazz} ${flags} ${editorflags}`}>
                <div className='master-menu menu-bar'>
                    <div className='icon-section'>
                        <div className='icon home' onClick={goto_home}><span className='fa-regular fa-home' /></div>
                    </div>

                    <div className='main-section'>
                    {humanize(`${props.title ?? props.highlight}`)}
                    </div>

                    <div className='filler'/>
                    <div className='main-section'>
                    </div>
                    <div className='icon-section'>
                        {current_user && <div className='username'>{username}</div>}
                        {current_user && 
                            <Tooltip placement='bottomLeft' title="Logout">
                                <div onClick={logout} className='icon user'><span className='fa-regular fa-sign-out-alt' /></div>
                            </Tooltip>}
                        {avatar && <div className='avatar'>{avatar}</div>}
                    </div>
                </div>
                <div className='app-bottom'>
                    <div className='left-menu'>
                        <AppMenu highlight={props.highlight} login_screen={props.login_screen} />
                    </div>
                    <div className='app-master'>
                        {
                            search_bar &&
                            <div className='search-bar'>
                                <div className='search-icon'>
                                    <span className='fa-regular fa-search' />
                                </div>
                                <div className='search-entry'>
                                    <input className="search-input" placeholder={placeholder} tabIndex={1} 
                                            onChange={searchChange} 
                                            disabled={!search_bar_active}        
                                    />
                                </div>
                            </div>
                        }
                        {props.children}
                    </div>
                </div>
            </div>
}

export const AppFrameLoading = (props) => 
{
    const { search_bar } = props
    const search_class = search_bar ? 'has-search-bar' : ''
    const clazz = classnames(props.className, search_class)
    const router = useRouter()


    const goto_home = (_) => router.goto("/")

    return  <div className={`app-frame ${clazz}`}>
                <div className='master-menu menu-bar'>
                    <div className='icon-section'>
                        <div className='icon home' onClick={goto_home}><span className='fa-regular fa-home' /></div>
                    </div>

                    <div className='filler' />
                    <div className='main-section'>
                    </div>
                </div>
                <div className='app-bottom'>
                    <div className='left-menu'>
                        <AppMenu highlight={props.highlight} loginScreen={true} />
                    </div>
                    <div className='app-master'>
                    </div>
                </div>
            </div>
}


export const AppFrame = (props) =>
{
    const context = useContext(DBContext)

    if (!context || !context.ready)
        return <AppFrameLoading {...props} />
    else
        return <AppFrameReady {...props}  />
}



const klasses = (props) => {
    let result = classnames(props.className)
    if (props.actions)
        result = `${result} has-form-actions`

    return result
}


export const ComponentMenuTop = (props) => (
    <div className={classnames('component-menu-top menu-bar', props.className)}>
        {props.children}
    </div>)

export const ComponentPanel = (props) => (
    <div className={classnames('component-panel', klasses(props))}>
        {props.children}
    </div>)


export const ComponentPanelLeft = (props) => {


    return <div className={classnames('component-panel component-panel-left', klasses(props))}>
        {props.children}
    </div>
}

export const ComponentPanelArrow = (props) => 
{
    return <div className={classnames('component-panel-arrow', klasses(props))} {...props}>
                {props.children}
            </div>
}




export const ComponentPanelRight = (props) => (
    <div id={props.id} className={classnames('component-panel component-panel-right editor-panel', klasses(props))}>
        {props.children}
    </div>)

export const ComponentPanelExpand = (props) => (
    <div className={classnames('component-panel component-panel-expand expand', klasses(props))}>
        {props.children}
    </div>)

export const ComponentPanelFill = (props) => (
    <div className={classnames('component-panel fill', klasses(props))}>
        {props.children}
    </div>)


export const ComponentPanelDocked = (props) => (
    <div className={classnames('component-panel docked', klasses(props))}>
        {props.children}
    </div>)


// This is used to display a component panel if data is still loading with a specific header
export const ComponentPanelBlank = (props) =>   <ComponentPanel {...props}>
                                                    <div className='head'>{props.title}</div>
                                                    <div className='body'></div>
                                                </ComponentPanel>


export const ComponentPanelContainerExpand = (props) => <div className={classnames('expand', props.className)}>
                                                            {props.children}
                                                        </div>



export const ComponentContainer = (props) => (
    <div className={classnames('component-container-master', klasses(props))}>
        {props.children}
    </div>)


