import React, { useContext } from 'react'
import { useRouter } from '../hooks/useRouter'


export const EntityButton = ({kind, entity}) =>
{
	const router = useRouter()

	if (kind == null || entity == null || entity.id == null)
		return null

	const target = 	{ 
						id: 			entity.id,
						indicator: 		entity.indicator 	 || kind, 
						display_title: 	entity.display_title || entity.title ||`${kind[0].substring(0,1).toUpperCase()}:${entity.id}`,
						url: 			`/${kind}/show/${entity.id}`
					}


	
					
	const link_to  = () => { router.pushInfoBox( kind, entity.id )}

	return 	<div className='entity-link' onClick={link_to}>
				<div className={`indicator ${target.indicator}-bg`}/>
				{ target.notinuse &&<div className='title'><i>{target.display_title}</i></div>}
				{!target.notinuse && <div className='title'>{target.display_title}</div>}
				<div className='icon fa fa-eye'/>
			</div>
}



export const EntityDisplay = ({kind}) => <div></div>
