import React, { Fragment, useContext, useState } from 'react'
import { post } from '../hooks/useDownloaded';

import { Form, formLine, TextBox, CheckBox, Select, SubmitButton, Spacer } from '../form/Form'

import { all_colors } from '../metadata/Color'
import './Colors.scss'

import { DBContext } from '../app/DBContext'

import {  notification, Popconfirm, Popover } from 'antd';

import {Tooltip } from 'antd';

import { blank } from '../utils/utils';

export const KeywordIndicator = ({  kw, onClick }) =>
{
	const click = (e) => { if (onClick) onClick(kw) }

	const result = <div className={`keyword kw-${kw.color}`}>
						<div className='title'>{kw.keyword}</div>
		<div className='icon' onClick={click}><span className='close-button'>✕</span></div>
					</div>


	if (blank( kw.description ))
		return result
	else
		return 	<Tooltip placement='bottom' title={kw.description || kw.keyword}>
					{result}
				</Tooltip>
}




export const Keywords = ({ document, refetch, edit_active }) => 
{
	const onClick = (kw) => {
		post(`/keyword/toggle/${kw.master_id}`, { obj_id: document.id, obj_table: document.table })
			.then(() => refetch())
	}

	return 	<Fragment>
				{document.keywords && document.keywords.length > 0 && 
					<div className='keyword-list metadata-section'>
						{
							document.keywords.map((k, i) =>
								<KeywordIndicator key={`KW-${k.id}-${i}`} document={document} kw={k} onClick={onClick} />)
						}
					</div>
				}
				<div className='keyword-menu  metadata-section'>
					<KeywordMenu document={document} refetch={refetch}  edit_active={edit_active}/>
				</div>
			</Fragment>
}



const KeywordListEntry = ({keyword, selected_id, onSelect}) =>
{
	const select_class = selected_id == keyword.id ? 'selected' : ''
	const click = (e) => {if (onSelect)  onSelect( keyword)}
	return <div className={`entry ${select_class}`} onClick={click}>
				<div className={`indicator mark-${keyword.color}-bg`}>&nbsp;</div>
				<div className='title'>{keyword.keyword}</div>
			</div>
}

export const KeywordEditor = ({table, db_table, onClose }) => 
{
	const context = useContext( DBContext)

	const keywords = context.keywords.all_for( table )

	const [selected, set_selected]		= useState( keywords.length > 0 ? keywords[0] : null )
	const selected_id = selected?.id

	const select_click 	= (kw) => set_selected( kw )

	const submitChanges = async (e, data) => 
	{
		data.table 		= table
		data.db_table 	= db_table
	

		let url = `/keyword/save`
		if (data.id )
			url = `/keyword/save/${data.id}`

		post( url, data )
		.then( (result) => {
			console.log( "Back from post")
			if (result.data.success)
				set_selected( result.data.keyword )
			setTimeout(() => context.reloadKeywords(), 0);	
		})
	}

	const createNew = async (e,data) =>
	{
		set_selected( null )
	}


	const onDelete = (kw) => (e) =>
	{
		post( `/keyword/remove_definition/${kw.id}`)
		.then( result => 
{
const args = {
	message: 'Tag Deleted',
	description: `Tag ${kw.keyword} removed from all ${document.table}s`,
	duration: 2,
};
notification.open(args);

setTimeout(() => context.reloadKeywords(), 0);	
		})
	}

	return 	<div className="keyword-edit-dialog component-panel">
				<div className='background-fade'></div>
				<div className='dialog'>
					<div className='head'>                        
						<div className='spacer'>&nbsp;</div>
						<div className='close-button' onClick={onClose}>✕</div>
					</div>
					<div className='body'>
						<div className='keyword-masterlist'>
							<div className='list'>
								{
									keywords.map( (kw,i) => <KeywordListEntry 
																		key={`KW-${i}-${kw.id}`} 
																		keyword={kw} 
																		selected_id={selected_id}
																		onSelect={select_click}/>)
								}
							</div>
							<div className='form-actions'>
								<button onClick={createNew}>New...</button>
							</div>
						</div>						
						<div className='keyword-edit'>
							<Form key={`${selected_id || 'non'}-${document.id}`} data={ selected } 
									onSubmit={submitChanges}>
								<div className='form-contents'>
									<div className='formtable'>
										{formLine('Name', 			<TextBox name='keyword' required='true' min_length={3} />)}
										{formLine('Description', 	<TextBox name='description' type='textarea' />)}
										{formLine('Color', 			<Select name='color' options={all_colors} html={true} />)}
										{formLine('Private', 		<CheckBox name='private' />)}
									</div>
								</div>
								<div className='form-actions'>
									<SubmitButton text='Save'  />
									<Spacer/>
									{	
										selected_id &&
											<Popconfirm
												title="Are you sure to delete this tag?"
												onConfirm={onDelete(selected)}
												okText="Yes"
												cancelText="No">

												<button>Delete</button>

											</Popconfirm>
									}
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
}


const KeywordSelector = ({ kw, document, onClick }) => 
{
	const click = (e) => { if (onClick) onClick(kw) }
	return 	<div className={`keyword-selector`} onClick={click}>
				<div className={`indicator mark-${kw.color}-bg`}>&nbsp;</div>
				<div className='title'>{kw.keyword}</div>
			</div>
}

const KeywordSelectorBox = ({document, keywords, onClick}) =>
{
	const list = []



	keywords.forEach( (kw,i) => 
	{
		const unused = document?.keywords ? document.keywords.find(dk => dk.master_id == kw.id) == undefined : null

		if (unused)
			list.push(<KeywordSelector key={`kw-${i}`} kw={kw} document={document} onClick={onClick} />)
	})

	if (list.length > 0)
			return <div className='keyword-selector-list'>{list}</div>

	return null
}



export const KeywordMenu = ({document, refetch, edit_active}) => 
{

	const { table } = document
	const [editing, set_editing] = useState(false)
	const [visible, setVisible] = useState(false);

	const toggle_editing = () => set_editing(!editing)

	const editor = editing ? <KeywordEditor table={table} db_table={table} onClose={toggle_editing} /> : null

	const context = useContext(DBContext)
	const keywords = context.keywords.all_for(document.table)


	const hide = () => 
	{
		setVisible(false);
	};

	const handleVisibleChange = (new_visible) => 
	{
		setVisible(!editing && new_visible);
	}

	const onClick = (kw) => 
	{
		hide()
		post( `/keyword/toggle/${kw.id}`, {obj_id: document.id, obj_table: document.klass || document.table})
		.then( (result) => 
		{
			if (refetch) 
				refetch()
		})
	}


	const items = 	<div className='keyword-selector-box'>
						<KeywordSelectorBox keywords={keywords} document={document} onClick={onClick} />
						{refetch && edit_active !== false && <div className={`keywords-edit`} onClick={toggle_editing}>
							Edit Tags...
						</div>}
					</div>

	return 	<div className='action-line'>
				<Popover 	content={items} 
							trigger="click" 
							overlayInnerStyle={ {backgroundColor:"#f8f8ff"}} 
							placement="leftBottom"
							open={visible && !editing}
							onOpenChange={handleVisibleChange}
						>
					{editor}
					<div className='keyword-menu-button action'><span className='fa-regular fa-tag icon'/>Tags...</div>
				</Popover>
			</div>
}