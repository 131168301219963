import JSONPretty from 'react-json-pretty'
import React, { useContext } from 'react'
import { useState } from 'react'

import '../filter/Filter.scss'

import { GUIStateContext } from '../app/GUIContext'

import { JSONFilter } from './JSONFilter'
import { JSONFilterTemplateList } from './JSONFilterTemplateList'

import { removeFilterMetaData } from './Filter'

export const JSONFilterEditor = ({editing, filterWrapper }) => 
{
	const { controller } 			= useContext(GUIStateContext) 
	const {table, focusTarget} 		= controller

	const filterDecorated 			= controller.getFilter( filterWrapper )


	const filterEditController 		= {
		editing 			: editing, 
		table 				: table,
		focusTarget 		: focusTarget,
		setFilter 			: (filter) 					=> controller.setFilter(filter),
		addFilter 			: (table, template) 		=> controller.addFilter(table, template),
		dropFilterTemplate	: (item, targetID) 			=> controller.dropFilterTemplate(item, targetID),
		moveFilterNode 		: (item, targetID) 			=> controller.moveFilterNode(item, targetID),
		removeFilter 		: (target) 					=> controller.removeFilter(target),
		onSubFilterOpen 	: (sub, filter, parameter) 	=> controller.subFilterToggle(sub, filter, parameter),
		setFocus 			: (target, force) 			=> controller.setFocus(target, force),
		getFocusedTable 	: () 						=> controller.getFocusedTable(),
		getFocusedNode 		: () 						=> controller.getFocusedNode()
	}


	let 	templateTable 	 = controller.getFocusedTable()


	return 	<div className='filter-body-json'>
				{editing && 
					<JSONFilterTemplateList table={templateTable} controller={filterEditController} />}
		
					<JSONFilter filter={filterDecorated} 
								table={table}
								controller={filterEditController} />

					{false && <div>
						<JSONPretty data={removeFilterMetaData(controller.getFilter( filterWrapper, true ))} />
					</div>}
			</div>
}

