
import React, { useState, useContext } from 'react';
import {
	TextBox, CheckBox, 
	DateTime, Duration, Time,
	Select, SelectCycleButton,
	HideShow
} from '../form/Form';


import { MaterialFormElement } from '../material/MaterialQuery';
import { usePersonSelection } from '../hooks/usePersons'
import { DBContext } from '../app/DBContext'

import { all_colors } from '../metadata/Color';


const SubFilterTrigger = ({ controller, filter, parameter }) => 
{

	if (!controller.editing)
		return <div className='subfilter-trigger'>&nbsp;</div>
	const toggleExpansion = (e) => 
	{
		console.log( "Trigger Subfilter")
		e.stopPropagation()
		controller.onSubFilterOpen(true, filter, parameter)
	}

	return <div className='subfilter-trigger'>
		{filter.subfilter && <button><span onClick={toggleExpansion} className='fa-regular fa-caret-down' /></button>}
		{!filter.subfilter && <button><span onClick={toggleExpansion} className='fa-regular fa-caret-up' /></button>}
	</div>
}


const FilterExpansionTrigger = ({ controller, filter }) => 
{
	const toggleExpansion = (e) => {
		e.stopPropagation()
		controller.onSubFilterOpen(false, filter)
	}

	return <div className='subfilter-trigger'>
		{filter.expanded 	&& <button><span onClick={toggleExpansion} className='fa-regular fa-caret-down' /></button>}
		{!filter.expanded	&& <button><span onClick={toggleExpansion} className='fa-regular fa-caret-up' /></button>}
	</div>
}

export const ParameterControl = ({controller, filter, children, withTrigger, withBoolean }) =>
	<div className={`control subfilter ${withBoolean ? 'noexpand' : ''}`}>
		<div className='subcontrol'>
			{children}
		</div>
		{withTrigger && <FilterExpansionTrigger controller={controller} filter={filter} />}
		{!withTrigger && <div className='subfilter-trigger'/>}
 	</div>





export const JSONSubFilterBooleanParameter = ({filter, parameter, entity, default_value}) =>
{
	const qualifier = parameter ? `:${parameter.id}` : ''
	const name = `OP-${filter.uuid}${qualifier}`

	return 	<div className='subcontrol boolean noexpand'>
				<Select options={[{ label: 'Any', value: 'or' }, { label: 'All', value: 'and' }]}
					name={name}
					default_value={default_value || 'and'}
					cycle_button={true} />
				<i>of...</i>
			</div>
}






export const JSONSubFilterMaterialParameter = ({ controller, filter, parameter, entity, default_value }) => 
{
	const qualifier = parameter ? `:${parameter.id}` : ''
	const name_op = `OP-${filter.uuid}${qualifier}`
	const name_mt = `SK-${filter.uuid}${qualifier}`

	console.log( filter.list )

	return 	<div className='subcontrol boolean noexpand'>
				<SelectCycleButton options={[{ label: 'Chemicals', value: 'chemical' }, { label: 'Solutions', value: 'solution' }]}
					name={name_mt}
					disabled={filter.list && filter.list.length > 0}
					default_value='solution' />
				<span>where &nbsp;</span>
				<Select options={[{ label: 'Any', value: 'or' }, { label: 'All', value: 'and' }]}
					name={name_op}
					default_value={default_value || 'and'}
					cycle_button={true} />
			</div>
}



export const JSONFilterParameter = ({ controller, inHeader, filter, parameter, ...extra_props }) => 
{
	const context 		= useContext( DBContext )
	const resources  	= context?.resources

	if (!parameter)
		return null;
	const { kind, ...rest } = parameter;
	const { fieldName, title, ...extras } = extra_props;


	rest.required = false; 		// This is a filter
	rest.name = `P-${filter.uuid}:${parameter.id}`

	const [person_options] = usePersonSelection(true)

	const expanded 	= filter.expanded == true
	
	if (kind == 'text' && inHeader && expanded)
		return <ParameterControl controller={controller} filter={filter} withTrigger={true} withBoolean={true}>
					<JSONSubFilterBooleanParameter filter={filter} default_value="or" parameter={parameter}/>
				</ParameterControl>

	if (kind == 'text' && inHeader && !expanded)
		return 	<ParameterControl controller={controller} filter={filter} withTrigger={true}>
					<TextBox {...rest} {...extras} />
				</ParameterControl>
	
	if (kind == 'text' && !inHeader)
		return 	<TextBox {...rest} {...extras} type="textarea" />


	if (kind == 'numeric' || kind == 'float' || kind == 'int') 
	{
		const { name, ...restrest } = rest;

		return 	<ParameterControl controller={controller} filter={filter}>
					<div className='rangeparam'>
						<TextBox name={`${name}:min`} type='numeric' {...restrest} {...extras} />
						<div className='sep'>-</div>
						<TextBox name={`${name}:max`} type='numeric' {...restrest} {...extras} />
					</div>
				</ParameterControl>
	}

	if (kind == 'time') 
	{
		const { name, ...restrest } = rest;

		return 	<ParameterControl controller={controller} filter={filter}>
					<div className='rangeparam'>
						<Time name={`${name}:min`} type='numeric' {...restrest} {...extras} />
						<div className='sep'>-</div>
						<Time name={`${name}:max`} type='numeric' {...restrest} {...extras} />
					</div>
				</ParameterControl>
	}



	if (kind == 'select' || kind == 'resource') {

		const opts = { ...rest }

		let select = null
		if (kind == 'select')
			select = <Select {...opts} {...extras} html={inHeader} />
		else {
			opts.options = resources ? resources.options(opts.resource) : []
			opts.multiple = true
			select = <Select {...opts} {...extras} html={inHeader} />
		}

		if (opts.multiple && inHeader)
			return null

		let control
		if (parameter.any_or == true && inHeader) {
			const yesno = [{ label: 'Any', value: 'or' }, { label: 'All', value: 'and' }]
			control = <div className='any-or'>
				<div className='any-or-selector'>
					<Select name={`YESNO-${rest.name}`}
						options={yesno}
						default_value='or'
						cycle_button={true}
						className='connector' /> <i>of...</i>
				</div>
			</div>
		}
		else
			control = select


		return <div className='control'>
			{control}
			<div className='subfilter-trigger'/>
		</div>
	}




	if (kind == 'keyword') 
	{
		const opts = { ...rest }

		opts.options = context.keywords.options_for( filter.table )

		if (opts.multiple && inHeader)
		 	return null

		let control
		if (inHeader && expanded)
			control = 	<ParameterControl controller={controller} filter={filter} withTrigger={true}>
							<JSONSubFilterBooleanParameter filter={filter} default_value="or" parameter={parameter}/>
						</ParameterControl>

		if (inHeader && !expanded)
			control = 	<ParameterControl controller={controller} filter={filter} withTrigger={true}>
							<Select {...opts} {...extras} html={inHeader} />
						</ParameterControl>
		
		if (!inHeader)
			control = <Select 	multiple={true} 
								default_value={[]} 
								checkboxes={true} {...opts} {...extras} html={inHeader} />

		return 	<div className='control'>
					{control}
				</div>
	}


	if (kind == 'color') {
		const opts = { ...rest }

		opts.options = all_colors
		opts.multiple = false


		const control = <Select  {...opts} {...extras} html={inHeader} />

		return <div className='control'>
			{control}
		</div>
	}

	if (kind == 'date')
		return 	<div className='control wide'>
					<DateTime {...rest} />
				</div>


	if (kind == 'checkbox')
		return 	<div className='control wide'>
					<CheckBox {...rest} {...extras} labelClass='title' />
				</div>


	if (kind == 'material') 
	{
		return <div className='control subfilter'>
			{filter.subfilter && <JSONSubFilterMaterialParameter controller={controller} filter={filter.subquery} entity={kind} />}
			{!filter.subfilter && <div className='subcontrol'>
				<MaterialFormElement {...rest} {...extras} />
			</div>}
			<SubFilterTrigger controller={controller} filter={filter} parameter={parameter} />
		</div>
	}

	if (kind == 'chemical')
	{
		return 	<div className='control subfilter'>
			{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity={kind}/>}
					{!filter.subfilter && <div className='subcontrol'>
							<MaterialFormElement {...rest} {...extras} />
					</div>}
					<SubFilterTrigger controller={controller} filter={filter} parameter={parameter}/>
				</div>
	}

	if (kind == 'solution') 
	{
		return <div className='control subfilter'>
			{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity={kind} />}
					{!filter.subfilter && 
						<div className='subcontrol'>
							<MaterialFormElement {...rest} {...extras} />
						</div>}
					<SubFilterTrigger controller={controller} filter={filter} parameter={parameter} />		
				</div>
	}

	if (kind == 'substrate_material') {
		return <div className='control subfilter'>
			{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity='material' />}
			{!filter.subfilter && 
				<div className='subcontrol'>
					<TextBox {...rest} {...extras} />
			</div>}
			<SubFilterTrigger controller={controller} filter={filter}  parameter={parameter} />
		</div>
	}



	if (kind == 'substrate')
		return <div className='control subfilter'>
					{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity={kind} />}
					{!filter.subfilter && 
						<div className='subcontrol'>
							<TextBox {...rest} {...extras} />
						</div>}
					<SubFilterTrigger controller={controller} filter={filter} parameter={parameter} />		
				</div>

	if (kind == 'person') 
	{
		return 	<div className='control subfilter'>
					{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity={kind} />}
					{!filter.subfilter && 
						<div className='subcontrol'>
							<Select html={true} {...rest} {...extras} options={person_options} />
						</div>}
					<SubFilterTrigger controller={controller} filter={filter} parameter={parameter}/>
				</div>
	}


	if (kind == 'experiment') {
		return <div className='control subfilter'>
					{filter.subfilter && <JSONSubFilterBooleanParameter filter={filter.subquery} entity={kind} />}
					{!filter.subfilter && 
						<div className='subcontrol'>
									<TextBox {...rest} {...extras} />
					</div>}
					<SubFilterTrigger controller={controller} filter={filter} parameter={parameter} />		
				</div>
	}

	if (kind == 'timestamp') 
	{
		if (inHeader)
			return null
		const { name, ...restrest } = rest
		const last_name = `${rest.name}:days`
		const kind_name = `${rest.name}:date_kind`

		const kind =	[
							{ label: 'From-To', value: 'from' },
							{ label: 'Last', value: 'last' }
						]

		return 	<div className='timerange control'>
					<div className='date-param'>
						<div className='title'>
							<Select name={kind_name}
									options={kind}
									default_value='last'
									cycle_button={true}
									className='connector' />
						</div>
						<div className='contents'>
							<HideShow hide={`${kind_name}=from`}>
								<Select html={true} options="7,14,30,90,365" name={last_name} /> days
							</HideShow>

							<HideShow show={`${kind_name}=from`}>
								<DateTime name={`${name}:from`}	{...restrest} {...extras} />
							</HideShow>
						</div>
					</div>

					<HideShow show={`${kind_name}=from`}>
						<div className='date-param'>
							<div className='title'>&nbsp;</div>
							<div className='contents control'>
								<DateTime name={`${name}:to`}	{...restrest} {...extras} />
							</div>
						</div>
					</HideShow>

				</div>
	}

	console.log( "KIND NOT FOUND: ", kind)


	return null;
};
