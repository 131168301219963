import React 							from 'react'
import {useState, useContext} 			from 'react'
import { DBContext } from '../app/DBContext'

import { ColorSelector } from '../metadata/Color'
import { formatTimeStamp } from '../utils/utils'

import { Dropdown, Menu, Space } from 'antd';

import { Keywords, KeywordIndicator } from './Keyword'


export const MetaData = ( {document, refetch, indicator, children, edit_active, title, subtitle, subtitle_left }) =>
{

	const table 	= document?.table || indicator
	const ind 		= indicator || document?.indicator || table

	const date = document?.updated_at ? formatTimeStamp(document.updated_at) : null

	const title_to_use = !document ? "<New Document>" : title || document.display_title
	
	return	<div className={`metadata ${ind}-bg`}>
				<div className='title-block'>
					<div className='title'>{title_to_use}</div>
					{document && <ColorSelector key={`C-${document.id}`} indicator={indicator}
						selected={document.color} table={table} id={document.id} onColorChange={refetch} />}
				</div>
				<div className='subtitle-block'>
					{subtitle_left && <div className='subtitle-left'>{subtitle_left}</div>}
					<div className='subtitle'>{subtitle}</div>
					{date && <div className='subtitle'>{date}</div>}
				</div>
				{<div className='metadata-bottom'>
					<Keywords document={document} table={`${table}`.toLowerCase()} db_table={table} 
								refetch={refetch} edit_active={edit_active !== false}/>
					{children}
				</div>}
			</div>
}
