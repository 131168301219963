import React, { FunctionComponent, ReactElement, useContext, useLayoutEffect, useRef, useState } from 'react'

import { ViewConfiguration, ViewSelectionType } from './ViewConfiguration'
import { ViewTableController } from './ViewTableController'
import { formatTime } from '../utils/utils'

import { isDragSource } from '../dnd/isDragSource'
import {
    DataIndexTree,
    DataType,
    Datum
} from './model/model'
import {
    ViewColumn,
    ViewSchema,
    ViewSection,
    ViewSectionType
} from './viewschema'

import { Dropdown, Menu } from 'antd'
import { DBContext } from '../app/DBContext'


import "./View.scss"


type StringHash = {[index : string] : string}



export const getWidthCSS = (schema: ViewSchema): StringHash => {
    const cat_count = schema.view_categories.length
    const cols = []

    const style: StringHash = {}

    let i = 1
    for (let cc = 0; cc < cat_count; cc++) {
        style[`--width-col-${i}`] = '15px'
        i++
    }

    for (let col of schema.view_columns) {
        style[`--width-col-${i}`] = `${col.view_column.width}px`
        i++
    }

    return style
}



const createColGroup = (schema: ViewSchema ) =>
{
    const cat_count = schema.view_categories.length
    const rv = [<col key='col0' className="col-0"/>]


    let i = 1
    for (let cc = 0; cc < cat_count; cc++) 
    {
        rv.push( <col key={`col${i}`} className={`col-${i}`}/>)
        i++
    }

    for (let col of schema.view_columns) 
    {
        rv.push(<col key={`col${i}`} className={`col-${i}`} />)
        i++
    }

    return <colgroup>{rv}</colgroup>
}





interface ViewSectionProps
{
    controller:    ViewTableController,
    index_node:         DataIndexTree
}



const Root : FunctionComponent<ViewSectionProps> = ({children, controller}) => 
{
    const schema = controller.viewConfig.schema
    const tableStyleWidth = getWidthCSS(schema)

    return <div className='view-root' style={tableStyleWidth}>
                {children}
            </div>
}

interface ViewSubSectionProps extends ViewSectionProps
{
    level           : number,
    value           : Datum
}

const Tab: FunctionComponent<ViewSubSectionProps> = ({ value, children }) =>    
    <div className='tab'>
        <div className='head'>{value}</div>
        <div className='body'>{children}</div>
    </div>

const Sec: FunctionComponent<ViewSubSectionProps> = ({ value, children }) => 
    <div className='section'>
        <div className='head'>{value}</div>
        <div className='body'>{children}</div>
    </div>






interface CatSectionProps extends ViewSubSectionProps
{}



const Cat: FunctionComponent<CatSectionProps> = ({ controller, value, children, level, index_node }) => 
{
    const schema = controller.viewConfig.schema

    const cat_count     = schema.view_categories.length
    const col_count     = schema.view_columns.length
    const total_count   = cat_count + col_count 

    const indent        = level - schema.first_category_level - 1

    const isClosed      = controller.isClosed( index_node.id )
    const open_close    = () => controller.toggleOpenClose( index_node.id )

    const ref = controller.viewRowBodyRefs[index_node.id] || React.createRef()
    controller.viewRowBodyRefs[index_node.id] = ref


    const indent_html   = []
    for (let cc = 0; cc < indent; cc++)
        indent_html.push(<td key={`cat-${cc}`} className='cat-head'>&nbsp;</td>) 


    return  <>
                <tr className={`catrow level-${level}`} key={`cat-${value}`} onClick={open_close}>
                    <td ref={ref}  key='cat-ind' className='spacer cat-bg cat-brd'>
                        <div    className='entry'></div>
                    </td>
                    {indent_html}
                    <td key='cat-caret' colSpan={total_count - indent + 1}>
                        <div className='caret'>
                            {isClosed && <span>►</span>}
                            {!isClosed && <span>▼</span>}
                        </div>
                       <div className='catvalue'>{value}</div>
                    </td>
                </tr>
                {!isClosed && children}
            </>
}







interface ColSectionProps extends ViewSectionProps
{
    level           : number,
    active          : boolean,
    selected_id     : string | null
}


const ColSection: FunctionComponent<ColSectionProps> = ({controller,index_node, active, selected_id, onClick, onDblClick}) =>
{
    const data_set  = controller.viewConfig.data_model?.data_set
    const schema    = controller.viewConfig.schema
    const data      = []

    const cat_count = schema.view_categories.length

    const row_class_base = active ? "view-row row active" : "view-row row inactive"

    if (!data_set)
        return null

    const context       = useContext(DBContext)
    const table         = controller?.viewConfig?.data_model?.schema?.table
    const keywords      = table ? context?.keywords.keywordHash : {}
    const resources     = context?.resources


    let r = 0
    for (let row_i in index_node.records)
    {
        const row_index     = index_node.records[row_i]
        const row_html      = []
        const row_entry     = data_set[row_index]
        const row_id        = `${row_entry.key}`
        const row_data      = row_entry.data

        const is_selected   = row_id == selected_id && active

        const color = row_entry?.color
        let   color_klass = color ? `mark-${color}-rowbg` : (row_i % 2 == 0 ? 'even' : 'odd')
        if (row_entry.auto)
            color_klass = `${color_klass} subobject`

        const row_class = `${color_klass } ${row_class_base} ${is_selected ? 'selected' : color_klass}`

        let   i             = 1
        const idx           = parseInt(`${row_i}`,10) + index_node.startRecord


        const row_key = `${index_node.id}-${row_i}`
        const ref = controller.viewRowBodyRefs[row_key] || React.createRef()
        controller.viewRowBodyRefs[row_key] = ref        


        row_html.push(  <td ref={ref} key={`cat-ind`}>
                            <div className='entry spacer'>&nbsp;</div>
                        </td>)

        for(let cc = 0; cc < cat_count; cc++)
        {
            row_html.push(  <td key={`cat-${cc}`} className={`cat col-${i}`}>
                                <div className={`cell col-${i}`}>&nbsp;</div>
                            </td>)
            i++
        }

        for( let col of schema.view_columns )
        {
            r++
            const   cid     = col.view_column.column_id
            let fullDatum   = row_data[cid]

            let value       = getRenderedValue(table, fullDatum, row_entry.auto, col, keywords, resources)

            let blank = value == null || value == undefined
            if (typeof value == 'string')
            {
                value = `${value}`.trim()
                if (value.length == 0)
                    blank = true
            }

            const unit = '' //blank ? col.view_column.unit || '' : '' 

            const cell = <td key={`r${r}c${cid}`} className={`col col-${i}`} >
                                <div key='data' className={`cell col-${i}`}>
                                    {value} {unit}
                                </div> 
                            </td>
            row_html.push( cell )
            i++
        }

        row_html.push(<td key='final' className='final'>&nbsp;</td>)

        data.push(  <tr     
                            onClick={active && onClick? onClick( row_id, idx ) : null}
                            onDoubleClick={active && onDblClick ? onDblClick(row_id, idx) : null}
                            key={`r2${r}-${row_entry.key}`} 
                            className={row_class}>
                                {row_html}
                    </tr> )
    }
    
    return  <>{data}</>
}





const ViewHeaderWidthDragHandle: FunctionComponent<{ controller: ViewTableController, left: boolean, idx: number }> = ({ controller, left, idx }) =>
{
    const startDrag = (e) => 
    { 
//        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.setData('text/html', `${idx}`);
        var img         = new Image()
        img.src         = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
        img.width       = 0
        img.height      = 0
        e.dataTransfer.setDragImage(img, 10000,10000);

        controller.colWidthDragStart( e.clientX, left ? idx - 1 : idx )
    }

    const stopDrag = (e: { clientX: number }) =>
    { 
        controller.colWidthDragStop( e.clientX )
    }

    const runDrag = (e) => 
    { 
        controller.colWidthDragRun(e)
    }

    const clazzName = left ? 'left' : 'right'
    return <div className={`width-draghandle ${clazzName}`} 
                draggable={true}
                onDrag={runDrag}
                onDragStart={startDrag} onDragEnd={stopDrag}> &nbsp;</div>
}


const ViewCatMenu: FunctionComponent<{ controller: ViewTableController, vc: ViewSection }> = ({ controller, vc }) => {
    const remove = () => controller.removeCategory(vc.view_column)
    const menu = <Menu
        items={[
            {
                label: <a className='ant-dropdown-entry' onClick={remove}>Remove</a>,
                key: '0'
            }
        ]}
    />

    const r =   <Dropdown overlay={menu} trigger={['click']}>
                    <div className='context-menu'>▼</div>
                </Dropdown>

    return r
}


const pushBooleanOption = (
                            controller: ViewTableController, 
                            items:  Array<any>, 
                            label: any,
                            value: any,
                            option: any,
                            vc: ViewColumn) =>
{



    items.push({
        label:  <a onClick={() => controller.setViewColumnOption(vc, option.id, value)}>
                    <div className='ant-dropdown-entry'>
                        {label}
                    </div>
                </a>,
        key: `${items.length + 1}`
    })
}

const addViewColumnKeyWordEntries = (controller: ViewTableController, items: any, 
                                     option: any, vc: ViewColumn )
{
    const context = useContext(DBContext)
    const table = controller?.viewConfig?.data_model?.schema?.table
    const keywords = table ? context?.keywords.all_for(table) : null

    
    const selected      = vc.options ? vc.options.single : null

    if (keywords)
    {
        items.push({
            type: 'divider',
        })


        const kwentry = (kw) => 
        {
            const selClass = selected == kw.id  ? 'selected' : ''
                
            return  <div className={`keyword-view-menu-entry ${selClass}`}>
                        <span className={`indicator mark-${kw.color}-bg`}>&nbsp;</span>
                        <span className='title'>{kw.keyword}</span>
                    </div>
            
        }

        const kwentry_all = () => 
        {
            const selClass = selected == null ? 'selected' : ''

            return  <div className={`keyword-view-menu-entry ${selClass}`}>
                        <span className={`indicator`}>&nbsp;</span>
                        <i className='title'>All Keywords</i>
                    </div>
        }

        pushBooleanOption( controller, items, kwentry_all(), null, option, vc)

        keywords.forEach((kw) => pushBooleanOption( controller, items, 
                                                    kwentry(kw), 
                                                    kw.id, option, vc)

    }
}


const addViewColumnOption = (controller: ViewTableController, items: any, option: any, vc: ViewColumn) =>
{
    if (option.type == 'tags')
    {
        addViewColumnKeyWordEntries( controller, items, option, vc )
    }
}

const addViewColumnOptions = (controller: ViewTableController, items: any, vc : ViewColumn) =>
{
    const col = vc.dataModelColumn

    if (col.options && col.options.length > 0)
    {
        col.options.forEach( option => addViewColumnOption( controller, items, option, vc )
    }
}


const ViewColMenu: FunctionComponent<{ controller: ViewTableController, vc: ViewSection }> = ({ controller, vc }) => 
{

    const categorize = () => controller.addCategory(vc.view_column)
    const remove = () => controller.removeCategory(vc.view_column)

    const items = [
        {
            label: <a onClick={categorize}>
                <div className='ant-dropdown-entry'>
                    Categorize
                </div>
            </a>,
            key: '0'
        },
        {
            label: <a onClick={remove}>
                <div className='ant-dropdown-entry'>
                    Remove
                </div>
            </a>,
            key: '1'
        }
    ]

        addViewColumnOptions( controller, items, vc.view_column )

    const menu = <Menu
        items={items}
    />

    const r =   <Dropdown overlay={menu} trigger={['click']}>
                    <div className='context-menu'>▼</div>
                </Dropdown>

    return r
}



const ViewHeaderCell: FunctionComponent<{controller: ViewTableController, col : ViewSection, i: number, idx: number, first: boolean, last: boolean}> = 
({controller, col, i, idx, first, last} ) =>
{
    const col_id = col.view_column.column_id
    const width = col.view_column.width

    const menuname = `COL-HEADER-${col_id}`


    const set_sort  = (col: ViewSection) => (e:MouseEvent) => controller.sortColumn(col.view_column, !e.shiftKey)

    const sort_icon = '▼'

    const table             = controller.viewConfig.data_model?.schema.table
    const indicator         = col.view_column.indicator
    const indicator_class   = table == indicator ? '' : `${indicator}-brd`

    return (
        <th className={`data-head col-${i} ${indicator_class}`} key={`${col_id}`}>
            <div className={`content col-${i} `}>
                {!first && <ViewHeaderWidthDragHandle controller={controller} key='dhl' left={true} idx={idx} />}
                <div className='text' onClick={set_sort(col)}>
                    {col.view_column.name}
                </div>

                <ViewColMenu controller={controller} vc={col} />

                {!last && <ViewHeaderWidthDragHandle controller={controller} key='dhr' left={false} idx={idx} />}
            </div>
        </th>)
}




const ViewTableColHeader: FunctionComponent<{ controller: ViewTableController}> = ({controller}) =>
{
    const schema = controller.viewConfig.schema

    const header    = []
    let   i         = 1

    header.push(<th key={`IND`} className='indicator'><div className='entry'>&nbsp;</div></th>)
    for (let cat of schema.view_categories) 
    {
        const col_id = cat.view_column.column_id
        const menuname = `CAT-HEADER-${col_id}`
        

        header.push(<th key={`CAT${cat.view_column.column_id}`} className={`cat-head col-${i}`}>
                        <ViewCatMenu controller={controller} vc={cat} />
                    </th>)
        i++
    }

    let idx = 0
    for (let col of schema.view_columns) 
    {
        const last  = idx == schema.view_columns.length - 1
        const first = idx == 0 
        header.push(<ViewHeaderCell controller={controller} key={`vh-${idx}`} col={col} last={last} first={first} idx={idx} i={i}/>)
        idx ++
        i++
    }

    header.push(<th key='final' className='final'>&nbsp;</th>)

    return      <tr>
                    {header}
                </tr>
}


const ViewRowDragHandle = ({controller, dragItemDescriptor, row_entry}) => 
{
    const di_desc    = dragItemDescriptor(row_entry)


    const type      = di_desc.type       || `${row_entry?.data?.kind}`.toUpperCase()
    const name      = di_desc.name       || `${row_entry?.data?.name}....       `.substring(0, 12).trim()
    const indicator = di_desc.indicator  || row_entry.indicator_class

    let item        = null
    let item_key    = row_entry.key

    const get_row = (n) => controller.viewConfig.data_model.data_set.find(r => r.key == n)?.data

    if (di_desc.item)
    {
        item        = di_desc.item
    }
    else if (di_desc.multiple)
    {
        item  = () => 
        {
            const selection = controller.viewConfig.selection

            const values     = []
            const keys = Object.keys(selection.records)

            for( let k of keys )
            {
                if (selection.records[k])
                {
                    const row_entry = get_row(k)
                    if (row_entry)
                        values.push( k )
                }
            }

            if (values.length == 0)
            {
                values.push( `${row_entry.key}` )
            }
            
            return values
        }
    }
    else
    {
        item    = row_entry.data
    }

    const [_, dragRef] = isDragSource({type, name, indicator, item, item_key})
    return <div className='fa-regular fa-grip-vertical drag-grip' ref={dragRef}/>
    
}


const ViewTableRowHeader: FunctionComponent<{controller: ViewTableController, viewIndex: DataIndexTree, row_head_visible : boolean | null}> = 
                                            ({controller, viewIndex, 
                                                row_head_visible,
                                                dragItemDescriptor,
                                                onDragStart, onDragEnd}) =>
{
    const result : [ReactElement?]   = []
    const schema                    = controller.viewConfig.schema
    const render_schema             = schema.viewRenderSchema

    let i = 0


    const onSelect = (node : DataIndexTree, row_id: number | null = null, start_index : number, end_index : number) => 
        e => {
            controller.selectionChange( e, node, row_id , e.shiftKey, start_index, end_index)
    }


    const selection = controller.viewConfig.selection

    const walk = (level: number, node : DataIndexTree) =>
    {
        i++
        if (level == 0)
        {
            node.children.forEach( c =>  walk( 1, c ))
        }
        else if (node.children.length > 0)
        {
            const row_key = `${node.id}`
            const ref = controller.viewRowHeadRefs[row_key] || React.createRef()
            controller.viewRowHeadRefs[row_key] = ref

            let select_class = ''
            if (selection.node_keys[row_key]        == ViewSelectionType.Some)
                select_class = 'some'
            else if (selection.node_keys[row_key]   == ViewSelectionType.All)
                select_class = 'all'

            result.push(<div ref={ref} className="row-head-cell catrow" key={`rhc-${i}`} onClick={onSelect(node, null, node.startRecord, node.endRecord)}>
                            <div className='indicator'>&nbsp;</div>
                            {row_head_visible && <div className={`selector ${select_class}`}/>}
                        </div>)
            if (!controller.isClosed(node.id))
                node.children.forEach(c => walk(level + 1, c))
        }

        if (node.records && node.records.length > 0)
        {
            const l = node.records.length
            for( let nn = 0; nn < l; nn++)
            {
                const row_key       = `${node.id}-${nn}`
                const row_entry     = controller.viewConfig?.data_model?.data_set[node.records[nn]]
                const row_auto      = row_entry?.auto
                const row_id        = row_entry?.key  ?? -nn
                const ref           = controller.viewRowHeadRefs[row_key] || React.createRef()
                const is_selected = selection.records[row_id] ? 'all' : ''

                const selector_visible = row_head_visible && row_auto !== true


                let indicator_class = row_entry?.indicator_class
                if (indicator_class)
                    indicator_class = `${indicator_class}-bg ${indicator_class}-brd ${dragItemDescriptor || onDragStart ? 'drag-grip' :''}`

                controller.viewRowHeadRefs[row_key]  = ref

                result.push( <div ref={ref} className={`row-head-cell normalrow`} key={`rhc-${i}-${nn}`} 
                                onClick={onSelect(node, row_id, node.startRecord + nn, node.startRecord + nn )}>                                
                                    <div key={`ind`} className={`indicator ${indicator_class || ''} `}>
                                    {selector_visible && onDragStart && <div className='fa-regular fa-grip-vertical drag-grip'
                                        draggable={true}
                                        onDragStart={onDragStart(row_entry)} onDragEnd={onDragEnd(row_entry)} />}

                                    {selector_visible && dragItemDescriptor && 
                                                <ViewRowDragHandle controller={controller} dragItemDescriptor={dragItemDescriptor} 
                                                            row_entry={row_entry}/>}
                                </div>

                                {selector_visible && <div className={`selector ${is_selected}`}/>}
                            </div>)
            }
        }
    }

    walk( 0, viewIndex )

    result.push(<div className="row-head-cell" key={`s1`}>&nbsp;</div>)
    result.push(<div className="row-head-cell" key={`s2`}>&nbsp;</div>)

    return result
}





const ViewTableRowTail: FunctionComponent<{ controller: ViewTableController, viewIndex: DataIndexTree }> = 
                                        ({ controller, viewIndex, tailClick, onTailClick, active}) => {
    const result: [ReactElement?] = []
    const schema = controller.viewConfig.schema

    let i = 0


    const walk = (level: number, node: DataIndexTree) => 
    {
        i++
        if (level == 0) {
            node.children.forEach(c => walk(1, c))
        }
        else if (node.children.length > 0) 
        {
            const row_key = `${node.id}`
            const ref = controller.viewRowTailRefs[row_key] || React.createRef()
            controller.viewRowTailRefs[row_key] = ref

            result.push(<div ref={ref} 
                             className="row-tail-cell" 
                             key={`rhc-${i}`}>&nbsp;</div>)
            if (!controller.isClosed[node.id])
                node.children.forEach(c => walk(level + 1, c))
        }

        if (node.records && node.records.length > 0) {
            const l = node.records.length
            for (let nn = 0; nn < l; nn++) {
                const row_key = `${node.id}-${nn}`

                const ref = controller.viewRowTailRefs[row_key] || React.createRef()

                controller.viewRowTailRefs[row_key] = ref

                const row = controller.viewConfig.data_model.data_set[node.records[nn]]

                let has_icon = false
                if (!active)
                    has_icon    = false
                else if (tailClick == true)
                    has_icon    = true
                else if (tailClick == false)
                    has_icon    = false
                else 
                    has_icon = tailClick( row )

                if (has_icon)
                    result.push(<div ref={ref} className="row-tail-cell" key={`rhc-${i}-${nn}`} 
                        onClick={onTailClick(row.key)}>
                        <span className="fa-regular fa-angle-right"/>
                    </div>)
                else
                    result.push(<div ref={ref} className="row-tail-cell" key={`rhc-${i}-${nn}`} />)

            }
        }
    }

    walk(0, viewIndex)

    result.push(<div className="row-tail-cell" key={`s1`}>&nbsp;</div>)
    result.push(<div className="row-tail-cell" key={`s2`}>&nbsp;</div>)

    return result
}


type ViewTableProps = {
    controller          : ViewTableController, 
    viewIndex           : DataIndexTree,
    tailClick           : boolean, 
    hasRowHead          : boolean | null,
    isDraggable         : boolean,
    active              : boolean,
    selected_id         : string | null,
    clickRow            : (idx     : number) => (e    : any) => void,
    dblClickRow         : (idx     : number) => (e    : any) => void,
    onTailClick         : any,
    onDragStart         : any,
    onDragEnd           : any,
    dragItemDescriptor  : any
}


const ViewRoot: FunctionComponent<ViewTableProps> = 
    ({
        controller, viewIndex, active, children, hasRowHead,
        selected_id,
        clickRow,
        dblClickRow,
        dragItemDescriptor,
        onDragStart, onDragEnd, isDraggable, onTailClick, tailClick }) =>
{
    const schema = controller.viewConfig.schema


    const refBodyContainer      = useRef(null)
    const refColHeadContainer   = useRef(null)
    const refRowHeadContainer   = useRef(null)
    const refRowTailContainer   = useRef(null)


    const bodyScrollHandler = () => {
        if (refBodyContainer.current) {
            if (refColHeadContainer.current &&
                refBodyContainer.current.dataset.scrollLeft != refBodyContainer.current.scrollLeft) 
            {
                refColHeadContainer.current.scrollLeft = refBodyContainer.current.scrollLeft
                refColHeadContainer.current.dataset.scrollLeft = refBodyContainer.current.scrollLeft
            }

            if (refRowHeadContainer.current &&
                refBodyContainer.current.dataset.scrollTop != refBodyContainer.current.scrollTop) 
            {
                refRowHeadContainer.current.scrollTop = refBodyContainer.current.scrollTop;
                refRowHeadContainer.current.dataset.scrollTop = refBodyContainer.current.scrollTop;
            }

            if (refRowTailContainer.current &&
                refBodyContainer.current.dataset.scrollTop != refBodyContainer.current.scrollTop) {
                refRowTailContainer.current.scrollTop = refBodyContainer.current.scrollTop;
                refRowTailContainer.current.dataset.scrollTop = refBodyContainer.current.scrollTop;
            }


        }
    }


    const colHeadScrollHandler = () => {
        if (refColHeadContainer.current && refBodyContainer.current) {
            if (refColHeadContainer.current.dataset.scrollLeft != refColHeadContainer.current.scrollLeft) {
                refBodyContainer.current.scrollLeft = refColHeadContainer.current.scrollLeft;
                refBodyContainer.current.dataset.scrollLeft = refColHeadContainer.current.scrollLeft;
            }
        }
    }


    const rowHeadScrollHandler = () => {
        if (refRowHeadContainer.current && refBodyContainer.current) {

            if (refRowHeadContainer.current.dataset.scrollTop != refRowHeadContainer.current.scrollTop) {
                refBodyContainer.current.scrollTop = refRowHeadContainer.current.scrollTop;
                refBodyContainer.current.dataset.scrollTop = refRowHeadContainer.current.scrollTop;
            }
        }
    }


    const rowTailScrollHandler = () => {
        if (refRowTailContainer.current && refBodyContainer.current) {

            if (refRowTailContainer.current.dataset.scrollTop != refRowTailContainer.current.scrollTop) {
                refBodyContainer.current.scrollTop = refRowTailContainer.current.scrollTop;
                refBodyContainer.current.dataset.scrollTop = refRowTailContainer.current.scrollTop;

                refRowHeadContainer.current.scrollTop = refRowTailContainer.current.scrollTop;
                refRowHeadContainer.current.dataset.scrollTop = refRowTailContainer.current.scrollTop;
            }
        }
    }



    const selectAll = (e) => 
    {
       controller.selectAll(e)
    }



    const with_tail             = !!tailClick

    const row_head_visible      = hasRowHead !== false

    const tail_class            = with_tail     ? 'with-rowtail'   : ''
    const head_class            = row_head_visible    ? 'with-rowhead' : ''
    const draggable_class       = isDraggable   ? 'draggable'   : ''
    const active_class          = active        ? 'active'      : 'inactive'

    let select_class = ''
    if (controller.viewConfig.selection.node_keys[""] == ViewSelectionType.Some)
        select_class = 'some'
    else if (controller.viewConfig.selection.node_keys[""] == ViewSelectionType.All)
        select_class = 'all'


    const keyUp = (e : any) => 
    {
        const kc    = e.keyCode
        let   tgt   = null

        if (kc == 13)
        {
            if (dblClickRow)
                dblClickRow(parseInt(`${selected_id}`, 10 ))(e)
        }
        else if (kc == 38)
            tgt     = controller.recordOffset( selected_id, -1)
        else if (kc == 40)
            tgt     = controller.recordOffset( selected_id, 1)
            

        if (tgt !== null && tgt !== undefined)
        {
            if (clickRow)
                clickRow(tgt)(e)
        }
    }


    const onFocus = (e : any) => 
    {
        let idx = null
        if (selected_id !== undefined && selected_id !== null)
            idx = controller.indexOfID( selected_id )
    }

    return  (<div   className={ `view-container ${tail_class} ${head_class} ${draggable_class} ${active_class}`}
                        tabIndex={1}
                        onFocus={onFocus}
                        onKeyUp={keyUp}>
                <div className='left-container  top-container view-corner-container'>
                    <div className='view-corner'>
                        {row_head_visible && <div className='indicator'>&nbsp;</div>
                        {row_head_visible && <div className={`selector ${select_class}`} onClick={selectAll} />}
                    </div>
                </div>

                <div className='right-container top-container view-colhead-container'
                        onScroll={colHeadScrollHandler}
                        ref={refColHeadContainer}>
                    <table className='view-colhead-table' >
                        {createColGroup(schema)}
                        <thead>
                            <ViewTableColHeader controller={controller}/>
                        </thead>
                    </table>
                </div>

                {with_tail &&   
                    <div className='tail-container  top-container'>
                        <div className='tail-corner'>&nbsp;</div>
                    </div>}



                <div className='left-container bottom-container view-rowhead-container'
                        onScroll={rowHeadScrollHandler}
                        ref={refRowHeadContainer}>
                        <div className='view-rowhead-table'>
                                <ViewTableRowHeader controller={controller} 
                                                    row_head_visible={row_head_visible}
                                                    viewIndex={viewIndex}
                                                    dragItemDescriptor={ active &&  dragItemDescriptor }
                                                    onDragStart={        active && !dragItemDescriptor && onDragStart} 
                                                    onDragEnd={          active && !dragItemDescriptor && onDragEnd}/>
                        </div>
                </div>


                <div className='right-container bottom-container view-body-container'
                        onScroll={bodyScrollHandler}
                        ref={refBodyContainer}>
                        <table className='view-body-table' >
                        {createColGroup( schema )}
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>

                {with_tail && 
                    <div className='tail-container bottom-container view-tail-container'
                        onScroll={rowTailScrollHandler}
                        ref={refRowTailContainer}>
                        <div className='view-rowtail-table'>
                            <ViewTableRowTail controller={controller} viewIndex={viewIndex}
                                              active={active}
                                              tailClick={active && tailClick}
                                              onTailClick={onTailClick} />
                        </div>
                    </div>}
            </div>)
}




interface ViewProps  
{
    viewConfig          : ViewConfiguration,
    active              : boolean, 
    selected_id         : string    | null,
    hasRowHead          : boolean   | null,
    refetch             : (force: boolean) => void

}


export abstract class ViewRenderer 
{
    abstract render(tree: DataIndexTree, depth: number) : ReactElement
}







export const View: FunctionComponent<ViewProps> = (props) =>
{
    const {  active } = props
    const viewConfig = props.viewConfig

    const [lastRepaint, setLastRepaint] = useState(0)
    const refresh = () => { const now = Date.now(); setLastRepaint( now ); return now}
    const {refetch}     = props

    const [controller]    = useState(new ViewTableController(viewConfig, refresh, refetch))

    controller.setViewConfiguration(viewConfig)
    controller.update()



    const {selected_id}         = props
    const {hasRowHead}          = props
    const {schema}              = viewConfig


    const viewIndex : DataIndexTree = controller.viewIndex


    const render_schema = schema.viewRenderSchema


    const get_row = (n) => controller.viewConfig.data_model.data_set.find( r => r.key == n)

    const clickRow      = !props.onClick    ? null 
                                            : (id : number) => (e) => 
                                                { 
                                                        const row_data = get_row(id)
                                                        props.onClick(          id, row_data?.data, row_data?.object) 
                                                }

    const dblClickRow = !props.onDblClick ? null
        : (id: number) => (e) => 
            {
            const row_data = get_row(id)
            props.onDblClick(id, row_data?.data, row_data?.object)
        }

    const clickTail     = !props.onTailClick? null 
                                            : (id : number) => (e) => 
                                                { 
                                                        const row_data = get_row(id)
                                                        props.onTailClick(      id, row_data?.data, row_data?.object) 
                                                }

    const dragStartRow  = !props.onDragStart? null 
                                            : (id : number) => (e) => 
                                                { 
                                                        const row_data = get_row(id)
                                                        props.onDragStart(  e, id, row_data?.data, row_data?.object) 
                                                }
                                            
    const dragEndRow    = !props.onDragEnd  ? null 
                                            : (id : number) => (e) => 
                                                { 
                                                        const row_data = get_row(id)
                                                        props.onDragEnd(    e, id, row_data?.data, row_data?.object) 
                                                }


    const walk_index = ( node : DataIndexTree, level : number) : ReactElement =>
    {
        const { value, id } = node

        const sub_tree = node.children.map((c, i) => walk_index(c, level + 1))

        let level_kind : ViewSectionType
        if (level == 0)     // root
            level_kind  = ViewSectionType.Root
        else
            level_kind = render_schema[level - 1].kind

        if (node.records.length > 0)
            return <ColSection  key         ={`${node.id}`} 
                                id          ={node.id} 
                                index_node  ={node} 
                                level       ={level} 
                                active      ={active}
                                selected_id ={selected_id}
                                controller = {controller}
                                onClick     ={clickRow} 
                                onDblClick  ={dblClickRow}   />
            

        if (level_kind == ViewSectionType.Root && (schema.section != null || schema.tab != null))
            return <Root id={id} index_node={node} controller={controller}>
                        {sub_tree}
                    </Root>

        if (level_kind == ViewSectionType.Root && schema.section == null && schema.tab == null)
            return <Root id={id} index_node={node} controller={controller}>
                        <ViewRoot key={`${node.id}`} 
                                    controller={controller} 
                                    viewIndex={node}
                                    hasRowHead={hasRowHead}
                                    tailClick={props.tailClick}
                                    dragItemDescriptor={props.dragItemDescriptor}
                                    onDragStart={dragStartRow}
                                    onDragEnd={dragEndRow}
                                    selected_id={selected_id}
                                    clickRow={clickRow}
                                    dblClickRow={dblClickRow}
                                    onTailClick={clickTail}
                                    active={active}
                                    isDraggable={!!props.onDragStart}>
                            {sub_tree}
                        </ViewTable>
                    </Root>
//
//
//            else if (level_kind == ViewSectionType.Tab && schema.section == null)
//                return <Tab id={id} index_node={node} level={level} value={value} controller={controller}>
//                            <ViewTable>
//                                {sub_tree}
//                            </ViewTable>
//                        </Tab>
//                        
//
//            else if (level_kind == ViewSectionType.Tab && schema.section != null)
//                return  <Tab id={id}  
//                            index_node={node} level={level} value={value} 
//                            controller={controller}>
//                            {sub_tree}
//                        </Tab>
//
//            else if (level_kind == ViewSectionType.Section)
//                return  <Sec id={id}  
//                            index_node={node}  level={level} value={value}
//                            controller={controller}>
//                            <ViewTable>
//                                {sub_tree}
//                            </ViewTable>
//                        </Sec>

        else if (level_kind == ViewSectionType.Category)
        {
            return  <Cat key={`cat-${id}`} index_node={node}  
                        level={level} value={value}
                        controller={controller}>
                        {sub_tree}
                    </Cat>
        }

        else
            return null

    }



    useLayoutEffect( () =>
    {
        const heights       : {[id:string] : number}      = {}

        for( let i = 0; i < 2; i++)
        {
            Object.keys(controller.viewRowHeadRefs).forEach(id => 
            {
                const ref = controller.viewRowHeadRefs[id]


                if (ref.current)
                    heights[id] = ref.current.clientHeight
            })

            Object.keys(controller.viewRowBodyRefs).forEach(id => {
                const ref = controller.viewRowBodyRefs[id]

    //            if (id == '2:10')
    //                console.log(ref, ref.current != null, ref.current && ref.current.clientHeight)

                if (ref.current)
                    heights[id] = Math.max( Math.max(ref.current.clientHeight, heights[id]), 34)
            })

            Object.keys(controller.viewRowTailRefs).forEach(id => {
                const ref = controller.viewRowTailRefs[id]

    //            if (id == '2:10')
    //                console.log(ref, ref.current != null, ref.current && ref.current.clientHeight)

                if (ref.current)
                    heights[id] = Math.max( Math.max(ref.current.clientHeight, heights[id]), 34)
            })
            

            Object.keys(controller.viewRowHeadRefs).forEach(id => {
                const ref = controller.viewRowHeadRefs[id]
                if (ref.current)
                    ref.current.style.height = `${heights[id]}px`
            })

            Object.keys(controller.viewRowTailRefs).forEach(id => {
                const ref = controller.viewRowTailRefs[id]
                if (ref.current)
                    ref.current.style.height = `${heights[id]}px`
            })


            Object.keys(controller.viewRowBodyRefs).forEach(id => {
                const ref = controller.viewRowBodyRefs[id]
                if (ref.current)
                {
                    ref.current.firstChild.style.height = `${heights[id]}px`
                    ref.current.style.height = `${heights[id]}px`
                }
            })
        }
    })


    if (render_schema.length == 0)  // the view does not contain any columns
        return <div />

    return  walk_index(viewIndex, 0)

}


function getRenderedValue(table: string | undefined, fullDatum: any, auto : boolean | undefined, col: ViewSection, keywords: any, resources: any)  : any
{
    let value
    const ckind = col.view_column.kind
    const dmc   = col.view_column.dataModelColumn
    if ((!dmc.entity || dmc.entity == table) && auto)
        return null

    console.log( "FULLD ", fullDatum )

    if (fullDatum === null || fullDatum == undefined)
        value = null
    else if (Array.isArray( fullDatum ))
        value = fullDatum.map( e => <div>{getRenderedSimpleDatum(ckind, e.value, e, keywords, resources )}</div>)
    else
        value = getRenderedSimpleDatum(ckind, fullDatum.value, fullDatum, keywords, resources)


    return value
}





function getRenderedSimpleDatum(ckind: DataType, value: any, fullDatum: any, keywords: any, resources: any)  : any
{
    if (ckind == DataType.TimeStamp) {
        return  timeStampConverter(value)
    }
    if (ckind == DataType.Duration) {
        return formatTime(value?.duration)
    }
    if (ckind == DataType.Time) {
        return  formatTime(value)
    }
    if (ckind == DataType.Material) {
        return value?.display_title
    }
    else if (ckind == DataType.Tags) 
    {
        const kw = keywords[`${value}`]
        if (!kw)
            return null
        return  <div key={`K${kw.id}`}
                    className={`keyword in-table kw-${kw?.color}`}>
                        {kw?.keyword}
                </div>
    }
    else if (ckind == DataType.Resource)
    {
        if (!value)
            return null
        else
        {
            const res = resources.resources[parseInt(value, 10)]
            if (res.code)
            {
                return  <div key={`{value}`} className='resource in-table'>
                            <div className='code'>{res.code}</div>
                            <div className='title'>{res.title}</div>
                        </div>
            }
            else{
                return <div key={`{value}`} className='resource in-table'><div className='title'>{res.title}</div></div>
            }
        }
    }

    return value
}

function timeStampConverter(t: number): String {
    const p0 = (x: any) => `00${x}`.slice(-2)
    var a = new Date(t * 1000);
    var year = a.getFullYear();
    var month = p0(a.getMonth() + 1);
    var date = p0(a.getDate());
    var hour = p0(a.getHours());
    var min = p0(a.getMinutes());
    var sec = p0(a.getSeconds());
    var time = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
    return time;
}




