export function blank( s )
{
  if (s === null || s === undefined || s === false)
    return true;
  if (`${s}`.trim().length == 0)
    return true;
  return false;
}

export function null_or_undef( s )
{
  if (s === null || s === undefined)
    return true;
  return false;
}



// Maps a hash
export function visit(hash, fn )
{
	if (!hash || !fn)
		return
	for( const k of Object.keys(hash) )
		fn( k, hash[k])
}


// Uppercase-trimmed or null if blank of list, stripping nulls
export function make_list( list, def = null )
{
	if (list && !Array.isArray(list))
		return make_list( `${list}`.split(",") )
	if (list === null || list === undefined)
		return def
	const rv = list.map( (x) => trimmed_or_blank(x) )
				   .filter( (x) => x !== null )
	if (rv.length === 0)
		return def
	return rv
}


export function expand_string_from_data( string, data )
{
	return string.replace( /\{((\w|\.)*)\}/g,  (match) => {
		let index = match.substring(1).substring( 0,match.length - 2 )
		var value = data;
		for( let el of index.split("."))
		{
			value = value[el];
			if (!value)
				return match;
		}
		return value;
	} )
}


export function string_default( x, def = null)
{
	if (x === null || x === undefined)
		return def;

	const n = `${x}`.trim();
	if (n.length === 0)
		return def;
	return n;
}


export function trimmed_or_blank(x, def = null)
{
	return string_default( x, def);
}

export function always_to_string(x, default_value = '')
{
	if (x === null)
		return default_value
	if (x === undefined)
		return default_value
	return `${x}`
}



export function format_val2( n, def_if_null  = '')
{
	if (n === null || n === undefined)
		return def_if_null

	const abs 		= Math.abs( n )
	const scale 	= Math.floor(Math.log( abs )/Math.log( 10 ))
	let   fix
	if (scale < 0)
		fix = (-scale + 2)
	else if (scale < 4)
		fix = 3
	else if (scale < 5)
		fix = 2
	else if (scale < 6)
		fix = 2
	else
		fix = 0

	const minus = n < 0 ? '-' : ''
	return  `${minus}${abs.toFixed( 0 )}`
}




export function fmt(x, def_if_null  = '', maximum_frac = 1000, minimum_frac = 0)
{
	if (x === null || x === undefined)
		return def_if_null
	if (typeof x !== 'number')
		return x

	let exp

	if (x > 0)
		exp  = Math.floor(Math.log10(x))
	else if (x < 0)
		exp  = Math.floor(Math.log10(-x))
	else
		exp  = 0
	let maxfd = exp < 0 ? -exp + 3 : 11 - exp


	if (exp >= 6)
		maxfd = 0
	if (maxfd > 8)
		maxfd = 10
	if (maxfd < 0)
		maxfd = 0

	if (maxfd > maximum_frac)
		maxfd = maximum_frac

	try
	{
		if (maxfd == 0)
			return Math.floor(x).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: maxfd})
		else
			return x.toLocaleString('en-US', {minimumFractionDigits: minimum_frac, maximumFractionDigits: maxfd})
	}
	catch(e)
	{
		return '---'
	}

}

// Format or null, returns a null on null input as opposed to a blank string
export function fmtn( x )
{
	return fmt( x, null )
}

export function format_val(x, def_if_null  = '', maximum_frac = 1000, minimum_frac = 0)
{
	return fmt( x, def_if_null, maximum_frac, minimum_frac )
}


export function humanize(s)
{
	if (!s)
		return ""
	const str = `${s}`.trim()
	if (str.length == 0)
		return ""
	return str
			.replace(/^[\s_]+|[\s_]+$/g, '')
			.replace(/[_\s]+/g, ' ')
			.replace(/^[a-z]/, function (m) { return m.toUpperCase(); });
}



export function fix_id( n )
{

	let id = !n ? '_' : `${n}`.trim().replace(/\s+/g, "_").replace(/[\W]+/g, "").toLowerCase()
	if (/^[0-9_]/.test(id))
		id = `_${id}`

	return id
}






export const isNum = x => (x !== null && x !== undefined && typeof x == 'number' && !isNaN(x))



export function fixDuration( v, set_raw = true)
{
	if (!v)
		return {}


	if (isNum(v))
		return { duration: v, duration_raw: `${v}` }
	if (typeof v == 'string') {
		const p = parseInt(v, 10)
		if (isNum(p))
			return { duration: p, duration_raw: v }
		else
			return {}
	}
	if (typeof v == 'object') 
	{
		const rv = {}
		let { start, end, duration, duration_raw } = v
		if (isNum(start)) 	rv.start 	= start
		if (isNum(end)) 	rv.end 		= end

		if (rv.start > rv.end) 
		{
			const c = rv.start
			rv.start = rv.end
			rv.end = c
		}

		if (isNum(start) && isNum(end) && !isNum(duration))
			duration = end - start


		if (isNum(duration)) 
		{
			rv.duration 		= duration
			if (set_raw)
				rv.duration_raw 	= formatTime( duration )
			else
				rv.duration_raw 	= duration_raw
		}

		return rv
	}

	return {}
}

export function  pad10(i)
{
	const v = parseInt(i,10)
	if (isNaN(v))
		return '??'
	else
		return v >= 0 && v < 10 ? `0${v}` : v
}

export function formatTime(val, def = '')
{
	if (isNum(val))
	{	
		const value 	= Math.floor(Math.abs(val))
		const seconds 	= value % 60
		const minutes 	= Math.floor(value / 60) % 60;
		const hours 	= Math.floor(value / 3600) % 24
		const days 		= Math.floor(value / 86400)

		if (days > 0)
			return `${days}d ${pad10(hours)}:${pad10(minutes)}:${pad10(seconds)}`
		else
			return `${pad10(hours)}:${pad10(minutes)}:${pad10(seconds)}`
	}
	else
		return def
}

export function formatTimeStamp(timestamp, def) 
{
	if (!timestamp)
		return def

	const parsed = parseInt(`${timestamp}`, 10)
	if (isNaN(parsed))
		return def

	let js_date = new Date(parsed * 1000)
	const pad10 = (i) => (i < 10 ? '0' : '') + i

	const YYYY = js_date.getFullYear()
	const MM = pad10(js_date.getMonth() + 1)
	const DD = pad10(js_date.getDate())
	const HH = pad10(js_date.getHours())
	const II = pad10(js_date.getMinutes())
	const SS = pad10(js_date.getSeconds())

	return `${YYYY}-${MM}-${DD} ${HH}:${II}:${SS}`
}


export function formatTimeStampDiv(timestamp, def) {
	if (!timestamp)
		return def

	const parsed = parseInt(`${timestamp}`, 10)
	if (isNaN(parsed))
		return def

	let js_date = new Date(parsed * 1000)
	const pad10 = (i) => (i < 10 ? '0' : '') + i

	const YYYY = js_date.getFullYear()
	const MM = pad10(js_date.getMonth() + 1)
	const DD = pad10(js_date.getDate())
	const HH = pad10(js_date.getHours())
	const II = pad10(js_date.getMinutes())
	const SS = pad10(js_date.getSeconds())

	return  <div className='timestamp'>
				<div className='date'>{`${YYYY}-${MM}-${DD}`}</div> 
				<div className='time'>{`${HH}:${II}:${SS}`}</div>
			</div>
}


export function timeRegex(x) {
	const regex = /^((\d+)d)?(\s*)(\d+)(:\d+)?(:\d+)?$/

	return `${x}`.trim().match(regex) != null

}

export function parseTime(str) {
	const spd = `${str}`.trim().split(/[^0-9:]/).map(x => `${x}`.trim())


	let days = 0
	if (spd.length == 2) {
		days = parseInt(`${spd[0]}`.trim(), 10)
		spd.shift()
	}

	if (spd.length == 1) {
		const spt = spd[0].split(":")

		while (days > 0 && spt.length < 3)
			spt.push("00")

		if (spt.length < 4 && spt.length > 0) {
			let hours = 0
			let minutes = 0
			let seconds = 0

			if (spt.length == 3)
				hours = parseInt(`${spt.shift()}`.trim(), 10)
			if (spt.length == 2)
				minutes = parseInt(`${spt.shift()}`.trim(), 10)
			if (spt.length == 1)
				seconds = parseInt(`${spt.shift()}`.trim(), 10)

			return days * 86400 + hours * 3600 + minutes * 60 + seconds
		}
		else
			return null
	}
	else return null
}



// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
// This is only used for column ids, so itdoes not have to be cryptographically secure
export function generateUUID()
{
  	let d = new Date().getTime()
 	let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;

	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => 
	{
		let r = Math.random() * 16;
		if (d > 0) {
		r = (d + r) % 16 | 0;
		d = Math.floor(d / 16);
		} else {
		r = (d2 + r) % 16 | 0;
		d2 = Math.floor(d2 / 16);
		}
		return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
	});

	return uuid.substring(0,5);
};
