
import {useRef}             from 'react'
import axios                from 'axios'
import useAxios             from 'axios-hooks'
import { backend_server }   from '../config/runconfig'


export function useDownloaded(url, initial, options) 
{
    const v = useRef( initial )
    const [{ data, loading, error }, refetch] = useAxios(url, options || {useCache: false})

    if (!loading)
        v.current = data

    if (error) return [v.current]
    return [v.current, refetch, loading]
}


export function useBackendData(url, initial) 
{
    let uri = ''
    if (url.startsWith('/'))
        uri = `${backend_server.server}${url}`
    else
        uri = `${backend_server.server}/${url}`

//    console.log( uri )
    return useDownloaded({
        method: "get",
        url: uri
    }, initial)
}



export function useBackendDataGet(url, data, initial) 
{
    let uri = ''
    if (url.startsWith('/'))
        uri = `${backend_server.server}${url}`
    else
        uri = `${backend_server.server}/${url}`


    return useDownloaded({
        method: "get",
        url: uri,
        data: data
    }, initial)
}

export function useBackendDataPost(url, data, initial) 
{
    let uri = ''
    if (!url)
    {}
    else if (url.startsWith('/'))
        uri = `${backend_server.server}${url}`
    else
        uri = `${backend_server.server}/${url}`

    return useDownloaded( {
        method: "post",
        url: uri,
        data: data
    }, initial)
}



export function submitForm(url, params, axiosparams = null)
{
    let submissionurl = ''
    if (url.startsWith('/'))
        submissionurl = `${backend_server.server}${url}`
    else
        submissionurl = `${backend_server.server}/${url}`

    if (axiosparams)
        return axios.post( submissionurl, params, axiosparams )
    else
        return axios.post(submissionurl, params )
}

export function post( url, data )
{
    return submitForm( url, data )
}


export function downloadBinary(url, data) 
{
    return submitForm(url, data, { responseType: 'blob' })
}



export function fetchMulti( ...fetcher )
{
    return () =>  fetcher.forEach( x => {
                    console.log( x )
                    if (x) 
                        setTimeout(x, 0)
                  })
}