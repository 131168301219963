//import { TableEditor } from "../TableEditor"

import { replace } from "lodash"
import { ViewSchema } from "../viewschema"
import Column from "antd/es/table/Column"

export enum DataType
{
    Number,
    String,
    Boolean,
    TimeStamp,
    Time,
    Duration,
    Material,
    Resource,
    Tags
}


export type Key = number

export type RootDatum   = string | number | null

export type FullDatum = {
    obj_id:     number | null,
    value:      RootDatum,
    cat_value:  RootDatum,
    sort_value: RootDatum,
    multiple?:  boolean
}

export type DataRowDatum    = FullDatum | Array<FullDatum>

export type Datum           = RootDatum | FullDatum 


export enum DatumType
{
    NormalValue,
    CatValue,
    SortValue
}


export enum SortType {
    None,
    Sort,
    Category,
    Tab,
    Section
}


export enum SortDir  
{
    None, Up, Down
}


export interface ColumnSort 
{
    column_id:      string,
    dir:            SortDir,
    new_level:      boolean,
    use_cat_value:  boolean
}

export type SortSpec = ColumnSort[]



export class DataModelColumn 
{
    id               : string
    server_id        : string
    name             : string
    kind             : DataType
    default_width    : number | null
    keep_category    : boolean
    use_cat_value    : boolean
    auto_filter_id   : string | null
    multiple         : boolean
    indicator        : string | null
    unit             : string | null
    options          : any | null
    static_params    : any
    entity           : string | null

   
    constructor(id: string,  name: string, kind: DataType | string, use_cat_value : boolean = false, keep_category : boolean = false, multiple: boolean = false, options: any | null = null )
    {
        this.id                 = id
        this.server_id          = id
        this.name               = name
        this.default_width      = null
        this.auto_filter_id     = null
        this.multiple           = multiple
        this.indicator          = null
        this.unit               = null
        this.options            = options
        this.entity             = null



        if (typeof kind == 'string')
        {
            if          (kind == 'text' || kind == 'string')
                this.kind   = DataType.String
            else if     (kind == 'number')
                this.kind   = DataType.Number
            else if     (kind == 'boolean')
                this.kind   = DataType.Boolean
            else if     (kind == 'timestamp')
                this.kind = DataType.TimeStamp
            else if     (kind == 'duration')
                this.kind = DataType.Duration
            else if     (kind == 'time' || kind == 'duration')
                this.kind = DataType.Time
            else if     (kind == 'material')
                this.kind = DataType.Material
            else if     (kind == 'resource')
                this.kind = DataType.Resource
            else if     (kind == 'tag')
                this.kind = DataType.Tags

            else
                this.kind   = DataType.String
        }
        else
            this.kind = kind


        this.use_cat_value  = use_cat_value
        this.keep_category  = keep_category
    }

    setAutoFilterId(id: string)
    {
        this.auto_filter_id = id
    }


    createCatValue( input : Datum | null) : Datum
    {
        if (!input)
            return '-'
        return `${input}-`.trim().substring( 0, 1 )
    }
}




export class DataModelSchema
{
    table       : string
    columns     : {[id:string] : DataModelColumn}

    constructor( table: string )
    {
        this.columns = {}
        this.table   = table
    }

    addColumn( spec : DataModelColumn )
    {
        this.columns[spec.id] = spec
    }

    getColumn( id : string )
    {
        return this.columns[id]
    }


    setupFromColumnSpec(column_json : any, processes : any, table : string)
    {
        const remove = (list : Array<any>, f : any) => {
            const rlist = []
            let template = []
            for (let e of list) {
                if (f(e))
                    template = e
                else
                    rlist.push(e)
            }

            return { list: rlist, template: template }
        }

        const addColumn = (ctemplate : any) => 
        {
            const column = new DataModelColumn( ctemplate.id, 
                                                ctemplate.title, 
                                                ctemplate.kind, 
                                                true, true, 
                                                ctemplate.multiple, 
                                                ctemplate.options)

            column.server_id = ctemplate.server_id || ctemplate.id
            column.indicator = ctemplate.indicator
            column.unit = ctemplate.unit
            column.static_params = ctemplate.static_params
            column.setAutoFilterId(ctemplate.auto_filter_id)
            column.entity        = ctemplate.entity
            this.addColumn(column)
        }

        Object.keys(column_json).forEach(id => 
        {
            const ctemplate = column_json[id]

            const parameters = ctemplate.parameters
            let template = null
            let list = null
            if (parameters && parameters.length > 0) 
            {
                const split = remove(parameters, p => p.kind == 'step_parameter')
                list = split.list
                template = split.template
            }


            if (list && template && template.kind == 'step_parameter') {
                this.addStepParameterColumns(table, processes, ctemplate, id, addColumn)
            }
            else
                addColumn(ctemplate)
        })
    }

    private addStepParameterColumns(table: string, processes: any, ctemplate: any, id: string, addColumn: (ctemplate: any) => void) {
        let tab = table
        if (table == 'substrate')
            tab = 'experiment'

        const p = processes.allProcessTypes[tab]
        if (p) {
            p.forEach(pid => {
                const processdef = processes.allProcessTemplates[pid]

                const { params } = processdef


                for (const param of params) {
                    const coldef = {
                        ...ctemplate,
                        id:             `${id}:${param.id}`,
                        server_id:      id,
                        title:          `${processdef.title}:${param.title}`,
                        unit:           param.unit,
                        kind:           param.kind,
                        static_params: { step_parameter_id: param.id }
                    }

                    addColumn(coldef)
                }
            })
        }
    }
}



type DataRowSet = { [index: string]: DataRowDatum }

export interface DataRow
{
    key             : Key,
    indicator_class : string | null,
    data            : DataRowSet,
    object          : any | null,
    color           : string | null,
    auto?           : boolean
}


export type DataIndex = Key[]

export enum DataIndexTreeKind
{
    Tree,
    Records
}


export type DataIndexTree = 
{
    id              : string,
    column_sort     : ColumnSort | null,
    value           : Datum,
    kind            : DataIndexTreeKind,
    children        : DataIndexTree[],
    records         : number[],
    dependents      : number[],
    startRecord     : number,
    endRecord       : number
}




export class DataModel
{
    schema              : DataModelSchema
    data_set            : DataRow[]
    data_set_loaded     : DataRow[]
    master_index        : DataIndex | null
    recordSeq           : number[]
    fullCount           : number

    constructor( schema: DataModelSchema )
    {
        this.schema                 = schema
        this.data_set_loaded        = []
        this.data_set               = []
        this.master_index           = null
        this.recordSeq              = []
        this.fullCount              = 0
    }


    loadJSONData( jsonData : any)
    {
        this.fullCount = jsonData.full_count 
        if (jsonData && jsonData.dataset) 
        {
            jsonData.dataset.forEach(row => 
            {
                row.indicator_class = row.indicator || `${jsonData.kind}`.toLowerCase()
                this.push(row)
            })
        }
    }


    set(    key_in:             Key | null, 
            indicator_class:    string|null, 
            data:               { [index: string]: Datum },
            object:             any | null,
            color:              string | null ) 
    {

        const key : Key = key_in === null ? this.data_set_loaded.length + 100000 : key_in

        const row: DataRow = {
            key: key,
            indicator_class: indicator_class,
            data: {}, 
            object: object,
            color: color
        }

        const createValue = (d : Datum)  =>
        {
            if (typeof d == 'number' || typeof d == 'string' || d == null)
                return {value: d, cat_value: d, sort_value:d}  as FullDatum

            let v,c,s

            v = d.value

            s = d.sort_value
            if (s === null || s === undefined)
                s = d.value

            c = d.cat_value
            if (c === null || c === undefined)
                c = d.sort_value
            if (c === null || c === undefined)
                c = d.value


            return {value: v, sort_value: s, cat_value: c, obj_id: d.obj_id}
        }


        for (let k in data) 
        {
            const datum: Datum  = data[k]

            if (Array.isArray( datum))
                row.data[k]  = datum.map( d => {return createValue(d)} )
            else
                row.data[k]  = createValue( datum )

        }

        this.data_set_loaded.push( row )
        this.master_index = null

        return row
    }


    push( data: {   key:                Key, 
                    indicator_class:    string|null, 
                    fields : { [index: string]: Datum },  
                    object : any | null,
                    color  : string | null
                } )
    {
        return this.set( data.key, data.indicator_class, data.fields, data.object, data.color )
    }



    createMasterIndex() : DataIndex
    {
        const index : DataIndex =  []
        this.data_set.forEach( ( row, row_i ) =>
        {
            const key = row.key
            index[key] = row_i
        })

        return index
    }

    masterIndex()
    {
        if (!this.master_index)
            this.master_index = this.createMasterIndex()
        return this.master_index
    }


    createSortExpansion(view_schema : ViewSchema, sort_spec: SortSpec )
    {
        this.data_set = this.data_set_loaded

        if (!sort_spec)
            sort_spec = []


        const copyRow = (row: DataRow, column_id: string, replacement: DataRowDatum, auto: boolean) : DataRow =>
        {
            const newData :  DataRowSet = {}
            if (row.data)
            {
                const match_column = row.data[column_id]

                Object.keys( row.data ).forEach( k => 
                {
                    const entry = row.data[k]

                    let new_entry = entry

                    if (k == column_id )
                        new_entry = replacement
                    else if (Array.isArray( entry) && replacement.obj_id !== null && replacement.obj_id !== undefined)
                    {
                        for( let re of entry)
                        {
                            if (re.obj_id == replacement.obj_id)
                                new_entry = re
                        }
                    }
                    
                    newData[k] = new_entry
                })
            }
             
            return { ...row, data: newData, auto: auto == false}

        }

        const sort_list = (sort_spec || []).map( s => { return {...s, auto: false}})


        view_schema.columns.forEach( c => 
        {
            const dmc = c.dataModelColumn
            if (dmc.multiple && dmc.entity !== this.schema.table)
            {
                const found = sort_list.findIndex( sl => sl.column_id == c.column_id )
                if (found == -1)
                    sort_list.push( {column_id: c.column_id, dir: SortDir.Up, new_level: false, use_cat_value: false, auto: true})
            }
        })

        let expansion : Array<DataRow> = []
        sort_list.forEach( s =>
        {
            const column = view_schema.findColumn( s.column_id )

            if (column?.dataModelColumn?.multiple == true)
            {
                expansion     = []
                this.data_set.forEach( row => 
                {

                    const column_value = row.data[column.column_id]

                    if (column_value && Array.isArray( column_value) && column_value.length > 0)
                        column_value.forEach( (v,idx) => expansion.push( copyRow(row, column.column_id, v, idx == 0 )))
                    else
                        expansion.push( row )
                })
                this.data_set = expansion
            }
        })        

    }


    createIndex( view_schema: ViewSchema, sort_spec : SortSpec )
    {
        this.createSortExpansion( view_schema, sort_spec )


        const index : Key[] = this.data_set.map( (_,i) => i )

        const sortFn =  (a : number, b : number) : number => 
        {
            const ai        = index[a]
            const bi        = index[b]

            if ((ai === undefined || ai === null) && bi !== undefined && bi !== null)
                return -1
            if ((bi === undefined || bi === null) && ai !== undefined && ai !== null)
                return  1

            const arow      = this.data_set[ai]
            const brow      = this.data_set[bi]

            if ((arow === undefined || arow === null ) && brow !== undefined && brow !== null)
                return -1
            if ((brow === undefined || brow === null)  && arow !== undefined && arow !== null)
                return  1

            for( let level = 0; level < sort_spec.length; level ++ )
            {
                const spec_lev  = sort_spec[level]
                const column_id = spec_lev.column_id
                const dir       = spec_lev.dir

                const aval      = arow.data[column_id]?.sort_value || ''
                const bval      = brow.data[column_id]?.sort_value || ''

                if ((aval === undefined || aval === null) && bval !== undefined && bval !== null)
                    return dir == SortDir.Up ? -1 :  1
                if ((bval === undefined || bval === null) && aval !== undefined && aval !== null)
                    return dir == SortDir.Up ?  1 : -1

                // This is to keep TypeScript happy
                if (aval == null)
                    return dir == SortDir.Up ? -1 :  1
                if (bval == null)
                    return dir == SortDir.Up ?  1 : -1

                if (aval < bval)
                    return dir == SortDir.Up ? -1 :  1
                else if (aval > bval)
                    return dir == SortDir.Up ?  1 : -1
            }

            return arow.key - brow.key
        }

        index.sort( sortFn )
        
        const index_tree = this.createIndexTree( sort_spec, index )
        this.createNodeKeys( index_tree, 1, [])
        this.recordSeq = []
        this.createRecordSeq( index_tree,  this.recordSeq )

        return index_tree
    }


    // Give each of the nodes in the tree a hierarchical index
    createNodeKeys(node: DataIndexTree,  i: number, id_list: string[] )
    {
        node.id = id_list.join(':')

        node.children.forEach((c, i) =>
        {
            id_list.push(`${i}`)
            if (c.records.length > 0)
                c.id = node.id
            else
                this.createNodeKeys(c, i, id_list)
            id_list.pop()
        })
    }


    createRecordSeq(node: DataIndexTree, seq_list: number[]) 
    {
        node.startRecord = seq_list.length
        if (node.records.length > 0)
            node.records.forEach( r => seq_list.push( r ))
        else
            node.children.forEach((c, i) => this.createRecordSeq(c, seq_list))

        node.endRecord = seq_list.length - 1
    }




    createIndexTree(spec : SortSpec, indices : number[]) : DataIndexTree
    {
        const createDIT = (column_sort : ColumnSort|null, value : RootDatum, kind: DataIndexTreeKind) : DataIndexTree =>  
                        {
                            if (column_sort)
                            {
                                const col   = this.schema.columns[column_sort?.column_id]
                                return { id: '', column_sort, value, kind, children: [], records: [], dependents: [] }
                            }
                            else
                                return { id: '', column_sort, value, kind, children: [], records: [], dependents: [] }
                        }


        const  fillIndexTree = ( level : number, start : number, end: number, tree: DataIndexTree, dep_lists: number[][]) : void  =>
        {
            if (level > spec.length)
                return

            const si = spec[level]

            if (!si || !si.new_level)
            {
                const subtree = createDIT(si, "", DataIndexTreeKind.Records)
                for( let i = start; i < end; i++ )
                {
                    subtree.records.push( indices[i] )
                    dep_lists.forEach(l => l.push(this.data_set[indices[i]].key ))
                }
                tree.children.push( subtree )
            }
            else
            {
                tree.kind = DataIndexTreeKind.Tree
                let last: Datum | undefined = undefined

                let i           = start
                let blockstart  = undefined
                while (i < end)
                {
                    const row     = this.data_set[indices[i]]

                    let   value: Datum   = ''
                    if (si.use_cat_value)
                        value = row.data[si.column_id]?.cat_value
                    else
                        value = row.data[si.column_id]?.value
                
                    if (last !== undefined  && 
                        value != last  &&  
                        blockstart !== undefined)
                    {                        
                        const subtree   = createDIT( si, last, DataIndexTreeKind.Tree )
                        dep_lists.push( subtree.dependents)
                        fillIndexTree( level + 1, blockstart, i, subtree, dep_lists)
                        dep_lists.pop()
                        tree.children.push( subtree )   
                    }

                    if (value != last)
                        blockstart = i

                    last = value
                    i++ 
                }

                if (blockstart !== undefined && last !== undefined)
                {
                    const subtree = createDIT(si, last, DataIndexTreeKind.Tree)
                    dep_lists.push(subtree.dependents)
                    fillIndexTree(level + 1, blockstart, i, subtree, dep_lists)
                    dep_lists.pop()
                    tree.children.push(subtree)   
                }
            }
        }
        
        const root = createDIT( null, "root", DataIndexTreeKind.Tree )
        fillIndexTree( 0, 0, indices.length, root, [root.dependents]  )

        return root
    }

    getCounts()
    {
        return {count: this.data_set_loaded.length, fullCount: this.fullCount}
    }
}


