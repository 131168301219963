

export const backend_server = {
	"server"		: "https://api.lab-logix.de",
	"websocket"		: "wss://api.lab-logix.de",
	"live" 			: false,
	"user_domains" 	: true,
	"client" 		: "https://%user%.lab-logix.de"
}


export default backend_server