import React, { Component } from 'react'

import { GUIStateContext }  from '../app/GUIContext'


export class GUIController 
{
    constructor()
    {
        this.count = 0
    }


    setComponent(component) 
    {
        this.component = component
    }

    s = (new_state = {}) => 
    {
        this.gui_state = { ...this.gui_state, ...new_state }
        this.count++

        if (this.component)
            setTimeout(() => this.component.update(), 0); 
    }

    update = () => this.s({})

    f = (new_state) => (e) => { this.s(new_state) }
}







export class ControlledComponent extends Component 
{
    constructor(props) {
        super(props)
        this.state = { count: 1000 }

        const { controller } = this.props

        controller.setComponent( this )
    }

    update() {
        this.setState({ count: this.state.count + 1 })
    }

    render() 
    {
        const { controller } = this.props

//        console.log("Rendering")
//        console.log(controller)


        if (!controller)
            return null
        else
            controller.setComponent(this)

        const { count } = this.state


        return  <GUIStateContext.Provider value={{controller, count }} >
                    {this.props.children}
                </GUIStateContext.Provider>
    }
}

