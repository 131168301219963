import React, { Component, createRef } from 'react'
import {
	useRef, useContext, useLayoutEffect,
	useState, useMemo
} from 'react'
import { GUIStateContext } from '../app/GUIContext'

import { processTemplates } from '../model/process'

import { renderProcessFormEdit, renderFormShow }
	from '../schema/StepKinds'


import classnames from 'classnames'
import { blank } from '../utils/utils'

import './TableEditor.scss'

import { useDrop } from 'react-dnd'

import { formatTimeStamp, formatTime, fixDuration } from '../utils/utils'

import { DBContext } from '../app/DBContext'

import {  getMaterialDisplayValue } from '../material/MaterialQuery'
import { Form } from '../form/Form'
import 	{ Select as AntSelect, Select } from "antd"
const 	{ Option } = AntSelect;

const SelectHandle = ({ experiment, row, col, gui_controller }) => {
	const selectHandleDown = gui_controller.selectHandleDown(experiment, row, col)
	return <div className='select-handle' onMouseDown={selectHandleDown} />
}




const onCopy = async (e) => {
	console.log("COPY!")
	try {

		const json = [
			{
				step: {
					id: 123,
					params: {
						2343: "Hell Oh World",
						3421: "99.5"
					}
				}
			}
		]

		console.log(json)
		const txt = JSON.stringify(json)

		console.log(txt)

		await navigator.clipboard.writeText(txt);
		console.log('Content copied to clipboard');
	} catch (err) {
		console.error('Failed to copy: ', err);
	}
}



const onPaste = async (e) => 
{
	console.log("PASTE!")
	try {

		console.log( e.getData())
	} catch (err) {
		console.error('Failed to copy: ', err);
	}
}



const CellDisplay = ({ experiment, row, column, readOnly, navigationEvents, focused, content, canEdit }) => {


	return (
		<input
			key='v'
			readOnly={readOnly}
//			onFocus={navigationEvents.cellDown(experiment, row, column)}
			onMouseDown={navigationEvents.cellDown(experiment, row, column)}
			onBlur={navigationEvents.mouseUp(experiment, row, column)}
			onKeyDown={navigationEvents.keyDown(experiment, row, column, canEdit)}
			onCopy={onCopy}
			onPaste={onPaste}
			className={`value`}
			tabIndex='-1'
			autoFocus={focused}
			value={content}
		/>)

}



const CellSelect = ({ experiment, row, column, readOnly, navigationEvents, focused, content, canEdit }) => 
{

	const onPaste = (e) => { console.log("PASTE!") }

	const ref = useRef(null)
	const [justArrived, setJustArrived] = useState(false)
	const [searchValue, setSearch] 		= useState( null )
	const [open, setOpen] 				= useState( false )
	const [editValue, setEditValue] 	= useState( content )

	const options = [
						{label: 'Alpha', 	value: 'Alpha'},
						{label: 'Beta', 	value: 'Beta'},
						{label: 'Gamma', 	value: 'Gamma'},
						{label: 'Delta', 	value: 'Delta'},
						{label: 'Eta', 		value: 'Eta'},
						{label: 'Zeta', 	value: 'Zeta'},
						{label: 'Iota', 	value: 'Iota'},
						{label: 'Kappa', 	value: 'Kappa'},
						{label: 'Lambda', 	value: 'Lambda'}  
					]


	const cancel  = () => { console.log( "CANCEL") }
	const commit  = (v) => { console.log( "Commit") }

	const keyDown = (e) => {
		if (e.key == 'Escape')
			cancel()
	}

	const onSelect = (v) =>
	{
		console.log( v )
		setEditValue( v )
	}

	return <Select className='ant-select-style' 
					open={open}
					onDropdownVisibleChange={setOpen}
					ref={ref}
					content={editValue}
					onInputKeyDown={keyDown}
					value={editValue}
					onSelect={onSelect}
	{ ... {options, searchValue}} />
}


class CellContents extends Component {
	static contextType = DBContext

	constructor(props) {
		super(props)
		this.state = { focused: this.props.focused }
	}


	//	setHTMLFocus()
	//	{
	//		return
	//		if (this.props.focused  && !this.props.is_editing)
	//		{
	//			if (this.contentRef.current)
	//			{
	//				this.contentRef.current.focus()
	//				this.contentRef.current.select()
	//			}
	//		}
	//	}
	//
	//	componentDidUpdate( prevProps )
	//	{
	//		this.setHTMLFocus()
	//	}
	//
	//	componentDidMount()
	//	{
	//		this.setHTMLFocus()
	//	}

	render() {
		const { focused } 			= this.props

		const { experiment } 		= this.props
		const { row, column } 		= this.props
		const { gui_controller } 	= this.props
		const { completed } 		= this.props

		const { colRefID, colRefs }	= this.props
		let { value }  				= this.props
		const { is_editing } 		= this.props

		const { parameter } 		= this.props



		const canEdit = !completed
		const readOnly = !is_editing || !focused

		const control = this.getDisplayControl(gui_controller, parameter, value, experiment, 
												row, column, canEdit, readOnly, focused)


		//`${value}-${is_editing ? 'IE' : ''}-${readOnly ? 'RO' :''}-${focused ? 'Focus' : ''}`

		let colWidthRef = null
		if (colRefID) {
			if (!colRefs[colRefID])
				colRefs[colRefID] = []
			colWidthRef = colRefs[colRefID][row] ? colRefs[colRefID][row] : React.createRef()
			colRefs[colRefID][row] = colWidthRef
		}


		const contents = [control]

		const { select_handle } = gui_controller.get_selection_data({ experiment, row, col: column })
		if (select_handle && !is_editing)
			contents.push(<SelectHandle key='sh' experiment={experiment} gui_controller={gui_controller} col={column} row={row} />)


		return 	<div key='key1' ref={colWidthRef} className={classnames("content", focused ? 'focus' : null)}>
					{contents}
				</div>
	}



	getDisplayControl(gui_controller, parameter, value, experiment, row, column, canEdit, readOnly, focused) {
		if (value === undefined || value == null)
			value = ""


//		const onCopyFn = focused && !is_editing ? (e) => onCopy : null

		const navigationEvents = gui_controller.navigationEvents


		if (parameter && parameter.kind == 'material' && value) 
		{
			value = getMaterialDisplayValue( experiment, value )
		}
		else if (parameter && (parameter.kind == 'text' || parameter.kind == 'textarea') )
		{
			value = `${value}`.trim()
		}
		else if (parameter && parameter.kind == 'resource' && value) {
			if (this.context && this.context.resources)
				value = this.context.resources.value_for(value)?.title
		}
		else if (parameter && parameter.kind == 'boolean') {
			if (value === true || value == 'Yes' || value == 'true')
				value = 'Yes'
			else if (value === false || value == 'No' || value == 'false')
				value = 'No'

			else
				value = ""
		}
		else if (parameter && parameter.kind == 'timestamp') {
			value = formatTimeStamp(value, '')
		}
		else if (parameter && (parameter.kind == 'duration' || parameter.kind == 'time')) {
			value = fixDuration(value)
			value = formatTime(value?.duration, '--:--')
		}

		let content = blank(value) ? '' : `${value}`

		if (false && parameter && parameter.kind == 'select')
		{
			console.log( parameter )
			return <CellSelect 		readOnly={readOnly}
									navigationEvents={navigationEvents}

									experiment={experiment}
									row={row}
									column={column}

									canEdit={canEdit}
									focused={focused}
									content={content} />	
		}
		else
		{
			return <CellDisplay 	key='celldisplay'
									readOnly={readOnly}
									navigationEvents={navigationEvents}

									experiment={experiment}
									row={row}
									column={column}

									canEdit={canEdit}
									focused={focused}
									content={content} />
		}
	}
}



const ClosedCell = ({ experiment, row, column, colRefs, gui_controller, step_id, substrate, set, active_class }) => 
{
	const mouseEnter 	= gui_controller.mouseEnter(experiment, row, column)
	const mouseLeave 	= gui_controller.mouseLeave(experiment, row, column)
	const cellDown 		= gui_controller.cellDown(experiment, row, column)
	const mouseUp 		= gui_controller.mouseUp(experiment, row, column)


	const colRefID = `${step_id}`
	const focustarget = gui_controller.focus_target && gui_controller.table_focused
	const is_focustarget = focustarget ? row == focustarget.r && c == focustarget.c : false

	const dom_id = `${experiment.id}-${row}-${column}`

	const complete_class = set && set.completed && active_class !== 'inactive' ? "complete" : ""
	const focus_class = is_focustarget ? 'focus' : ''

	const { select_class } = gui_controller.get_selection_data({ experiment, row, col: column })


	return 	<td id={dom_id}
				className={classnames('process-edge condensed', focus_class, select_class, active_class, complete_class)}
				onMouseDown={cellDown} onMouseUp={mouseUp}
				onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
				<CellContents key='cell-contents'
					focused={is_focustarget}
					experiment={experiment}
					colRefID={colRefID} colRefs={colRefs}
					substrate={substrate} step_id={step_id}
					gui_controller={gui_controller} row={row} column={column}>
				</CellContents>
			</td>
}



const DataCell = ({ experiment, row, column, colRefs, gui_controller,
	substrate, step_id, parameter, set, active_class, last, index, id }) => {
	const colRefID = `${step_id}:${column}`

	const displays_data = active_class == 'active'
	const skipped 		= active_class == 'inactive'

	let value = displays_data && set ? set[id] : null
	if (typeof value == 'number' && isNaN(value))
		value = null
	if (value == undefined)
		value = null

	const completed = set && set.completed == true
	const incomplete = set && set._has_data && !set._all_required

	//		console.log( "COMPL ", row, column, completed, incomplete )

	const clazzlist = []
	if (last)
		clazzlist.push('process-edge')

	const selection_data = gui_controller.get_selection_data({ experiment, row, col: column })
	const { select_class, selected_type } = selection_data
	const is_active = gui_controller.isActive()
	clazzlist.push(select_class)
	clazzlist.push(active_class)

	if (!selected_type && !skipped) {
		if (completed)
			clazzlist.push("complete")
		else if (incomplete)
			clazzlist.push("incomplete")

		if (parameter.required && value == null)
			clazzlist.push("incomplete-cell")
	}

	const focustarget = gui_controller.focus_target && gui_controller.table_focused
	const is_focustarget = focustarget ? row == focustarget.r && column == focustarget.c : false
	if (is_focustarget)
		clazzlist.push('focus')

	const main_selected = gui_controller.is_main_selected(experiment, row, column)



	const mouseEnter = gui_controller.mouseEnter(experiment, row, column)
	const mouseLeave = gui_controller.mouseLeave(experiment, row, column)
	const cellDown = gui_controller.cellDown(experiment, row, column)
	const mouseUp = gui_controller.mouseUp(experiment, row, column)

	const { gui_state } = gui_controller
	const { cell_editing } = gui_state
	const is_editing = cell_editing && main_selected

	const dom_id = `${experiment.id}-${row}-${column}`
	//	onMouseDown={cellDown}
	const cell = <td id={dom_id}
		onClick={focus}
		className={classnames(clazzlist)}
		onMouseUp={mouseUp}
		onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
		<CellContents key='cell-contents'
			focused={is_focustarget && is_active}
			gui_controller={gui_controller}
			experiment={experiment}
			row={row} column={column}
			substrate={substrate} step_id={step_id}
			parameter={parameter}
			completed={completed}
			colRefID={colRefID}
			colRefs={colRefs} is_editing={is_editing} value={value}>
		</CellContents>
	</td>

	return cell
}






const add_process_block = ({ experiment, row, col, step_id, substrate, gui_controller, colRefs }) => {
	let column = col


	const set = experiment.getProcessData(substrate, step_id)
	const step_kind = experiment.getStep(step_id).step_kind_id

	const template = processTemplates[step_kind]

	const is_closed = gui_controller.is_closed(experiment.id, step_id)

	const active_class = experiment.filledState(substrate, step_id)


	if (!template)
		return { cells: [], column }

	if (is_closed) {
		const cell = <ClosedCell experiment={experiment}
			key={`R${row}C${column}`}
			row={row} column={column}
			gui_controller={gui_controller}
			step_id={step_id}
			substrate={substrate}
			active_class={active_class}
			experiment={experiment}
			set={set}
			colRefs={colRefs} />
		return { cells: [cell], column: column + template.params.length }
	}

	const ids = template.params.map(x => x.id)

	const cells = []
	ids.forEach((id, index) => {
		const parameter = template.params[index]

		const cell = <DataCell key={`R${row}C${column}`}
			experiment={experiment}
			row={row} column={column}
			active_class={active_class}
			parameter={parameter}
			index={index}
			id={id}
			last={index == ids.length - 1}
			colRefs={colRefs}
			gui_controller={gui_controller}
			set={set}
			step_id={step_id}
			substrate={substrate} />

		cells.push(cell)

		column++
	})

	return { cells, column }
}



const ProcessTableRow = (props) => {

	const { gui_controller } = useContext(GUIStateContext)

	const { substrate, row } = props
	const { experiment } = props
	const { colRefs, rowRef } = props

	let col = 0
	const block_cells = []
	experiment.step_ids.forEach((step_id, n) => {
		const { cells, column } = add_process_block({
			experiment, row, col,
			step_id, substrate,
			gui_controller, colRefs
		})

		block_cells.push(...cells)
		col = column
	})

	return <tr key={`row-${row}`} ref={rowRef}>
		{block_cells}
	</tr>
}




const add_blank_process_block = ({ experiment, step_id, col, gui_controller }) => {
	let column = col
	const step_kind = experiment.getStep(step_id).step_kind_id

	const template = processTemplates[step_kind]

	const is_closed = gui_controller.is_closed(experiment.id, step_id)

	if (!template)
		return { cells: [], column }

	if (is_closed) {
		const cell = <td key={`ID-c-${col}`} className="blank closed"><div className='content'>&nbsp;</div></td>
		return { cells: [cell], column: column + template.params.length }
	}

	const ids = template.params.map(x => x.id)

	const cells = []
	ids.forEach((id, index) => {
		const cell = <td key={`ID-${index}-${col}-${id}`} className="blank open"><div className='content'>&nbsp;</div></td>
		cells.push(cell)
		column++
	})

	return { cells, column }
}




const ProcessTableBlankRow = (props) => {
	const { gui_controller } = useContext(GUIStateContext)

	const { experiment } = props
	const { rowRef } = props

	const block_cells = []
	let col = 0
	experiment.step_ids.forEach((step_id) => {
		const { cells, column } = add_blank_process_block({
			experiment,
			col,
			step_id,
			gui_controller
		})

		col = column
		block_cells.push(...cells)
	})

	return <tr key={`row-blank`} ref={rowRef}>
		{block_cells}
	</tr>
}



const ProcessTableColHead = (props) => {

	const { gui_controller } = useContext(GUIStateContext)

	const { experiment } = props
	const { colRefs } = props

	let col = 0


	const select_step = (step_id) => (e) => {
		e.stopPropagation()
		e.preventDefault()

		gui_controller.selectEntireStep(step_id)
	}

	const open_close = (step_id) => (e) => {
		e.stopPropagation()
		e.preventDefault()
		gui_controller.toggleOpenClose(experiment.id, step_id)
	}





	return 	<table className='process-table-col-head'>
				<thead>
					<tr>
						{
							experiment.step_ids.map((step_id, i) => 
							{
								const is_closed = gui_controller.is_closed(experiment.id, step_id)
								const template = experiment.getStepTemplate(step_id)
								const l = is_closed ? 1 : template.params.length
								const step = experiment.getStep(step_id)
								const counter = experiment.getStepCounter(step)

								if (is_closed)
									return <th id={`step-header-${step_id}`}
										className=' process-edge main step-header-title'
										onClick={select_step(step_id)}
										key={`${i}`}
										colSpan={`${l}`}>
										<div className='content'>{`${i+i} ${template.abbr} ${counter}`}</div>
										<div onClick={open_close(step_id)}
											className='indicator open fa-caret-right fa' />
									</th>
								else
									return <th id={`step-header-${step_id}`}
										className=' process-edge main step-header-title'
										onClick={select_step(step_id)}
										key={`${i}`}
										colSpan={`${l}`}>
										<div className='indicator close fa-caret-left fa'
											onClick={open_close(step_id)} />
										<div className='content'>{`${i+1} ${template.title} ${counter}`}</div>
									</th>
							})
						}
					</tr>
					<tr>
						{
							experiment.step_ids.map((step_id, i) => {
								const template = experiment.getStepTemplate(step_id)

								if (gui_controller.is_closed(experiment.id, step_id)) {
									const selected = gui_controller.get_col_selection_data({ experiment, col }) > 0 ? 'selected' : null

									const selectColumn = gui_controller.selectColumn(col, col + template.params.length - 1)


									let ref = colRefs[step_id]
									if (!ref)
										ref = React.createRef()
									colRefs[step_id] = ref

									col += template.params.length
									return <th key={`closed-${i}`}
										onClick={selectColumn}
										className={classnames('process-edge', 'sub', selected)}>
										<div className='content' ref={ref}>&nbsp;</div>
									</th>
								}

								return template.params.map((p, j) => {
									let colRef = colRefs[`${step_id}:${col}`]
									if (!colRef)
										colRef = React.createRef()
									colRefs[`${step_id}:${col}`] = colRef

									const selected = gui_controller.get_col_selection_data({ experiment, col }) > 0 ? 'selected' : null
									const selectColumn = gui_controller.selectColumn(col, col)

									col++
									const clazzes = ['sub']
									if (j == template.params.length - 1)
										clazzes.push("process-edge")
									if (selected)
										clazzes.push(selected)
									return <th key={`${j}`}
										onClick={selectColumn}
										className={classnames(clazzes)}>
										<div className='content' ref={colRef}>{p.title}</div>
									</th>
								})
							})
						}
					</tr>
				</thead>
			</table>
}




const RowCell = ({ experiment, selected, s, rowRef }) => {
	const { gui_controller } = useContext(GUIStateContext)

	const [{ isOver, canDrop }, dropRef] = useDrop(() => (
		{
			accept: ['SUBSTRATE'],
			collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
			canDrop: (dragData) => {
				return (!!(s.number) == false || !s.contains_data) && (dragData.item_count == 1)
			},
			drop: (dragData) => gui_controller.parent.assignSubstrateToSubstrate(experiment, s, dragData.item[0])
		}))

	const removeSubstrate = (e) => {
		e.preventDefault()
		e.stopPropagation()
		gui_controller.parent.removeSubstrate(experiment, s)
	}

	const clazzes = ['batch-id substrate-rowhead']
	if (selected > 0)
		clazzes.push('selected')

	if (!s.number) {
		clazzes.push("unassigned")
	}

	if (isOver && canDrop)
		clazzes.push("drag-over")

	if (experiment.subject == 'experiment')
		return (
			<td className={classnames(clazzes)}
				key={`rhe-${s.id}`}
				ref={rowRef}>
				<div className='content'>
					<div className='title' ref={dropRef}>{s.display_name}</div>
					{!s.contains_data &&
						<div className='remove icon'
							onClick={removeSubstrate}>
							<span className='fa-regular fa-times' />
						</div>}
					{s.contains_data && <div className='remove'>&nbsp;</div>}
				</div>
			</td>)
	else
		return (
			<td className={classnames(clazzes)}
				key={`rh-${s.id}`}
				ref={rowRef}>
				<div className='content'>
					<div className='title'>{experiment.full_id}</div>
				</div>
			</td>)
}



const AddSubstrateCell = ({ experiment, rowRef }) => {
	const { gui_controller } = useContext(GUIStateContext)

	const [{ isOver }, dropRef] = useDrop(() => (
		{
			accept: ['SUBSTRATE'],
			collect: (monitor) => ({ isOver: monitor.isOver() }),
			drop: (dragData) => {

				console.log( "DROP SUBSTRATE")
				console.log( dragData )

				gui_controller.parent.addSubstrate(experiment, dragData.item)
			}
		}))


	const clazzes = ['batch-id batch-add']
	if (isOver)
		clazzes.push("drag-over")


	const addBlankSubstrate = () => {
		gui_controller.parent.addSubstrate(experiment, null)
	}


	return (
		<td className={classnames(clazzes)}
			onClick={addBlankSubstrate}
			key={`rh-add`}
			ref={rowRef}>
			<div ref={dropRef} className='content'>
				<span className='fa-regular fa-plus' />
			</div>
		</td>)
}





export const ProcessTableRowHead = (props) => {
	const { gui_controller } = useContext(GUIStateContext)

	const { experiment } = props
	const { rowRefs } = props

	experiment.substrates.forEach((s) => rowRefs[s.id] = React.createRef())
	rowRefs['*ADD*'] = React.createRef()


	const rows = experiment.substrates.map((s, row) => {
		const selected = gui_controller.get_row_selection_data({ experiment, row })

		return <tr key={`rh-${s.id}-${row}`}>
			<RowCell experiment={experiment} s={s} selected={selected} rowRef={rowRefs[s.id]} />
		</tr>
	})

	const add_substrate = <tr key={`rh-add`}>
		<AddSubstrateCell experiment={experiment} rowRef={rowRefs['*ADD*']} />
	</tr>

	const padding = <tr key={`rh-pad`}>
		<td className='rowpadding'>&nbsp;</td>
	</tr>

	return 	<table className='process-table-row-head'>
				<tbody>
					{rows}
					{experiment.subject == 'experiment' && add_substrate}
					{padding}
				</tbody>
			</table>
}







export const ProcessTableBody = (props) => {
	const { experiment } = props

	const { rowRefs } = props
	const { colRefs } = props


	rowRefs['*ADD*'] = React.createRef()
	const rowRefBlank = rowRefs['*ADD*']
	const blank_row = <ProcessTableBlankRow key='pt-blank'
		experiment={experiment}
		rowRef={rowRefBlank} />


	return <table className='process-table-body'>
		<tbody>
			{
				experiment.substrates.map((s, r) => {
					rowRefs[s.id] = React.createRef()
					return <ProcessTableRow
						substrate={s}
						rowRef={rowRefs[s.id]}
						colRefs={colRefs}
						key={`pt-${s.id}-${r}`}
						row={r}
						experiment={experiment} />
				})
			}
			{blank_row}
		</tbody>
	</table>
}


export const ProcessTable = (props) => {
	const { gui_controller } = useContext(GUIStateContext)



	const { experiment } = props

	const hRowRefs = {}
	const hColRefs = {}

	const bRowRefs = {}
	const bColRefs = {}


	const refTable = useRef(null)
	const refBodyContainer = useRef(null)
	const refColHeadContainer = useRef(null)
	const refRowHeadContainer = useRef(null)

	// We have to do it this way since Reacts mouse leave is... not good
	useLayoutEffect(() => {
		if (refBodyContainer.current) {
			refBodyContainer.current.addEventListener("mouseleave", gui_controller.mouseLeaveTable)
			refBodyContainer.current.addEventListener("mouseover", gui_controller.mouseReEnterTable, true)

			refBodyContainer.current.addEventListener("focusin", gui_controller.focusTable)
			refBodyContainer.current.addEventListener("focusout", gui_controller.blurTable, true)
		}
	}, [refBodyContainer])


	const printScroll = () => {
		if (refBodyContainer.current) {
			console.log(refBodyContainer.current.scrollLeft,
				refBodyContainer.current.scrollTop)
		}
	}


	// Scroll handlers
	const bodyScrollHandler = (event) => {
		if (refBodyContainer.current) {
			if (refColHeadContainer.current &&
				refBodyContainer.current.dataset.scrollLeft != refBodyContainer.current.scrollLeft) {
				refColHeadContainer.current.scrollLeft = refBodyContainer.current.scrollLeft
				refColHeadContainer.current.dataset.scrollLeft = refBodyContainer.current.scrollLeft
			}

			if (refRowHeadContainer.current &&
				refBodyContainer.current.dataset.scrollTop != refBodyContainer.current.scrollTop) {
				refRowHeadContainer.current.scrollTop = refBodyContainer.current.scrollTop;
				refRowHeadContainer.current.dataset.scrollTop = refBodyContainer.current.scrollTop;
			}
		}
	}


	const colHeadScrollHandler = (event) => {
		if (refColHeadContainer.current && refBodyContainer.current) {
			if (refColHeadContainer.current.dataset.scrollLeft != refColHeadContainer.current.scrollLeft) {
				refBodyContainer.current.scrollLeft = refColHeadContainer.current.scrollLeft;
				refBodyContainer.current.dataset.scrollLeft = refColHeadContainer.current.scrollLeft;
			}
		}
	}


	const rowHeadScrollHandler = (event) => {
		if (refRowHeadContainer.current && refBodyContainer.current) {

			if (refRowHeadContainer.current.dataset.scrollTop != refRowHeadContainer.current.scrollTop) {
				refBodyContainer.current.scrollTop = refRowHeadContainer.current.scrollTop;
				refBodyContainer.current.dataset.scrollTop = refRowHeadContainer.current.scrollTop;
			}
		}
	}


	useLayoutEffect(() => {
		const row_heights = {}

		Object.keys(bRowRefs).forEach(id => {
			const ref = bRowRefs[id]
			if (ref.current)
				row_heights[id] = ref.current.clientHeight
		})

		Object.keys(hRowRefs).forEach(id => {
			const ref = hRowRefs[id]
			if (ref.current)
				row_heights[id] = Math.max(ref.current.clientHeight, row_heights[id])
		})

		Object.keys(bRowRefs).forEach(id => {
			const ref = bRowRefs[id]
			if (ref.current)
				ref.current.style.height = `${row_heights[id]}px`
		})

		Object.keys(hRowRefs).forEach(id => {
			const ref = hRowRefs[id]
			if (ref.current)
				ref.current.style.height = `${row_heights[id]}px`
		})



		const col_widths = {}

		Object.keys(bColRefs).forEach(id => {
			const refs = bColRefs[id]
			col_widths[id] = 0
			for (const ref of refs) {
				//				console.log( "BCOL ", id, ref.current, ref.current ? ref.current.clientWidth : 'NONE')
				if (ref.current)
					col_widths[id] = Math.max(col_widths[id], ref.current.clientWidth)
			}
		})

		Object.keys(hColRefs).forEach(id => {
			const ref = hColRefs[id]
			if (ref.current)
				col_widths[id] = Math.max(ref.current.clientWidth, col_widths[id], 180)
		})

		Object.keys(bColRefs).forEach(id => {
			const refs = bColRefs[id]
			for (const ref of refs) {
				if (ref.current) {
					ref.current.style.width = `${col_widths[id]}px`
					ref.current.parentNode.style.maxWidth = `${col_widths[id]}px`
					ref.current.parentNode.style.width = `${col_widths[id]}px`
				}
			}
		})


		Object.keys(hColRefs).forEach(id => {
			const ref = hColRefs[id]
			if (ref.current) {
				ref.current.style.width = `${col_widths[id]}px`
				ref.current.parentNode.style.maxWidth = `${col_widths[id]}px`
				ref.current.parentNode.style.width = `${col_widths[id]}px`
			}
		})



		const dom_id = `${experiment.id}-MAIN`
		const element = document.getElementById(dom_id)

		//		console.log(gui_controller.gui_state.keep_visible, gui_controller.gui_state.focus_target)

		if (element && true && gui_controller.gui_state.keep_visible) {
			//			element.scrollIntoView();
			element.setAttribute("tabindex", 1);
			//			element.focus();
		}
		gui_controller.gui_state.keep_visible = false

	})

	let toptitle = ''
	let bottitle = ''
	if (experiment) {
		if (experiment.subject == 'solution')
			toptitle = 'Solution'
		else if (experiment.subject == 'solution' && experiment.template)
			toptitle = 'Sol. Temp.'
		else if (experiment.subject == 'experiment' && experiment.template)
			toptitle = 'Exp. Temp.'
		else if (experiment.subject == 'experiment' && experiment.planning)
			toptitle = 'In Planning'
		else if (experiment.subject == 'experiment')
			toptitle = 'Experiment'


		const l = experiment.substrates ? experiment.substrates.length : 0
		const count = experiment.substrates ? `${l} Substrate${l == 1 ? '' : 's'}` : `No Substrates`
		bottitle = count
	}

	return <div className='process-table'
		ref={refTable}
		tabIndex='1'>

		<div className='left-container top-container corner-container'>
			<div className='process-table-corner'>
				<div className='top'>{toptitle}</div>
				<div className='bot'>{bottitle}</div>
			</div>
		</div>

		<div className='right-container top-container colhead-container'
			onScroll={colHeadScrollHandler}
			ref={refColHeadContainer}>
			<ProcessTableColHead {...props} colRefs={hColRefs} />
		</div>

		<div className='left-container bottom-container rowhead-container'
			onScroll={rowHeadScrollHandler}
			ref={refRowHeadContainer}>
			<ProcessTableRowHead {...props} rowRefs={hRowRefs} />
		</div>

		<div
			key='bodycontainer'

			id='bodycontainer'
			className='right-container bottom-container body-container'
			onScroll={bodyScrollHandler}


			onMouseLeave={null}
			ref={refBodyContainer}
		>
			<ProcessTableBody 	 {...props} rowRefs={bRowRefs} colRefs={bColRefs} />
		</div>
	</div>
}




export class TableEditor extends Component {
	static contextType = GUIStateContext

	constructor(props) {
		super(props)
	}


	render() {
		const controller = this.context.controller

		const { experiment_id } = this.props

		if (!controller)
			return <div />

		const experiment = controller.experiment(experiment_id)
		const gui_controller = controller.getGuiController(experiment_id)

		if (!experiment || !controller || !gui_controller)
			return <div />

		return <GUIStateContext.Provider value={{ gui_controller }} >
			{experiment && <ProcessTable experiment={experiment} />}
		</GUIStateContext.Provider>
	}
}


