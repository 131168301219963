import React, {useContext, useState} from 'react'
import { Dropdown, Menu, Space } from 'antd';
import { all_colors } from '../metadata/Color'
import axios from 'axios'
import { DBContext } from '../app/DBContext'
import { backend_server } from '../config/runconfig'
import { ColorSelector } from '../metadata/Color'
import { KeywordEditor } from './Keyword'

export const  MetaDataMenu = ({table, view, refetch}) => 
{


	const context 		= useContext(DBContext)
	const keywords		= context.keywords.all_for(table)
	const [editing, set_editing] = useState(false)

	const editor = editing ? <KeywordEditor table={table} db_table={table} onClose={toggleEdit} /> : null


	const toggleEdit    = () =>
	{
		set_editing( !editing )
	}

	const setKeyword 	= (add,kw) => 
	{


		if (view?.current)
		{
			const selection = view?.current?.state?.controller?.getSpecificSelected()
			if (!selection || selection.length == 0)
				return

			const url 	= add ? `${backend_server.server}/keyword/add_set/${kw.id}`
							  : `${backend_server.server}/keyword/del_set/${kw.id}`
			const data  = {
				obj_table 	: table,
				obj_ids 	: selection
			}

			axios.post(url, data )
				 .then( () => refetch())
		}
	}

	const kwItems 	= (add) => keywords.map( (keyword,i) => 
	{
		const kwentry = <a 	className='keyword-top-menu-entry' 
							onClick={() => setKeyword(add, keyword)}>								
							<span className={`indicator mark-${keyword.color}-bg`}>&nbsp;</span>
							<span className='title'>{keyword.keyword}</span>
						</a>

		return {
			label:  kwentry,
			key:   `KW:${keyword.id}`
		}
	})

	const editEntry = () =>
	{	
		return <a 	className='keyword-top-menu-entry' 
					onClick={() => toggleEdit()}>								
							<span className={`indicator`}>&nbsp;</span>
							<span className='title'>Edit Tags...</span>
						</a>

	}

	const changecolor = (c) => 
	{
		if (view?.current) 
		{
			const selection = view?.current?.state?.controller?.getSpecificSelected()
			if (!selection || selection.length == 0)
				return

			const url =  `${backend_server.server}/color/set_list`
			const data = {
				obj_table: table,
				obj_ids: selection,
				color: c
			}

			axios.post(url, data)
				.then(() => refetch())
		}
	}

	const color = <ColorSelector key={`C`} selected={null}
								expanded={false}
								table={table} id={document.id} onColorChange={changecolor} />

	
	const menu = (add) =>	<Menu items={[...kwItems(add),editEntry()]}/>

	const addkw = 	<Dropdown overlay={menu(true)} trigger={['click']}>
						<div className='context-menu metadata-button'>
							<div className='tag-icon fa fa-tag'></div>
							<div className='tag-action'>+</div>
						</div>
					</Dropdown>

	const delkw = 	<Dropdown overlay={menu(false)} trigger={['click']}>
						<div className='context-menu metadata-button'>
							<div className='tag-icon fa fa-tag'></div>
							<div className='tag-action'>-</div>
						</div>
					</Dropdown>


	return <div className='metadata-edit'>{color}{addkw}{delkw}{editor}</div>
}

