import React, { Fragment, useContext } from 'react'
import { processTemplates } from '../model/process'
import { StepFormShow } from '../schema/StepKinds'
import { EntityButton } from '../navigation/EntityButton'
import { formatTimeStamp } from '../utils/utils'

const get_data_set = ({ substrate, experiment, step }) => 
{
	if (!step || !experiment || !substrate)
		return null

	const sub_block = experiment?.data[`${substrate.id}`]
	if (!sub_block)
		return null

	return sub_block[`${step.id}`]
}



const all_complete = (substrate, experiment) => 
{
	for( let step of experiment.process_steps )
	{
		const data_set = get_data_set({ substrate, experiment, step })
		{
			if (!data_set || !data_set.completed)
				return false
		}
	}
	return true
}






export const SolutionDataTimeLine = ({ solution }) => 
{
	const substrate = solution?.substrates[0]
	if (!substrate)
		return null
	else
		return 	<div className='experiment-timeline'>
					<TimeLineExperiment key={`K-${solution.id}`} experiment={solution} substrate={substrate} />
				</div>
}



export const ExperimentDataTimeLine = ( {substrate }) =>
{
	if (!substrate)
		return null

	const rv = []

	substrate.experiments && substrate.experiments.map((experiment, i) =>
	{
		const ac = all_complete( substrate, experiment )
		const completed = ac ? 'complete' : 'incomplete'

		if (experiment.subject !== 'solution')
			rv.push( 
				<div key={`E-${experiment.id}`} className={`row experiment ${completed}`}>
					<div className='step-parameter-container'>
						<EntityButton kind='experiment' entity={experiment} />
					</div>
					<div className='link-arrow right'>
						<div className='circle'>&nbsp;</div>
					</div>
				</div>
			)
		rv.push(<TimeLineExperiment key={`K-${i}${experiment.id}`} experiment={experiment} substrate={substrate} />)
	})

	if (rv.length == 0)
				return null
	else
		return <div className='experiment-timeline'>
			{rv}
		</div>
}




export const TimeLineExperiment = ({ substrate, experiment }) => 
{
	if (!experiment.process_steps)
		return null
	

	const rv = []
	experiment.process_steps.forEach((step, i) =>
			{
				const data_set = get_data_set({ substrate, experiment, step })
				const completed = data_set?.completed ? 'complete' : 'incomplete'

				rv.push(	
						<div key={`${step.id} ${i}`} className={`row step ${completed}`}>
							<div className='step-parameter-container left'>
								<StepData 	params={true}
											key={`${i}-${step.id}`}								 
											substrate={substrate}
											experiment={experiment}
											step={step}
											data_set={data_set} />
							</div>
							<div className='link-arrow right'>
								<div className='circle'>&nbsp;</div>
							</div>
							<div className='filler right'/>
						</div>
				)
			})
	return rv
}




export const StepData = ({ substrate, experiment, step, data_set, params }) => 
{
	if (!step || !experiment || !substrate)
		return null

	if (!data_set || !data_set.values)
		return <div className='step-data-section'>
					<table className='step-parameter-table step-brd'>
						<thead>
							<tr>
								<th colSpan='2'>
									<div className='title'>{`${step.name}`.replace(/_/g, " ")}</div>
								</th>
							</tr>
						</thead>
					</table>
				</div>



	const completed = data_set.completed ? 'complete' : 'incomplete'

	const step_template = processTemplates[step.step_kind_id]


	//	{
	//		data_set.completed && data_set.completed_at &&
	//		<tr className='completion' key={`cpldate-${step.id}`}>
	//			<th className='title completed'>Completed on</th>
	//			<th className='value completed'>{formatTimeStampDiv(data_set.completed_at)}</th>
	//		</tr>
	//	}
	//	{
	//		data_set.completed && data_set.person &&
	//		<tr className='completion' key={`cplperson-${step.id}`}>
	//			<th className='title completed'></th>
	//			<th className='value completed'>{data_set.person.lastname}, {data_set.person.firstname}</th>
	//		</tr>
	//	}



	return <div className={`step-data-section ${completed}`}>
		<table className='step-parameter-table step-brd'>
			<thead>
				<tr>
					<th colSpan='2'>
						<div className='title'>{`${step.name}`.replace(/_/g, " ")}</div>
					</th>
				</tr>

				{
					data_set &&
					<tr className='completion' key={`cpldate-${step.id}-1`}>
						<th className='title completed'>Started</th>
						<th className='value completed'>{formatTimeStamp(data_set.created_at)}</th>
					</tr>
				}
				{
					data_set && data_set.completed && data_set.completed_at &&
					<tr className='completion' key={`cpldate-${step.id}-2`}>
						<th className='title completed'>Completed on</th>
						<th className='value completed'>{formatTimeStamp(data_set.completed_at)}</th>
					</tr>
				}
				{
					data_set && data_set.completed && data_set.person &&
					<tr className='completion' key={`cplperson-${step.id}-3`}>
						<th className='title completed'></th>
						<th className='value completed'>{data_set.person.lastname}, {data_set.person.firstname}</th>
					</tr>
				}
			</thead>

			{params &&
				<tbody>
					{Object.keys(data_set.values).map((k, i) => {
						const v = data_set.values[k]

						const param = step_template?.params.find(p => `${p.id}` == k)

						if (!param)
							return null
						const formElement = StepFormShow(param, v, { experiment, step_template, add_link: true })

						return <tr key={`${i}-${step.id}-${i}`} className='step-parameter-row'>
							<td className='step-title'>{param.title}</td>
							<td className='step-value'>{formElement}</td>
						</tr>
					})}
				</tbody>
			}
		</table>
	</div>


}


export const ExperimentDataTable = ({ substrate, experiment }) => {
	return <div className='experiment-table'>
		{experiment?.process_steps &&
			<section>
				{experiment.subject !== 'solution' && <EntityButton kind='experiment' entity={experiment} />}
				<h4>Steps</h4>
				{
					experiment.process_steps.map((s, i) => <StepData key={`${i}-${s.id}`} params={true} substrate={substrate} experiment={experiment} step={s} />)
				}
			</section>
		}

		{
			experiment.materials && Object.keys(experiment.materials).length > 0 &&
			<section>
				<h4>Materials Used</h4>
				{Object.keys(experiment.materials).sort().map((k, i) => {
					const mat = experiment.materials[k]
					return <EntityButton key={`${i}-${k}`} kind={mat.subject} entity={mat} />
				})}
			</section>
		}
	</div>
}


export const ExperimentMaterials = ({substrate,experiment}) =>
{
	
	if (experiment.materials && Object.keys(experiment.materials).length > 0)
	{
		return	<section>
					<h4>Materials Used</h4>
					{Object.keys(experiment.materials).sort().map((k, i) => {
						const mat = experiment.materials[k]
						return <EntityButton key={`${i}-${k}`} kind={mat.subject} entity={mat} />
					})}
				</section>
	}
	else
		return null
}