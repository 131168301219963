import React, { useState, useRef } from 'react'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'

import { AppFrame } from '../app-frame/AppFrame'
import { ComponentMenuTop } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer } from '../app-frame/AppFrame'

import './Experiment.scss'

import {
	ExperimentCreateComponent,
	ExperimentEditComponent,
	ExperimentCloneComponent,
	ExperimentCloneTemplateComponent,
	ExperimentShowComponent
} from '../experiment/ExperimentEditor'




const initialFilter = {
	table: "boolean",
	id: "boolean",
	active: true,
	operator: "and",
	list: [
		{
			group: 	"Properties",
			table: "experiment",
			id: "display_title",
			value: "",
			active: true,
		}
	]
}



const initialColumns = 	[ "display_title", "mode", "description"]


export const ExperimentFrame = (props) => {

	const router = useRouter()
	const [fts, setFts] = useState("")
	const [display_mode, setDisplayMode] = useState( 'experiment')

	const { id } = router.params

	const [experiment, refetchComponent] = useBackendData(`experiment/get/${id}`, null)
	const viewRef = useRef()
	const refetch = (fetch_component) => 
	{

		console.log( "FC ", fetch_component )

		if (viewRef.current)
			viewRef.current.refetchData()
		if (refetchComponent && fetch_component)
			refetchComponent()
	}


	const setTableRow = (id) => {
		router.goto(`/experiment/show/${id}`, { focus: 'r', i: null })
	}


	const createExperiment = (_) => {
		router.goto(`/experiment/edit`, { focus: 'r', i: null })
	}

	const createTemplate = (_) => {
		router.goto(`/experiment/create-template`, { focus: 'r', i: null })
	}

	let component_type = null
	const location = router.location.pathname



	if 		(location.startsWith('/experiment/edit/') && id)
		component_type = 'edit'

	else if (location.startsWith('/experiment/edit') && !id)
		component_type = 'create'

	else if (location.startsWith('/experiment/clone-template') && id)
		component_type = 'clone-template'

	else if (location.startsWith('/experiment/clone') && id)
		component_type = 'clone'

	else if (location.startsWith('/experiment/create-template') && !id)
		component_type = 'create-template'
		
	else if (location.startsWith('/experiment/show'))
		component_type = 'show'

	const editing = !(component_type == 'show' || component_type == null)



	const showFilter = component_type == null || component_type == 'show'

	const filterWraapper = display_mode == 'experiment' ? null : 
								{filterId: 'experiment', mainTable: 'experiment', wrappedTable: 'substrate'}

	return <AppFrame search_bar={true} highlight='experiment' onSearchChange={setFts} component_type={component_type}>
				<ComponentMenuTop>
					<div className='filler' />
					<div className='main-section'>
						<div className='section'>
							<button onClick={createExperiment}>
								<span className='fa fa-wand-magic-sparkles' disabled={editing}  />
								&nbsp;
								Create Experiment...
							</button>
						</div>
					</div>

					<div className='main-section'>
						<div className='section'>
							<button onClick={createTemplate} disabled={editing}>
								<span className='fa fa-wand-magic-sparkles' />
								&nbsp;
								Create Template...
							</button>
						</div>
					</div>


					<div className='metadata-section'>
						<MetaDataMenu table='experiment' view={viewRef} refetch={refetch} />
					</div>

					{false && <div className='main-section left'>
						<div className='section'>
							<button className={`mode border-experiment ${display_mode == 'experiment' ? 'active' : 'inactive'} `} onClick={() => setDisplayMode('experiment')}>Experiments</button>
							<button className={`mode border-experiment ${display_mode == 'substrate'  ? 'active' : 'inactive'}`} onClick={ () => setDisplayMode('substrate')}>Substrates</button>
						</div>
					</div>}

				</ComponentMenuTop>

				<ComponentContainer className='l-r'>
					<ViewFrame ref={viewRef}
						table="experiment"
						viewTable={display_mode}
						filterWrapper={null}
						initialColumns={initialColumns}
						initialFilter={initialFilter}
						fts={fts}
						dataURL='experiment/get_filter'
						rowID={id}
						setTableRow={setTableRow}
						active={!editing}
						showFilter={showFilter} 
						dataDownloadAction='get_data_xlsx'>
						

						{
							component_type == 'show' && id &&
							<ExperimentShowComponent experiment={experiment} refetch={refetch} />
						}
						{
							component_type == 'edit' && id &&
							<ExperimentEditComponent experiment={experiment} refetch={refetch} />
						}
						{
							component_type == 'clone-template' && id &&
							<ExperimentCloneTemplateComponent experiment={experiment} refetch={refetch} />
						}						
						{
							component_type == 'clone' && id &&
							<ExperimentCloneComponent experiment={experiment}  refetch={refetch} />
						}
						{
							component_type == 'create' &&
							<ExperimentCreateComponent experiment={experiment} refetch={refetch} template={false} />
						}

						{
							component_type == 'create-template' &&
							<ExperimentCreateComponent experiment={experiment} refetch={refetch} template={true}/>
						}
					</ViewFrame>
				</ComponentContainer>
			</AppFrame>
}


