import React from 'react'
import { useRouter } from '../hooks/useRouter'
import { ComponentPanelRight } from '../app-frame/AppFrame'
import { submitForm } from '../hooks/useDownloaded'

import { MetaData } from '../metadata/MetaData'


import {
    Form, TextBox,
    SubmitButton,
    formLine, displayLineAlways, displayLineValue
} from '../form/Form'




export const SubstrateKindEditForm = ({ orig_id, substrate_kind, refetch }) => 
{
    const router = useRouter()

    const result_url = () => {
        let url
        if (substrate_kind.id)
            url = `/substrate-kind/show/${substrate_kind.id}`
        else if (orig_id)
            url = `/substrate-kind/show/${orig_id}`
        else
            url = "/substrate-kind"

        return url
    }

    const onCancel = (e) => {
        router.goto(result_url(), { focus: 'l' })
    }

    const submitChanges = async (e, data) => {

        const url = substrate_kind.id ? `substrate_kind/save/${substrate_kind.id}`
                                     : `substrate_kind/save`

        const result = await submitForm(url, data)

        console.log( data )

        if (refetch)
            refetch()

        if (result.data && result.data.success && result.data.id)
            router.goto(`/substrate-kind/show/${result.data.id}`, { focus: 'r', i: null })
        else
            onCancel()
    }

    const errors = {}


    if (!substrate_kind) {
        return  <ComponentPanelRight className='wide substrate_kind-details'>
                    <div className='head'>
                        <div className='title'>Edit</div>
                    </div>
                    <div className='body'>
                    </div>
                </ComponentPanelRight>
    }

    return <ComponentPanelRight className='wide substrate_kind-details'>
                <Form className='narrow form table'
                    data={substrate_kind}
                    errors={errors} onSubmit={submitChanges} >
                    <div className='head'>
                        <div className='title'>Edit {substrate_kind ? substrate_kind.id : ''}</div>
                    </div>
                    <div className='body'>
                        <div className='form-contents'>
                            <div className='formtable'>
                                {formLine('ID',                 <TextBox name='title' max_length={6} min_length={1} required={true} />)}
                                {formLine('Next Substrate ID',  <TextBox name='next_id'  type='numeric' min={1} unit='mm' />)}
                                {formLine('Cells',  <TextBox name='cells' required={true} type='numeric' min={1} unit='mm' />)}
                                {formLine('Width',  <TextBox name='xsize' required={true} type='numeric' min={1} unit='mm' />)}
                                {formLine('Length', <TextBox name='ysize' required={true} type='numeric' min={1} unit='mm' />)}
                                {formLine('Comment', <TextBox name='comment'  type='textarea'  />)}
                            </div>
                            <div className='helptext'>
                                <b>Warning</b>
                                <p>Changing the <i>Next Substrate ID</i> field will change the IDs of all substrates assigned with this type in the future.</p>
                                <p>This cannot be reverted, please be careful when changing this parameter.</p>
                                <p>Leave this parameter blank for make sure no changes occur.</p>
                            </div>                            
                        </div>
                        <div className='form-actions'>
                            <SubmitButton>Save</SubmitButton>
                            <button onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ComponentPanelRight>
}


export const SubstrateKindEditComponent = ({ substrate_kind, refetch }) => 
{
    return <SubstrateKindEditForm substrate_kind={substrate_kind} refetch={refetch} />
}


export const SubstrateKindCreateComponent = ({ refetch, substratekind }) => 
{
    return <SubstrateKindEditForm orig_id={substratekind?.id} substrate_kind={{}} refetch={refetch} />
}



export const SubstrateKind = ({ refetch, id }) => {

    return <SubstrateKindEditForm orig_id={id} substrate_kind={{}} refetch={refetch} />

}

// <button onClick={cloneThis(db, id)}>Create Copy</button>





const SubstrateKindShow = ({ substrate_kind, refetch }) => {
    if (!substrate_kind)
        return null

    const nextIDN =  `0000000000${substrate_kind.next_id}`.slice( -6 )
    const nextID   = `${substrate_kind.title}${nextIDN}`

    return <div className = 'body'>
                <MetaData key={`C-${substrate_kind.id}`} 
                    indicator='substrate'
                     document={substrate_kind} refetch={refetch} />

                <div className='details'>
                    <table className='document-show'>
                        <tbody>
                            {displayLineAlways(substrate_kind, 'Name',  'title')}
                            {displayLineValue(nextID, 'Next Substrate ID')}
                            {displayLineValue('', '')}
                            {displayLineAlways(substrate_kind, 'Cells', 'cells')}
                            {displayLineAlways(substrate_kind, 'Width', 'xsize')}
                            {displayLineAlways(substrate_kind, 'Length','ysize')}
                            {displayLineAlways(substrate_kind, 'Comment', 'comment')}
                            
                        </tbody>
                    </table>
                </div>
            </div>
}


export const SubstrateKindShowComponent = ({ substrate_kind, refetch }) => 
{
    if (!substrate_kind)
        return null

    const router = useRouter()

    const editSubstrate = (e) => 
    {
        console.log( "")
        router.goto(`/substrate-kind/edit/${substrate_kind.id}`, { focus: 'r', i: null })
    }

    const closePanel = (e) => {
        router.goto(`/substrate-kind`, { focus: "l", i: null })
    }

    return <ComponentPanelRight className='substrate_kind-details'>
                <div className='head'>
                    <div className='title'></div>
                    <div className='right button' onClick={closePanel}>
                        <span className='close-button'>✕</span>
                    </div>
                </div>
            
                <SubstrateKindShow substrate_kind={substrate_kind} refetch={refetch} />

                <div className='form-actions'>
                    <button onClick={editSubstrate}>Edit</button>
                </div>

            </ComponentPanelRight>
}

