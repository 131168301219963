import React, { Component, Fragment, useContext, useRef } from 'react'
import { useState } from 'react'

import './WorkspaceEditor.scss'

import { allProcessTypes, processTemplates } from '../model/process.js'
import { TableEditor } from '../table/TableEditor'
import { AppFrame } from '../app-frame/AppFrame'
import {
  ComponentContainer, ComponentMenuTop,
  ComponentPanelBlank, ComponentPanelFill, ComponentPanelContainerExpand,
  ComponentPanelArrow
} from '../app-frame/AppFrame'

import { GUIStateContext } from '../app/GUIContext'
import { WorkspaceController } from './workspaceController'
import { ControlledComponent } from '../controller/guiController'


import { ProcessEditorComponent } from '../process-form/ProcessEditor'


import { useRouter } from '../hooks/useRouter'
import { StepComponent } from './StepComponent'
import { StepTemplateComponent } from './StepTemplateComponent'
import { SubstrateSelector } from '../substrate/SubstrateFrame'
import { MaterialSelector } from '../material/MaterialSelector'
import { InfoBoxShow, InfoBoxTabList }            from '../info/InfoBox'


import { useDrop } from 'react-dnd'
import '../styles/Dock.scss'
import { moveTab, splitTab, setFocusedExperiment, removeTab, selectableSibling } from '../dock/dock'
import { isDragSource}   from '../dnd/isDragSource'

import { DownloadWorkspaceExperiment } from '../download/Download'

const DockTab = ({ parent_dock, current_tab, tab_index, id, select_experiment, experiments, has_separator }) => 
{
  const experiment = experiments[parseInt(id, 10)]

  const { controller } = useContext(GUIStateContext)
  const { focused_experiment_id } = controller

  const [_, dragRef] = isDragSource({
      type:       'MATERIAL',
      name:       experiment.title,
      indicator:  experiment.indicator,
      item:       { kind: "experiment",  id: id, parent_dock_id: parent_dock.id  }
  })

  if (!experiment)
    return <div className={`tab`}>Experiment</div>

  const remove = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const fid = controller.getFocusedExperimentID()
    const si  = selectableSibling(controller.layout, id)
    controller.transformLayout(node => removeTab(node, id))

    if (fid == id && fid != null)
      controller.switchToNewFocusedExperiment( si )
  }

  const select = () => select_experiment(id)
  const clazz1 = id == current_tab ? `selected exp` : ''
  const clazz2 = focused_experiment_id == id ? `focused` : `unfocused`

  const clazzbg = `${experiment.indicator}-bg`

  return <div className='tab-section'>
            <div className={`tab experiment-tab ${clazz1} ${clazz2} ${clazzbg}`} onClick={select} ref={dragRef}>
              <div className='content'>{experiment.title}</div>
              <div className='icon' onClick={remove}><span className='icon  field-clear-button'>✕</span></div>
            </div>
            <div className={`separator ${has_separator ? 'show' : 'hide'}`}>&nbsp;</div>
          </div>
}


const DockTabs = ({ parent_dock, experiment_ids, current_tab, select_experiment, experiments }) => 
{
  const { controller } = useContext(GUIStateContext)
  const { focused_experiment_id } = controller


  const [{ isOver }, dropRef] = useDrop(() => 
  ({
    accept: ['MATERIAL', 'EXP-TEMPLATE'],
    collect: (monitor) => ({ isOver: monitor.isOver()}),
    drop: ({ item }, monitor) => 
    {
      const itemType = monitor.getItemType()
      if (itemType == 'MATERIAL' && item.subject?.value == 'solution')
        controller.transformLayout(l => moveTab(l, item?.id?.value, parent_dock.id))
      if (itemType == 'EXP-TEMPLATE')
        controller.transformLayout(l => moveTab(l, item?.id, parent_dock.id))
    }
  }))


  const el = experiment_ids.length
  const tablist = experiment_ids.map((id, n) => {
    const this_experiment = experiments[experiment_ids[n]]
    const next_experiment = experiments[experiment_ids[n + 1]]

    if (!this_experiment)
      return null

    const has_separator = n < el - 1 && next_experiment
      && `${next_experiment.id}` !== focused_experiment_id
      && `${this_experiment.id}` !== focused_experiment_id

    return <DockTab id={id} tab_index={n}
                    key={`et-${id}-${n}`}
                    parent_dock={parent_dock}
                    experiments={experiments}
                    current_tab={current_tab}
                    has_separator={has_separator}
                    select_experiment={select_experiment} />
  })

    return  <div className = {`${isOver ? 'drag-over': ''} tab-row`} ref = { dropRef } >
              {tablist}
            </div>
}




const ExperimentDock = ({ layout }) => {
  const { main, ids = [] } = layout

  const { controller } = useContext(GUIStateContext)

  controller.addExperiments(ids)

  const { focusedExperiment } = controller

  const experiment = focusedExperiment ? focusedExperiment() : null

  const focused_id = experiment?.id //layout.selected_id

  const select_experiment = (id) => 
  {
    controller.switchToNewFocusedExperiment(id, false)
    controller.transformLayout( node => setFocusedExperiment( node, id ) )
  }

/*  const [_, dropRef] = useDrop(() => (
  {
    accept: ['MATERIAL'],
    drop: (dragData) => controller.addMaterial(dragData.item, focused_id)
  }))  */

  return  <div className='tab-dock'>
            <DockTabs experiment_ids={ids}
                  parent_dock={layout}
                  experiments={controller.experiment_store}
                  current_tab={focused_id}
                  select_experiment={select_experiment} />
            <div className='content'>
              {ids.length == 0 &&
                <div className='helptext'>
                  <div className='title'>The Workspace is currently empty</div>
                  <div><a href="/experiment">Add an experiment to the workspace</a></div>
                  <div><a href="/solution">Add a solution to the workspace</a></div>
                </div>}
              {ids.length > 0 && focused_id &&
                <TableEditor key={`te-${focused_id}`}
                             experiment_id={focused_id} />}

              {ids.length > 0 && !focused_id &&
                <div className='helptext'>Experiment has been closed</div>}
            </div>
        </div>
}


const DockStackVertical = ({ layout }) => {
  const { parts } = layout

  const components = []
  const parts_used = (parts || [])
  parts_used.forEach((s, i) => {
    components.push(<div key={`dv-${s.id}`} className="vertical-component">
      <DockElement key={`dve-${s.id}`}layout={s} />
    </div>)
  })

  return <div className="docklayout vertical">
    {components}
  </div>

}



const DockStackHorizontal = ({ layout }) => {
  const { parts } = layout

  const components = []
  const parts_used = (parts || [])
  parts_used.forEach((s, i) => {
    components.push(<div key={`dh-${s.id}`} className="horizontal-component">
      <DockElement key={`dhe-${s.id}`} layout={s} />
    </div>)
  })

  return <div className="docklayout horizontal">
    {components}
  </div>

}




const DockSplitTarget = ({ active, parent, kind }) => {
  const { controller } = useContext(GUIStateContext)

  const [{isOver}, dropRef] = useDrop(() => (
    {
      accept: ['MATERIAL','EXP-TEMPLATE'],
      collect: (monitor) => ({ isOver: monitor.isOver() }),
      drop: ({item}) => controller.transformLayout(l => splitTab(l, item.id, parent.id, kind))
    }))


  const hover_class   = isOver ? 'hover'  : ''
  const active_class  = active ? 'active' : ''

  return <div className={`dock-split-droptarget ${kind} ${hover_class} ${active_class}`} ref={dropRef}>&nbsp;</div>
}


const DockPanel = ({ layout }) => 
{
  const { controller } = useContext(GUIStateContext)
  if (!controller || !controller.experiment_store)
    return <ComponentPanelBlank />

  const { focusedExperiment } = controller

  const experiment = focusedExperiment ? focusedExperiment() : null

  const [{isOver }, dropRef] = useDrop(() => ({
    accept: ['MATERIAL', 'EXP-TEMPLATE'],
    collect: (monitor) => ({ isOver: monitor.isOver()})
  }))

  return <ComponentPanelFill className='dockpanel'>
            <div className='body dock' ref={dropRef}>
              <ExperimentDock layout={layout} />
              <DockSplitTarget key={`dsplit-${layout.id}-b`} active={isOver} parent={layout} kind='bottom' />
              <DockSplitTarget key={`dsplit-${layout.id}-l`} active={isOver} parent={layout} kind='left' />
              <DockSplitTarget key={`dsplit-${layout.id}-r`} active={isOver} parent={layout} kind='right' />
            </div>
          </ComponentPanelFill>
}


const DockRoot = ({ layout }) => 
{
  const { parts } = layout
  return  <ComponentPanelContainerExpand className='dock'>
            {parts && parts.length > 0 && <DockElement layout={parts[0]} />}
          </ComponentPanelContainerExpand>
}


const DockElement = ({ layout }) => 
{
  const { kind, ...rest } = layout

  if (kind == 'dock')         return <DockPanel layout={rest} />
  if (kind == 'vertical')     return <DockStackVertical layout={rest} />
  if (kind == 'horizontal')   return <DockStackHorizontal layout={rest} />
  if (kind == 'root')         return <DockRoot layout={rest} />

  return <div />
}



const WorkspaceEditorAppFrame = ({selected_id}) => 
{
  const router = useRouter()
  const { controller } = useContext(GUIStateContext)

  const experiment = controller.focusedExperiment( selected_id )
  
  const exp_subject = experiment ? experiment.subject : ''

  const hasSteps = experiment && experiment.step_ids?.length > 0

  const stp_def     = hasSteps ? 'stp' : 'stpedit'

  const toggleClosed = () =>
  {
      if (closed && mode_lflag != 'off')
        router.setFlag({ closed: 'false', focus: 'l' })
      else if (closed)
        router.setFlag({ closed: 'false', mode_lft: stp_def, focus: 'l' })
      else
        router.setFlag( {closed: 'true'} )      
  }

  const setModeL = (s) => () => 
  {
    if (mode_lft == s)
      router.setFlag({ closed: 'true', mode_lft: "off",  focus: 'l' })
    else
      router.setFlag({ closed: 'false', mode_lft: s,      focus: 'l' })
  }

  const setModeR = (s) => () => 
  {
    if      (info_flag && s == 'inf')
    {}

    else if (info_flag && s == 'off')
      router.setFlag({ mode_rgt: "off", focus: 'l', i: null })

    else if (info_flag && s == 'prm')
      router.setFlag({ mode_rgt: "prm", focus: 'r', i: null, fe: false, fo: false })

    else if (mode_rgt == s)
      router.setFlag({ mode_rgt: null,  focus: 'l', i: null })

    else
      router.setFlag({ mode_rgt: s,      focus: 'r', i: null, fe: false, fo: false })
  }





  const info_flag = router.hasInfoBox()

  const mode_lflag  = router.param("mode_lft", 'off')
  let   mode_rgt    = info_flag ? 'inf' : router.param("mode_rgt", 'off')


  const focus_lft   = router.param("focus", 'l') == 'l'

  const tim_flag  = router.flag("tim", null)
  const fe_flag   = router.flag("fe", false)
  const fo_flag   = router.flag("fo", false)

  const closed    = router.flag("closed", mode_lflag == 'off')
  const mode_lft  = closed ? "off" : mode_lflag

  if (fe_flag || fo_flag)
    mode_rgt = 'off'

  const is_wide    = ( fe_flag) && (mode_lft == 'mat' || mode_lft == 'sub')


  controller.subSelectorRef = useRef()
  const substrateDragDescriptor = (row) => ({ type:     'SUBSTRATE', 
                                              name:     row.data?.title?.value || '', 
                                              multiple: true })    
  const substrateSelect = (id, data) =>  controller.setFocusedObject({ kind: 'substrate', id: id })

  

  const subTailClick = async (row) => 
  {
    if (experiment) 
    {
      await controller.assignSubstrateToExperiment(experiment, row)
    }
  }
  


  controller.materialSelectorRef = useRef()
  const materialDragDescriptor  = (row)       => ( {type: 'MATERIAL',   
                                                    name: row.data?.display_title?.value || ''})
  const materialTailClick       = (id, data)  => controller.addMaterial(data, null)
  const materialSelect          = (id, data)  => 
  {
    if (!data?.kind?.value)
      {}    // nop
    else if (data.kind.value == 'Solution')
      controller.setFocusedObject(  {kind: 'solution', id: id} )
    else if (data.kind.value == 'Chemical')
      controller.setFocusedObject( {kind: 'chemical', id: id })
  }

  
  const focusedKind     = controller.focusedKind()
  let   placeholder     = null
  if (mode_lft == 'sub')
    placeholder = 'Search Substrates...'
  if (mode_lft == 'mat')
    placeholder = 'Search Materials...'

  const layout = controller.getLayout()

  const [fts,set_fts]     = useState( null )
  const startFts = (q) => set_fts(q)

  const has_search_bar = mode_lft == 'sub' || mode_lft == 'mat'

  const searchTerm = !fts || `${fts}`.trim().length == 0 ? null : fts
  const sub_fts = mode_lft == 'sub' ? searchTerm : null
  const mat_fts = mode_lft == 'mat' ? searchTerm : null

  const layout_flag   = mode_rgt && mode_rgt != 'off' ? 'infobox' : ''

  // This was removed:  key={flag_status}
  return <AppFrame  title='Workspace'
                    highlight='workspace' 
                    search_bar={true} 
                    search_bar_active={has_search_bar} 
                    onSearchChange={startFts} 
                    placeholder={placeholder}
                    layout_flag={layout_flag}>
            <ComponentMenuTop>
              <div className='icon-section'>
                <div className='section button-group' >
                  <div onClick={setModeL('mat')} className={`indicator ${mode_lft == 'mat'}`}>&nbsp;</div>
                  <button className={`${mode_lft == 'mat'} indicator-label`} onClick={setModeL('mat')}>Materials</button>

                  <div onClick={setModeL('sub')} className={`indicator ${mode_lft == 'sub'}`}>&nbsp;</div>
                  <button className={`${mode_lft == 'sub'} indicator-label`} onClick={setModeL('sub')}>Substrates</button>

                  <div onClick={setModeL('stp')} className={`indicator ${mode_lft == 'stp' || mode_lft=='stpedit'}`}>&nbsp;</div>
                  <button className={`${mode_lft == 'stp'} indicator-label`} onClick={setModeL('stp')}>Process Steps</button>

                  <div style={{width: '5em'}}>
                    &nbsp;
                  </div>

                  <div onClick={setModeR('prm')} className={`indicator ${mode_rgt == 'prm'}`}>&nbsp;</div>
                  <button className={`${mode_rgt == 'prm'} indicator-label`} onClick={setModeR('prm')}>Parameters</button>

                  <div onClick={setModeR('inf')} className={`indicator ${mode_rgt == 'inf'}`}>&nbsp;</div>
                  <button className={`${mode_rgt == 'inf'} indicator-label`} onClick={setModeR('inf')}>Info</button>

            <div style={{ width: '2em' }}>
              &nbsp;
            </div>


                  {experiment && <DownloadWorkspaceExperiment experiment={experiment} />}
                </div>

              </div>
            </ComponentMenuTop>
            <ComponentContainer className='l-r stretch'>
              {mode_lft == 'sub' && <SubstrateSelector
                                                    ref={controller.subSelectorRef}
                                                    fts={sub_fts}
                                                    active={exp_subject == 'experiment'}
                                                    focused={focusedKind == 'substrate'}
                                                    dragItemDescriptor={substrateDragDescriptor}

                                                    tailClick={true}
                                                    
                                                    trailClick={(row) => {
                                                        const experiments = row?.object?.experiments || []
                                                        return experiments.length == 0
                                                    }}
                                                    onSubstrateSelect={substrateSelect}
                                                    onTailClick={subTailClick} />}

              {mode_lft == 'stpedit' && <StepTemplateComponent />}

              {mode_lft == 'mat' && <MaterialSelector
                                        focused={focusedKind == 'solution' || focusedKind == 'chemical'}
                                        fts={mat_fts}
                                        ref={controller.materialSelectorRef}
                                        experiment={experiment}
                                        onMaterialSelect={materialSelect}
                                        dragItemDescriptor={materialDragDescriptor}
                                        tailClick={true} 
                                        onTailClick={materialTailClick}
              />}
              
             {(mode_lft == 'stp' || mode_lft == 'stpedit') && <StepComponent />}



              <DockElement layout={layout} />

              {!info_flag && mode_rgt == 'prm' && !is_wide && <ProcessEditorComponent
                                                                        version={experiment?.serial}
                                                                        onClose={setModeR('off')} />}

              {!info_flag && mode_rgt == 'inf' && !is_wide && <InfoBoxShow controller={controller} 
                                                                onClose={setModeR('off')}/>}
              { info_flag && mode_rgt == 'inf' && !is_wide && <InfoBoxTabList refetch={() => {}} />}              
            </ComponentContainer>
          </AppFrame>
}






export const WorkspaceEditorFrame = (props) => 
{
  const router = useRouter()
  const { id } = router.params



  const [controller, set_controller] = useState(null)
  

  if (!controller) {
    set_controller(new WorkspaceController(router))
    return <div />
  }
  else
  {
    if (id)
      controller.setFocusedExperiment( id, false )
  }



  return  <ControlledComponent key='ctrl' controller={controller}>
            <WorkspaceEditorAppFrame key='aframe' selected_id={id} />
          </ControlledComponent>
}



//<ComponentPanelArrow onClick={toggleClosed}>
//  {mode_lft == 'off' &&
//    <div className='button fa fa-caret-right hideshow' />}
//  {mode_lft !== 'off' &&
//    <div className='button fa fa-caret-left hideshow' />}
//</ComponentPanelArrow>

