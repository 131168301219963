import React, { Component, Fragment, useContext } from 'react'
import { ComponentPanelLeft } from '../app-frame/AppFrame'
import { GUIStateContext } from '../app/GUIContext'
import { DBContext } from '../app/DBContext'

import { useBackendData } from '../hooks/useDownloaded'

import { isDragSource } from '../dnd/isDragSource'

export const StepTemplateButton = ({ process }) => {
    const { controller } = useContext(GUIStateContext)


    const addStep = (e) => {
        controller.insertStep(process.id)
    }

    const [{ isDragging, item }, dragRef] = isDragSource({
        type: 'STEP-TEMPLATE',
        name: `${process?.title || 'STEP'}`,
        indicator: 'step',
        item: () => process
    })

    return  <div className='button-container' onDoubleClick={addStep} ref={dragRef}>
                <div  className='button step-button'>
                    <div className='fa-regular fa-grip-vertical drag-grip step-bg'/>
                    <div className='title'>{process.title}</div>
                    <div className='arrow' onClick={addStep}>
                        <span className='fa-regular fa-angle-right' />
                    </div>
                </div>
            </div>
}



export const ExpTemplateButton = ({ template }) => {
    const { controller } = useContext(GUIStateContext)

    const addStep = (e) => {
        controller.insertTemplate(template.id)
    }

    const indicator = template.subject == 'solution' ? 'stemplate' : 'template'

    const [{ isDragging, item }, dragRef] = isDragSource({
        type: 'EXP-TEMPLATE',
        name: `${template.title}`,
        indicator,
        item: () => template
    })


    return  <div className='button-container' onDoubleClick={addStep} ref={dragRef}>
                <div className='button step-button'>
                    <div className={`fa fa-grip-vertical drag-grip ${indicator}-bg`}/>
                    <div className='title'>{template.title}</div>
                    <div className='arrow' onClick={addStep}>
                        <span className='fa-regular fa-angle-right' />
                    </div>
                </div>
            </div>
        }




const addTemplateToGroup = (groups, group_list, template) => {
    let gname = template.group_name
    if (gname == null)
        gname = ''

    let tgroup = groups[gname]
    if (!groups[gname]) 
    {
        tgroup =
        {
            title: gname,
            elements: []
        }

        groups[gname] = tgroup
        group_list.push(tgroup)
    }

    tgroup.elements.push(template)
}


const makeTemplateGroups = ( processes, template_ids ) => 
{
    if (template_ids    == null)
        template_ids    = []

    const grp = { title: '', elements: [] }
    const groups = { '': grp }
    const group_list    = [grp]

    template_ids.forEach( id => 
    {
        const process = processes.allProcessTemplates[id]
        addTemplateToGroup( groups, group_list, process )
    })

    return {groups, group_list}
}


const TemplateGroup = ({group}) =>
{
    const body = []
    const sorted = group.elements.sort( (a,b) => 
    {
        const nameA = `${a.name.toUpperCase()}`
        const nameB = `${b.name.toUpperCase()}`
        if (nameA < nameB) 
            return -1;
        
        if (nameA > nameB) 
            return 1;
        
        return 0;
    })
    sorted.forEach((process) =>
        body.push(<StepTemplateButton key={`${group.title}-${process.id}`} process={process} />))

    return (<div className='button-container-group'>
                    {group.title != '' &&
                        <div className='title'>{group.title}</div>}
                    <div className='body'>{body}</div>
                </div>)

}

export const StepTemplateComponent = () => 
{
    const { controller } = useContext(GUIStateContext)
    const [result, refetch] = useBackendData(`/experiment/all_pinned_templates`, [])

    const experiment = controller.focusedExperiment()

    const template_list = experiment && result ? result.filter(x => x.subject == experiment?.subject) : []

    const processes = useContext(DBContext)?.processes
    if (!processes)
        return <div />

    const subject = experiment ? experiment.subject : '-NONE-'
    const all_types = processes.allProcessTypesID[subject]

    const {groups, group_list}  = makeTemplateGroups( processes, all_types )


    const list = []
    if (all_types !== null)
        group_list.forEach((group,i) => list.push( <TemplateGroup key={`TG=${i}`} group={group} />))

    return <ComponentPanelLeft className={`process-list `} key={`${experiment ? experiment.id : 'E'}-${subject}`}>
        <div className='head'>Available Steps</div>
        <div className='body'>
            {
                list.length == 0 && <div className='helptext'>Select an Experiment or Solution</div>
            }
            {list.length > 0 && list}
        </div>

        {template_list && template_list.length > 0 && 
            <div className='head'>Available Templates</div>
        }
        {
            template_list &&
                <div className='body'>
                    {template_list.map((tpl) =>
                        <ExpTemplateButton key={tpl.id} template={tpl}/>)}
                </div>
        }
    </ComponentPanelLeft>
}

