import React from 'react'

import { useBackendData } from './useDownloaded'
import { Select} 			from "../form/Form"


export function usePersons() 
{
	const [persons, refetch, loading] = useBackendData(`person/selection_list`, [])
	return [persons, refetch, loading]
}



export function usePersonSelection(allow_blank = false, all_option = null) 
{

	const [persons, refetch, loading] = useBackendData(`person/selection_list`, [])
	if (!persons || loading)
		return []

	const options = persons.sort( (x,y) => "#{x}".toUpperCase - "#{y}".toUpperCase() )
						 .map( p => {
							 			let ln 		= `${p.lastname}`
										let fn 		= `${p.firstname}`
										let initial = `${p.firstname} `[0]

							 			let label = `${p.lastname}, ${p.firstname}`
										if (label.length > 32)
											label = `${p.lastname}, ${initial}`
										if (label.length > 32)
											label = `${p.lastname}`
										if (label.length > 32)
											label = label.substring( 32 )
										return 	{	
													value : p.id,
													label : label
												}	
									})
	if (allow_blank)
	{
		const extra_option = all_option || { value: -1, label: '--- All ---' }
		return [[extra_option].concat( options ), refetch, loading]
	}
	else
		return [options, refetch, loading]
}
