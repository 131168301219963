import React, { Fragment, Component } 			from 'react'

import { FOCUS_ARROWS,  Form, FormComponent, Error } 	from '../form/Form'
import { ActionButton, SubmitButton } 			from '../form/Form'
import { DateTime, CheckBox, ClearButton } 		from '../form/Form'

import { renderProcessFormEdit } 				from '../schema/StepKinds'
import { formatTimeStamp } 						from '../utils/utils'

import { ComponentPanelRight } from '../app-frame/AppFrame'
import { GUIStateContext } from '../app/GUIContext'



const VALUE_EQUAL 			= 1
const VALUE_INEQUAL 		= 0
const VALUE_PARTIAL_EQUAL 	= -1

export class ProcessEditorComponent extends Component
{
	static contextType = GUIStateContext

	constructor( props )
	{
		super(props)
		this.state = {count: 0}
	}

	getTitle()
	{
		const { controller } = this.context
		if (!controller)
			return ""
		const gui_controller = controller ? controller.gui_controller : null
		if (!gui_controller || !gui_controller.gui_state)
			return ""

		const { selection } = gui_controller?.gui_state
		if (!selection || gui_controller.gui_state.multi_step_focus)
			return "Parameters"

		const { focused_step } = selection ?? {}
		if (!focused_step)
			return "Parameters"

		const exp = controller?.getFocusedExperiment()
		const step = exp?.getStep(focused_step)

		if (!step)
		 	return "Parameters"

		const n = exp.getStepCounter(step)

		const template = exp.getStepTemplate( step )

		return `${template?.title} ${n}`
	}

	render()
	{
		const { controller } = this.context
		const gui_controller = controller ? controller.gui_controller : null

		const title = this.getTitle()

		const empty = !gui_controller || !controller //|| !controller.focused_experiment
			return <ComponentPanelRight key='blank' className={`wide process-details ${open ? 'open' : 'closed'}`}>
						<div className='head'>
							<div className='title'>{title}</div>
							<div className='right button' onClick={this.props.onClose}>
								<span className='close-button'>✕</span>
							</div>
						</div>
						<div className='body'>
							{empty && 	<div className='description'>
											Select cells to edit the parameter values
										</div>}
							{!empty && <ProcessEditor 	key='processeditor' 
														version={this.props.version} 
														controller={gui_controller}/>}
						</div>
					</ComponentPanelRight>
	}
}


const setCompleteNow = (controller,experiment) => (e, form) => 
{
	const seconds = Math.floor((new Date()).getTime() / 1000)

	form.setFieldValues([
		{ name: "completed", value: true, raw: 'true' },
		{ name: "completed_at", value: seconds, raw: `${seconds}` }
	])

	controller.startBatchUpdate( experiment.id )
}

const setIncomplete = (e, form) => {
	const seconds = Math.floor((new Date()).getTime() / 1000)

	form.setFieldValues([
		{ name: "completed", 	value: false, 	raw: 'false' },
		{ name: "completed_at", value: null, 	raw: `` }
	])
}



const get_completion_status_of_selection = ( selection, block_list, experiment, data ) =>
{
	let all_complete = false
	let all_incomplete = false
	let all_skipped 	= false
	let all_not_skipped 	= false
	let all_placeholder 	= true

	const { multi_step_focus, focused_step } = selection

	let completed_at 	= null
	let completed_by 	= []
	let all_required 	= true
	let has_data 		= false

	if (!multi_step_focus) 
	{
		all_complete 	= true
		all_incomplete 	= true

		all_skipped 	= true

		all_not_skipped = true

		for( let block of block_list)
		{
			for (let substrate of block.selected_batches) 
			{
				const experiment_data = experiment.getProcessData(substrate, focused_step)
				const ca = experiment_data ?  experiment_data.completed_at : null
				
				if (substrate.kind != null)
					all_placeholder = false

				if (ca != null)
				{
					if 		(completed_at == null)
						completed_at = ca
					else if (ca !== completed_at)
						completed_at = -1
				}
				
				if (experiment_data && experiment_data.completed)
					all_incomplete = false
				else if (experiment_data && !experiment_data.completed)
					all_complete = false
				else if (!experiment_data)
					all_complete = false

				if 		(experiment_data && experiment_data.skipped)
					all_not_skipped = false
				else if (experiment_data && !experiment_data.skipped || !experiment_data)
					all_skipped = false

				if (experiment_data && !experiment_data._all_required)
					all_required = false

				if (experiment_data &&  experiment_data._has_data)
					has_data = true
			}
		}
	}

	const rv =  {
					all_complete, all_incomplete, completed_by,  
					all_skipped, all_not_skipped,
					all_placeholder, 
					all_required, has_data 
				}
	if (completed_at !== null && completed_at > 0)
	{
		rv.completed_at 			= completed_at
		rv.same_completion_date 	= true
	}
	else
	{
		rv.completed_at 			= null
		rv.same_completion_date 	= false
	}

	return rv
}



class ActiveBlock extends FormComponent
{
	constructor(props) {
		super(props)
	}

	render() {
		const { experiment } = this.props
		const { form_spec } = this.props
		const { controller } = this.props
		const { selection, block_list } = controller.gui_controller.gui_state
		const data = this.context.form.getData()

		const { all_skipped, all_not_skipped, all_incomplete, all_required, has_data }
			= get_completion_status_of_selection(selection, block_list, experiment, data)


		if (has_data)
			return null

		return <Fragment>
			{
				experiment && form_spec && !experiment.planning &&
				<div key={`${experiment.serial}-AC`} className='line meta-information'>
					<div className='field-name'>
							{!has_data &&  all_skipped && !all_not_skipped && <span>Skipped</span>}
							{!has_data && !all_skipped &&  all_not_skipped && <span>Skipped</span>}
					</div>
					<div className='control'>
							{!has_data &&  all_skipped && !all_not_skipped && <span><CheckBox liveSubmit={false} name='all_skipped'/></span>}
							{!has_data && !all_skipped && all_not_skipped && <span><CheckBox liveSubmit={false} name='all_not_skipped'/></span>}
					</div>					
					<div className='unit'>
					</div>
				</div>
			}
			<div key={`${experiment.serial}-AC`} className='line'>
				<div className='field-name'>&nbsp;</div>
				<div className='control'>&nbsp;</div>
				<div className='unit'>&nbsp;</div>
			</div>			
		</Fragment>
	}
}

class CompletionBlock extends FormComponent
{
	constructor(props) {
		super(props)
	}

	render() 
	{
		const { controller } = this.props
		const { experiment } = this.props
		const { form_spec }  = this.props
		const { selection, block_list } = controller.gui_controller.gui_state

		const data = this.context.form.getData()

		const { all_complete, all_incomplete, completed_at, same_completion_date, completed_by, all_placeholder,
				all_required}  = get_completion_status_of_selection( selection, block_list, experiment, data )

		const show_msg_different = all_complete && !same_completion_date
		const show_msg_date 	 = all_complete &&  same_completion_date
		const show_date_control = !show_msg_different && !show_msg_date

		const can_be_completed 	= experiment && !experiment.planning && !experiment.template

		if (all_placeholder)
			return null

		return 	<Fragment>
					<div className='line'>
						<div className='field-name'>&nbsp;</div>
						<div className='control'>&nbsp;</div>
						<div className='unit'>&nbsp;</div>
					</div>

					{
						can_be_completed && experiment && form_spec && !experiment.planning &&
		 			<div key={`${experiment.serial}-CPL1`} className='line meta-information'>
							<div className='field-name'>
								Completed At
							</div>
							<div className='control'>
								{show_date_control && <DateTime name='completed_at' required={true} />}
								{show_msg_date && <span>{formatTimeStamp( completed_at )}</span>}		

								{show_msg_different && <i>Completed at different times</i>}
								
							</div>
							<div className='unit'>&nbsp;</div>
							<div className='warning'>&nbsp;</div>
							<div className='clear-button'>&nbsp;</div>
						</div>
					}

					{
						can_be_completed && experiment && form_spec && !experiment.planning && completed_by && completed_by.length > 0 &&
						<div key={`${experiment.serial}-CPL2`} className='line meta-information'>
							<div className='field-name'>
								Completed By
							</div>
							<div className='control'>
							{
								completed_by.map( p => `${p.lastname}` ).join( ", ")
							}
							</div>
							<div className='unit'>&nbsp;</div>
							<div className='warning'>&nbsp;</div>
							<div className='clear-button'>&nbsp;</div>
						</div>
					}

					{
						can_be_completed && experiment && form_spec && !experiment.planning &&
						<div key={`${experiment.serial}-CPL3`} className='line meta-information'>
							<div className='field-name'>
								Completed
							</div>
							<div className='control'>
							{all_required  && !all_placeholder && 
									<CheckBox liveSubmit={false} name='completed' disableOnError={true} />}
							{!all_required && <i>Required Data Fields Missing</i>}
							</div>
							<div className='unit'>&nbsp;</div>
							<div className='warning'>&nbsp;</div>
							<div className='clear-button'>&nbsp;</div>
						</div>
					}

					{
						form_spec &&
						<div key={`${experiment.serial}-CPL`} className='line meta-information'>
								<div className='field-name'>&nbsp;</div>
								<div className='control'>
									<br/>

									<div className='meta-information info'>
										{experiment.planning &&
											<span>Experiment is in planning, completion cannot be set</span>}
										{experiment.template &&
											<span>Experiment is a template, completion cannot be set</span>}
										{!all_complete && !all_incomplete &&
											<span>Only incomplete steps will be saved.</span>}
										{ all_complete && !all_incomplete && 
											<span>All blocks marked as completed. Only completion status will be updated.</span>}
									</div>
								</div>
								<div className='unit'>&nbsp;</div>
								<div className='warning'>&nbsp;</div>
								<div className='clear-button'>&nbsp;</div>
							</div>
					}
				</Fragment>
	}
}




export class ProcessEditor extends Component 
{
	static contextType = GUIStateContext
	constructor(props) {
		super(props)
		this.form_errors = {}
		this.form_data = this.props.data

		this.state = { editmode: true, counter: 0  }
	}

	formErrors(form, data, raw_data, errors) {
	}


	getFormComponent(element, i, disable_entry, experiment) 
	{
		if (!element)
			return null

		const disabled 		= !this.state.editmode || disable_entry
		const formElement = renderProcessFormEdit(element, { disabled, submitOnEnter: !disabled, experiment, add_link: true })

		if (formElement)
			return formElement
		return null
	}



	renderForm(data, multi, form_spec, disabled,  experiment) 
	{
		if (!form_spec)
			return null
			
		const list = []
		form_spec.forEach((element, i) => 
		{
			const rendered = this.getFormComponent(element, i, disabled, experiment)

			let warning = data[element.id]?.warning
			if (!warning && multi[element.id])
				warning = "Multiple Values"

			if (rendered) {
				list.push(
				<div key={`line-${i}`}
					className='line'>
					<div className='field-name'>{element.title}</div>
					<div className={`control ${warning ? 'warning' : ''}`}>
						{rendered}
					</div>
					<div className='unit'>
						{element.unit}
					</div>
					<div className='error'>
						<Error name={element.id} errors={warning}/>
					</div>
					<div className='clear-button'>
					{!disabled && <ClearButton name={`${element.id}`} title={`Clear ${element.title}?`} />}
					</div>
				</div>)
			}
		})

		return list
	}

	count = 0

	updateProcessData(exp, batch, step, data, completed, completed_at, skipped) 
	{
		exp.updateProcessData(batch, step, data, completed, completed_at, skipped)
	}

	experiment = () => 
	{
		return this.props.controller.experiment()
	}

	submitFormOrLive = (event, live, data) => 
	{
		const { controller } 						= this.context
		const { selection, block_list } 			= controller.gui_controller.gui_state

		const { multi_step_focus, focused_step } 	= selection
		const exp = this.experiment()
		
		const {completed, completed_at} 			= data
		const {all_skipped, all_not_skipped} 		= data

		let skipped = undefined
		if (all_skipped == false)
			skipped = false
		else if (all_not_skipped == true)
			skipped = true


		if (multi_step_focus)
			return 				// We can't really be here in any case


		for (let block of block_list) 
		{
			if (block.selected_batches) 
			{
				for (let batch of block.selected_batches) 
				{
					const transfer = {}
					for( let param of block.focused_params)
					{
						let data_point = data[param.parameter.id]

						if (param.parameter.kind == 'resource' || param.parameter.kind == 'select')
							if (data_point == '')
								data_point = null

						if (event)
						{
							if (event.name == `${param.parameter.id}`)
							{
								transfer[param.parameter.id] = data_point
							}
						}
						else if (data_point !== undefined)
						{
							transfer[param.parameter.id] = data_point
						}
					}

					if (event && event.name == 'all_not_skipped')
					{
						skipped 				= event.value 
						transfer['skipped'] 	= event.value
					}

//					console.log( transfer )
					const already_complete = exp.getCompletion(batch, focused_step)
					if (!already_complete)
						this.updateProcessData(exp, batch, focused_step, transfer,
							completed, completed_at, skipped)
					else
						this.updateProcessData(exp, batch, focused_step, {},
							completed, completed_at, skipped)
				}
			}
		}
		controller.startBatchUpdate(this.experiment().id)

		if (live)
			controller.updateData()

	}

	submitForm 		= (event, data) => this.submitFormOrLive(event, false, data)
	liveSubmitForm 	= (event, data) => this.submitFormOrLive(event, true,  data)

	dateChange 		= (event) 	=> {console.log( "Date changed")}

//
//
//	toggle_active = () => {
//		const { controller } = this.context
//		const { selection } = controller.gui_state
//		const { focused_step, focused_batch } = selection
//		const { selected_batches, selected_step_ids } = selection
//		const { multi_step_focus, multi_batch_focus } = selection
//
//		const active = !selection.focused_active
//		const exp 	 = this.experiment()
//
//		if (!multi_batch_focus)
//			exp.setActive(focused_batch, focused_step, active)
//		else {
//			selected_step_ids.forEach(step_id => {
//				selected_batches.forEach(batch => {
//					exp.setActive(batch, step_id, active)
//				})
//			})
//		}
//
//		controller.updateSelection()
//	}


	checkequal( param, x,y )
	{
		const xn = x == null
		const yn = y == null
		if (xn && yn)
			return {eq:  VALUE_EQUAL, value_to_use: null}
		else if (xn)
			return { eq: VALUE_PARTIAL_EQUAL, value_to_use: y }
		else if (yn)
			return { eq: VALUE_PARTIAL_EQUAL, value_to_use: x }

		const kind = param.parameter.kind

		if (kind == 'duration')
		{
			if (x.duration != y.duration)
				return {eq: VALUE_INEQUAL }
			if (x.end != y.end && x.start != y.start || x.warning || y.warning)
			{
				const value_to_use	= {
					duration: x.duration, duration_raw: x.duration_raw, 
					warning: "Different start or end times"
				}
				return { eq: VALUE_PARTIAL_EQUAL, value_to_use }
			}
			else
				return {eq: VALUE_EQUAL, value_to_use: x}
		}

		if (kind == 'material')
		{
			if (x.id != y.id || x.subject != y.subject)
				return { eq: VALUE_INEQUAL}

			if (x.comment !== y.comment || x.warning || y.warning) 
			{
				const value_to_use = { ...x, comment: null, warning: "Different Material Notes" }
				return { eq: VALUE_PARTIAL_EQUAL, value_to_use }
			}
			else
				return { eq: VALUE_EQUAL, value_to_use: x }		}

		if (x == y)
			return {eq: VALUE_EQUAL, value_to_use: x}
		else
			return {eq: VALUE_INEQUAL}
	}


	getCommonData = (block_list, selection) => 
	{
//		console.log( "----====----====----====----")
		const { multi_step_focus} 	= selection
		const { focused_step } 		= selection

		const 	data 	= {}
		const 	blank 	= {}
		const 	multi 	= {}

		if (!multi_step_focus) 
		{
			for (let block of block_list) {
				if (block.focused_params)
				{
					for (let param of block.focused_params)
						data[param.parameter.id] = null
				}
			}
			
			for( let block of block_list )
			{
				if (block.selected_batches)
				{
					for( let substrate of block.selected_batches)
					{
						const experiment_data = this.experiment().getProcessData(substrate, focused_step)
						if (!experiment_data)
							continue

						for (let param of block.focused_params)
						{
							const k = param.parameter.id

							const { eq, value_to_use } = this.checkequal(param, experiment_data[k], data[k])

							if (blank[k])
							{
								data[k] = null
							}
							else if (data[k] == null)
							{
								data[k] = value_to_use
							}
							else 
							{
								if (eq == VALUE_INEQUAL)
								{
									blank[k] 	= true
									data[k] 	= null
									multi[k] 	= true
								}
								else if (eq == VALUE_PARTIAL_EQUAL)
									data[k] 	= value_to_use
							}
						}
					}
				}
			}

//			console.log( data )
//			console.log("======================")
		}
		return {data, multi}
	}


	render() 
	{

		if (!this.context || !this.experiment())
			return <div/>
		const { controller } 			= this.context
	


		if (	!controller.gui_controller.gui_state 
			|| !controller.gui_controller.gui_state.selection)
		{
			return <div className='helptext'>Select some cells to edit</div>
		}
		const { selection, block_list }	= controller.gui_controller.gui_state

		const { focused_params } 		= selection
		const { focused_step }			= selection

		const experiment 				= this.experiment()

		const {data,multi} 				= this.getCommonData( block_list, selection )


		const process = experiment.getStepTemplate(focused_step)

		const { all_complete, completed_at, all_skipped, all_not_skipped } = get_completion_status_of_selection(selection, block_list, experiment, data)

		data.completed 			= all_complete
		data.completed_at 		= completed_at
		

		console.log( experiment.data )
		
		if (all_skipped && !all_not_skipped)
			data.all_skipped = true
		else if (!all_skipped && all_not_skipped)
			data.all_not_skipped = false

		
		const manual_parameters = !selection.single_cell_select && focused_params && focused_params.length > 0 || block_list.length > 1
		let form_spec = null

		if (!block_list || block_list.length == 0)
		{
			form_spec = null
		}
		else if (selection.multi_step_focus) 
		{
			form_spec = null
		}
		else if (manual_parameters) 
		{
			const parameters = []

			const find_existing = (p) =>
			{
				let rv = false
				parameters.forEach( param => {
					if (param.id == p.id)
						rv = true
				})
				return rv
			}

			block_list.forEach( b => {
				b.focused_params.forEach( p => {
					if (!find_existing( p.parameter ))
						parameters.push( p.parameter )
				})
			})

			form_spec = [...parameters]
		}
		else if (process) {
			form_spec = [...process.params]
		}

		if (!form_spec || form_spec == null) {
			return  <div className='description'>
					Select a set of entries in the table to the left to directly edit them here
					</div>
		}

		this.count++
		const active_button = !selection.contains_open
	
		const key  = `${experiment.id}-${controller.uuid}-${selection.count}`

		
		return <div key={key}  className='process-form'>

				<Form key={key}
						data={data}
						errors={this.form_errors}
						className='form'
						verify_form={this.formErrors}
						focusModel={FOCUS_ARROWS}
						live_submit={true}
						onLiveSubmit={this.liveSubmitForm}
						onSubmit={this.submitForm}>
						<div className='body'>
								<div className='formtable'>
									{!all_skipped && this.renderForm(data, multi, form_spec, all_complete || all_skipped,  experiment)}

									{!all_skipped && <CompletionBlock 	controller={controller} 
														experiment={experiment} 
														form_spec={form_spec} />}

									<ActiveBlock 		controller={controller}
														experiment={experiment}
														form_spec={form_spec} />
								</div>
						</div>
					</Form>				
				</div>
	}
}

//							{active_button && <ActionButton onClick={this.toggle_active}>Active</ActionButton>}
