import React, { useState, useRef } from 'react'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'


import { AppFrame } from '../app-frame/AppFrame'
import { useRouter } from '../hooks/useRouter'
import { ViewFrame } from '../view/ViewController'
import { ComponentContainer, ComponentMenuTop } from '../app-frame/AppFrame'
import { SubstrateComponentTop } from './SubstrateComponentTop'




import {
    SubstrateMaterialCreateComponent,
    SubstrateMaterialEditComponent,
    SubstrateMaterialShowComponent, 
    SubstrateMaterialCopyComponent,
    SubstrateMaterialCreateBatchComponent
} from './SubstrateMaterialEditor'

import {
    SubstrateBatchShowComponent
} from './SubstrateBatchEditor'




const initialFilter = {
    table: "boolean",
    id: "boolean",
    operator: "and",
    list: [
        {
            table: "substrate_material",
            id: "title",
            value: ""
        }
    ]
}


export const SubstrateMaterialFrame = (props) => 
{

    const router = useRouter()
    const initialColumns = ["title", "created_at"]
    const [fts, setFts] = useState("")

    const { id } = router.params
    const { batch_id } = router.params 


    const [substrate_material, refetchComponent] = useBackendData(`substrate_material/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }


    const setTableRow = (id) => {
        router.goto(`/substrate-material/show/${id}`, { focus: 'r', i: null })
    }

    const create = (e) => {
        router.goto(`/substrate-material/create/${id}`, { fe: false, fo: false, focus: 'r', i: null })
    }


    let component_type = null
    const location = router.location.pathname

    if (location.startsWith('/substrate-material/edit') && id)
        component_type = 'edit'
    else if (location.startsWith('/substrate-material/create-batch'))
        component_type = 'create-batch'
    else if (location.startsWith('/substrate-material/create'))
        component_type = 'create'
    else if (location.startsWith('/substrate-material/copy'))
        component_type = 'copy'
    else if (location.startsWith('/substrate-material/show-batch'))
        component_type = 'show-batch'
    else if (location.startsWith('/substrate-material/show'))
        component_type = 'show'

    const editing = !(component_type == 'show' || component_type == 'show-batch' || component_type == null)



    console.log( component_type, id, batch_id )

    const showFilter = component_type == null || component_type == 'show'

    return <AppFrame search_bar={true} highlight='substrate_material' onSearchChange={setFts} layout_flag={component_type}>
            <ComponentMenuTop>
                <SubstrateComponentTop editing={editing}/>
                <div className='filler' />
                <div className='main-section'>
                    <div className='section'>
                        <button onClick={create} disabled={editing}>
                            <span className='fa fa-wand-magic-sparkles'/>
                            &nbsp;
                            Create...
                        </button>
                    </div>

                    <div className='metadata-section'>
                        <MetaDataMenu table='substrate_material' view={viewRef} refetch={refetch} />
                    </div>

                </div>
            </ComponentMenuTop>

            <ComponentContainer className='l-r'>
                <ViewFrame ref={viewRef}
                    table="substrate_material"
                    initialColumns={initialColumns}
                    initialFilter={initialFilter}
                    fts={fts}
                    dataURL='substrate_material/get_filter'
                    rowID={id}
                    setTableRow={setTableRow}
                    showFilter={showFilter}>
        

                    {
                        component_type == 'show' && id &&
                        <SubstrateMaterialShowComponent substrate_material={substrate_material} refetch={refetch} />
                    }
                    {
                        component_type == 'edit' && id &&
                        <SubstrateMaterialEditComponent substrate_material={substrate_material} refetch={refetch} />
                    }
                    {
                        component_type == 'copy' &&
                        <SubstrateMaterialCopyComponent substrate_material={substrate_material} refetch={refetch} />
                    }                    
                    {
                        component_type == 'create' &&
                        <SubstrateMaterialCreateComponent substrate_material={substrate_material} refetch={refetch} />
                    }
                    {
                        component_type == 'create-batch' &&
                        <SubstrateMaterialCreateBatchComponent substrate_material={substrate_material} refetch={refetch} />
                    }
                    {
                        component_type == 'show-batch' &&
                        <SubstrateBatchShowComponent id={batch_id} refetch={refetch} />
                    }                
                </ViewFrame>
            </ComponentContainer>
    </AppFrame>
}

