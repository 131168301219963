import axios from 'axios'
import { backend_server } from '../config/runconfig'





export const allProcessTypes = {
	experiment: [],
	solution: 	[]
}


export const processTemplates = {

}


export class ProcessLoader
{
	constructor()
	{
		this.allProcessTypes = {
			experiment: [],
			solution: 	[]
		}

		this.allProcessTypesID = {
			experiment: [],
			solution: []
		}


		this.processTemplates = {
			experiment: {},
			solution: 	{}
		}

		this.allProcessTemplates = {}
	}

	load(callback) 
	{
		const step_entries = []

		const url = `${backend_server.server}/step/process_definitions?api_key=caa9041159f0b845c2902a2b705e889`
		console.log( url )
		axios.get(url)
			 .then(result => {
				if (!result?.data?.dataset)
					throw "Process load error"
				
				result.data.dataset.forEach(entry => step_entries.push( entry ))


				step_entries.forEach( se => 
				{
					this.allProcessTypes[se.subject].push(se.id)
					this.allProcessTypesID[se.subject].push(se.id)
					processTemplates[se.id] = se

					this.processTemplates[se.subject][se.id] = se
					this.allProcessTemplates[se.id] = se
				})

				this.ready = true
				if (callback)
					callback({ success: true })
			})
			.catch(e => {
				console.log(e)
				this.ready = false
				if (callback)
					callback({ success: false, error: e })
			})
	}
}







