import React, { useState }              from 'react'
import { useRef, forwardRef }  from 'react'

import './Substrate.scss'
import '../filter/Filter.scss'

import { AppFrame }                                 from '../app-frame/AppFrame'
import { useRouter }                                from '../hooks/useRouter'
import { ViewFrame }                                from '../view/ViewController'
import { ComponentContainer, ComponentMenuTop }     from '../app-frame/AppFrame'

import { SubstrateComponentTop  } from './SubstrateComponentTop'

import { useBackendData } from '../hooks/useDownloaded'
import { MetaDataMenu } from '../metadata/MetaDataMenu'


import {
    SubstrateShowComponent
} from './SubstrateEditor'






const initialFilter = {
    table: "boolean",
    id: "boolean",
    operator: "and",
    list: [
        {
            table:      "substrate",
            id:         "title",
            value:      ""
        },
//        {
//            table: "substrate",
//            id: "identifier",
//            value: null
//        },
//        {
//            table:      "substrate",
//            id:         "free",
//            value:      "all"
//        }
        
//        ,
//        {
//            table:      "substrate",
//            id:         "created_at",
//            value:      {type: "last", value: "90"}
//        }
    ]
}





const initialColumns = ["title", "material",  "keywords", "kind"]


export const SubstrateFrame = (props) => 
{


    const router = useRouter()
    const [fts, setFts] = useState("")

    const { id } = router.params

    const [substrate, refetchComponent] = useBackendData(`substrate/get/${id}`, null)
    const viewRef = useRef()
    const refetch = () => {

        if (viewRef.current)
            viewRef.current.refetchData()
        if (refetchComponent)
            refetchComponent()
    }


    const setTableRow = (id) => {
        router.goto(`/substrate/show/${id}`, { focus: 'r', i: null } )
    }



    let component_type = null
    const location = router.location.pathname



    if (location.startsWith('/substrate/edit/') && id)
        component_type = 'edit'

    else if (location.startsWith('/substrate/edit') && !id)
        component_type = 'create'


    else if (location.startsWith('/substrate/show'))
        component_type = 'show'



    const showFilter = component_type == null || component_type == 'show'

    return <AppFrame search_bar={true} highlight='substrate' onSearchChange={setFts} layout_flag={component_type}>
        
        <ComponentMenuTop>
            <SubstrateComponentTop />

            <div className='metadata-section'>
                <MetaDataMenu table='substrate' view={viewRef} refetch={refetch} />
            </div>            
        </ComponentMenuTop>


        <ComponentContainer className='l-r'>
            <ViewFrame ref={viewRef}
                table="substrate"
                initialColumns={initialColumns}
                initialFilter={initialFilter}
                fts={fts}
                dataURL='substrate/get_filter'
                rowID={id}
                setTableRow={setTableRow}
                showFilter={showFilter}
                dataDownloadAction='get_data_xlsx'>

                {
                    component_type == 'show' && id &&
                    <SubstrateShowComponent substrate={substrate} refetch={refetch} />
                }
            </ViewFrame>
        </ComponentContainer>
    </AppFrame>
}

 


export const SubstrateSelector = forwardRef((props, ref) =>
{

    const initialFilter = {
        table: "boolean",
        id: "boolean",
        operator: "and",
        list: [
            {
                table: "substrate",
                id: "title",
                value: ""
            },
            {
                table: "substrate",
                id: "identifier",
                value: null
            },            
            {
                table: "substrate",
                id: "free",
                value: "free"
            },
            {
                table: "substrate",
                id: "kind",
                value: ""
            }
        ]
    }


    const { fts }       = props
    const searchTerm    = !fts || `${fts}`.trim().length == 0 ? null : fts 

    const columns       = ["title", "kind"]

    const handleRowSelect = (id, substrate) => 
    {
        if (props.onSubstrateSelect)
            props.onSubstrateSelect(id, substrate)
    }


    return  <ViewFrame  ref                 = {ref}
                        table               = "substrate"
                        embedded            = {true}
                        fts                 = {searchTerm}
                        initialColumns      = {columns}
                        initialFilter       = {initialFilter}
                        dataURL             = 'substrate/get_filter?objects=true'
                        rowID               = {-1}
                        showFilter          = {true}
                        selected_id         = {props.selected_id}
                        dragItemDescriptor  = {props.dragItemDescriptor}
                        setTableRow         = {handleRowSelect} 
                        tailClick           = {props.tailClick} 
                        onTailClick         = {props.onTailClick}
                        dataDownloadAction  = 'get_data_xlsx'
                        />
})
   