
import React, { Component,Fragment }				from 'react'
import {Form, TextBox, CheckBox, SubmitButton, Time,
		HideShow, Select, Error}					from './Form'
		

export class FormTest  extends Component
{
	constructor(props)
	{
		super( props )
		this.sub_form_data  = {
			value: 		"Hello Sub",
		}

	}

	uc = (x) => `${x}`.toUpperCase()

	handleSubmit = (event, data) =>
	{
		console.log( "Submitting data: ")
		console.log( data )
	}

	handleChange = ( {name,value,raw,data} ) => 
	{
		console.log( "CH: " , name,  {value},  raw)

	}


	formErrors( form, data, raw_data, errors )
	{
		console.log( "ERROR!!!")
		console.log( form )
		if (data.box1 == 100 && data.box2 == 'BOX2' && data.checked)
		{
			form.addError( errors, "form", "Form Error")
		}
	}


 	render()
	{

		const data 	  = {
			def_val: 	"DEF Val",
			nodef_val: 	"NODEF Val",
			num_def_val: 1,

			ngr1: "Alpha",
			dgr1: "Alpha",

			nlr1: ['Beta', 'Eta'],
			nsr1: 'Beta,Eta',

			dlr1: ['Beta', 'Eta'],
			dsr1: 'Beta,Eta'				
		}


		  const style = `
		  		.test {
						margin-top: 1em; display: flex; flex-flow: row nowrap;
				}

			  	.test b
				{
					flex - grow: 0;
					width: 10em;
				}

				.error
				{
					color 	: red;
					border-color 	: red;
				}

				.title
				{
					font-size 	: 1.4em;
					color 		: #468;
					font-weight : bold;
					margin  	: 2em 0;
					border-top 	: 1px solid #468;
				}

				.error
				{
					border 		: 1px solid indianred;
				}
		`
		
		const options = ['DEF', 'Alpha', 'Beta', 'Gamma', 'Eta', 'Zeta']

		return (
			<html>
				<head>
					<style>
					{style}
					</style>
				</head>
				<body>
				<div>
					<Form 	data={data}
							errors={this.form_errors}
							verify_form={this.formErrors}
							onSubmit={this.handleSubmit}
							onChange={this.handleChange}>

						<div className='title'>No Def</div>										

						<div className='test'>
							<b>Single Value</b>
							<Select required={true} name='ngr1' options={options}  />
						</div>

						<div className='test'>
							<b>Single NoValue</b>
							<Select required={true}  name='ngr2' options={options}  />
						</div>


						<div className='test'>
							<b>List Value</b>
							<Select required={true} name='nlr1' options={options} multiple={true} checkboxes={false} />
						</div>
					
						<div className='test'>
							<b>StringList Value</b>
							<Select required={true} name='nsr1' options={options} multiple={true} checkboxes={false} />
						</div>

						<div className='test'>
							<b>List no value</b>
							<Select required={true} name='nlr2' options={options} multiple={true} checkboxes={false} list={true} />
						</div>

						<div className='test'>
							<b>StringList no value</b>
							<Select required={true} name='nslr2' options={options} multiple={true} checkboxes={false} list={false} />
						</div>

						<div className='test'>
							<b>StringList no value Cycle</b>
							<Select cycle_button={true} required={true} name='nslr2cy' options={options}  list={false} />
						</div>

						<div className='test'>
							<b>StringList no value CB</b>
							<Select multiple={true} checkboxes={true} required={true} name='nslr2cb' options={options}  list={false} />
						</div>


						<br/>
						<br/>
							<div className='title'>Def</div>

							<div className='test'>
								<b>Single Value</b>
								<Select required={true} name='dgr1' options={options} default_value="DEF"/>
							</div>

							<div className='test'>
								<b>Single NoValue</b>
								<Select required={true} name='dgr2' options={options} default_value="DEF"/>
							</div>


							<div className='test'>
								<b>List Value</b>
								<Select required={true} name='dlr1' default_value={["DEF", "Alpha", "Gamma"]} 
													options={options} multiple={true} checkboxes={false} />
							</div>

							<div className='test'>
								<b>StringList Value</b>
								<Select required={true} name='dsr1' default_value="DEF,Alpha,Eta"
													options={options} multiple={true} checkboxes={false} />
							</div>

							<div className='test'>
								<b>List no value</b>
								<Select required={true} name='dlr2' default_value={["DEF", "Zeta", "Gamma"]}
													options={options} multiple={true} checkboxes={false} list={true} />
							</div>

							<div className='test'>
								<b>StringList no value</b>
								<Select required={true} name='dslr2'default_value="DEF,Gamma,Beta" 
													options={options} multiple={true} checkboxes={false} list={false} />
							</div>

						<SubmitButton/>
					</Form>
				</div>
			</body>
		</html>
		)

	}

}


const texttest = () => <div>
		<div className = 'test' >
							<b>Def - No Val</b>
							<TextBox name='def_noval' default_value="DEF1"/>
						</div >

						<div className='test'>
							<b>Def - Val</b>
							<TextBox name='def_val' default_value="DEF2" />
						</div>

						<div className='test'>
							<b>No Def - No Val</b>
							<TextBox name='nodef_noval'  />
						</div>

						<div className='test'>
							<b>No Def - Val</b>
							<TextBox name='nodef_val'  />
						</div>

						<div className='test'>
								<b>I Def Val</b>
								<TextBox type='int' name='num_def_val' />
							</div>

						<div className='test'>
								<b>Int</b>
								<TextBox type='int' name='num_integer' />
							</div>

						<div className='test'>
								<b>Num F Def Val</b>
								<TextBox type='float' name='num_float' precision={2} />
						</div>

		</div>



//
//						<div className='test'>
//							<b>List</b>
//							<Select name='lr1' options={options} multiple={true} checkboxes={false} />
//						</div>
//					
//						<div className='test'>
//							<b>String</b>
//							<Select name='sr1' options={options} multiple={true} checkboxes={false} />
//						</div>
//
//						<div className='test'>
//							<b>List no def</b>
//							<Select name='lr2' options={options} multiple={true} checkboxes={false} list={true} />
//						</div>
//
//						<div className='test'>
//							<b>String no def</b>
//							<Select name='slr2' options={options} multiple={true} checkboxes={false} list={false} />
//						</div>

//
//export class FormTestSelect extends Component 
//{
//	constructor(props) {
//		super(props)
//		this.sub_form_data = {
//			value: "Hello Sub",
//		}
//
//
//		this.form_data = {
//			box1: 100,
//			time: 65,
//			box2: "Box2",
//			sel1: "Rayong",
//			ta: "Zurg\nZorg",
//			ta2: "Hello Mr Textbox",
//			checked: false,
//		}
//		this.form_errors = { download: ["Error in DownUpLoad"] }
//	}
//
//	uc = (x) => `${x}`.toUpperCase()
//
//	handleSubmit = (event, data) => {
//		console.log("Submitting data: ")
//		console.log(data)
//	}
//
//
//	formErrors(form, data, raw_data, errors) {
//		console.log("ERROR!!!")
//		console.log(form)
//		if (data.box1 == 100 && data.box2 == 'BOX2' && data.checked) {
//			form.addError(errors, "form", "Form Error")
//		}
//	}
//
//
//	render() {
//
//		const data = {
//			sm1: ['Beta', 'Eta'],
//			sm2: 'Beta,Eta',
//
//			sr1: ['Beta', 'Eta'],
//			sr2: 'Beta,Eta'
//		}
//
//		const options = ['Alpha', 'Beta', 'Gamma', 'Eta', 'Zeta']
//
//
//		return (
//			<div>
//				<Form data={data}
//					errors={this.form_errors}
//					verify_form={this.formErrors}
//					onSubmit={this.handleSubmit}>
//					<Error name='download, form' />
//					<br />
//
//					<Select name='sm1' options={options} multiple={true} checkboxes={true} />
//					<br />
//
//					<Select name='sm2' options={options} multiple={true} checkboxes={true} />
//					<br />
//
//					<Select name='sr1' options={options} multiple={true} checkboxes={false} />
//					<br />
//
//					<Select name='sr2' options={options} multiple={true} checkboxes={false} />
//					<br />
//
//
//					<TextBox name='ta' type='textarea' />
//					<br />
//					<br />
//
//					<TextBox name='ta2'  />
//					<br />
//
//					<br />
//
//					<SubmitButton />
//				</Form>
//			</div>
//		)
//
//	}
//}



export default FormTest

