import React, { useState, useRef} from 'react'
import { useDrop } from 'react-dnd'
import { useEffect } from 'react'

const is_top_half = (ref, monitor) => 
{
	if (!monitor.getClientOffset())
		return 0;
	const hoverBoundingRect = ref.current?.getBoundingClientRect()
	const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
	const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

	return hoverActualY < hoverMiddleY
}




export const SortListSeparator = ({ position, accept, onDrop, active, className }) => {
	const [{ isOver, }, dropRef] = useDrop(() =>
	({
		accept: accept,
		drop: ({item}) => {
			if (onDrop)
				return onDrop(item, position)
		}
	}))

	return 	<div className={`${className || ''} list-sort-separator `} ref={dropRef}>
				&nbsp;
			</div>
}


export const SortListItem = ({ accept, children, position, onDrop }) => 
{
	const [hoverPosition, set_hp] 	= useState(0)
	const sli_ref 					= useRef()

	const [{ isOver }, dropRef] = useDrop(() =>
	({
		hover: (_, monitor) => {
			const tgt_hp = (sli_ref && sli_ref.current &&
				is_top_half(sli_ref, monitor)) ? -1 : 1
			set_hp(tgt_hp)
		},
		accept: accept,
		collect: (monitor) => ({ isOver: monitor.isOver(), item: monitor.getItem() }),
		drop: ({item}, monitor) => {
			if (onDrop) 
				onDrop(item, position, monitor.getItemType())
			set_hp(0)
		}
	}))

	useEffect( () => 
	{
		if (isOver && hoverPosition == -1) 
		{
			sli_ref.current.firstChild.classList.add("active")
			const next_sib = sli_ref.current.nextSibling

			if (next_sib?.classList.contains("list-sort-wrapper"))
				next_sib.firstChild.classList.remove("active")
			if (next_sib?.classList.contains("list-sort-separator"))
				next_sib.firstChild.classList.remove("active")
		}
		else if (isOver && hoverPosition == 1) {
			sli_ref.current.firstChild.classList.remove("active")
			const next_sib = sli_ref.current.nextSibling

			if (next_sib?.classList.contains("list-sort-wrapper"))
				next_sib.firstChild.classList.add("active")
			if (next_sib?.classList.contains("list-sort-separator"))
				next_sib.firstChild.classList.add("active")
		}
		else if (!isOver && sli_ref && sli_ref.current) {
			sli_ref.current.firstChild.classList.remove("active")
			const next_sib = sli_ref.current.nextSibling

			if (next_sib?.classList.contains("list-sort-wrapper"))
				next_sib.firstChild.classList.remove("active")
			if (next_sib?.classList.contains("list-sort-separator"))
				next_sib.firstChild.classList.remove("active")
			if (hoverPosition !== 0)
				set_hp(0)
		}
	}, [isOver, hoverPosition] )



	return 	<div key={`list-sort-${position}`} className='list-sort-wrapper' ref={dropRef(sli_ref)}>
				<SortListSeparator key={`sep${position - 1}`}
					accept={accept}
					position={position - 1} />
				{children}
			</div>

}
