import React, { Component } 		from "react";
import {BrowserRouter, Navigate,
		Route, Routes} 						from "react-router-dom"

import {WorkspaceEditorFrame} 		from '../workspace/WorkspaceEditor'

import {ExperimentFrame}			from '../experiment/ExperimentFrame'

import {SolutionFrame}				from '../solution/SolutionFrame'
import {SubstrateFrame} 			from '../substrate/SubstrateFrame'

import { SubstrateMaterialFrame } 	from '../substrate/SubstrateMaterialFrame'
import { SubstrateKindFrame } 		from '../substrate/SubstrateKindFrame'
import { SubstrateBatchFrame } 		from '../substrate/SubstrateBatchFrame'

import { MeasurementFrame } 		from '../measurement/MeasurementFrame'

import { ChemicalFrame } 			from '../chemical/ChemicalFrame'
 
import { PersonFrame } 				from '../person/PersonFrame'


import { ResourceFrame } 			from '../resource/ResourceFrame'


import { LoginPage } 				from "../person/Login";


import {ReactTest} 					from '../test/ReactTest'
import { FormTest } 				from '../form/FormTest'
//import { AntTest } 					from '../test/AntTest'

import { StepFrame }				from '../schema/StepFrame'


export class MasterRouter extends Component
{
	constructor(props)
	{
		super(props)
	}

	render = () =>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" 	element={<Navigate to="/workspace" replace />} />
					<Route exact path="/rt" element={<ReactTest/>} />
					<Route exact path="/ft" element={<FormTest/>} />

					<Route exact path="/experiment"	 				 element={<ExperimentFrame/>} />
					<Route exact path="/experiment/show/:id" 		 element={<ExperimentFrame/>} />
					<Route exact path="/experiment/edit" 			 element={<ExperimentFrame/>} />
					<Route exact path="/experiment/edit/:id" 		 element={<ExperimentFrame/>} />
					<Route exact path="/experiment/clone/:id" 		 element={<ExperimentFrame/>} />
					<Route exact path="/experiment/clone-template/:id" element={<ExperimentFrame />} />
					<Route exact path="/experiment/create-template"  element={<ExperimentFrame/>} />


					<Route exact path="/workspace" 					 element={<WorkspaceEditorFrame/>} />
					<Route exact path="/workspace/:id" 				 element={<WorkspaceEditorFrame/>} />

					<Route exact path="/substrate" 					 element={<SubstrateFrame/>} />
					<Route exact path="/substrate/show/:id" 		 element={<SubstrateFrame/>} />
					<Route exact path="/substrate/edit/:id" 		 element={<SubstrateFrame/>} />


					<Route exact path="/substrate-material" 		 element={<SubstrateMaterialFrame/>} />
					<Route exact path="/substrate-material/show/:id" element={<SubstrateMaterialFrame/>} />
					<Route exact path="/substrate-material/edit/:id" element={<SubstrateMaterialFrame/>} />
					<Route exact path="/substrate-material/create" 	 element={<SubstrateMaterialFrame/>} />
					<Route exact path="/substrate-material/create/:id" element={<SubstrateMaterialFrame />} />
					<Route exact path="/substrate-material/copy/:id" element={<SubstrateMaterialFrame />} />
					<Route exact path="/substrate-material/create-batch/:id" element={<SubstrateMaterialFrame />} />
					<Route exact path="/substrate-material/show-batch/:id/:batch_id" element={<SubstrateMaterialFrame />} />


					<Route exact path="/substrate-kind" 			 element={<SubstrateKindFrame/>} />
					<Route exact path="/substrate-kind/show/:id" 	 element={<SubstrateKindFrame/>} />
					<Route exact path="/substrate-kind/edit/:id" 	 element={<SubstrateKindFrame/>} />
					<Route exact path="/substrate-kind/create" 		 element={<SubstrateKindFrame/>} />

					<Route exact path="/substrate-batch" 			 element={<SubstrateBatchFrame/>} />
					<Route exact path="/substrate-batch/show/:id" 	 element={<SubstrateBatchFrame/>} />
					<Route exact path="/substrate-batch/create" 	 element={<SubstrateBatchFrame/>} />


					<Route exact path="/measurement" 				 element={<MeasurementFrame/>} />
					<Route exact path="/measurement/show/:id" 		 element={<MeasurementFrame/>} />
					<Route exact path="/measurement/edit/:id" 		 element={<MeasurementFrame/>} />
					<Route exact path="/measurement/create" 		 element={<MeasurementFrame/>} />

					<Route exact path="/chemical" 					 element={<ChemicalFrame/>} />
					<Route exact path="/chemical/show/:id" 			 element={<ChemicalFrame/>} />
					<Route exact path="/chemical/edit/:id" 			 element={<ChemicalFrame/>} />
					<Route exact path="/chemical/create" 			 element={<ChemicalFrame/>} />

					<Route exact path="/resource/list/:subject_id?" 		element={<ResourceFrame/>} />
					<Route exact path="/resource/show/:subject_id/:id" 		element={<ResourceFrame/>} />
					<Route exact path="/resource/edit/:subject_id/:id" 		element={<ResourceFrame/>} />
					<Route exact path="/resource/create/:subject_id" 		element={<ResourceFrame/>} />
					<Route exact path="/resource" 							element={<ResourceFrame/>} />

					<Route exact path="/resource_type/list/:subject_id" 	element={<ResourceFrame/>} />
					<Route exact path="/resource_type/show/:subject_id" 	element={<ResourceFrame/>} />
					<Route exact path="/resource_type/edit/:subject_id" 	element={<ResourceFrame/>} />
					<Route exact path="/resource_type/create" 				element={<ResourceFrame/>} />
					<Route exact path="/resource_type" 						element={<ResourceFrame/>} />


					<Route exact path="/solution" 					 element={<SolutionFrame/>} />
					<Route exact path="/solution/show/:id" 			 element={<SolutionFrame/>} />
					<Route exact path="/solution/edit/:id" 			 element={<SolutionFrame/>} />
					<Route exact path="/solution/clone/:id" 		 element={<SolutionFrame />} />
					<Route exact path="/solution/clone-template/:id" element={<SolutionFrame />} />
					<Route exact path="/solution/create" 			 element={<SolutionFrame/>} />
					<Route exact path="/solution/create-template" 	 element={<SolutionFrame/>} />


					<Route exact path="/person" 					 element={<PersonFrame/>} />
					<Route exact path="/person/show/:id" 			 element={<PersonFrame/>} />
					<Route exact path="/person/edit/:id" 			 element={<PersonFrame/>} />
					<Route exact path="/person/create" 				 element={<PersonFrame/>} />

					<Route exact path="/login" 						 element={<LoginPage/>} />
					<Route exact path="/login/changepassword/:key" 	 element={<LoginPage/>} />
					<Route exact path="/login/authenticate/:key" 	 element={<LoginPage/>} />

					<Route exact path="/step" 						 element={<StepFrame/>} />
					<Route exact path="/step/show/:id" 				 element={<StepFrame/>} />
					<Route exact path="/step/edit/:id" 				 element={<StepFrame/>} />
					<Route exact path="/step/create/:subject" 		 element={<StepFrame/>} />

					<Route exact path="/test/form" 					 element={<FormTest/>} />

					<Route path="*"		element={<Navigate to="/" replace />}/>
				</Routes>
			</BrowserRouter>
}


// <Route exact path="/solution/edit/:id" element={<SolutionFrame/>} />
