import React, { Fragment, useContext } from 'react'
import { useRouter } from '../hooks/useRouter'

import { MaterialFormElement } from '../material/MaterialQuery'
import {fix_id} 				from '../utils/utils'
import {
	Form, 

	TextBox, CheckBox, Select,
	DateTime, Time, Duration,

	Error,
	Derived
} from '../form/Form'

import { useBackendData } from '../hooks/useDownloaded'

import { DBContext } from '../app/DBContext'

import {getMaterialFrom} from '../model/experiment'
import { Resource } from '../resource/resource'

import { formatTime } from '../utils/utils'

import {ResourceSubjectSelector} from '../resource/ResourceFrame'


class SPTypeRegistry
{
	constructor()
	{
		this.registry = {}
		this.registrationList = []
	}


	register(sp_type) {
		if (!sp_type)
			return
		const { id } = sp_type
		if (!id || `${id}`.trim().length == 0)
			return

		this.registry[id] = sp_type
		this.registrationList.push({ id, definition: sp_type })
	}

	get(id) {
		return this.registry[id]
	}

	allTypesAsList() {
		return this.registrationList
	}
}


export const stepParameterTypes = new SPTypeRegistry()




export const SPLineEdit = ({ id, title, children }) =>
	<div className='line' key={`sp_form-${id}`}>
		<div className='title'>{title}</div>
		<div className='control'>{children}</div>
		<div className='error'><Error names={id} /></div>
	</div>


const SPLineShow = ({ id, title, children }) => {
	if (!children)
		return null
	return <div className='line' key={`sp_form-${id}`}>
		<div className='title'>{title}</div>
		<div className='control'>{children}</div>
	</div>
}



const SPParamsDefaultEdit = ({ template, param, unit, placeholder }) => 
{
	const edit_id = param && (!param.name || `${param.name}`.trim().length == 0) && !param.used !== true



	return 	<Fragment>
				<SPLineEdit id='id' title='ID' >
					{param && edit_id && <Derived className='value' live="name" fn={(p) => fix_id( p?.title )}/>}
					{param && !edit_id && <div className='value'><i>{param.name}</i></div>}
				</SPLineEdit>
				<SPLineEdit id='title' title='Title' >
					<TextBox name='title' required={true} disabled={param.used == true} />
				</SPLineEdit>
				<SPLineEdit id='required' title='Required' >
					<CheckBox name='required' />
				</SPLineEdit>
				{unit && <SPLineEdit id='required' title='Unit' >
					<TextBox name='unit' disabled={param.used == true} />
				</SPLineEdit>}
				{placeholder && <SPLineEdit id='required' title='Placeholder' >
					<TextBox name='placeholder' />
				</SPLineEdit>}			
			</Fragment>
}


const SPParamsDefaultShow = ({ template, param, unit, placeholder }) => {
	return <Fragment>
		<SPLineShow id='id' title='ID' >
			<i>{param.name}</i>
		</SPLineShow>
		<SPLineShow id='title' title='Title' >
			{param.title}
		</SPLineShow>
		<SPLineShow id='required' title='Required' >
			{param.required ? 'Required' : 'Not Required'}
		</SPLineShow>
		{unit && <SPLineShow id='required' title='Unit' >
			{param.unit}
		</SPLineShow>}
		{placeholder && <SPLineShow id='required' title='Placeholder' >
			{param.placeholder}
		</SPLineShow>}
	</Fragment>
}


const SPBooleanParamEdit = ({ onChange, template, param }) =>
	<Form onChange={onChange} className='form step-param-form' data={param}>
		<SPParamsDefaultEdit param={param} placeholder={false} />
		<SPLineEdit id='default' title='Default' >
			<CheckBox name='default_value' />
		</SPLineEdit>
	</Form>


class SPTypeBoolean {
	constructor() {
		this.name = 'Boolean'
		this.id = 'boolean'
	}

	renderParamEdit = (template, param, onChange)=> 
		<SPBooleanParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<div className='parameter-show'>
			<SPParamsDefaultShow param={param} unit={false} />
		</div>
	


	renderFormShow = (param, data) 	=>
	{
		return <div className='form-value boolean'>
				{data == 'true' ? param.trueValue || 'Yes' : param.falseValue || 'No'}
			</div>
	}

	renderFormEdit =  (props) => 
		<Select empty_option_label='--- Select ---' {...props} 
				options={[{label: 'No', value: false}, {label:'Yes', value: true}]} />

}
stepParameterTypes.register(new SPTypeBoolean())



// ------------------ Text

const SPSimpleParamEdit = ({ onChange, template, param, placeholder }) =>
	<Form onChange={onChange} className='form step-param-form' data={param}>
		<SPParamsDefaultEdit param={param} unit={false} placeholder={placeholder !== false}/>
	</Form>


const SPSimpleParamShow = ({ onChange, template, param }) =>
	<div className='parameter-show'>
		<SPParamsDefaultShow param={param} unit={false} placeholder={true} />
	</div>





class SPTypeText {
	constructor() {
		this.name = 'Text'
		this.id = 'text'
	}
	renderParamEdit = (template, param, onChange, used) =>
		<Form onChange={onChange} className='form step-param-form' data={param}>
			<SPParamsDefaultEdit param={param} unit={true} placeholder={true} used={used}/>
			<SPLineEdit id='default' title='Default' >
				<TextBox name='default_value' />
			</SPLineEdit>
		</Form>


	renderParamShow = (template, param, onChange) =>
		<div>
			<SPParamsDefaultShow param={param} unit={true} placeholder={true} />
			<SPLineShow id='default' title='Default' >
				{param.default || <i>--None--</i>}
			</SPLineShow>
		</div>


	renderFormEdit = (props) => 
		<TextBox {...props} />

	renderFormShow = (param,data) => 
		<div className='form-value text'>{data}&nbsp;</div>
}
stepParameterTypes.register(new SPTypeText())



class SPTypeTextArea {
	constructor() {
		this.name = 'Text Area'
		this.id = 'textarea'
	}
	renderParamEdit = (template, param, onChange) =>
		<Form onChange={onChange} className='form step-param-form' data={param}>
			<SPParamsDefaultEdit param={param} unit={true} placeholder={true} />
			<SPLineEdit id='default' title='Default' >
				<TextBox name='default_value' />
			</SPLineEdit>
		</Form>


	renderParamShow = (template, param, onChange) =>
		<div>
			<SPParamsDefaultShow param={param} unit={true} placeholder={true} />
			<SPLineShow id='default' title='Default' >
				{param.default || <i>--None--</i>}
			</SPLineShow>
		</div>


	renderFormEdit = (props) =>
		<TextBox type='textarea' {...props} />

	renderFormShow = (param, data) =>
		<div className='form-value text'>{data}&nbsp;</div>
}
stepParameterTypes.register(new SPTypeTextArea())



const SPNumericParamEdit = ({ onChange, template, param }) =>
	<Form onChange={onChange} className='form step-param-form' data={param}>
		<SPParamsDefaultEdit param={param} unit={true} placeholder={true} />
		<SPLineEdit id='default' title='Default'  >
			<TextBox name='default_value' type="numeric" />
		</SPLineEdit>
		<SPLineEdit id='min' title='Min' 	 >
			<TextBox name='min' type="numeric" />
		</SPLineEdit>
		<SPLineEdit id='max' title='Max' 	 >
			<TextBox name='max' type="numeric" />
		</SPLineEdit>

	</Form>



const SPNumericParamShow = ({ onChange, template, param }) =>
	<div>
		<SPParamsDefaultShow param={param} unit={true} placeholder={true} />
		<SPLineShow id='default' title='Default'  >
			{param.default_value}
		</SPLineShow>
		<SPLineShow id='min' title='Min' 	 >
			{param.min}
		</SPLineShow>
		<SPLineShow id='max' title='Max' 	 >
			{param.max}
		</SPLineShow>

	</div>


class SPTypeInt {
	constructor() {
		this.name = 'Int'
		this.id = 'int'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPNumericParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<SPNumericParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data) => 
			<div className='form-value int'>{data}</div>

	renderFormEdit = (props) => 
		<TextBox   type='int' {...props} />	// MinMax already in props

}
stepParameterTypes.register(new SPTypeInt())


class SPTypeFloat {
	constructor() {
		this.name = 'Float'
		this.id = 'float'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPNumericParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<SPNumericParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data) 	=> 
		<div className='form-value float'>{data}</div>

	renderFormEdit = (props) 			=> 
		<TextBox  type='float' {...props} />	// MinMax already in props

}
stepParameterTypes.register(new SPTypeFloat())


class SPTypeTimeRange {
	constructor() {
		this.name = 'Time'
		this.id = 'time'
	}
	renderParamEdit = (template, param, onChange) 	=> 
		<SPSimpleParamEdit 	key={this.id} template={template} onChange={onChange} param={param} 
							placeholder={false}/>
	renderParamShow = (template, param) 			=> 
		<SPSimpleParamShow key={this.id} template={template} param={param} />


	renderFormShow = (param, data)=> 
		<div className='form-value float'>{data?.from} -  {data?.to}</div>

	renderFormEdit = (props)=> <Time {...props} />

}
stepParameterTypes.register(new SPTypeTimeRange())



class SPTypeDuration {
	constructor() {
		this.name = 'Duration'
		this.id = 'duration'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPSimpleParamEdit key={this.id} template={template} onChange={onChange} param={param}
							placeholder={false} />

	renderParamShow = (template, param) => 
		<SPSimpleParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data)=> 
		<div className='form-value float'>
			{formatTime( data ? data?.duration : "-" )}		
		</div>

	renderFormEdit = (props)=> 
		<Duration {...props} />
}
stepParameterTypes.register(new SPTypeDuration())



class SPTypeTimeStamp {
	constructor() {
		this.name = 'Timestamp'
		this.id = 'timestamp'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPSimpleParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<SPSimpleParamShow key={this.id} template={template} param={param} />


	renderFormShow = (param, data)=> 
		<div className='form-value float'>{data} <span className='unit'>{param.unit}</span></div>

	renderFormEdit = (props)=> 
		<DateTime  {...props} />

}
stepParameterTypes.register(new SPTypeTimeStamp())


class SPTypeMaterial 
{
	constructor() {
		this.name = 'Material'
		this.id = 'material'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPSimpleParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<SPSimpleParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data, {experiment, add_link})=> 
	{
		const router = useRouter()
		
		if (!data)
			return ''
		let v = data
		if (experiment)
		{
			const mat = getMaterialFrom( experiment, data )
			if (mat)
				v = mat.display_title
			else
				v = data?.display_title || ''
		}
		else
			v = v = data?.display_title || `${data?.subject}${data?.id}`

		const openInfo = () => { router.pushInfoBox(data.subject, data.id) }

		if (add_link == true && data && data.subject && data.id)
			return  <div className='form-value material' onClick={openInfo}>
						<div className='value'>{v}</div>
						<div className='icon fa fa-eye'/>
					</div>
		else
			return <div className='form-value'>{v}</div>
	}

	renderFormEdit = (props)=> 
		<MaterialFormElement  include_notes={true} {...props} />	

}
stepParameterTypes.register(new SPTypeMaterial())



// Select boxes

const SPSelectParamEdit = ({ onChange, template, param }) => {
	const { options, ...form_data } = param

	const all_options = []
	const p_options = param.options || []
	p_options.forEach((o, i) => {
		const v = `${o}`.trim()
		if (v.length > 0)
			all_options.push(v)
	})
	all_options.push("")

	form_data.options = all_options.join("\n")

	const onOptionChange = (value) => {
		if (value.name != 'options') {
			onChange(value)
		}
		else {
			const options = value?.value ? value.value.split("\n") : []
	
			onChange({ name: "options", value: options })
		}
	}

	return 	<Form onChange={onOptionChange} className='form step-param-form' data={form_data}>
				<SPParamsDefaultEdit param={param} unit={false} />
				<SPLineEdit id='default' title='Type' >
					<Select name='subkind' options={[{ label: 'String', option: 'string' }, { label: 'Int', option: 'int' }]} />
				</SPLineEdit>
				<SPLineEdit id='options' title='Options' >
					<TextBox name='options' type='textarea' required={true}/>
				</SPLineEdit>
				<SPLineEdit id='default' title='Default' >
					<Select name='default_value' options={all_options} />
				</SPLineEdit>
			</Form>
}


const SPSelectParamShow = ({ template, param }) => {
	const o = param.options || []
	const opts = <div>{o.map((x, i) => <div key={`o-${i}`}><i >{x}</i></div>)}</div>

	return 	<div>
				<SPParamsDefaultShow param={param} unit={false} />
				<SPLineShow id='default' title='Type' >
					{param.kind}
				</SPLineShow>
				<SPLineShow id='options' title='Options' >
					{opts}
				</SPLineShow>
				<SPLineShow id='default' title='Default' >
					{param.default_value || <i>-- No Default --</i>}
				</SPLineShow>
			</div>
}

class SPTypeSelect 
{
	constructor() {
		this.name = 'Select'
		this.id = 'select'
	}
	renderParamEdit = (template, param, onChange) => 
		<SPSelectParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) => 
		<SPSelectParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data) => 
	<div className='form-value'>{`${data}`}<span className='unit'>{param.unit}</span></div>

	renderFormEdit = (props) => 
		<Select empty_option_label='--- Select ---' {...props} />		
}
stepParameterTypes.register(new SPTypeSelect())






// Select boxes

const SPResourceParamEdit = ({ onChange, template, param }) => 
{
	const { options, ...form_data } = param

	const context = useContext(DBContext)
	const all_resources = context?.resources
	let a_options = null
	if (all_resources)
		a_options = all_resources.options(form_data.resource_kind)
	
	const subjects = all_resources ? all_resources.resource_type_list : null

	if (!subjects)
		return <div/>

	const r_options = subjects.map((s, i) => {
		return {label: s.title, value: s.id}
	})

	return 	<Form className='form step-param-form' onChange={onChange} data={form_data}>
				<SPParamsDefaultEdit param={param} unit={false} />
				<SPLineEdit id='type' title='Type' >
					<Select options={r_options} name='resource_kind'  required />
				</SPLineEdit>
				<SPLineEdit id='favorites' title='Default' >
					<Select options={a_options} name='default_value' />
				</SPLineEdit>
				<SPLineEdit id='favorites' title='Favorites' >
					<Select options={a_options} name='resource_options'  multiple />
				</SPLineEdit>
			</Form>
}


const SPResourceParamShow = ({ template, param }) => 
{
	const context = useContext(DBContext)

	const resources = context?.resources || {}

	let title
	let def
	let opts = []
	if (resources || !param.resource_kind)
	{
		title = '---'
		def   = null
	}
	else
	{
		title = resources.type_for( param.resource_kind )?.title || '-- Unknown --'
		def   = resources.string_for( param.default_value) || null
		opts  = param.resource_options || []		
	}

	return 	<div>
				<SPParamsDefaultShow param={param} unit={false} />
				<SPLineShow id='type' title='Type' >
					{title}
				</SPLineShow>
				<SPLineShow id='default_value' title='Default' >
					{param.default_value || <i>-- No Default --</i>}
				</SPLineShow>
				{ 	opts && opts.length > 0 && 
					<SPLineShow id='favirotes' title='Favorites' >
					{
						opts.map( o => resources.string_for(o) || '--').join( ", ")
					}
					</SPLineShow>
				}
			</div>
}


const ResourceSelector = (props) => 
{
	const context = useContext(DBContext)

	if (!context || !context.resources || !props.resource_kind)
		return <div />

	let options = context.resources.options(props.resource_kind)
	if (!options)
		return <div />

	if (options && props.resource_options && props.resource_options.length > 0)
	{
		const options1 = options.filter( o => props.resource_options.indexOf(`${o.value}` ) > -1)
		const options2 = options.filter(o => props.resource_options.indexOf(`${o.value}`) == -1)
		options = [...options1, ...options2]
	}

	return <Select options={options} {...props} />
}



class SPTypeResource 
{
	constructor() {
		this.name = 'Resource'
		this.id = 'resource'
	}
	
	static contextType = DBContext;

	renderParamEdit = (template, param, onChange) =>
		<SPResourceParamEdit key={this.id} template={template} onChange={onChange} param={param} />

	renderParamShow = (template, param) =>
		<SPResourceParamShow key={this.id} template={template} param={param} />

	renderFormShow = (param, data) =>
		<div className='form-value'><Resource id={data}/><span className='unit'>{param.unit}</span></div>

	renderFormEdit = (props) => <ResourceSelector {...props} />
}
stepParameterTypes.register(new SPTypeResource())





export const renderProcessFormEdit = (param, extra_props = {}) => 
{
	if (!param)
		return null

	const param_handler = stepParameterTypes.get( param.kind || '--none--')

	const {name,id, ...rest} 	= param

	rest.param_name = name
	rest.name 		= `${param.id}`

	if (param_handler)
		return param_handler.renderFormEdit( {...rest, ...extra_props} )

	return null
}





export const renderFormEdit = (param, extra_props = {}) => {
	if (!param)
		return null

	const param_handler = stepParameterTypes.get(param.kind || '--none--')


	if (param_handler)
		return param_handler.renderFormEdit({ ...param, ...extra_props })

	return null
}




export const StepFormShow = (param, data, extra_props = {}) => 
{
	if (!param)
		return null

	const param_handler = stepParameterTypes.get(param.kind || '--none--')

	if (param_handler)
		return param_handler.renderFormShow(param, data, extra_props)		

	
	return null
}
