import React, { Component, Fragment } from 'react'



class A extends Component
{
    componentDidMount()
    {
        console.log( "CDM A")
    }

    componentWillMount() {
        console.log("CWM A")
    }

    render()
    {
        return <div>A {this.props.value}</div>
    }
}




class B extends Component {
    componentDidMount() {
        console.log("CDM B")
    }

    componentWillMount() {
        console.log("CWM B")
    }

    render() {
        return <div>B {this.props.value}</div>
    }

}



class Container extends Component
{


    componentDidMount() {
        console.log("CDM CTR")
    }

    componentWillMount() {
        console.log("CWM CTR")
    }


    render()
    {
        return  <div>
                    <div>Before</div>
                    {this.props.children}
                    <div>Before</div>
                </div>
    }
}


export const ReactTest = ( props ) => <Container>
        <A value="1"/>
        <B value="2"/>
</Container>

