import { generateUUID, isNum } from "../utils/utils"

export const paramCanHaveSubfilter = ( parameter ) =>
{
	const k = parameter.kind
	return (k == 'experiment' || k == 'substrate' || 
			k == 'chemical' || k == 'solution'  || 
			k == 'material' || k == 'substrate_material' || 
			k == 'person')		
}





export const filterHasComplexControls = ( filter, parameter ) =>
{
	if (filter.expanded) 				return true 
	if (parameter.any_or == true)		return true


	const k = parameter.kind
	const v = (k == 'date' 			|| 
			k == 'timestamp' 		|| 
			k == 'resource'			||
			k == 'select' && parameter.multiple || 
			parameter.is_open)

	return v
}

export const filterNodeSingleParameter = ( node ) =>
{
	if (node.parameters && node.parameters.length == 1)
		return node.parameters[0]
	else
		return null

}


export const normalizeFilterRun = (node, focusTarget, root, idx, depth, is_subfilter, hash) => 
{
	if (root == null)
		return normalizeFilterRun(node, "", "", 0, 0, false, [])

	if (node == null)
		return null

	node.is_root = !root || root == ""
	node.node_id = `${root}${idx}`
	if (!node.uuid)
		node.uuid 	 = node.is_root ? "0" : generateUUID()
	node.depth = depth;
	node.expanded = node.expanded === true ? true : false

	const singleParameter = filterNodeSingleParameter( node )

	if (node.subquery)
		node.subquery = normalizeFilterRun(node.subquery, node.uuid, `${node.node_id}-`, 0, depth + 1, true, hash)


	node.parentFocusTarget = focusTarget
	if (node.id == 'boolean') 
	{
		node.focusTarget 	= node.uuid
		node.is_subfilter 	= is_subfilter
		const subroot 		= `${node.node_id}-`
		node.list.forEach((n, i) => normalizeFilterRun(n, node.uuid, subroot, i, depth + 1, false, hash))
	}
	else if (node.subfilter && node.subquery)
	{
		node.focusTarget 	= node.subquery.uuid
	}
	else
	{
		node.focusTarget = focusTarget
	}
	

	hash.push( node.node_id )
	return { ...node }
}



export const normalizeFilter = (node) => 
{
	const hash = []
	const rv = normalizeFilterRun(node, "", "", 0, 0, false, hash )

	rv.hash = hash.join(":")
	return rv
}


export const removeFilterMetaData = (node) =>
{
	if (node == null)
		return null

	const {node_id, group, is_root, is_subfilter, focusTarget, 
				parentFocusTarget, depth, list, subquery, hash,  ...rest} = node

	if (rest.id == 'boolean')
		rest.list = node.list.map(n => removeFilterMetaData( n ))

	if (node.subquery)
		rest.subquery = removeFilterMetaData( node.subquery )

	return rest

}



export const findFilterInsertPoint = (node, table) => {
	if (node.table == table && node.id == 'boolean')
		return node

	if (node.id == 'boolean') {
		for (let f of node.list) {
			const r = findFilterInsertPoint(f, table)
			if (r)
				return r
		}
	}

	if (node.subquery) {
		const r = findFilterInsertPoint(node.subquery, table)
		if (r)
			return r
	}

	return {}
}



export const findFilterNode = ( node, node_id, parent = null ) => 
{
	if (node.node_id == node_id || node.uuid == node_id)
		return {node, parent}

	if (node.id == 'boolean')
	{
		for( let f of node.list )
		{
			const r = findFilterNode( f, node_id, node)
			if (r.node)
				return {node: r.node, parent:r.parent}
		}
	}

	if (node.subquery)
	{
		const r = findFilterNode(node.subquery, node_id, node )
		if (r.node)
			return { node: r.node, parent: r.parent }
	}

	return {}
}


export const instantiateFilterNode = (table, template) => 
{
	if (template.id == 'boolean')
	{
		return {
			table: table,
			group: "Boolean",
			active: true,
			id: 	"boolean",
			operator: "and",
			list: []
		}
	}

	const { parameters, full_id, ...filter } = template

	filter.active = true
	
	if (parameters)
	{
		parameters.forEach( p => 
		{
			if (p.default_value)
				filter[p.id] = p.default_value
		})
	}

	return filter
}


export const addTemplateToID = (filter, template, node_id ) => 
{
	const {node} = findFilterNode( filter, node_id )
	if (node)
	{
		const new_node = instantiateFilterNode( filter.table, template )
		node.list.push( new_node )
	}
	return normalizeFilter(filter)
}



export const initialSubFilter = ( filter, params) => 
{
}

export const toggleSubFilter = (filter, parameter, node_id ) =>
{
	let focusTarget = null


	const tgtNodeID = generateUUID()
	const {node} = findFilterNode(filter, node_id)
	if (node) 
	{

		console.log( "TOGGLE ON ", node)

		if (paramCanHaveSubfilter( parameter ))
			node.subfilter = node.subfilter !== true

		if (node.subfilter && !node.subquery) {
			node.subquery = {
				uuid: 		tgtNodeID,
				table: 		parameter.kind || node.table || 'boolean',
				active: 	true,
				id: 		"boolean",
				operator: 	"and",
				hidden: 	true,
				list: 		[]
			}
			node.indicator = parameter.kind
		}
		else
		{
			node.indicator = null
		}
	}

	const newFilter = normalizeFilter(filter)

	if (node)
	{
		const newNode 	= findFilterNode( newFilter, node.uuid ).node


		if (newNode.subfilter && newNode.subquery)
			focusTarget = newNode.subquery.uuid
		else
			focusTarget = newNode.parentFocusTarget || '0'

	}
	else
		focusTarget = '0'
	
	
	return {filter: newFilter, focusTarget}
}


export const toggleFilterExpansion = (filter, node_id) => 
{
	const { node } = findFilterNode(filter, node_id)
	if (node)
		node.expanded = !node.expanded



	return  normalizeFilter(filter)
}


export const removeFilterFromTree = (filter, node_id ) => 
{
	const { parent } = findFilterNode(filter, node_id)
	if (parent && parent.list)
		parent.list = parent.list.filter( n => n.node_id !== node_id )
	
	return normalizeFilter( filter )
}


export const transformFilterNode = (filter,node_id, fn ) =>
{
	const { node } = findFilterNode(filter, node_id)
	if (node && fn)
		fn( node )
	
	return normalizeFilter( filter )	
}



export const parameterToForm = ( template, filter, parameter, data ) =>
{
	const filter_value 	= filter[parameter.id] || parameter.default_value
	const base_id 		= `${filter.uuid}:${parameter.id}`
	const param_id 		= `P-${base_id}`
	const k 			= parameter.kind


	if (filter_value == null || filter_value == undefined)
	{
	}
	else if (k == 'int' || k == 'float' || k == 'time') 
	{
		if (isNum(filter_value.min))
			data[`${param_id}:min`] = filter_value.min
		if (isNum(filter_value.max))
			data[`${param_id}:max`] = filter_value.max

	}
	else if (k == 'timestamp') 
	{
		const ft = `${param_id}:date_kind`

		data[ft] 				= filter_value.date_kind
		data[`${param_id}:days`] = filter_value.days || '7'

		if (isNum(filter_value.from))
			data[`${param_id}:from`] = filter_value.from
		if (isNum(filter_value.to))
			data[`${param_id}:to`] 	= filter_value.to
	}				
	else if (k == 'boolean' && parameter.id == 'inverted')
	{
		data[param_id] 	= filter_value === true ? 'isnot' : 'is'
	}
	else
		data[param_id] = filter_value || parameter.default_value

	if (k == 'text' || k == 'keyword' || k == 'resource' || k == 'select')
	{
		const yn 	= `OP-${base_id}`
//		console.log("--- > Form ", yn, filter[`operator:${parameter.id}`])
		data[yn] 	= filter[`operator:${parameter.id}`] || 'or'
	}

}



export const filterToForm = (templates, filter, data = {}) => 
{
	if (filter == null)
		return

	data[`ACT-${filter.uuid}`] = filter.active !== false

	if (filter.id == 'boolean') 
	{
		data[`OP-${filter.uuid}`] = filter.operator
		filter.list.forEach(n => filterToForm(templates, n, data))
	}
	else {
		const template = templates[filter.table][filter.id]

		if (template?.parameters)
			template.parameters.forEach(p => parameterToForm( template, filter, p, data ))

		if (filter.subquery)
			filterToForm(templates, filter.subquery, data)
	}

	if (filter.is_subfilter && filter.subKind)
		data[`SK-${filter.uuid}`] = filter.subKind

	return data
}




export const formToFilterRun = (templates, filter, data) => 
{
	if (filter == null)
		return

	const node 		= {...filter}
	node.active 	= data[`ACT-${filter.uuid}`] !== false

	if (data[`SK-${filter.uuid}`] && node.is_subfilter)
		node.subKind = data[`SK-${filter.uuid}`]

	if (filter.id == 'boolean') 
	{
		const list = filter.list || []
		
		node.operator = 	data[`OP-${filter.uuid}`] || 'and',
		node.list = 		list.map(n => formToFilterRun(templates, n, data))

		return node
	}
	else {
		if (filter.subquery)
			node.subquery 	= formToFilterRun(templates, filter.subquery, data)
		else
			node.subfilter 	= false

		const template 		= templates[filter.table][filter.id]

		if (template?.parameters)
		{
			template.parameters.forEach(p => 
			{
				const pn 	= `P-${filter.uuid}:${p.id}`
				const k 	= p.kind

				if (k == 'int' || k == 'float' || k == 'time')
				{
					const rv = {}
					if (isNum(data[`${pn}:min`]) )
						rv.min = data[`${pn}:min`]
					if (isNum(data[`${pn}:max`]) )
						rv.max = data[`${pn}:max`]
					
					node[p.id] 	= rv
				}
				else if (k == 'timestamp') 
				{
					const ft = `${pn}:date_kind`
					const rv = {}

					rv.date_kind = data[ft]
					if (isNum(data[`${pn}:from`]))	rv.from = data[`${pn}:from`]
					if (isNum(data[`${pn}:to`]))	rv.to = data[`${pn}:to`]
					rv.days = "" + parseInt("" + data[`${pn}:days`], 10)

					node[p.id] = rv
				}				
				else if (p.id == 'inverted' && p.kind == 'boolean')
				{
					node.inverted = data[pn] == 'isnot'
				}
				else
				{
					node[p.id] = data[pn] || template.default_value
				}

				if (k == 'text' || k == 'keyword' || k == 'resource' || k == 'select') 
				{
					const yn = `OP-${filter.uuid}:${p.id}`
					if (data[yn])
						node[`operator:${p.id}`] = data[yn]
				}
			})
		}
		return node
	}



}


export const formToFilter = (templates, filter, data) => 
{
	const f = formToFilterRun(templates, filter, data)
	return normalizeFilter( f)
}




export const addFilterToGroup = (groups, group_list, filter, group_name = null) => 
{
	let group = filter.group
	if (group_name == '')
		group = ''
	
	if (group == null || group == undefined)
		group = group_name

	let filter_group = groups[group]
	if (!groups[group]) 
	{
		filter_group = 
		{
			title: group,
			filters: []
		}

		groups[group] = filter_group
		if (group == '')
			group_list.unshift(filter_group)
		else
			group_list.push(filter_group)
	}
	filter_group.filters.push(filter)
}